import * as types from "./types";
import { surveyInit } from "initials/survey.init";
import { ExploreActionType, ExploreState } from "./types";

const INITIAL_STATE: ExploreState = {
  surveyFree: surveyInit,
}

export const explore = (prevState = INITIAL_STATE, action: ExploreActionType) => {
  switch (action.type) {
    case types.SURVEY_FREE: 
      return { ...prevState, surveyFree: action.payload };

    default: 
      return prevState;
  }
}
