import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Grid, Paper, Button } from '@material-ui/core';
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import { servicesTable } from '../Constants';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  project: { marginBottom: 15 },
  header: {
    color: 'white',
    width: '100%',
    padding: '8px 15px',
    fontSize: 19,
    alignItems: 'center',
    borderRadius: '10px 10px 0 0',
    textTransform: 'uppercase',
  },
  table: { backgroundColor: 'white' },
  color: {
    '& .MuiTableCell-root': {
      fontWeight: 'bold',
      '& .MuiSvgIcon-root': { color: '#5dc90a' },
    },
    '&:nth-of-type(odd)': { backgroundColor: '#e8e8e8' },
  },
  btn: { 
    color: 'white', 
    borderRadius: '0 0 10px 10px',
  },
  link: {
    padding: 0,
    "& a": { 
      width: "100%", 
      color: "initial", 
      padding: "6px 8px",
      textDecoration: "none",
    },
  },
}));

interface IProps {
  text: string
  link?: string
  color: string
  title: string
  users: string
  adminUsers: string
  alamcenamiento: string
  onClick: () => void
}

const ProjectTable: FC<IProps> = ({
  text,
  link,
  color,
  title,
  users, 
  adminUsers, 
  alamcenamiento, 
  onClick,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid className={classes.project} container>
      <Paper className={classes.header} style={{backgroundColor: color}}>
        {t(title)}
      </Paper>
      <Table className={classes.table} size="small">
        <TableBody>
          <TableRow className={classes.color}>
            <TableCell align="center">
              {alamcenamiento} {t("de almacenamiento")}
            </TableCell>
          </TableRow>
          <TableRow className={classes.color}>
            <TableCell align="center">
              {users} {t("usuarios finales")}
            </TableCell>
          </TableRow>
          <TableRow className={classes.color}>
            <TableCell align="center">
              {adminUsers} {t("usuarios administradores")}
            </TableCell>
          </TableRow>
          {servicesTable.map((name) => (
            <TableRow key={name} className={classes.color}>
              <TableCell align="center">
                {t(name)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {link ? (
        <Button 
          className={clsx(classes.btn, classes.link)}
          variant="contained" 
          style={{backgroundColor: color}}
          fullWidth
        >
          <a href={link}>{t(text)}</a>
        </Button>
      ): (
        <Button 
          className={classes.btn}
          variant="contained" 
          style={{backgroundColor: color}}
          onClick={onClick}
          fullWidth
        >
          {t(text)}
        </Button>
      )}
      
    </Grid>
  )
}

export default ProjectTable;
