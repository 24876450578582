import { IUserAnonymous } from "dto/user-anonymous.dto";
import { IQuestion, IQuestionPreview } from "dto/question.dto";
import { questionInit, questionPreviewInit } from "./question.init";

export const anonymousUserInit: IUserAnonymous = {
  uuid: "",
  date: "",
  tasks: [],
  birthday: "",
  userType: "",
}

export const initQuestions: IQuestion[] = [{ ...questionInit }];
export const initPreviews: IQuestionPreview[] = [{ ...questionPreviewInit }];

export const mainQuestions: IQuestion[] = [
  { 
    ...questionInit,
    type: -1,
    question: "¿Cuál es tu fecha de nacimiento?",
    subtitle: "¡Empecemos!",
    description: "Llena los campos",
    header_color: "#ffebde",
    image_header: "/gif/question/ola_neutral.gif",
    background_image: "/img/background/clase_06.png",
  },
  {
    ...questionInit,
    type: 6,
    question: "¿Cuál es tu nivel de educación?",
    subtitle: "Opción múltiple",
    description: "Respuesta única",
    header_color: "#ffebde",
    image_header: "/gif/question/ola_tomando_cafe.gif",
    background_image: "/img/background/entorno_b2.png",
    answers: [
      "Básica primaria", 
      "Básica secundaria", 
      "Educación superior", 
      "Posgrado",
      "Ninguna de las anteriores",
    ]
  },
  {
    ...questionInit,
    type: 6,
    question: "Actualmente, yo...",
    subtitle: "Opción múltiple",
    description: "Respuesta única",
    header_color: "#ffebde",
    image_header: "/gif/question/ola_tablet.gif",
    background_image: "/img/background/minas_02.png",
    answers: [
      "Estudio", 
      "Trabajo", 
      "Estoy buscando estudiar o trabajar",
    ]
  },
];
export const mainPreviews: IQuestionPreview[] = [
  { 
    ...questionPreviewInit,
    type: -1,
    image_header: "/gif/question/ola_neutral.gif",
    background_image: "/img/background/clase_06.png",
  },
  {
    ...questionPreviewInit,
    type: 6,
    image_header: "/gif/question/ola_tomando_cafe.gif",
    background_image: "/img/background/entorno_b2.png",
  },
  {
    ...questionPreviewInit,
    type: 6,
    image_header: "/gif/question/ola_tablet.gif",
    background_image: "/img/background/minas_02.png",
  },
];
  
export const studentsQuestions: IQuestion[] = [
  { 
    ...questionInit,
    type: 14,
    question: "Escoge tus intereses",
    subtitle: "Opción múltiple",
    description: "Múltiple respuesta",
    header_color: "#ffebde",
    image_header: "/gif/question/ola_lupa.gif",
    background_image: "/img/background/entorno_02.png",
    answers: [
      "Liderazgo", 
      "Emprendimiento", 
      "Desarrollo de carrera", 
      "Orientación vocacional",
      "Tecnología",
      "Conocimiento general",
      "Conseguir un empleo"
    ],
  },
];
export const studentsPreviews: IQuestionPreview[] = [
  { 
    ...questionPreviewInit,
    type: 14,
    image_header: "/gif/question/ola_lupa.gif",
    background_image: "/img/background/entorno_02.png",
  },
];
  
export const employeesQuestions: IQuestion[] = [
  { 
    ...questionInit,
    type: 14,
    question: "¿A qué área laboral perteneces?",
    subtitle: "Opción múltiple",
    description: "Múltiple respuesta",
    header_color: "#ffebde",
    image_header: "/gif/question/ola_neutral.gif",
    background_image: "/img/background/entorno_02.png",
    answers: [
      "Comercial", 
      "Operaciones", 
      "Recursos humanos", 
      "Tecnología",
      "Gestión de proyectos",
      "Marketing y comunicaciones",
      "Responsabilidad social",
      "Ninguna de las anteriores",
    ],
  },
  { 
    ...questionInit,
    type: 6,
    question: "¿En qué tipo de organización trabajas?",
    subtitle: "Opción múltiple",
    description: "Respuesta única",
    header_color: "#ffebde",
    image_header: "/gif/question/ola_lupa.gif",
    background_image: "/img/background/clase_03.png",
    answers: [
      "Empresa privada", 
      "Entidad sin ánimo de lucro",
      "Institución educativa",
      "Entidad pública",
      "Startup",
    ],
  },
  { 
    ...questionInit,
    type: 6,
    question: "¿Qué te describe mejor?",
    subtitle: "Opción múltiple",
    description: "Respuesta única",
    header_color: "#ffebde",
    image_header: "/gif/question/ola_tomando_cafe.gif",
    background_image: "/img/background/entorno_b1.png",
    answers: [
      "Miembro de equipo", 
      "Lider de equipo",
      "Empresario",
      "Freelancer",
      "Director / VP",
    ],
  },
  { 
    ...questionInit,
    type: 6,
    question: "¿Cuántos integrantes tiene tu organización?",
    subtitle: "Opción múltiple",
    description: "Respuesta única",
    header_color: "#ffebde",
    image_header: "/gif/question/ola_tablet.gif",
    background_image: "/img/background/minas_03.png",
    answers: [
      "1 - 30", 
      "31 - 100", 
      "101 - 500", 
      "501 - 1000", 
      "1000+", 
    ],
  },
  { 
    ...questionInit,
    type: 14,
    question: "Escoge tus intereses",
    subtitle: "Opción múltiple",
    description: "Respuesta múltiple",
    header_color: "#ffebde",
    image_header: "/gif/question/ola_neutral.gif",
    background_image: "/img/background/entorno_02.png",
    answers: [
      "Habilidades blandas", 
      "Gestión de proyectos",
      "Gestión de equipos", 
      "Desarrollo de carrera", 
    ],
  },
];
export const employeesPreviews: IQuestionPreview[] = [
  { 
    ...questionPreviewInit,
    type: 14,
    image_header: "/gif/question/ola_neutral.gif",
    background_image: "/img/background/entorno_02.png",
  },
  { 
    ...questionPreviewInit,
    type: 6,
    image_header: "/gif/question/ola_lupa.gif",
    background_image: "/img/background/clase_03.png",
  },
  { 
    ...questionPreviewInit,
    type: 6,
    image_header: "/gif/question/ola_tomando_cafe.gif",
    background_image: "/img/background/entorno_b1.png",
  },
  { 
    ...questionPreviewInit,
    type: 6,
    image_header: "/gif/question/ola_tablet.gif",
    background_image: "/img/background/minas_03.png",
  },
  { 
    ...questionPreviewInit,
    type: 14,
    image_header: "/gif/question/ola_neutral.gif",
    background_image: "/img/background/entorno_02.png",
  },
];
