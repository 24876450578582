import React, { FC } from "react";
import BackgroundImage from "components/Common/BackgroundImage";
import BackgroundVideo from "components/Common/BackgroundVideo";

interface IProps {
  backgroundGif?: boolean;
  backgroundImage?: string;
  backgroundVideo?: string;
  backgroundVideoAudio?: boolean;
  backgroundVideoAutoplay?: boolean;
}

const Background: FC<IProps> = (props) => {
  return (!!props.backgroundVideo ? (
    <BackgroundVideo 
      video={props.backgroundVideo} 
      audio={props.backgroundVideoAudio} 
      autoplay={props.backgroundVideoAutoplay}
    />
  ) : (
    <BackgroundImage 
      gif={props.backgroundGif} 
      image={props.backgroundImage} 
    />
  ));
}

export default Background;
