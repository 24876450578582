import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Grid, Typography, Button } from '@material-ui/core';
import { IDonation } from '../../services/checkout.services';

const useStyles = makeStyles((theme) => ({
  thank: { height: 'calc(100% - 20px)' },
  header: { padding: 13, borderBottom: '1px solid darkgray' },
  content: { 
    height: 'calc(100% - 100px)', 
    padding: 30,
    [theme.breakpoints.down("xs")]: { minHeight: 300 },
  },
  text: { color: '#7a7a7a', fontWeight: 700 },
  footer: { padding: '0 15px' },
  btn: { 
    color: 'white', 
    backgroundColor: '#ff6315', 
    '&:hover': { backgroundColor: 'white', color: '#ff6315' },
  },
}));

interface IProps { 
  donation: IDonation
  finalizeDonation: () => void
}

const ThankYou: FC<IProps> = ({donation, finalizeDonation}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid className={classes.thank} container>
      <Grid className={classes.header} container justifyContent="center" alignItems="center">
        <Typography variant="h6" component="div" color="textSecondary">
          {t("¡Gracias por tu ayuda!")}
        </Typography>
      </Grid>

      <Grid className={classes.content} container justifyContent="center" alignItems="center">
        <Typography className={classes.text} variant="h5" align="center">
          {t("Gracias por tu donación de")} ${donation.amount} {donation.currency === "USD" ? t("Dólares") : t("Pesos")} 
        </Typography>
      </Grid>

      <Grid className={classes.footer} container justifyContent="center" alignItems="stretch">
        <Button className={classes.btn} variant="contained" onClick={finalizeDonation} fullWidth>
          {t("Finalize")}
        </Button>
      </Grid>
    </Grid>
  )
}

export default ThankYou;
