const svgImage = (c1: String) => `
  <svg width="76" height="30" viewBox="0 0 76 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.8705 20.755C25.7343 19.5716 27.0952 16.2937 25.9 13.4299C24.7166 10.5662 21.4387 9.2053 18.5749 10.3887C15.7112 11.572 14.3503 14.8618 15.5455 17.7137C16.7289 20.5775 20.0068 21.9383 22.8705 20.7431"
      fill="${c1}"
    />
    <path
      d="M20.5276 23.8908C15.8533 23.8908 12.0665 20.104 12.0665 15.4297C12.0665 10.7554 15.8533 6.96861 20.5276 6.96861C25.2019 6.96861 28.9886 10.7554 28.9886 15.4297C28.9886 20.104 25.2019 23.8908 20.5276 23.8908ZM34.7398 14.2582C34.7398 14.2582 34.6925 14.1635 34.6451 14.1635C31.8405 10.1401 20.8116 5.70243 19.2614 5.09891C19.072 5.05158 18.93 4.90957 18.8472 4.76757L18.7998 4.72023C18.7052 4.53089 18.7052 4.34155 18.8472 4.16404C19.1312 3.78537 19.3087 3.32386 19.2259 2.76768C19.1312 1.88015 18.433 1.13464 17.5455 1.03997C16.3739 0.897971 15.3917 1.83282 15.3917 2.95702V3.28836C15.3917 5.48942 13.6167 7.21715 11.463 7.21715C9.49859 7.21715 7.91288 5.82076 7.58154 3.95105C7.15553 2.12866 5.47514 0.815137 3.51076 1.00448C1.64104 1.14648 0.102636 2.68486 0.00796644 4.55457C-0.134038 6.80297 1.64104 8.72001 3.8421 8.72001H4.26809C6.18515 8.72001 7.72354 10.2584 7.72354 12.1755C7.72354 14.0925 6.27982 15.5007 4.49293 15.5835C3.55808 15.7255 2.81257 16.5184 2.81257 17.5006C2.81257 18.5775 3.65276 19.4177 4.72962 19.4177H4.91897C5.1083 19.4177 5.25029 19.465 5.38046 19.607C5.52246 19.7963 5.52246 19.9856 5.52246 19.9856C5.52246 19.9856 5.56979 20.6365 5.66446 21.2992C5.71179 21.9027 6.17332 25.7842 6.26798 26.6243C6.36265 27.7485 7.20286 28.6834 8.27972 28.9201C10.1968 29.3461 13.1433 28.1746 13.1433 28.1746L21.6044 24.8611C29.9708 21.2637 33.5209 17.7609 34.5031 16.6367C34.5978 16.5421 34.6925 16.4001 34.7871 16.3054C34.9765 16.0214 35.0711 15.6545 35.0711 15.2759C35.0711 14.8972 34.9291 14.5777 34.7398 14.2937"
      fill="${c1}"
    />
    <path
      d="M50.6325 21.3703C48.0173 21.3703 45.9109 19.264 45.9109 16.6487C45.9109 14.0335 48.0173 11.9271 50.6325 11.9271C53.2477 11.9271 55.3541 14.0335 55.3541 16.6487C55.3541 19.264 53.2477 21.3703 50.6325 21.3703ZM50.0763 8.8385C46.2422 9.12251 43.1063 12.1993 42.8341 16.0807C42.5028 20.6603 46.112 24.4471 50.6443 24.4471C52.0407 24.4471 53.4016 24.0684 54.5731 23.4176C54.5731 23.9738 55.0346 24.3998 55.5553 24.3998H57.425C57.9812 24.3998 58.4072 23.9264 58.4072 23.4176V16.6369C58.4546 12.1519 54.6678 8.55448 50.0882 8.82665"
      fill="${c1}"
    />
    <path
      d="M39.8758 1.02859H38.0061C37.45 1.02859 37.0239 1.50193 37.0239 2.01077V23.3706C37.0239 23.9267 37.4855 24.3527 38.0061 24.3527H39.8758C40.432 24.3527 40.8581 23.8794 40.8581 23.3706V2.01077C40.8581 1.50193 40.432 1.02859 39.8758 1.02859Z"
      fill="${c1}"
    />
    <path
      d="M68.2056 21.3705C65.5904 21.3705 63.484 19.2641 63.484 16.6489C63.484 14.0336 65.5904 11.9272 68.2056 11.9272C70.8208 11.9272 72.9272 14.0336 72.9272 16.6489C72.9272 19.2641 70.7735 21.3705 68.2056 21.3705ZM75.9685 16.0927C75.6845 12.2586 72.6078 9.16999 68.821 8.85048C67.1879 8.70847 65.5904 9.13448 64.2887 9.88V2.02243C64.2887 1.46625 63.8153 1.04025 63.3065 1.04025H61.3894C60.8332 1.04025 60.4072 1.50175 60.4072 2.02243V23.3822C60.4072 23.9384 60.8806 24.3644 61.3894 24.3644H63.3065C63.8626 24.3644 64.2887 23.8911 64.2887 23.3822C65.4602 24.0331 66.7619 24.4117 68.1701 24.4117C72.7024 24.4591 76.2998 20.6723 75.9803 16.0927"
      fill="${c1}"
    />
  </svg>
`;

export const SvgOLab02 = (c1?: string) => {
  const a = !!c1 ? c1 : "#ff6300";
  return `data:image/svg+xml;charset=UTF-8;base64,${btoa(svgImage(a))}`;
};

export default SvgOLab02;
