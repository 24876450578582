import React, { FC } from "react";
import { ILaboratory, ILaboratoryCongratulations } from "dto/laboratory.dto";
import SuccessHeader from "./SuccessHeader";
import SuccessContent from "./SuccessContent";

interface IProps {
  rate: number;
  laboratory: ILaboratory;
  languageAudio: string;
  congratulations: ILaboratoryCongratulations;
  saveUserTask: () => void;
  handleAnalytics: (name: string, params?: { [key: string]: string; }) => void;
}

const LaboratoryCongratulations: FC<IProps> = (props) => {
  const audioSrc = props.rate > 2 && props.languageAudio === "1"
    ? props.congratulations?.complete_audio_sl
    : props.rate > 2 && props.languageAudio !== "1" 
    ? props.congratulations?.complete_audio_fl 
    : props.languageAudio === "1" 
    ? props.congratulations?.incomplete_audio_sl
    : props.congratulations?.incomplete_audio_fl;
  const subtitle = props.rate > 2 ? "" : "Curso no completado";

  const saveUserTask = () => {
    props.saveUserTask();
    props.handleAnalytics("perform_laboratory");
  } 

  return (
    <>
      <SuccessHeader 
        rate={props.rate} 
        subtitle={subtitle}
        primaryColor={props.laboratory?.menu_color}
        congratulations={props.congratulations} 
      />
      <SuccessContent
        rate={props.rate}
        primaryColor={props.laboratory?.menu_color}
        challengerLast
        congratulations={props.congratulations}
        saveUserTask={saveUserTask} 
      />
      <audio src={audioSrc} autoPlay>
        Your browser does not support the <code>audio</code> element.
      </audio>
    </>
  )
}

export default LaboratoryCongratulations;
