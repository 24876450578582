import React, { FC } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Stage, Layer } from "react-konva";
import { ITemplate } from "dto/template.dto";
import StageBackground from "./StageBackground";
import TemplateItemGif from "./TemplateItemGif";
import TemplateItemText from "./TemplateItemText";
import TemplateItemImage from "./TemplateItemImage";
import TemplateItemTextReadOnly from "./TemplateItemTextReadOnly";
import TemplateItemShapeSingleColor from "./TemplateItemShapeSingleColor";
import TemplateItemShapeVariousColor from "./TemplateItemShapeVariousColor";

const useStyles = makeStyles({ template: { overflow: "auto" } });

interface IProps {
  template: ITemplate;
  userName?: string;
  entityName?: string;
  laboratoryName?: string;
}

const TemplateStage: FC<IProps> = (props) => {
  const classes = useStyles();

  return (
    <Grid className={classes.template} container>
      <Stage
        ref={props.template?.ref}
        width={props.template.width}
        height={props.template.height}
      >
        <Layer>
          <StageBackground
            fill={props.template.fill}
            width={props.template.width}
            height={props.template.height}
          />
          {props.template?.template_items?.map((templateItem, key) =>
            templateItem.type === "shape-single-color" ? (
              <TemplateItemShapeSingleColor key={key} templateItem={templateItem} />
            ) : templateItem.type === "shape-various-color" ? (
              <TemplateItemShapeVariousColor key={key} templateItem={templateItem} />
            ) : templateItem.type === "text/text" ? (
              <TemplateItemText key={key} templateItem={templateItem} />
            ) : templateItem.type === "text/read-only" ? (
              <TemplateItemTextReadOnly 
                key={key}
                userName={props.userName}
                entityName={props.entityName}
                templateItem={templateItem}
                laboratoryName={props.laboratoryName}
              />
            ) : templateItem.type === "image/gif" ? (
              <TemplateItemGif key={key} templateItem={templateItem} />
            ) : templateItem.type === "image/png" ? (
              <TemplateItemImage key={key} templateItem={templateItem} />
            ) : (
              <React.Fragment key={key}></React.Fragment>
            )
          )}
        </Layer>
      </Stage>
    </Grid>
  );
};

export default TemplateStage;
