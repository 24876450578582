import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Grid, Typography, Button } from '@material-ui/core';
import { TextField, InputAdornment } from '@material-ui/core';
import { IDonation } from '../../services/checkout.services';
import Amounts from './Amounts';
import Currencies from './Currencies';

const useStyles = makeStyles(() => ({
  quantities: { height: 'calc(100% - 20px)' },
  header: { padding: 13, borderBottom: '1px solid darkgray' },
  content: { height: 'calc(100% - 100px)', padding: 15 },
  footer: { padding: '0 15px' },
  accept: { 
    color: 'white',
    backgroundColor: '#ff6315', 
    '&:hover': { backgroundColor: 'white', color: '#ff6315' },
  },
}));

interface IProps {
  donation: IDonation
  saveDonation: () => void
  updateDonation: (value: IDonation) => void
}

const Quantities: FC<IProps> = ({donation, saveDonation, updateDonation}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const usdAmounts = [1, 5, 10, 20, 50, 100, 150, 200, 300];
  const copAmounts = [10000, 50000, 100000, 200000, 500000, 1000000, 1500000, 2000000,3000000];
  const [amounts, setAmounts] = React.useState(usdAmounts);
  const [fieldAmount, setFieldAmount] = React.useState(0);

  const changeCurrency = (currency: "USD" | "COP") => {
    if (currency === "USD") setAmounts(usdAmounts);
    if (currency === "COP") setAmounts(copAmounts);
    setFieldAmount(0);
    updateDonation({...donation, amount: 0, currency});
  }

  const selectAmount = (amount: number) =>{
    updateDonation({...donation, amount});
    setFieldAmount(0);
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldAmount(Number(e.target.value));
    if (!e.target.value) updateDonation({...donation, amount: 0});
    else updateDonation({...donation, amount: Number(e.target.value)});
  }
  const onKeyPress = (e: {key: string, preventDefault: () => void}) => {
    const reg = new RegExp('^[0-9]+$');
    if (!reg.test(e.key)) e.preventDefault();
  }

  return (
    <Grid className={classes.quantities} container>
      <Grid className={classes.header} item xs={12}>
        <Typography variant="h6" align="center" color="textSecondary">
          {t("Elige cómo quieres ayudar")}
        </Typography>
      </Grid>
      
      <Grid className={classes.content} container>
        <Currencies currency={donation.currency} changeCurrency={changeCurrency} />

        <Grid container alignItems="center">
          <Amounts 
            amounts={amounts} 
            amount={donation.amount} 
            selectAmount={selectAmount} 
          />
        </Grid>
        
        <Grid container alignItems="flex-end">
          <TextField 
            size="small"
            value={fieldAmount ? fieldAmount : ""}
            variant="outlined"
            onChange={onChange}
            onKeyPress={onKeyPress}
            label={t("Cantidad personalizada")}
            InputProps={{
              endAdornment:(
                <InputAdornment position="end">
                  {donation.currency === "USD" ? "$USD" : "$COP"}
                </InputAdornment>
              )
            }}
            fullWidth 
          />
        </Grid>
      </Grid>
      
      <Grid className={classes.footer} container alignItems="center">
        <Button 
          className={classes.accept} 
          variant="contained" 
          onClick={saveDonation}
          fullWidth
        >
          {t("Continuar")}
        </Button>
      </Grid>
    </Grid>
  )
}

export default Quantities;
