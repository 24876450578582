import { IChallenger } from "dto/challenger.dto";
import { IStep, IStepFiles, IAudioPdf, stepFilesInit } from "dto/step.dto";
import * as utils from "utils";

export const getStepsFiles = async (
  challenger: IChallenger, 
  path01: string, 
  path02: string,
) => {
  const steps = challenger.steps ?? [];
  const stepsFiles: IStepFiles[] = [];
  try {
    for (let i = 0; i < steps.length; i++) {
      stepsFiles.push({
        ...stepFilesInit,
        gif: await utils.downloadFile(path02, steps[i].gif),
        type: getStepType(steps[i]),
        audio: await getStepAudio(steps[i], i, path01, path02, challenger.audio),
        pdf_web: await getStepPdf(steps[i], path02),
        audio_wa: await getStepAudioWa(steps[i], i, path01, path02, challenger.audio_wa),
        video_url: await getStepVideo(steps[i], path02),
        audio_pdf: await getStepAudioPdf(steps[i], path02),
      });
    }
    return stepsFiles;
  } catch (error) {
    return [];
  }
}

const getStepPdf = async (step: IStep, path: string) => {
  try {
    if (!!step.pdf_web) {
      const pdf_web = await utils.downloadFile(path, step.pdf_web);
      return pdf_web;
    } else if (!!step.pdf) {
      const pdf = await utils.downloadFile(path, step.pdf);
      return pdf;
    } else return "";
  } catch (error) {
    return "";
  }
}
const getStepType = (step: IStep) => {
  if (!!step.video_url || !!step.video) return 1;
  else if (!!step.pdf_web || !!step.pdf) return 2;
  else return 0;
}
const getStepAudio = async (
  step: IStep, 
  index: number,
  path01: string, 
  path02: string, 
  challengerAudio: string,
) => {
  try {
    if (
      !index && 
      (step.audio === "" || step.audio === "audio_default.ogg") &&
      !!challengerAudio && challengerAudio !== "audio.ogg"
    ) {
      const audio = await utils.downloadFile(path01, challengerAudio);
      return audio;
    } else {
      const audio = await utils.downloadFile(path02, step.audio);
      return audio;
    }
  } catch (error) {
    return "";
  }
}
const getStepVideo = async (step: IStep, path: string) => {
  try {
    if (step.video_type && !!step.video) {
      const video = await utils.downloadFile(path, step.video);
      return video;
    } else if (!!step.video_url) return step.video_url;
    else if (!!step.video) {
      const video = await utils.downloadFile(path, step.video);
      return video;
    } else return "";
  } catch (error) {
    return "";
  }
}
const getStepAudioWa = async (
  step: IStep, 
  index: number,
  path01: string, 
  path02: string, 
  challengerAudioWa: string,
) => {
  try {
    if (
      !index && 
      (step.audio_wa === "" || step.audio_wa === "audio_default.ogg") &&
      !!challengerAudioWa && challengerAudioWa !== "audio_wa.ogg"
    ) {
      const audio_wa = await utils.downloadFile(path01, challengerAudioWa);
      return audio_wa;
    } else {
      const audio_wa = await utils.downloadFile(path02, step.audio_wa);
      return audio_wa;
    }
  } catch (error) {
    return "";
  }
}
const getStepAudioPdf = async (step: IStep, path: string) => {
  try {
    const audioPdf = step.audio_pdf;
    const audioPdfAux: IAudioPdf[] = []; 
    if (!!audioPdf && !!audioPdf.length) {
      for (let i = 0; i < audioPdf.length; i++) {
        if (audioPdf[i]) {
          audioPdfAux.push({
            audio: await utils.downloadFile(path, audioPdf[i].audio),
            audio_wa: await utils.downloadFile(path, audioPdf[i].audio_wa),
          })
        }
      }
      return audioPdfAux;
    } else return [];
  } catch (error) {
    return [];
  }
}
