import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, Grid, Typography } from "@material-ui/core";
import { IQuestion, IQuestionPreview } from "dto/question.dto";
import Types from "components/Question/QuestionTypes";
import Information from "components/Question/QuestionInformation/Information";

const useStyles = makeStyles((theme) => ({
  root: { 
    height: "100%", 
    [theme.breakpoints.down("xs")]: { 
      overflow: "auto",
      "&::-webkit-scrollbar": { width: 8 },
      "&::-webkit-scrollbar-track": { backgroundColor: "white" },
      "&::-webkit-scrollbar-thumb": { backgroundColor: "#c1c1c1", borderRadius: 8 },
    } 
  },
  content: {
    height: "100%", 
    flexWrap: "initial", 
    [theme.breakpoints.down("xs")]: { 
      height: "fit-content",
      flexWrap: "inherit",
    } 
  },
  divider: { 
    width: 2, 
    backgroundColor: "#f0f0f0",
    [theme.breakpoints.down("xs")]: { display: "none" }, 
  },
  text: {
    "& p": { fontFamily: "inherit !important" },
    "& span": { fontFamily: "inherit !important" },
  },
}));

interface IProps {
  file: { file: File; path: string; };
  zoom?: boolean;
  book?: boolean;
  answer: string;
  preview: IQuestionPreview;
  question: IQuestion;
  answerRating: boolean;
  viewFeedback: boolean;
  languageAudio: string;
  questionNumber: number;
  questionsTotal: number;
  progressBarColor: string;
  selectFile: (file: File, path: string) => void;
  selectAnswer: (answer: string) => void;
}

const QuestionContent: FC<IProps> = (props) => {
  const classes = useStyles();

  const getQuestionText = (t: string) => {
    return <span dangerouslySetInnerHTML={{__html: t}} />;
  }

  return (
    <Grid className={classes.root} container>
      <Grid className={classes.content} container>
        <Information 
          preview={props.preview}
          question={props.question}
          answerRating={props.answerRating}
          languageAudio={props.languageAudio}
          viewFeedback={props.viewFeedback}
          questionNumber={props.questionNumber}
          questionsTotal={props.questionsTotal}
          progressBarColor={props.progressBarColor}
        >
          {props.question.type !== 3 && (
            <Typography className={classes.text}>
              {getQuestionText(props.question.question)}
            </Typography>
          )}
        </Information>

        <Divider className={classes.divider} orientation="vertical" flexItem />

        <Types
          file={props.file}
          zoom={props.zoom} 
          answer={props.answer}
          preview={props.preview}
          question={props.question}
          answerRating={props.answerRating}
          viewFeedback={props.viewFeedback}
          selectFile={props.selectFile}
          selectAnswer={props.selectAnswer}
        />
      </Grid>
    </Grid>
  )
}

export default QuestionContent;
