import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import FormButton from "components/Form/FormButton";
import FormInputCode from "components/Form/FormInputCode";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  h5: { 
    color: "#5f5f5f", 
    textAlign: "center", 
    fontWeight: 700,
  },
  subtitle: { 
    color: "#929292", 
    fontSize: 18, 
    textAlign: "center",
    fontWeight: 500, 
    marginBottom: 16,
  },
  caption: {
    color: "#a9a9a9",
    display: "block",
    textAlign: "center",
    fontWeight: 500,
  },
}));

interface IProps {
  entityCode: string;
  sendEntityCode: () => void;
  updateEntityCode: (code: string) => void;
  registerWithEmail: () => void;
}

const EntityCodeForm: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography className={classes.h5} variant="h5">
          {t("¿Tienes un código organizacional?")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.subtitle}>
          {t("Si tu organización te compartió un código de O-Lab ingrésalo en el siguiente campo, de lo contrario salta este paso")}.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormInputCode onChange={props.updateEntityCode} />
      </Grid>
      <Grid item xs={12}>
        <FormButton 
          primary 
          onClick={props.sendEntityCode}
          disabled={props.entityCode.length !== 6}
          fullWidth
        >
          {t("Unirme a mi organización")}
        </FormButton>
      </Grid>
      <Grid item xs={12}>
        <FormButton onClick={props.registerWithEmail} fullWidth>
          {t("Saltar, no tengo código")}
        </FormButton>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.caption} variant="caption">
          {t("Si todavía no tienes el código organizacional, después lo puedes ingresar desde tu perfil")}.
        </Typography>
      </Grid>
    </Grid>
  )
}

export default EntityCodeForm;
