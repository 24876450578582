export const languaje_EN = {
  translation: {
    Curso:"Course",

    "¡Curso completado!":"Course completed!",
    "¡Unidad terminada!":"Unit finished!",
    "¡Módulo completado!":"Module completed!",
    "Curso no completado":"Course not completed",
    "Unidad no completada":"Unit not completed",
    "Módulo no completado":"Module not completed",
    "Dale continuar para avanzar":"Click continue to advance",
    "Avanza a la siguiente unidad":"Advance to the next unit",
    "Has desbloqueado la siguiente unidad":"You have unlocked the next unit",
    "Ya has completado el curso satisfactoriamente":"You have already completed the course satisfactorily",

    Estudio:"Study",
    Trabajo:"Job",
    Startup:"Startup",
    unidades:"units",
    Posgrado:"Postgraduate",
    Comercial:"Comercial",
    Liderazgo:"Leadership",
    Tecnología:"Technology",
    Freelancer:"Freelancer",
    Empresario:"Businessman",
    Operaciones:"Operations",
    Emprendimiento:"Entrepreneurship",
    "Director / VP":"Director / VP",
    "Lider de equipo":"Team leader",
    "Entidad pública":"Public entity",    
    "Empresa privada":"Private company",
    "Básica primaria":"Elementary school",
    "Recursos humanos":"Human Resources",
    "Básica secundaria":"Basic high school",
    "Miembro de equipo":"Team member",
    "Educación superior":"Higher education",
    "Actualmente, yo...":"Actually me...",
    "Gestión de equipos":"Team management",
    "Conseguir un empleo":"Getting a job",
    "Habilidades blandas":"Soft skills",
    "Gestión de proyectos":"Project management",
    "Conocimiento general":"General knowledge",
    "Escoge tus intereses":"Choose your interests",
    "Desarrollo de carrera":"Career development",
    "Institución educativa":"Educational institution",
    "Responsabilidad social":"Social responsability",
    "Orientación vocacional":"Vocational orientation",
    "¿Qué te describe mejor?":"What describes you best?",
    "Ninguna de las anteriores":"None of the above",
    "Entidad sin ánimo de lucro":"Non-profit entity",
    "Marketing y comunicaciones":"Marketing and communications",
    "¿A qué área laboral perteneces?":"What area of work do you belong to?",
    "¿Cuál es tu nivel de educación?":"What is your level of education?",
    "¿Cuál es tu fecha de nacimiento?":"What is your birth date?",
    "Estoy buscando estudiar o trabajar":"I am looking to study or work",
    "¿En qué tipo de organización trabajas?":"What type of organization do you work for?",
    "¿Cuántos integrantes tiene tu organización?":"How many members does your organization have?",

    Idioma:"Language",
    Ingles:"English",
    Español:"Spanish",
    "¡Hola!":"Hello!",
    "¡Todo listo!":"All ready!",
    "Súper administrador":"Super admin",
    "¡Bienvenido a O-lab!":"Welcome to O-lab!",
    "¡Ya casi terminamos!":"We are almost done!",
    "Crea tu perfil ahora":"Create your profile now",
    "¿Salir del laboratorio?":"Get out of the lab?",
    "¿Tienes un código organizacional?":"Do you have an organizational code?",
    "Configura el idioma de esta plataforma":"Set the language of this platform",
    "Si te sales del laboratorio perderás el progreso guardado hasta el momento":"If you leave the laboratory you will lose the progress saved so far",
    "Te recomendamos crear una nueva cuenta para tener tu propio perfil, además de guardar el progreso de tus contenidos, compartir en la comunidad, chatear y mucho más":"We recommend that you create a new account to have your own profile, as well as save the progress of your content, share in the community, chat and much more",

    Hola:"Hello",
    Omitir:"Skip",
    Explora:"Explore",
    Comenzar:"Start",
    "Ver curso":"See course",
    "Todo listo":"All ready",
    "Busca labs":"Search labs",
    "¡Empecemos!":"Let us begin!",
    "Elige un país":"Choose a country",
    "Elige tu país":"Choose your country",
    "Ver formulario":"See form",
    "Solicitar demo":"Request demo",
    "Nombre completo":"Full name",
    "Respuesta única":"Single answer",
    "Crea una cuenta":"Create an account",
    "Elige tu género":"Choose your gender",
    "Ya tengo cuenta":"I already have an account",
    "Crear cuenta con":"Create account with",
    "Llena los campos":"Fill in the fields",
    "Ingresa tu correo":"Enter your email",
    "Ingresa tu nombre":"Enter your name",
    "Antes de comenzar":"Before starting",
    "Bienvenido a O-lab":"Welcome to O-lab",
    "Completa tus datos":"Complete your data",
    "Múltiple respuesta":"Multiple answer",
    "Ya casi terminamos":"We are almost done",
    "Edad minima 7 años":"Minimum age 7 years",
    "Mínimo 6 caracteres":"Min 6 characters",
    "Repite tu contraseña":"Repeat your password",
    "Prefiero no responder":"Prefer not to answer",
    "Saltar, no tengo código":"Skip, I have no code",
    "Unirme a mi organización":"Join my organization",
    "Fecha de nacimiento incorrecta":"Wrong date of birth",
    "Labs libres ofrecidos por O-lab":"Free labs offered by O-lab",
    "Tienes un código organizacional":"Do you have an organizational code",
    "El código ingresado no es válido":"The code entered is invalid",
    "Ahora haces parte de la organización":"Now you are part of the organization",
    "Necesitamos saber un poco más sobre ti":"We need to know a little more about you",
    "Personaliza O-lab para tu organización":"Customize O-lab for your organization",
    "Todavía no perteneces a una organización":"You don't belong to an organization yet",
    "ya tienes acceso a su contenido y comunidad en O-lab":"you already have access to its content and community at O-lab",
    "Lleva tus entrenamientos, inducciones, comunicaciones y más al siguiente nivel":"Take your trainings, inductions, communications and more to the next level",
    "Si todavía no tienes el código organizacional, después lo puedes ingresar desde tu perfil":"If you still do not have the organizational code, you can enter it later from your profile",
    "Responde este corto cuestionario para poder empezar a explorar el contenido gratutito ofrecido por O-lab":"Take this short quiz to start exploring the free content offered by O-lab",
    "Si tu organización te compartió un código de O-Lab ingrésalo en el siguiente campo, de lo contrario salta este paso":"If your organization shared an O-Lab code with you, enter it in the next field, otherwise skip this step",

    o:"or",
    País:"Country",
    Falso:"False",
    Ninguno:"None",
    Primaria:"Primary",
    Verdadero:"True",
    Finalizar:"Finalize",
    Incorrecta:"Incorrect",
    Formulario: "Form",
    Secundaria:"High school",
    "Idioma urdu":"Urdu language",
    "Cargar audio":"Upload sound",
    "Grabar audio":"Record audio",
    "Continúa asi":"Keep it up",
    "Configuración":"Settings",
    "Editar género":"Edit gender",
    "Cargar imagen":"Upload image",
    "¡Felicidades!": "Congratulations!",
    "Idioma inglés":"English language",
    "Idioma tagalo":"Tagalog language",
    "Idioma español":"Spanish language",
    "Idioma francés":"French language",
    "Felicitaciones":"Congratulations",
    "Notificaciones":"Notifications",
    "Opción múltiple":"Multiple choice",
    "No te descuides":"Don't neglect",
    "Nueva contraseña":"New password",
    "Verdadero y falso":"True and false",
    "Guardar grabación":"Save recording",
    "Detener grabación":"Stop recording",
    "Iniciar grabación":"Start recording",
    "Respuesta Correcta":"Correct Answer",
    "Seleccionar idioma":"Select language",
    "Fecha de nacimiento":"Birthdate",
    "Vuelve a intentarlo":"Try again",
    "Respuesta Incorrecta":"Incorrect Answer",
    "Información personal":"Personal information",
    "Salir de la grabación":"Exit recording",
    "¡Bienvenido de nuevo!":"Welcome back!",
    "Terminaste esta encuesta":"You finished this survey",
    "Iniciar sesión con email":"Sign in with email",
    "Haga clic para regístrate":"Click to sign up",
    "Haga clic para ingresar a":"Click to enter",
    "Editar fecha de nacimiento":"Edit date of birth",
    "Confirmar nueva contraseña":"Confirm new password",
    "Iniciar sesión con teléfono":"Sign in with phone",
    "Encuentra todas las palabras":"Find all the words",
    "Seleccionar verdadero o falso":"Select true or false",
    "Haga clic para iniciar sesión":"Click to login",
    "Escribe tu contraseña de acceso":"Write your access password",
    "Seleccione la respuesta correcta":"Select the correct answer",
    "Haga clic para ingresar al tutorial":"Click to enter the tutorial",
    "Haga clic para volver al menú anterior":"Click to return to the previous menu",
    "Haga clic para seleccionar la respuesta":"Click to select answer",
    "Escribe tu correo electrónico de acceso":"Write your access email",
    "Haga clic para restablecer la contraseña":"Click to reset password",
    "Haga clic para iniciar sesión con el email":"Click to login with email",
    "Haga clic para ingresar a la vista de blog":"Click to enter blog view",
    "Haga clic para ingresar a la vista de notas":"Click to enter note view",
    "Haga clic para regresar al listado de módulo":"Click to return to module listing",
    "Haga clic para ingresar al buzón de mensajes":"Click to enter the mailbox",
    "Haga clic para iniciar sesión con el teléfono":"Click to login with phone",
    "Haga clic para regresar a la lección anterior":"Click to return to the previous lesson",
    "Haga clic para regresar a la pregunta anterior":"Click to return to the previous question",
    "Haga clic para continuar a la lección siguiente":"Click to continue to the next lesson",
    "Haga clic para reproducir el audio de la lección":"Click to play the audio of the lesson",
    "Haga clic para continuar a la pregunta siguiente":"Click to continue to the next question",
    "Haga clic para reproducir el audio de la pregunta":"Click to play the audio of the question",
    "Haga clic para regresar al listado de laboratorios":"Click to return to the list of laboratories",
    
    "Cargar archivo":"Upload file",
    "Guardar y salir":"Save and exit",
    "Educación continua":"Continuing education",
    "Aceleración escolar":"School acceleration",
    "Repetir formulario":"Repeat form",
    "Formulario privado":"Private form",
    "Finalizar formulario":"Finish form",
    "Página no encontrada":"Page not Found",
    "Educación vocacional":"Vocational education",
    "Formulario no encontrado":"Form not found",
    "Educación complementaria":"Complementary education",
    "No tienes laboratorios habilitados":"You do not have enabled laboratories",
    "El formulario es privado, verifique el acceso":"The form is private, check access",
    "Debes finalizar primero los módulos anteriores":"You must finish the previous modules first",
    "El formulario solicitado no existe, verifique el acceso":"The requested form does not exist, check access",
    "Lo sentimos, no se pudo encontrar la página que solicitó, vuelva a la página de inicio":"we' re sorry the page you requested could not be found please go back to the homepage",

    a:"to",
    El:"The",
    OK:"OK",
    Yo:"I",
    ID:"ID",
    año:"year",
    Año:"Year",
    mes:"month",
    Mes:"Month",
    Ver:"See",
    Blog:"Blog",
    años:"years",
    Nota:"Note",
    Reto:"Challenger",
    Para:"To",
    Edad:"Age",
    Sexo:"Sex",
    Otro:"Other",
    Mayo:"May",
    Retos:"Challenges",
    Notas:"Notes",
    Buzón:"Mailbox",
    Enero:"January",
    Marzo:"March",
    Abril:"April",
    Junio:"June",
    Julio:"July",
    Otros:"Others",
    Dudas:"Doubts",
    Donar:"Donate",
    Pesos:"Pesos",
    Nuevo:"New",
    Salir:"Leave",
    Grado:"Grade",
    Asunto:"Subject",
    Unidad:"Unit",
    Módulo:"Module",
    Enviar:"Send",
    Volver:"Back",
    Género:"Gender",
    Título:"Title",
    Perfil:"Profile",
    Inicio:"Home",
    Nombre:"Name",
    Buscar:"Search for",
    Cerrar:"Close",
    Editar:"Edit",
    Agosto:"August",
    Cursos:"Courses",
    Dólares:"Dollars",
    Mensual:"Monthly",
    Mensaje:"Message",
    Aceptar:"Accept",
    Público:"Public",
    Privado:"Private",
    Octubre:"October",
    Febrero:"February",
    Docente:"Teacher",
    Lección:"Lesson",
    Repetir:"Repeat",
    Guardar:"Save",
    Módulos:"Modules",
    Reenviar:"Resend",
    Mensajes:"Messages",
    Tutorial:"Tutorial",
    Eliminar:"Remove",
    Unidades:"Units",
    Cancelar:"Cancel",
    Explicar:"Explain",
    Enviados:"Sent",
    Lenguaje:"Language",
    Teléfono:"Phone",
    Femenino:"Female",
    Licencia:"License",
    Usuarios:"Users",
    usuarios:"users",
    Respuesta:"Answer",
    Noviembre:"November",
    Diciembre:"December",
    Comunidad:"Community",
    Recibidos:"Received",
    Responder:"Reply",
    Compartir:"Share",
    Continuar:"Next",
    Masculino:"Male",
    Siguiente:"Next",
    finalizar:"finalize",
    Resultados:"Results",
    Septiembre:"September",
    Estudiante:"Student",
    Bienvenido:"Welcome",
    Regístrate:"Sign up",
    Materiales:"Materials",
    Contraseña:"Password",
    Destacados:"Featured",
    Actualizar:"To update",
    Respuestas:"Answers",
    Contáctanos:"Contact us",
    Actividades:"Activities",
    Laboratorio:"Laboratory",
    Descripción:"Description",
    Institución:"Institution",
    Felicidades:"Congratulations",
    Comentarios:"Comments",
    Calificación:"Qualification",
    Laboratorios:"Laboratories",
    Nacionalidad:"Nationality",
    Organización:"Organization",
    Administrador:"Manager",
    Almacenamiento:"Storage",

    "E-mail":"E-mail",
    "Ver todos":"See all",
    "Ver todas":"See all",
    "Mis blogs":"My blogs",
    "Mis notas":"My notes",
    "y nuestra":"and our",
    "Enviar nota":"Send note",
    "Crear cuenta":"Create account",
    "Guardar nota":"Save a note",
    "Bienvenido a":"Welcome to",
    "Validar edad":"Validate age",
    "Ver unidades":"View units",
    "Sin realizar":"Undone",
    "Escribe aquí":"Write here",
    "Cerrar sesión":"Log out",
    "Publicado por":"Published by",
    "Mensaje nuevo":"New message",
    "Nuevo mensaje":"New message",
    "Ver resultados":"See results",
    "Progreso total":"Overall progress",
    "Regístrate con":"Sign up with",
    "Primera lengua":"First language",
    "Segunda lengua":"Second language",
    "Iniciar sesión":"Log in",
    "Enviar mensaje":"Send message",
    "Blog principal":"Main blog",
    "Compartir nota":"Share a note",
    "del laboratorio":"of the lab",
    "Guardar cambios": "Save changes",
    "Respuesta libre":"Free answer",
    "RESPUESTA LIBRE":"FREE ANSWER",
    "Campo requerido":"Required field",
    "Subir evidencia":"Upload evidence",
    "Actualizar nota":"Update a note",
    "Única respuesta":"Only answer",
    "Verificar código":"Check code",
    "Enviar respuesta":"Send reply",
    "Siguiente noticia":"Next news",
    "Actualizar Perfil":"Update profile",
    "Has completado un":"You have completed",
    "Editar laboratorio":"Edit lab",
    "Cambiar contraseña":"Change Password",
    "Correo electrónico":"E-mail",
    "Perfil actualizado":"Updated profile",
    "Contraseña invalida":"Invalid password",
    "Personalizar avatar":"Customize avatar",
    "Objetivos pedagógicos":"Pedagogical objectives",
    "Descargar certificado":"Download certificate",
    "Restablecer contraseña":"Restore password",
    "Estudiantes registrados":"Registered students",
    "Ciudad/País":"City/Country",
    "Comprar ahora":"Buy now",
    "Aplicación web":"Web application",
    "Plan grande":"Big plan",
    "Donación segura":"Secure donation",
    "Usuarios finales":"End users",
    "usuarios finales":"end users",
    "Plan mediano":"Medium plan",
    "Plan pequeño":"Small plan",
    "de almacenamiento":"storage",
    "Completar registro":"Complete registration",
    "Confirmar contraseña":"Confirm password",
    "Servicios adicionales":"Additional services",
    "Asistencia pedagógica":"Pedagogical assistance",
    "Plan personalizado":"Personalized plan",
    "Cantidad personalizada":"Custom amount",
    "Usuarios administradores":"Admin users",
    "Voluntariado corporativo":"Corporate volunteering",
    "usuarios administradores":"admin users",

    "Ver en línea"                    :"Watch online",
    "Sopa de letras"                  :"Alphabet soup",
    "Nombre de unidad"                :"Unit name",
    "Código del curso"                :"Course code",
    "Título de la nota"               :"Note title",
    "Nombre y apellido"               :"Full name",
    "Buzón de mensajes"               :"Inbox",
    "Buscar por nombre"               :"Search by name",
    "Número de teléfono"              :"Phone number",
    "Iconos de posición"              :"Position icons",
    "Iniciar sesión con"              :"Login with",
    "Compartir como blog"             :"Share as blog",
    "Completa la oración"             :"Complete the sentence",
    "Escribe tu respuesta"            :"Write your answer",
    "Máximo 50 carácteres"            :"Maximum 50 characters",
    "Términos de servicio"            :"Terms of Service",
    "Código de verificación"          :"Verification code",
    "Política de privacidad"          :"Privacy Policy",
    "Encuentra las palabras"          :"Find the words",
    "Seleccionar una imagen"          :"Select an image",
    "Dar una vez"                     :"Give once",
    "Doblaje de voz"                  :"Voiceover",
    "Número de tarjeta"               :"Card number",
    "No hay actividades"              :"No activities",
    "Compra de licencia"              :"License purchase",
    "Nombre del titular"              :"Name of owner",
    "Diseño de contenidos"            :"Content design",
    "Completa tu registro"            :"Complete your registration",
    "Agrega tu información"           :"Add your information",
    "Nombre de organización"          :"Organization name",
    "Comprar/renovar licencia"        :"Buy/renew license",
    "Condiciones del servicio"        :"Conditions of service",
    "Nota enviada exitosamente"       :"Note sent successfully",
    "Nota guardada exitosamente"      :"Note saved successfully",
    "Nota eliminada exitosamente"     :"Note removed successfully",
    "Nota compartida exitosamente"    :"Successfully shared note",
    "Verificar correo electrónico"    :"Verify email",
    "Organización/Escuela/Empresa"    :"Organization/School/Company",
    "Nota actualizada exitosamente"   :"Note updated successfully",
    "Soporte técnico virtual"         :"Virtual technical support",
    "Plataforma web personalizable"   :"Customizable web platform",
    "Aplicación móvil personalizable" :"Customizable mobile app",
    "Servicios adicionales (opcional)":"Additional services (optional)",
    
    
    "Enviar a un amigo"                           :"Send to a friend",
    "Guardar y enviar nota"                       :"Save and send a note",
    "Validar y guardar edad"                      :"Validate and save age",
    "Acceder con el teléfono"                     :"Access with the phone",
    "Has completado el módulo"                    :"You have completed the module",
    "Has completado la unidad"                    :"You have completed the unit",
    "El módulo no tiene retos"                    :"Module has no challenges",
    "La edad debe ser mayor a 0"                  :"Age must be greater than 0",
    "Escribe aquí tu comentario"                  :"Write your comment here",
    "Regístrate con tu teléfono"                  :"Sign up with your phone",
    "Configuración de tu cuenta"                  :"Configuration of your account",
    "Envía un mensaje o llama a"                  :"Send a message or call to",
    "Correo enviado exitosamente"                 :"Mail sent successfully",
    "Verificar número de teléfono"                :"Verify phone number",
    "Ingresa tu número de teléfono"               :"Enter your phone number",
    "Envia un correo electrónico a"               :"Send an email to",
    "El usuario ya está registrado"               :"The user is already registered",
    "El usuario no está registrado"               :"User is not registered",
    "Arrastra la respuesta correcta"              :"Drag the correct answer",
    "Las contraseñas deben coincidir"             :"Passwords must match",
    "El laboratorio no tiene módulos"             :"The lab has no modules",
    "Selecciona la respuesta correcta"            :"Select the correct answer",
    "Acceder con el correo electrónico"           :"Access with email",
    "No estás habilitado para ingresar"           :"You are not able to enter",
    "¿Deseas comunicarte con un tutor?"           :"Do you want to communicate with a tutor?",
    "Existe un usuario con ese teléfono"          :"There is a user with that phone",
    "Regístrate con tu correo electrónico"        :"Sign up with your email",
    "Nota guardada y enviada exitosamente"        :"Note saved and sent successfully",
    "para que puedas cambiar tu contraseña"       :"so you can change your password",
    "El código de verificación no es válido"      :"Verification code is invalid",
    "La unidad siguiente no tiene lecciones"      :"The next unit has no lessons",
    "¡Lo hacemos por ti!"                         :"We do it for you!",
    "por un período de un"                        :"for a period of one",
    "se realizó con éxito"                        :"was done successfully",
    "¡Gracias por tu ayuda!"                      :"Thanks for your help!",
    "La donación fue exitosa"                     :"The donation was successful",
    "Elige cómo quieres ayudar"                   :"Choose how you want to help",
    "Gracias por tu donación de"                  :"Thank you for your donation of",
    "¡Cerremos la brecha juntos!"                 :"Let's bridge the gap together!",
    "El valor de la donación es $0"               :"The value of the donation is $0",
    "Reportes de impacto específicos"             :"Specific impact reports",
    "Sistema de gestión de proyectos"             :"Project Management System",
    "Asistencia técnica y de soporte"             :"Technical assistance and support",
    "Análisis y reportes automáticos"             :"Automatic analysis and reports",
    "Mensajes de audio personalizables"           :"Customizable audio messages",
    "Plataforma de gestión de contenido"          :"Content management platform",
    "Diseño y animación de avatares y más"        :"Animations, illustrations and more",
    "Funciones de seguimiento y evaluación"       :"Monitoring and evaluation features",
    "Sistema de gestión de aprendizaje (LMS)"     :"Learning management system (LMS)",
    "Asistencia en coordinación de proyectos"     :"Professional project assistance",
    "Existe un usuario con esa identificación"    :"There is a user with that ID",
    "Iniciar sesión con tu correo electrónico"    :"Sign in with your email account",
    "Institución educativa o comunidad afiliada"  :"Educational institution or affiliated community",
    "Existe un usuario con ese correo electrónico":"There is a user with that email",

    "Puedes acceder y ver los contenidos que se ofrecen":"You can access and view the content offered",
    "Ante cualquier duda puedes contactar a tu docente":"If you have any questions, you can contact your teacher",
    "Al continuar, estás indicando que aceptas nuestros":"By continuing, you are indicating that you accept our",
    "Ingresa el código de 6 digitos que enviamos al numero":"Enter the 6-digit code that we sent to the number",
    "de estudiantes registrados ha terminado el laboratorio":"of registered students have finished the lab",
    "Se enviará un mensaje con un link al correo electrónico":"A message will be sent with a link to the email",
    "Debes ser mayor de edad para poder acceder a esta sección":"You must be of legal age to access this section",
    "Mensaje enviado exitosamente, revisa tu correo electrónico":"Message sent successfully, check your email",
    "Correo electrónico ya está siendo utilizada por otra cuenta":"Email is already being used by another account",
    "Acceso desactivado temporalmente debido a muchos intentos fallidos":"Access temporarily disabled due to many unsuccessful attempts",
    "¡Hola! Te damos la bienvenida a la versión web de la aplicación O-lab":"Hi there! We welcome you to the web version of the O-lab application",
    "¡Te mantendremos informado sobre el impacto de tu donación! #somoselorigen":"We will keep you updated with the impact of your donation! #wearetheorigin",
    "Los laboratorios son tu espacio de trabajo en los que realizarás actividades":"The laboratories are your workspace where you will carry out activities",
    "Aquí podrás ver la sección en la que te encuentras dentro de la aplicación web":"Here you can see the section in which you are within the web application",
    "Estos mensajes son monitoreados para garantizar la seguridad de nuestros usuarios":"These messages are monitored to ensure the safety of our users",
    "Los módulos son secciones en las que ingresarás para aprender y realizar actividades":"Modules are sections that you will enter to learn and perform activities",
    "Esta sección solo esta habilitada para mayores de edad, por favor valida tu edad para continuar":"This section is only enabled for adults, please validate your age to continue",
    "Se enviará un mensaje a la dirección de correo electrónico que has ingresado para que puedas cambiar tu contraseña.":"A message will be sent to the email address you have entered so that you can change your password.",
    "Si presionas Verificar número de teléfono se enviará un SMS. Se aplicarán las tarifas de mensaje y datos":"If you press Verify phone number an SMS will be sent. Message and data rates will apply.",
    "En esta sección puedes cambiar tus datos personales, escoger una foto de perfil y revisar el progreso de tus cursos":"In this section you can change your personal data, choose a profile photo and check the progress of your courses",
    "En este espacio encontrarás material de estudio que está dividido por unidades, lecciones y actividades":"In this space you will find study material that is divided by units, lessons and activities",
    "Este es un espacio público en el que encontraras material de estudio realizado y compartido por los estudiantes de O-Lab":"This is a public space where you will find study material made and shared by O-Lab students",
    "En esta sección se registran todo tipo de apuntes que quieras guardar, pueden ser posteriormente publicadas en el apartado de blog si así lo desea":"In this section all kinds of notes that you want to save are recorded, they can be later published in the blog section if you wish",
    "El 100% de cada donación va directamente a una persona/escuela/comunidad específica que espera nuestro kit #educacióndigitalparatodos que los equipa con tecnología, capacitación a profesores y nuestro LMS y aplicación de aprendizaje fuera de línea O-lab lleno de cursos complementarios, vocacionales y STEAM creados en colaboración con maestros e instituciones locales en todo el mundo.":"100% of each donation goes directly to a specific person/school/community waiting for our #digitaleducationforall kit that equips them with technology, training and our LMS & offline learning app O-lab filled with complementary, vocational and STEAM courses co-created with local teachers and institutions worldwide.",
    "Con o-lab es posible personalizar los requerimientos de todas las instituciones, entidades educativas u organizaciones. Con todas las características y funciones que tus beneficiarios o educadores necesitan para presentar todos los temas, conferencias, cursos y más.":"With o-lab it is possible to customize the requirements of all institutions, educational entities or organizations. With all the features and functions that your grantees or educators need to present all the topics, conferences, courses and more.",
    "Árabe":"Arabian"
  }
};

export default languaje_EN;