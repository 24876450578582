export const languaje_Es = {
  translation: {
    "Yo"            :"Yo",
    "ID"            :"ID",
    "Reto"          :"Reto",
    "Edad"          :"Edad",
    "Sexo"          :"Sexo",
    "Otro"          :"Otro",
    "Para"          :"Para",
    "Notas"         :"Notas",
    "Buzón"         :"Buzón",
    "Nuevo"         :"Nuevo",
    "Salir"         :"Salir",
    "Grado"         :"Grado",
    "Retos"         :"Retos",
    "Unidad"        :"Unidad",
    "Módulo"        :"Módulo",
    "Perfil"        :"Perfil",
    "Inicio"        :"Inicio",
    "Cursos"        :"Cursos",
    "E-mail"        :"E-mail",
    "Enviar"        :"Enviar",
    "Volver"        :"Volver",
    "Género"        :"Género",
    "Título"        :"Título",
    "Lección"       :"Lección",
    "Repetir"       :"Repetir",
    "Guardar"       :"Guardar",
    "Módulos"       :"Módulos",
    "Explicar"      :"Explicar",
    "Enviados"      :"Enviados",
    "Cancelar"      :"Cancelar",
    "Lenguaje"      :"Lenguaje",
    "Teléfono"      :"Teléfono",
    "Femenino"      :"Femenino",
    "Compartir"     :"Compartir",
    "Continuar"     :"Continuar",
    "Masculino"     :"Masculino",
    "Siguiente"     :"Siguiente",
    "Comunidad"     :"Comunidad",
    "Recibidos"     :"Recibidos",
    "Responder"     :"Responder",
    "Destacados"    :"Destacados",
    "Actualizar"    :"Actualizar",
    "Bienvenido"    :"Bienvenido(a)",
    "Materiales"    :"Materiales",
    "Contraseña"    :"Contraseña",
    "Respuestas"    :"Respuestas",
    "Laboratorio"   :"Laboratorio",
    "Felicidades"   :"Felicidades",
    "Comentarios"   :"Comentarios",
    "Institución"   :"Institución",
    "Laboratorios"  :"Laboratorios",
    "Identificación":"Identificación",

    "Ver todos"            :"Ver todos",
    "Ver todas"            :"Ver todas",
    "Mis blogs"            :"Mis blogs",
    "Mis notas"            :"Mis notas",
    "Enviar nota"          :"Enviar nota",
    "Bienvenido a"         :"Bienvenido a",
    "Crear cuenta"         :"Crear cuenta",
    "Guardar nota"         :"Guardar nota",
    "Publicado por"        :"Publicado por",
    "Mensaje nuevo"        :"Mensaje nuevo",
    "Cerrar sesión"        :"Cerrar sesión",
    "Primera lengua"       :"Primera lengua",
    "Segunda lengua"       :"Segunda lengua",
    "Iniciar sesión"       :"Iniciar sesión",
    "Enviar mensaje"       :"Enviar mensaje",
    "Blog principal"       :"Blog principal",
    "Compartir nota"       :"Compartir nota",
    "Campo requerido"      :"Campo requerido",
    "Subir evidencia"      :"Subir evidencia",
    "Actualizar nota"      :"Actualizar nota",
    "Única respuesta"      :"Única respuesta",
    "Verificar código"     :"Verificar código",
    "Enviar respuesta"     :"Enviar respuesta",
    "Siguiente noticia"    :"Siguiente noticia",
    "Actualizar Perfil"    :"Actualizar Perfil",
    "Correo electrónico"   :"Correo electrónico",
    "Perfil actualizado"   :"Perfil actualizado",
    "Personalizar avatar"  :"Personalizar avatar",
    "Objetivos pedagógicos":"Objetivos pedagógicos",

    
    "Sopa de letras"          :"Sopa de letras",
    "Código de curso"         :"Código de curso",
    "Código del curso"        :"Código del curso",
    "Título de la nota"       :"Título de la nota",
    "Nombre y apellido"       :"Nombre y apellido",
    "Número de teléfono"      :"Número de teléfono",
    "Iconos de posición"      :"Iconos de posición",
    "Completa la oración"     :"Completa la oración",
    "Máximo 50 carácteres"    :"Máximo 50 carácteres",
    "Escribe tu respuesta"    :"Escribe tu respuesta",
    "Encuentra las palabras"  :"Encuentra las palabras",
    "Seleccionar una imagen"  :"Seleccionar una imagen",
    "Código de verificación"  :"Código de verificación",
    "Política de privacidad"  :"Política de privacidad",
    "Condiciones del servicio":"Condiciones del servicio",


    "Guardar y enviar nota"                       :"Guardar y enviar nota",
    "Acceder con el teléfono"                     :"Acceder con el teléfono",
    "Has completado el módulo"                    :"Has completado el módulo",
    "Has completado la unidad"                    :"Has completado la unidad",
    "El módulo no tiene retos"                    :"El módulo no tiene retos",
    "Configuración de tu cuenta"                  :"Configuración de tu cuenta",
    "Envía un mensaje o llama a"                  :"Envía un mensaje o llama a",
    "Verificar número de teléfono"                :"Verificar número de teléfono",
    "Ingresa tu número de teléfono"               :"Ingresa tu número de teléfono",
    "Envia un correo electrónico a"               :"Envia un correo electrónico a",
    "Arrastra la respuesta correcta"              :"Arrastra la respuesta correcta",
    "El laboratorio no tiene módulos"             :"El laboratorio no tiene módulos",
    "Selecciona la respuesta correcta"            :"Selecciona la respuesta correcta",
    "Existe un usuario con ese correo"            :"Existe un usuario con ese correo",
    "Acceder con el correo electrónico"           :"Acceder con el correo electrónico",
    "No estás habilitado para ingresar"           :"No estás habilitado para ingresar",
    "¿Deseas comunicarte con un tutor?"           :"¿Deseas comunicarte con un tutor?",
    "Existe un usuario con ese teléfono"          :"Existe un usuario con ese teléfono",
    "Existe un usuario con esa identificación"    :"Existe un usuario con esa identificación",
    "Institución educativa o comunidad afiliada"  :"Institución educativa o comunidad afiliada",
    "Existe un usuario con ese correo electrónico":"Existe un usuario con ese correo electrónico",


    "Ingresa el código de 6 digitos que enviamos al numero":"Ingresa el código de 6 digitos que enviamos al numero",
    "Si presionas Verificar número de teléfono se enviará un SMS. Se aplicarán las tarifas de mensaje y datos":"Si presionas Verificar número de teléfono se enviará un SMS. Se aplicarán las tarifas de mensaje y datos.",
  }
};

export default languaje_Es;