import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IUser } from "dto/user.dto";
import { State } from "store/types";
import { history } from "configureStore";
import { TypeLanguageCode } from "dto/layout.dto";
import * as actions from "store/actions";
import Loading from "components/Common/Loading";
import Profile from "components/Profile/Profile";
import AppBarLayout from "layouts/AppBarLayout";
import ProfileAnonymous from "components/Profile/ProfileAnonymous";
import { messageSuccess } from "components/Common/Toast";

const useStyles = makeStyles((theme) => ({
  root: { height: "100vh" },
  view: { 
    height: "calc(100% - 70px)", 
    position: "relative", 
  },
  page: {
    height: "100%",
    overflow: "auto",
    position: "relative",
    alignItems: "flex-start",
    backgroundColor: "white",    
  },
}));

const ProfilePage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const auth = useSelector((s: State) => s.auth.auth);
  const user = useSelector((s: State) => s.user.user);
  const entity = useSelector((s: State) => s.entity.entity);
  const fbauth = useSelector((s: State) => s.firebase.auth);
  const genders = useSelector((s: State) => s.gender.genders);
  const section = useSelector((s: State) => s.profile.section);
  const countries = useSelector((s: State) => s.country.countries);
  const authLoading = useSelector((s: State) => s.auth.loading);
  const languageCode = useSelector((s: State) => s.layout.language.code);
  const photoLoading = useSelector((s: State) => s.user.photoLoading);
  const languageAudio = useSelector((s: State) => s.user.languageAudio);
  const profileLoading = useSelector((s: State) => s.user.profileLoading);
  const passwordLoading = useSelector((s: State) => s.user.passwordLoading);
  const { isEmpty, isLoaded } = fbauth;
  const anonymous = localStorage.getItem("anonymousUser");

  useEffect(() => { 
    if (!!isEmpty && !!isLoaded && !anonymous) history.push("/");
  }, [isEmpty, isLoaded, anonymous]);

  const updateProfile = (data: IUser, callback: () => void) => {
    dispatch(actions.updateProfile(
      data, 
      () => {
        messageSuccess(t("Perfil actualizado"));
        callback();
      },
    ));
  }

  const updateProfilePhoto = (file: File) => {
    dispatch(actions.updateProfilePhoto(file, () => messageSuccess(t("Perfil actualizado"))));
  }

  const changeLanguageAudio = (lang: number) => dispatch(actions.changeLanguageAudio(lang));

  const passwordReset = (email: string, callback: () => void) => {
    dispatch(actions.passwordReset(email, callback));
  }

  const selectSection = (section: number) => dispatch(actions.selectSection(section));

  const handleLogout = () => dispatch(actions.handleLogout());

  const changePassword =(password: string) => {
    dispatch(actions.changePassword(user.email, password));
  }

  const sendEntityCode = (code: string) => {
    dispatch(actions.updateUserSponsorByEntityCode(user, code, entity));
  }

  const updateLanguage = (code: TypeLanguageCode) => {
    dispatch(actions.updateLanguage(code));
  }
  
  return (
    <Grid className={classes.root} container>
      <AppBarLayout hideButtonLanguage={!!user?.nit} />
      
      <Grid className={classes.view} container>
        <Grid className={classes.page} container>
          {!!user?.nit ? (
            <Profile 
              user={user}
              fbauth={!!auth.isEmpty ? "" : auth.data.providerId}
              entity={entity}
              genders={genders}
              section={section}
              countries={countries}
              authLoading={authLoading}
              languageCode={languageCode}
              photoLoading={photoLoading}
              languageAudio={languageAudio}
              profileLoading={profileLoading}
              passwordLoading={passwordLoading}
              logOut={handleLogout}
              updatePhoto={updateProfilePhoto} 
              selectSection={selectSection}
              changePassword={changePassword}
              updateProfile={updateProfile}
              passwordReset={passwordReset}
              sendEntityCode={sendEntityCode}
              updateLanguage={updateLanguage}
              changeLanguageAudio={changeLanguageAudio}
            />
          ) : (
            <ProfileAnonymous />
          )}
        </Grid>
      </Grid>
      
      <Loading loading={passwordLoading} white />
    </Grid>
  )    
}

export default ProfilePage;
