import React, { FC, useEffect, useState } from "react";
import { IStep, IStepFiles } from "dto/step.dto";
import { IChallenger } from "dto/challenger.dto";
import StepPdf from "./StepPdf";
import StepVideo from "./StepVideo";
import StepNormal from "./StepNormal";
import NotebookContent from "components/Notebook/NotebookContent";
import NotebookActions from "components/Notebook/NotebookActions";

interface IProps {
  step: IStep;
  zoom: boolean;
  stepIndex: number;
  stepFiles: IStepFiles;
  challenger: IChallenger;
  buttonColor: string;
  headerColor: string;
  languageAudio: string;
  backgroundColor: string;
  buttonSoundImage: string;
  progressBarColor: string;
  buttonNextColorText: string;
  nextStep: () => void;
  prevStep: () => void;
  pauseAudio: () => void;
  updateZoom: () => void;
  toggleAudio: () => void;
  updateAudiosrc: (a: string) => void;
}

const Step :FC<IProps> = (props) => {
  const { step, stepFiles } = props;
  const [pdfNumberPage, setPdfNumberPage] = useState(1);
  const [pdfNumberPages, setPdfNumberPages] = useState(0);

  useEffect(() => { 
    getStepAudio(); 
    setPdfNumberPage(0);
    if (!!step?.text) setPdfNumberPage(0); 
    else setPdfNumberPage(1);
    setPdfNumberPages(0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const getStepAudio = () => {
    if (stepFiles.type === 1) {
      props.updateAudiosrc("");
    } else if (props.languageAudio === "1") {
      props.updateAudiosrc(stepFiles.audio_wa);
    } else if (props.languageAudio !== "1") {
      props.updateAudiosrc(stepFiles.audio);
    } else  props.updateAudiosrc("");
  }

  const prevStep = () => {
    props.pauseAudio();

    if (stepFiles.type === 1) props.prevStep();
    else if (!!pdfNumberPage) prevPdf();
    else props.prevStep();
  }
  const nextStep = () => {
    props.pauseAudio();

    if (stepFiles.type === 1) props.nextStep();
    else if (
      (!!stepFiles.pdf_web && !pdfNumberPage) || 
      (pdfNumberPage < pdfNumberPages)
    ) {
      nextPdf();
    } else props.nextStep();
  }

  const prevPdf = () => {
    const pdfPageNumberAux = pdfNumberPage - 1
    if (!pdfPageNumberAux && !step?.text) props.prevStep();
    else if (!pdfPageNumberAux) {
      setPdfNumberPage(0);
      if (props.languageAudio === "1") {
        props.updateAudiosrc(stepFiles.audio_wa);
      } else props.updateAudiosrc(stepFiles.audio);
    } else {
      setPdfNumberPage(pdfPageNumberAux);
      const audioPdf = stepFiles.audio_pdf[pdfPageNumberAux - 1];
      if (props.languageAudio === "1" && !!audioPdf?.audio_wa) {
        props.updateAudiosrc(audioPdf.audio_wa);
      } else if (props.languageAudio !== "1" && !!audioPdf?.audio) {
        props.updateAudiosrc(audioPdf.audio);
      } else  props.updateAudiosrc("");
    }
  }

  const nextPdf = () => {
    setPdfNumberPage(pdfNumberPage + 1);
    const audioPdf = stepFiles.audio_pdf[pdfNumberPage];
    if (props.languageAudio === "1" && !!audioPdf?.audio_wa) {
      props.updateAudiosrc(audioPdf.audio_wa);
    } else if (props.languageAudio !== "1" && !!audioPdf?.audio) {
      props.updateAudiosrc(audioPdf.audio);
    } else  props.updateAudiosrc("");
  }

  return (
    <>
      <NotebookContent zoom={props.zoom}>
        {stepFiles.type === 1 ? (
          <StepVideo videoUrl={stepFiles.video_url} />
        ) : stepFiles.type === 2 ? (
          <>
            {!!pdfNumberPage ? (
              <StepPdf 
                pdf={stepFiles.pdf_web}
                zoom={props.zoom}
                numberPage={pdfNumberPage}
                selectNumberPages={setPdfNumberPages} 
              />
            ) : (
              <StepNormal 
                step={props.step}
                objective={props.challenger?.objective}
                stepIndex={props.stepIndex}
                stepFiles={stepFiles}
                challenger={props.challenger}
                description={props.challenger?.description}
                headerColor={props.headerColor} 
              />
            )}
          </>
        ) : (
          <StepNormal 
            step={props.step}
            objective={props.challenger?.objective}
            stepIndex={props.stepIndex}
            stepFiles={stepFiles}
            challenger={props.challenger}
            description={props.challenger?.description}
            headerColor={props.headerColor} 
          />
        )}
      </NotebookContent>
      <NotebookActions
        zoom={props.zoom}
        buttonAudioImage={props.buttonSoundImage}
        buttonNextColorText={props.buttonNextColorText}
        buttonBackOnClick={prevStep}
        buttonNextOnClick={nextStep}
        buttonZoomOnClick={props.updateZoom}
        buttonAudioOnClick={props.toggleAudio}
      />
    </>
  )
}

export default Step;
