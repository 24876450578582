import React, { FC, useEffect, useState } from "react";
import { IModule } from "dto/module.dto";
import { IChallenger } from "dto/challenger.dto";
import { IStep, IStepFiles } from "dto/step.dto";
import * as utils from "utils/step.utils";
import Step from "components/Step/Step";
import Notebook from "components/Notebook/Notebook";

interface IProps {
  zoom: boolean;
  module: IModule;
  loading: boolean;
  challenger: IChallenger;
  buttonColor: string;
  headerColor: string;
  languageAudio: string;
  laboratoryName: string;
  backgroundColor: string;
  buttonSoundImage: string;
  progressBarColor: string;
  buttonNextColorText: string;
  pauseAudio: () => void;
  toogleZoom: () => void;
  toggleAudio: () => void;
  openQuestions: () => void;
  updateLoading: (loading: boolean) => void;
  updateAudiosrc: (audio: string) => void;
  openDialogSuccess: () => void;
  deselectChallenger: () => void
}

const NotebookStep: FC<IProps> = (props) => {
  const {  laboratoryName } = props;
  const [steps, setSteps] = useState<IStep[]>([]);
  const [stepsIndex, setStepsIndex] = useState(0);
  const [stepsFiles, setStepsFiles] = useState<IStepFiles[]>([]);
  const path01 = `${laboratoryName}/${props.module?.name}/challenges`;
  const path02 = `${laboratoryName}/${props.module?.name}/challenges/steps`;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { getChallengerSteps(); }, [props.challenger?.steps]);

  const getChallengerSteps = async () => {
    if (!!props.challenger?.steps?.length) {
      setSteps(props.challenger.steps);
      props.updateLoading(true);
      const stepsFilesAux = await utils.getStepsFiles(props.challenger, path01, path02);
      props.updateLoading(false);
      setStepsFiles(stepsFilesAux);
    } else {
      setSteps([]);
      setStepsFiles([]);
    }
  }

  const prevStep = () => {
    if (!!stepsIndex) setStepsIndex(stepsIndex - 1);
    else props.deselectChallenger();
  }
  const nextStep = () => {
    if (stepsIndex < (steps.length - 1)) {
      setStepsIndex(stepsIndex + 1);
    } else if (!!props.challenger?.questions?.length) {
      props.openQuestions();
    } else props.openDialogSuccess();
  }

  return (
    <Notebook
      zoom={props.zoom} 
      loading={props.loading} 
      backgroundColor={props.backgroundColor}
    >
      {(!!steps?.length && !!stepsFiles?.length) && (
        <Step 
          zoom={props.zoom}
          step={steps[stepsIndex]}
          stepIndex={stepsIndex} 
          stepFiles={stepsFiles[stepsIndex]}
          challenger={props.challenger}
          buttonColor={props.buttonColor}
          headerColor={props.headerColor}
          languageAudio={props.languageAudio}
          backgroundColor={props.backgroundColor}
          buttonSoundImage={props.buttonSoundImage}
          progressBarColor={props.progressBarColor}
          buttonNextColorText={props.buttonNextColorText}
          nextStep={nextStep}
          prevStep={prevStep}
          pauseAudio={props.pauseAudio}
          updateZoom={props.toogleZoom}
          toggleAudio={props.toggleAudio}
          updateAudiosrc={props.updateAudiosrc}
        />
      )}
    </Notebook>
  )
}

export default NotebookStep;
