import React, { FC } from "react";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { CheckCircle, Info, Warning, Error } from "@material-ui/icons";

interface IProps {
  info?: boolean;
  warn?: boolean;
  error?: boolean;
  message: string;
  success?: boolean;
}

const ToastMessage: FC<IProps> = ({ info, warn, error, success, message }) => {
  const { t } = useTranslation();

  return (
    <>
      {!!info && (<><Info fontSize="large" /><Typography>{t(message)}</Typography></>)}
      {!!warn && (<><Warning fontSize="large" /><Typography>{t(message)}</Typography></>)}
      {!!error && (<><Error fontSize="large" /><Typography>{t(message)}</Typography></>)}
      {!!success && (<><CheckCircle fontSize="large" /><Typography>{t(message)}</Typography></>)}
    </>
  )
}

export default ToastMessage;
