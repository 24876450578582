import { ITask } from "dto/user.dto";

export const taskInit: ITask = {
  text: "",
  date: "",
  rate: 0,
  module: "",
  questions: [],
  laboratory: "",
  challenger: "",
  is_gradable: true,
  device_image: "",
  remote_image: "",
}

export const tasksInit: ITask[] = [];
