import React, { FC } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { IQuestion, IQuestionPreview } from "dto/question.dto";
import ProgressBar from "components/Common/ProgressBar";
import ImageOptional from "./ImageOptional";
import QuestionBubble from "./QuestionBubble";

const useStyles = makeStyles((theme) => ({
  left: {
    height: "100%",
    [theme.breakpoints.down("xs")]: { 
      height: "fit-content", 
      padding: "20px 8px 0",
    }, 
  },
  header: { padding: "16px 16px 0" },
  content: { 
    height: "calc(100% - 36px)",
    padding: 16, 
    overflowY: "auto",
    overflowX: "hidden",
    alignItems: "center",
  },
}));

interface IProps {
  preview: IQuestionPreview;
  question: IQuestion;
  answerRating: boolean;
  languageAudio: string;
  viewFeedback: boolean;
  questionNumber: number;
  questionsTotal: number;
  progressBarColor: string;
}

const Information: FC<IProps> = (props) => {
  const classes = useStyles();

  return (
    <Grid className={classes.left} container>
      <Grid className={classes.header} item xs={12}>
        <ProgressBar 
          max={props.questionsTotal} 
          value={props.questionNumber}
          color={props.progressBarColor}
        />
      </Grid>

      <Grid className={classes.content} item container>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <QuestionBubble 
              preview={props.preview}
              question={props.question}
              answerRating={props.answerRating}
              languageAudio={props.languageAudio}
              viewFeedback={props.viewFeedback}
            />
          </Grid>
          
          {props.children && (<Grid item xs={12}>{props.children}</Grid>)}

          <ImageOptional image={props.preview?.image_optional} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Information;
