import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Grid, Button } from '@material-ui/core';
import { amountInit, ILicense, IProject } from '../../../services/checkout.services';
import ProjectTable     from '../Service/ProjectTable';
import ServicesOptional from '../Service/ServicesOptional';

const useStyles = makeStyles((theme) => ({
  stepOneMobile: { 
    display: 'none',
    padding: 15,
    [theme.breakpoints.down("xs")]: { display: 'flex' }, 
  },
  link: { width: '100%', textDecoration: 'none', color: 'initial' },
  btn: { borderRadius: 25, marginTop: 15 },
}));

interface IProps {
  projects: IProject[]
  selectService: (value: ILicense) => void
  openContactUs: () => void
  openServiceOptional: () => void
}

const PayStepOneMobile: FC<IProps> = ({
  projects,
  selectService, 
  openContactUs, 
  openServiceOptional,
}) => {
  const classes  = useStyles();
  const { t } = useTranslation();
  const data = [
    { users: "1 - 100",     adminUsers: "2",  alamcenamiento: "5 GB"  },
    { users: "101 - 1000",  adminUsers: "5",  alamcenamiento: "10 GB" },
    { users: "1001 - 5000", adminUsers: "10", alamcenamiento: "20 GB" },
  ]

  return (
    <Grid className={classes.stepOneMobile} container>
      {projects.map((service, i) => (
        <ProjectTable 
          key={service.uid}  
          text="Ver"
          color={service.color_one}
          title={service.name}
          users={data[i].users}
          adminUsers={data[i].adminUsers}
          alamcenamiento={data[i].alamcenamiento}
          onClick={() => selectService({...service, amount: amountInit})}
        />
      ))}
      <ProjectTable 
        text="Contáctanos"
        color="#ff6315"
        title="Proyecto personalizado"
        users="+5001"
        adminUsers={"+11"}
        alamcenamiento="+20 GB"
        onClick={openContactUs}
      />
      <ServicesOptional openServiceOptional={openServiceOptional} />
      <a className={classes.link} href="https://www.originlearningfund.org/o-lab">
        <Button className={classes.btn} variant="contained" fullWidth>
          {t("Volver")}
        </Button>
      </a>
    </Grid>
  )
}

export default PayStepOneMobile;
