import React, { FC, useState } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import GoogleMapReact from "google-map-react";
import Marker from "components/Question/Marker";

const useStyles = makeStyles((theme) => ({ type: { height: "100%" } }));

interface IProps {
  answer: string;
  selectAnswer: (answer: string) => void;
}

const Type25: FC<IProps> = (props) => {
  const classes = useStyles();
  const [lat, setLat] = useState<number | undefined>(undefined);
  const [lng, setLng] = useState<number | undefined>(undefined);
  const defaultCenter = { lat: 4.60971, lng: -74.08175 };

  const onClick = (value: GoogleMapReact.ClickEventValue) => {
    setLat(value?.lat);
    setLng(value?.lng);
  }

  return (
    <Grid className={classes.type} container>
      <GoogleMapReact
        defaultZoom={7}
        defaultCenter={defaultCenter}
        bootstrapURLKeys={{ key: "AIzaSyCbyliFhNtysJCfJDVCnfx2qEzHNRzoz3c" }}
        onClick={onClick}
      >
        {!!lat && !!lng && <Marker lat={lat} lng={lng} />}
      </GoogleMapReact>
    </Grid>
  )
}

export default Type25;
