import React, { FC } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { date, object } from "yup";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as utils from "utils";
import FieldDate from "./FieldDate";

const useStyles = makeStyles((theme) => ({
  type: { 
    padding: 16,
    alignItems: "center",
    justifyContent: "center",
  },
  form: { width: "100%" },
  field: { 
    "& .MuiInputBase-root": {
      borderRadius: 8,
      "&:after": {borderBottom: "2px solid #f0f0f0"},
      "&:before": { borderBottom: "2px solid #f0f0f0" },
      "&:hover": { "&:before": { borderColor: "#f0f0f0" }, },
      "&.Mui-focused": { "&:before": { borderColor: "#f0f0f0" } },
      "& .MuiInputBase-input": {
        color: "#cdcdcd",
        padding: "12px 16px",
        fontSize: 18,
        fontWeight: 500,
      },
      "&.Mui-error": { 
        "&:after": { borderColor: "#f44336" },
        "&:before": { borderColor: "#f44336" },
      },
    },
  },
}));

interface IProps {
  answer: string;
  selectAnswer: (answer: string) => void;
}

const TypeDate: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dateMin = new Date("01-01-1960");
  const initialValues: { date: string | Date } = { date: props.answer };
  const validationSchema = object().shape({
    date: date()
      .required(t("Fecha de nacimiento incorrecta"))
      .min(dateMin, "Fecha de nacimiento incorrecta")
      .test(
        "global-ok",
        t("Edad minima 7 años"),
        async (date) => utils.getMinAge(date ?? "", 7)
      ),
  });

  return (
    <Grid className={classes.type} container>
      <Formik
        initialValues={initialValues} 
        validationSchema={validationSchema} 
        onSubmit={() => {}}
      >
        {({ errors, values, touched }) => (
          <Form className={classes.form}>
            <FieldDate 
              error={errors.date} 
              value={values.date}
              touched={touched.date} 
              selectAnswer={props.selectAnswer}
            />
          </Form>
        )}
      </Formik>
    </Grid>
  )
}

export default TypeDate;
