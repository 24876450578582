import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, Grid, Typography, Avatar } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  item: { padding: '0 30px 0 30px' },
  platform: {
    width: 'fit-content',
    marginBottom: 15,
    '&:hover': { '& .text': { display: 'block' }},
  },
  iconContent: { width: 60, marginRight: 10 },
  icon: { cursor: 'pointer', borderRadius: 0 },
  admin: { width: 52, },
  app: { width: 35, height: 51 },
  web: { width: 60 },
  text: { 
    border: '1px solid white', 
    padding: '0 5px',
    fontWeight: 500,
    display: 'none',
  },
  link: { textDecoration: 'none', color: 'initial' },
}));

const MenuApps = () => {
  const classes  = useStyles();

  return (
    <Grid className={classes.item} item xs={6}>
      <Grid className={classes.platform} container alignItems="center">
        <Grid className={classes.iconContent} container justifyContent="center">
          <a className={classes.link} href="https://teachers.o-lab.app">
            <Avatar 
              className={clsx(classes.icon, classes.admin)} 
              alt="" 
              src="/images/landing_platform.svg" 
            />
          </a>
        </Grid>
        <Typography className={clsx(classes.text, "text")}>
          Plataforma gestión de contenidos
        </Typography>
      </Grid>

      <Grid className={classes.platform} container alignItems="center">
        <Grid className={classes.iconContent} container justifyContent="center">
          <Avatar 
            className={clsx(classes.icon, classes.app)} 
            alt="" 
            src="/images/landing_app.svg" 
          />
        </Grid>
        <Typography className={clsx(classes.text, "text")}>
          O-lab app
        </Typography>
      </Grid>
      
      <Grid className={classes.platform} container alignItems="center">
        <Grid className={classes.iconContent} container justifyContent="center">
          <Link className={classes.link} to="login">
            <Avatar 
              className={clsx(classes.icon, classes.web)} 
              alt="" 
              src="/images/landing_web.svg" 
            />
          </Link>
        </Grid>
        <Typography className={clsx(classes.text, "text")}>
          O-lab web
        </Typography>
      </Grid>
    </Grid>
  )
}

export default MenuApps;
