import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import { object, string } from 'yup';
import clsx from 'clsx';
import { makeStyles, Grid, TextField, Typography, Button, CircularProgress } from '@material-ui/core';
import { messageSuccess } from '../../components/Common/Toast';
import { soundClick } from 'utils';

const useStyles = makeStyles(() => ({
  item: {
    margin: '7px 0',
    '& .MuiFormControl-root': {
      margin: 0,
      '& .MuiInputBase-root ': {
        backgroundColor: 'white',
        borderRadius: 50,
        '& .MuiOutlinedInput-input': { padding: '5px 15px' }
      },
      '& .MuiFormHelperText-root': { marginTop: 0 }
    },
  },
  accept: {
    backgroundColor: '#ff6315',
    color: 'white',
    borderRadius: 25,
    padding: '3px 15px',
    marginBottom: 15,
    '&:hover': { backgroundColor: 'white', color: '#ff6315' },
  },
  button: { borderRadius: 25, padding: '3px 15px' },
  pr: { paddingRight: 5 },
  pl: { paddingLeft: 5 },
}));

interface IInitialValues { email: string }

interface IProps {
  loading: boolean
  passwordReset: (email: string, callback: () => void) => void
  closePassReset: () => void
}

const PasswordReset: FC<IProps> = ({loading, passwordReset, closePassReset}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const initialValues = { email: "" }
  const validations = object().shape({
    email: string().required(t('Campo requerido')).email('example@example.com'),
  });

  const onSubmitCallback = () => {
    messageSuccess("Mensaje enviado exitosamente, revisa tu correo electrónico");
    closePassReset();
  }
  const onSubmit = (values: IInitialValues) => passwordReset(values.email, onSubmitCallback);

  return (
    <Grid container>
      <Formik initialValues={initialValues} validationSchema={validations} onSubmit={onSubmit}>
        {({errors, touched}) => (
          <Form>
            <Typography variant="h6"><b>{t("Ingresa tu correo electrónico")}</b></Typography>
            <Grid container>
              <Grid className={classes.item} item xs={12}>
                <Field 
                  as={TextField}
                  name="email"
                  variant="outlined"
                  placeholder={t("Correo electrónico")}
                  size="small"
                  fullWidth
                  autoFocus
                  disabled={loading}
                  error={errors.email && touched.email}
                  helperText={touched.email && errors.email}
                />
              </Grid>

              <Grid className={clsx(classes.item, classes.pr)} item xs={6}>
                <Button 
                  className={classes.button}
                  variant="contained"
                  disabled={loading}
                  onClick={closePassReset}
                  onMouseDown={soundClick}
                  fullWidth
                >
                  {t("Cancelar")}
                </Button>
              </Grid>

              <Grid className={clsx(classes.item, classes.pl)} item xs={6}>
                <Button 
                  className={classes.accept}
                  variant="contained"
                  type="submit"
                  onMouseDown={soundClick}
                  disabled={loading}
                  fullWidth
                >
                  {!loading ? t("Enviar") : <CircularProgress size={25}/>}
                </Button>
              </Grid>

              <Grid className={classes.item} item xs={12}>
                <Typography variant="subtitle2" align="center" display="block">
                  {t("Se enviará un mensaje a la dirección de correo electrónico que has ingresado para que puedas cambiar tu contraseña.")}
                </Typography>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  )
}

export default PasswordReset;
