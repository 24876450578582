import { ILaboratory, ILaboratoryCongratulations } from "dto/laboratory.dto";

export const laboratoryCongratulationsInit: ILaboratoryCongratulations = {
  complete_image: "/gif/default/laboratory/congratulations_complete_image_default.gif",
  complete_audio_fl: "/audio/default/laboratory/congratulations_complete_audio_default.mp3",
  complete_audio_sl: "/audio/default/laboratory/congratulations_complete_audio_default.mp3",
  complete_text_one: "¡Curso completado!",
  complete_text_two: "Dale continuar para avanzar",
  incomplete_image: "/gif/default/laboratory/congratulations_incomplete_image_default.gif",
  incomplete_audio_fl: "/audio/default/laboratory/congratulations_incomplete_audio_default.mp3",
  incomplete_audio_sl: "/audio/default/laboratory/congratulations_incomplete_audio_default.mp3",
  incomplete_text_one: "¡Oops!",
  incomplete_text_two: "Vuelve a intentarlo",
}

export const laboratoryInit: ILaboratory = {
  key: "",
  name: "",
  type: "laboratory",
  tags: [],
  image: "",
  state: "draft",
  ageMax: 0,
  ageMin: 0,
  modules: [],
  sponsor: "",
  imageUrl: "",
  language: "",
  displayName: "",
  update_date: 0,
  congratulations: laboratoryCongratulationsInit,
  background_image: "",
  background_video: "",
  organization_image: "",
  background_video_audio: false,
  background_video_autoplay: false,

  not_repeat: false,
  sequential_mode: false,
  modules_blocked: false,
  modules_rate_min: 0,
  challenges_blocked: false,
  challenges_rate_min: 0,
}

