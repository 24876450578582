import { Router } from "react-typesafe-routes";
import Home from "pages/Home";
import Login from "pages/LoginPage";
import Plans from "pages/PlansPage";
import SignIn from "pages/SignInPage";
import Survey from "pages/SurveyPage";
import Explore from "pages/ExplorePage";
import Profile from "pages/ProfilePage";
import Landing from "pages/LandingPage";
import Page404 from "pages/Page404Page";
import Donation from "pages/DonationPage";
import Laboratory from "pages/LaboratoryPage";
import PlansPrices from "components/Payment/PlansPrices/PlansPrices";
import SurveyPublic from "pages/SurveyPublicPage";
import TermsService from "pages/TermsServicePage";
import AnonymousUser from "pages/AnonymousUserPage";
import CreateAccount from "pages/CreateAccountPage";
import ExploreSurvey from "pages/ExploreSurveyPage";
import PrivacyPolicy from "pages/PrivacyPolicyPage";
import LaboratoryPublic from "pages/LaboratoryPublicPage";
import AnonymousUserTest from "pages/AnonymousUserTestPage";
import ExploreLaboratory from "pages/ExploreLaboratoryPage";
// import Note from "pages/NotePage";
// import Blog from "pages/BlogsPage";
// import Email from "pages/EmailPage";

export const router = Router((route) => ({
  aa: route("/", { component: Login }), 
  ab: route("home", { component: Home }), 
  // ac: route("note", { component: Note }), 
  // ad: route("blogs", { component: Blog }), 
  // ae: route("email", { component: Email }), 
  af: route("plans", { component: Plans }), 
  ag: route("sign-in", { component: SignIn }), 
  ah: route("explore", { component: Explore }), 
  ai: route("profile", { component: Profile }), 
  aj: route("landing", { component: Landing }),
  ak: route("donation", { component: Donation }),
  al: route("terms-service", { component: TermsService }),
  am: route("privacy-policy", { component: PrivacyPolicy }),
  an: route("plans-checkout", { component: PlansPrices }),
  ao: route("create-account", { component: CreateAccount }),
  ap: route("anonymous-user", { component: AnonymousUser }),
  ar: route("anonymous-user/test", { component: AnonymousUserTest }),
  // @ts-ignore
  as: route("survey/:survey_uuid", { component: Survey }), 
  // @ts-ignore
  at: route("survey/public/:survey_uuid", { component: SurveyPublic }), 
  // @ts-ignore
  au: route("lab/:laboratory_key", { component: LaboratoryPublic }),
  // @ts-ignore
  av: route("laboratory/:laboratory_key", { component: Laboratory }),
  // @ts-ignore
  aw: route("laboratory/draft/:laboratory_key", { component: LaboratoryPublic }),
  // @ts-ignore
  ax: route("laboratory/public/:laboratory_key", { component: LaboratoryPublic }),
  // @ts-ignore
  ay: route("explore/survey/:survey_uuid", { component: ExploreSurvey }),
  // @ts-ignore
  az: route("explore/laboratory/:laboratory_key", { component: ExploreLaboratory }),
  ba: route("*", { component: Page404 }),
}));
