import React, { FC, MouseEventHandler } from "react";
import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    width: 44,
    height: 44,
    border: "2px solid #ffffff",
    boxShadow: "0px 2px 0px 0px #ffffff",
    borderRadius: 16,
  },
}));

interface IProps {
  children: React.ReactNode;
  boxShadow?: string;
  borderColor?: string;
  marginBottom?: number | string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const FormIconButton: FC<IProps> = (props) => {
  const classes = useStyles();
  const style = { 
    boxShadow: props.boxShadow, 
    borderColor: props.borderColor, 
    marginBottom: props.marginBottom,
  }

  return (
    <IconButton 
      className={classes.button} 
      style={style} 
      onClick={props.onClick}
    >
      {props.children}
    </IconButton>
  )
}

export default FormIconButton;
