import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import FormButton from "components/Form/FormButton";
import TermsConditions from "../TermsConditions";

const useStyles = makeStyles(() => ({
  title: { 
    color: "#5f5f5f", 
    fontSize: 40, 
    fontWeight: 700, 
  },
  body1: { fontWeight: 700, lineHeight: 0.4 },
}));

interface IProps {
  selectView: (s: number) => void;
}

const CreateAccountMenu: FC<IProps> = ({ selectView }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const openRegisterEmail = () => selectView(1);
  const openRegisterPhone = () => selectView(2);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography className={classes.title} align="center">
          <b>{t("Crear cuenta con")}:</b>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormButton primary fullWidth onClick={openRegisterEmail}>
          {t("Correo electrónico")}
        </FormButton>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.body1} align="center">
          {t("o")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormButton primary fullWidth onClick={openRegisterPhone}>
          {t("Número de teléfono")}
        </FormButton>
      </Grid>
      <Grid item xs={12}><TermsConditions /></Grid>
    </Grid>
  )
}

export default CreateAccountMenu;
