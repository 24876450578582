const svgImage = (color1: string, color2: string) => `<svg 
  xmlns="http://www.w3.org/2000/svg" 
  width="479.304" height="163.665" 
  viewBox="0 0 479.304 163.665"
>
  <g transform="translate(-4471.346 -4387.739)">
    <path
      d="M4939.6,4387.739h-457.12c-6.078,0-11.052,4.183-11.052,9.294V4542.11c0,5.112,4.974,9.294,11.052,9.294H4939.6c6.079,0,11.052-4.182,11.052-9.294V4397.033C4950.65,4391.922,4945.677,4387.739,4939.6,4387.739Z"
      fill="#fff" 
    />
    <path
      d="M4890.179,4440.783a341.611,341.611,0,0,0-85.239,0s-35.4,5.946-81.558,0a341.608,341.608,0,0,0-85.239,0s-35.4,5.946-81.558,0a341.606,341.606,0,0,0-85.239,0v76.911a341.634,341.634,0,0,1,85.239,0c46.159,5.947,81.558,0,81.558,0a341.636,341.636,0,0,1,85.239,0c46.159,5.947,81.558,0,81.558,0a341.639,341.639,0,0,1,85.239,0c46.16,5.947,60.471,0,60.471,0v-76.911S4936.339,4446.729,4890.179,4440.783Z"
      fill="${color2}" 
    />
    <path 
      d="M4950.65,4440.783v76.911s-14.311,5.947-60.471,0a341.639,341.639,0,0,0-85.239,0Z" 
      fill="${color1}" 
    />
    <path 
      d="M4638.143,4440.783s-35.4,5.946-81.558,0a341.606,341.606,0,0,0-85.239,0v76.911Z" 
      fill="${color1}" 
    />
  </g>
</svg>`

export const LicenseBackground = (color1: string, color2: string) => {
  return `data:image/svg+xml;charset=UTF-8;base64,${btoa(svgImage(color1, color2))}`
}

export default LicenseBackground;

