import React, { FC, useEffect, useState } from "react";
import { Dialog } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ITask } from "dto/user.dto";
import { IModule } from "dto/module.dto";
import { IChallenger } from "dto/challenger.dto";
import { ILaboratory } from "dto/laboratory.dto";
import { moduleCongratulationsInit } from "initials/module.init";
import { challengerCongratulationsInit } from "initials/challenger.init";
import { laboratoryCongratulationsInit } from "initials/laboratory.init";
import * as utils from "utils";
import Loading from "components/Common/Loading";
import ModuleCongratulations from "./ModuleCongratulations";
import ChallengerCongratulations from "./ChallengerCongratulations";
import LaboratoryCongratulations from "./LaboratoryCongratulations";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    height: "100%",
    maxWidth: 580,
    maxHeight: 580,
    borderRadius: 32,
    backgroundColor: "white",
  },
}));

interface IProps {
  open: boolean;
  task: ITask;
  tasks: ITask[];
  module: IModule;
  challenger: IChallenger;
  laboratory: ILaboratory;
  taskLoading: boolean;
  languageAudio: string;
  saveUserTask: () => void;
  handleAnalytics: (name: string, params?: { [key: string]: string; }) => void;
}

const DialogSuccess: FC<IProps> = (props) => {
  const classes = useStyles();
  const [moduleCongratulations, setModuleCongratulations] = useState(
    moduleCongratulationsInit
  );
  const [challengerCongratulations, setChallengerCongratulations] = useState(
    challengerCongratulationsInit
  );
  const [laboratoryCongratulations, setLaboratoryCongratulations] = useState(
    laboratoryCongratulationsInit
  );
  const taskCurrent = { 
    ...props.task,
    laboratory: props.laboratory?.name,
    module: props.module?.name,
    challenger: props.challenger?.name
  }
  const moduleRate = utils.gatModuleRate(
    props.laboratory?.name, 
    props.module, 
    [...props.tasks, taskCurrent],
  );
  const moduleLast = props.laboratory?.modules[props.laboratory?.modules?.length - 1];
  const laboratoryRate = utils.getLaboratoryRate(
    props.laboratory, 
    [...props.tasks, taskCurrent],
  );
  const challengerNext = utils.getChallengerNext(props.module, props.challenger);
  const challengerLast = utils.getChallengerLast(props.module, props.challenger);
  const laboratoryCompleted = utils.getLaboratoryCompletedUsingProgress(
    props.laboratory, 
    props.module, 
    props.challenger, 
    [...props.tasks, taskCurrent],
  );
  const laboratoryCondition = 
    !!laboratoryCompleted && 
    moduleLast?.name === props.module?.name &&
    !!challengerLast;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { getCongratulations(); }, [props.challenger]);

  const getCongratulations = async () => {
    if (!!laboratoryCondition) {
      const data = await utils.getLaboratoryCongratulations(props.laboratory);
      setLaboratoryCongratulations(data);
    } else if (!!challengerLast) {
      const data = await utils.getModuleCongratulations(props.laboratory?.name, props.module);
      setModuleCongratulations(data);
    } else {
      const data = await utils.getChallengerCongratulations(
        props.laboratory?.name,
        props.module?.name,
        props.challenger,
      );
      setChallengerCongratulations(data);
    }
  }

  return (
    <Dialog 
      open={props.open} 
      classes={{ paper: classes.paper }}
      disableEscapeKeyDown
    >
      {!!props.open && !!laboratoryCondition ? (
        <LaboratoryCongratulations 
          rate={laboratoryRate}
          laboratory={props.laboratory}
          languageAudio={props.languageAudio}
          congratulations={laboratoryCongratulations}
          saveUserTask={props.saveUserTask}
          handleAnalytics={props.handleAnalytics}
        />
      ) : !!props.open && !!challengerLast ? (
        <ModuleCongratulations 
          rate={moduleRate}
          module={props.module}
          languageAudio={props.languageAudio}
          congratulations={moduleCongratulations}
          saveUserTask={props.saveUserTask}
        />
      ) :!!props.open && (
        <ChallengerCongratulations 
          rate={props.task?.rate}
          challenger={props.challenger}
          languageAudio={props.languageAudio}
          challengerNext={challengerNext}
          congratulations={challengerCongratulations}
          saveUserTask={props.saveUserTask}
        />
      )}
      
      <Loading loading={props.taskLoading} />
    </Dialog>
  )
}

export default DialogSuccess;
