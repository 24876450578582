import React, { FC, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { IChallenger } from "dto/challenger.dto";
import { IStep, IStepFiles } from "dto/step.dto";

const useStyles = makeStyles((theme) => ({
  normal: { height: "100%" },
  header: { 
    color: "white",
    height: 60,
    padding: "0 16px",
    fontSize: 40, 
    fontWeight: 700,
    alignItems: "center",
  },
  content: { 
    height: "calc(100% - 60px)",
    flexWrap: "initial",
    backgroundColor: "white", 
    [theme.breakpoints.down("xs")]: { display: "block", overflow: "auto" },
  },
  image: {
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    "& img": { maxWidth: "100%", maxHeight: "100%" },
    [theme.breakpoints.down("xs")]: { height: "fit-content" },
  },
  progress: { color: "#ff6315", margin: 16 },
  half: {
    width: 0,
    height: "100%",
    border: "1px solid #00000021",
    [theme.breakpoints.down("xs")]: { display: "none" }, 
  },
  text: {
    padding: 16,
    overflow: "auto",
    "&::-webkit-scrollbar": { width: 8 },
    "&::-webkit-scrollbar-track": { backgroundColor: "white" },
    "&::-webkit-scrollbar-thumb": { backgroundColor: "#c1c1c1", borderRadius: 8 },
    [theme.breakpoints.down("xs")]: { height: "fit-content" },
  },
}));

interface IProps {
  step: IStep;
  objective?: string;
  stepFiles: IStepFiles;
  stepIndex: number;
  challenger: IChallenger;
  description?: string;
  headerColor: string;
}

const StepNormal: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [stepImage, setStepImage] = useState("");
  const headerTitle = !props.stepIndex && !!props.challenger?.displayName 
    ? props.challenger.displayName
    : !props.stepIndex && !!props.challenger?.name
    ? props.challenger.name
    : !!props.step?.title
    ? props.step.title
    : t("Lección");

  useEffect(() => {
    setStepImage("");
    const timer = setTimeout(() => setStepImage(props.stepFiles.gif) , 500);

    return () => clearTimeout(timer);
  
  }, [props.stepFiles.gif]);

  const getStepText = (html: string) => {
    return <span dangerouslySetInnerHTML={{__html: html}} />;
  }

  const formatText = (text: string) => {
    const resp: string = text.split('\n').join('<br />');
    return resp;
  }

  return (
    <Grid className={classes.normal} container>
      <Grid 
        className={classes.header} 
        container 
        style={{ backgroundColor: props.headerColor }}
      >
        {headerTitle}
      </Grid>

      <Grid className={classes.content} container>
        <Grid className={classes.image} container>
          {!!stepImage ? (
            <img src={stepImage} alt="" />
          ) : (
            <CircularProgress className={classes.progress} />
          )}
        </Grid>

        {!!props.step.text && (<Grid className={classes.half} container />)}

        {!!props.step.text && (
          <Grid className={classes.text} container>
            <Grid container spacing={1}>
              <Grid className={classes.text} item xs={12}>
                <Typography>
                  {getStepText(props.step.text)}
                </Typography>
              </Grid>

              {!props.stepIndex && !!props.objective && (
                <Grid item xs={12}>
                  <Typography>
                    <b>{t("Objetivos pedagógicos")}:</b>{" "}
                    {getStepText(formatText(props.objective))}
                  </Typography>
                </Grid>
              )}

              {!props.stepIndex && !!props.description && (
                <Grid item xs={12}>
                  <Typography>
                    <b>{t("Descripción")}:</b>{" "}
                    {getStepText(formatText(props.description))}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default StepNormal;
