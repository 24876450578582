import { ILanguage } from "dto/layout.dto";
import { AppThunkAction } from "store/types";
import { LayoutActionType } from "./actions";

export * from "./actions";
export * from "./constants";

export interface LayoutState {
  language: ILanguage;
  moduleLogo: string;
  organizationImage: string;
}

export type Action = AppThunkAction<LayoutActionType>
