import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IUser } from "dto/user.dto";
import { IEntityCode } from "dto/entity-code.dto";
import { State } from "store/types";
import { history } from "configureStore";
import * as actions from "store/actions";
import CreateAccount from "components/Login/CreateAccount/CreateAccount";
import PageLoginCarrusel from "components/Pages/PageLoginCarrusel";

const CreateAccountPage = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: State) => state.user.user);
  const confirm = useSelector((state: State) => state.auth.confirm);
  const loading = useSelector((state: State) => state.auth.loading);
  const countries = useSelector((s: State) => s.country.countries);
  const isNewUser = useSelector((state: State) => state.auth.isNewUser);
  const codeVerification = useSelector((state: State) => state.auth.codeVerification);
  const verifiedEmailError = useSelector((s: State) => s.auth.verifiedEmailError);

  useEffect(() => { if (!!user.nit) history.push("/home") }, [user]);

  const verifiedEmail = (email: string) => {
    dispatch(actions.verifiedEmail(email));
  }
  const verifiedPhone = (phoneNumber: string, reCAPTCHA: any) => {
    dispatch(actions.verifiedPhone(phoneNumber, reCAPTCHA))
  }

  const closeEmailForm = () => dispatch(actions.setIsNewUser(false));

  const registerWithEmail = (
    email: string, 
    password: string, 
    user: IUser, 
    entityCode?: IEntityCode,
  ) => {
    dispatch(actions.registerWithEmail(email, password, user, entityCode));
  }
  const registerWithPhone = (code: string, user: IUser) => {
    dispatch(actions.registerWithPhone(code, user));
  }

  const changePhoneNumber = () => dispatch(actions.changePhoneNumber());
  const changeCodeVerification = (code: string) => {
    dispatch(actions.changeCodeVerification(code));
  }

  const updateVerifiedEmailError = (value: boolean) => {
    dispatch(actions.setVerifiedEmailError(value)); 
  }

  return (
    <PageLoginCarrusel>
      <CreateAccount
        confirm={confirm}
        loading={loading}
        countries={countries}
        isNewUser={isNewUser}
        codeVerification={codeVerification}
        verifiedEmailError={verifiedEmailError}
        verifiedEmail={verifiedEmail}
        verifiedPhone={verifiedPhone}
        closeEmailForm={closeEmailForm}
        changePhoneNumber={changePhoneNumber}
        registerWithEmail={registerWithEmail}
        registerWithPhone={registerWithPhone}
        changeCodeVerification={changeCodeVerification}
        updateVerifiedEmailError={updateVerifiedEmailError}
      />
    </PageLoginCarrusel>
  )
}

export default CreateAccountPage;
