import { IModule } from "dto/module.dto";
import { ILaboratoryCongratulations } from "dto/laboratory.dto";

export const moduleCongratulationsInit: ILaboratoryCongratulations = {
  complete_image: "/gif/default/module/congratulations_complete_image_default.gif",
  complete_audio_fl: "/audio/default/module/congratulations_complete_audio_default.mp3",
  complete_audio_sl: "/audio/default/module/congratulations_complete_audio_default.mp3",
  complete_text_one: "¡Módulo completado!",
  complete_text_two: "Dale continuar para avanzar",
  incomplete_image: "/gif/default/module/congratulations_incomplete_image_default.gif",
  incomplete_audio_fl: "/audio/default/module/congratulations_incomplete_audio_default.mp3",
  incomplete_audio_sl: "/audio/default/module/congratulations_incomplete_audio_default.mp3",
  incomplete_text_one: "¡Oops!",
  incomplete_text_two: "Vuelve a intentarlo",
}

export const moduleInit: IModule = {
  name: "",
  logo: "",
  image: "",
  version: 0,
  imageUrl: "",
  challenges:  [],
  displayName: "",
  hide_ratings: false,
  congratulations: moduleCongratulationsInit,
  background_image: "",
  background_video: "",
  background_video_audio: false,
}
