import React from 'react';
import { makeStyles, Typography, DialogContent } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  content: { '& .MuiTypography-root': { marginBottom: 15 } },
}));

const TermsServiceEN = () => {
  const classes = useStyles();

  return (
    <DialogContent className={classes.content}>
      <Typography variant="subtitle2" align="justify" gutterBottom>
        O-lab is a platform and mobile application
        (together, the "Platforms") that contains
        courses and programs of online and offline
        training created by public and private
        institutions for users of the O-lab mobile
        application. El Origen Foundation
        registered under the NIT 900900464-0 in Bogotá - Colombia is the one
        who participates as a licensee of the O-lab app in Colombia.
      </Typography>

      <Typography variant="caption" align="justify" display="block" gutterBottom>
        These terms and conditions refer to the use of the platform & app (the
        “Platforms”) of courses and training programs, called “O-lab”. This is
        applicable on all its platforms; APP for Android operating systems, and
        web APP, as well as any functionality on the website: www.o-lab.app
        or any other service that may be provided to the user or educational
        institution and sponsor. These terms and conditions are considered
        accepted by the users of the Platforms when registering and entering
        and in the case of not accepting them, they must stop being used
        immediately. FUNDACIÓN EL ORIGEN as its licensee reserves the right
        to make changes to these terms and conditions that will take effect
        from the date of their publication. The use of these platforms will
        constitute acceptance of these changes.
      </Typography>

      <Typography variant="caption" align="justify" display="block" gutterBottom>
        1. Conditions of access and use EL ORIGEN FOUNDATION conditions
        the use of the services of the Platforms to the prior completion of forms
        and the corresponding payment for the provision of their services. The
        client guarantees the authenticity and timeliness of all the data
        provided and will be solely responsible for any false or inaccurate
        statements made.
      </Typography>

      <Typography variant="caption" align="justify" display="block" gutterBottom>
        2. About the information posted on these Platforms The content on this
        site is for educational purposes only. All the information deposited on
        these Platforms, as well as names, images, photos, etc. They are
        provided without any representation or endorsement and without
        warranty of any kind, either express or implied. In no case will
        Fundación El Origen be held responsible for any direct or indirect
        damage.<br />
        caused by the use of the Platforms, whether by action or omission. The
        following guidelines and policies are defined in order to improve the
        use and administration of the O-lab educational platforms.
      </Typography>

      <Typography variant="caption" align="justify" display="block" gutterBottom>
        3. Limited license to use Fundación El Origen authorizes the user / client /
        partner to access the Platforms for educational purposes. Any type of
        reproduction or modification of any content published on this site is
        prohibited, unless Fundación El Origen authorizes otherwise, expressly and
        in writing. Fundación El Origen reserves all rights that are not explicitly
        granted in this document.
      </Typography>

      <Typography variant="caption" align="justify" display="block" gutterBottom>
        4. Intellectual property The intellectual property of the development of the
        application and the service provided is exclusive to Fundación El Origen.
        This includes the entire software and its parts, as well as the brand, name,
        logo, and / or any of its components.<br /><br />
        Copying and / or distribution of all or part of the software is prohibited,
        without the owner's written permission, even if the purpose of such
        copying is a non-profit activity. Fundación El Origen software is
        non-transferable under any title and under any modality.<br />
        Likewise, all the information made available, inserted, mentioned, used in
        the Platforms and their contents (texts, photographs, graphics, images,
        technology, software, links, contents, graphic design, source code, etc.), as
        well as The trademarks and other distinctive signs are the property of the
        EL ORIGEN FOUNDATION or of third parties that have authorized the
        Institution to use their intellectual property rights. The use of and access to
        the Platforms does not grant, grant, or transfer any type of right, license or
        authorization of use or provision of intellectual property rights. Therefore,
        the total or partial reproduction of the contents protected by the intellectual
        property law, as well as the commercial use of the contents of the
        mobile application, its sale, distribution to third parties or its modification.
        Any other use requires prior, written and explicit authorization from the EL
        ORIGEN FOUNDATION, therefore, whenever the information related to it is
        used, the copyright granted by the Law must be protected. Any
        unauthorized use will constitute a violation of these Terms and Conditions
        of Use, and the current national and international regulations on Intellectual
        Property.
      </Typography>

      <Typography variant="caption" align="justify" display="block" gutterBottom>
        5. Consideration The entity that wishes to sponsor the access of the app to
        "Administrators" users, will pay the EL ORIGEN FOUNDATION annually the
        consideration for the payment of its services: the amount, date of payment,
        payment method and the Rights and obligations of the parties (the
        Company and the Foundation) will be subject to the specifications
        established in the Contract for the Provision of Services that is entered
        into. With each specific entity, so in case of doubt, the specific contract should
        be consulted.
      </Typography>

      <Typography variant="caption" align="justify" display="block" gutterBottom>
        6. Policies for use and restrictions of administrators and users Regarding
        the policies and restrictions of administrators and users, the following
        points defined in the migration requirements already known are
        considered: I. User policies and restrictions:<br />
        a. The use of the Platforms by anyone attributes the quality of user and
        this implies adherence to the terms of use.<br />
        b. Through the Platforms, the user will have access to the platform and the
        use of educational content.<br />
        c. The user acknowledges that the use of the contents does not provide him
        with any property rights over them.<br />
        d. To access the contents of the Platforms, the user is obliged to enroll in
        the specified time and manner and to the current regulations of the EL
        ORIGEN FOUNDATION.<br />
        e. The administrator of the Platforms provides the user with a course
        code, these are unique data for each user. The user assumes the
        responsibility of maintaining the confidentiality and the use of his user data
        and password provided. The user should not change the assigned
        passwords or user names for any reason.<br />
        f. It is the user's responsibility to inform the administrator, in the event that
        they suspect that someone else is using their account to access the
        Platforms, a password or user change will be made.<br />
        g. The Platforms may only be used for academic and training purposes.<br />
        h. The user must report to the administrator any case of which he believes 
        that copyright is being infringed, or violates intellectual property, 
        as well as inappropriate material.<br />
        i. The user undertakes to use the content correctly and undertakes to
        respect all intellectual property rights over the content.<br />
        j. The content is intended only for the use of authorized users, therefore it is
        allowed to print and copy said content as long as it is for personal study
        and academic purposes. <br />
        k. To use the contents of the Platforms it is
        necessary for the user to provide certain personal data assigned in the
        Profile, FUNDACIÓN EL ORIGEN will not share any personal information
        that has been provided to third parties.<br />
        l. The platforms allow the development of content and establish exchange
        spaces as task spaces, the student user is responsible for the material they
        use, as well as any act that occurs through the use of their user password.<br />
        m. The user must guarantee the following actions:<br />
        i. Avoid publishing content that affects the image of the 
        EL ORIGEN FOUNDATION and O-lab, as well as its members.<br />
        ii. Do not publish political or religious propaganda.<br />
        iii. Do not publish any link with an electronic address that affects the dignity
        of the educational community. iv. Ensure that the content, images, videos
        and links that you publish are as small as possible so that they do not affect
        the performance of the system. v. Avoid publishing content of which the
        author is not, or, where appropriate, define the corresponding references.<br />
        n. The user must check that the files he publishes are virus-free and comply
        with the aforementioned guidelines.<br />
        o. FUNDACIÓN EL ORIGEN reserves the right to review, remove, edit a
        material that a user has published without taking into account the above
        considerations, as well as temporarily or permanently suspend the
        responsible user.<br />
        p. The EL ORIGEN FOUNDATION is alien to external internet links and that
        can be accessed through the content on the Platforms.<br />
        q. The total or partial reproduction of the contents of this page, by any
        means or procedure, without the prior, express and written authorization of
        the EL ORIGEN FOUNDATION is prohibited. The images, text design,
        graphics, as well as the content presented on this page, are illustrative and
        informative and are the property of Fundación El Origen, acknowledging the
        intellectual authorship of the designers. Any form of unauthorized use will
        be prosecuted.<br />
        r. In users, the role assignment is established as follows:<br />
        1. The administrator requires a superuser class on the O-lab platform and
        may have administrative privileges.<br />
        2. The tutor only needs teacher privileges without editing permission except
        those that are authorized by the administrator.<br />
        3. The student only requires learner privileges. 4. The superuser has all
        administrative privileges.<br />
      </Typography>

      <Typography variant="caption" align="justify" display="block" gutterBottom>
        7. Access and navigation in the application By using the Platforms, the User
        undertakes to act lawfully and diligently and undertakes not to carry out any
        of the activities described below:<br />
        1. Reproduce, copy, distribute, modify, sell, license, adapt, the content, 
        exposed here, without the prior, express and written authorization of the 
        EL ORIGEN FOUNDATION.<br />
        2. Reproduce, copy, distribute, modify, sell, license, adapt, any element, text,
        photograph or audiovisual material of the Platforms subject to intellectual
        property rights without the prior authorization of the EL ORIGEN
        FOUNDATION.<br />
        3. Violate, evade, manipulate in any way or by any procedure the
        technological security measures.<br />
        4. Use the contents of the Platforms for illegal purposes, contrary to these
        Terms and Conditions of Use, which violate the law. You do not need to ask
        for permission before providing a link to the resources on this course
        platform.<br />
      </Typography>

      <Typography variant="caption" align="justify" display="block" gutterBottom>
        8. Security The only persons responsible for keeping safe and avoiding the
        use by third parties of the access passwords to the O-lab platforms are the
        users themselves, and the FOUNDATION is not the responsibility of the EL
        ORIGEN FOUNDATION. We advise you not to reveal any personal
        information about yourself or anyone else.
      </Typography>

      <Typography variant="caption" align="justify" display="block" gutterBottom>
        9. Breach of terms and conditions Any controversy or conduct of conduct
        prohibited by these terms and conditions and / or the general regulations of
        the EL ORIGEN FOUNDATION, will result in the immediate cancellation of
        the profile of the sanctioned user. Both normative documents will always be
        available and accessible to users on the home pages of the educational
        digital platforms they use.
      </Typography>

      <Typography variant="caption" align="justify" display="block" gutterBottom>
        10. Technical support We offer remote technical service via email with a
        response within 24 to 48 hours.
      </Typography>
    </DialogContent>
  )
}

export default TermsServiceEN;
