import React, { FC, useEffect, useState } from "react";
import clsx from "clsx";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { RouteComponentProps } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { State } from "store/types";
import { IQuestion } from "dto/question.dto";
import { IRouterParams } from "dto/router.dto";
import { history } from "configureStore";
import { permissionsPlatformInit } from "initials/permission.init";
import * as actions from "store/actions";
import Survey from "components/Survey/Survey";
import Loading from "components/Common/Loading";
import Background from "components/Common/Background";
import AppBarLayout from "layouts/AppBarLayout";

const useStyles = makeStyles(() => ({
  root: { height: "100vh" },
  page: { 
    height: "calc(100% - 70px)", 
    position: "relative", 
    "&.zoom": { height: "100%" },
  },
  content: {
    height: "100%",
    position: "relative", 
    overflow: "auto",
    alignItems: "center",
    justifyContent: "center",
    "&::-webkit-scrollbar": { width: 8 },
    "&::-webkit-scrollbar-track": { backgroundColor: "white" },
    "&::-webkit-scrollbar-thumb": { backgroundColor: "#c1c1c1", borderRadius: 8 },
  },
}));

interface IProps extends RouteComponentProps<IRouterParams> {}

const SurveyPage: FC<IProps> = ({ match }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((s: State) => s.user.user);
  const survey = useSelector((s: State) => s.survey.surveyCurrent);
  const loading = useSelector((s: State) => s.survey.surveyLoading);
  const languageAudio = useSelector((s: State) => s.user.languageAudio);
  const saveAnswerLoading = useSelector((s: State) => s.survey.saveAnswerLoading);
  const dialogCongratulations = useSelector((s: State) => s.survey.dialogCongratulations);
  const [zoom, setZoom] = useState(false);
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { getSurveyPrivate(); }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { if (!loading && !survey.uuid) closeSurveyBook() }, [loading]);

  const getSurveyPrivate = () => {
    if (!survey.uuid) {
      const uid = match.params.survey_uuid;
      dispatch(actions.getSurveyPrivate(uid));
    } else dispatch(actions.setSurveyCurrent(survey));
  }

  const saveSurveyAnswers = (questions: IQuestion[]) => {
    dispatch(actions.saveSurveyAnswers(survey.uuid, questions, {
      ...user,
      tasks: [],
      permissions: [],
      permissions_survey: [],
      permissionsPlatform: permissionsPlatformInit,
    }));
  }

  const onClickZoom = () => setZoom(!zoom);
  const closeSurveyBook = () => {
    history.push("/home");
    dispatch(actions.resetSurveyCurrent());
  }

  const closeCongratulations = () => dispatch(actions.closeCongratulations());

  const handleAnalytics = (name: string, params?: { [key: string]: string }) => {
    dispatch(actions.analytics(name, params));
  }

  return (
    <Grid className={classes.root} container>
      <AppBarLayout zoom={zoom} hideButtonLanguage={!!user?.nit} />

      <Grid className={clsx(classes.page, zoom && "zoom")} container>
        <Background 
          backgroundImage={survey.properties.wallpaper_url}
          backgroundVideo={survey.properties.background_video_url}
        />

        <Grid className={classes.content} container>
          {!!survey?.questions?.length && (
            <Survey 
              zoom={zoom} 
              survey={survey} 
              questions={survey.questions}
              languageAudio={String(languageAudio)}
              saveAnswerLoading={saveAnswerLoading}
              dialogCongratulations={dialogCongratulations}
              onClickZoom={onClickZoom}
              closeSurveyBook={closeSurveyBook}
              handleAnalytics={handleAnalytics}
              saveSurveyAnswers={saveSurveyAnswers}
              closeCongratulations={closeCongratulations}
            />
          )}
        </Grid>
      </Grid>

      <Loading loading={loading} />
    </Grid>
  )
}

export default SurveyPage;
