import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { makeStyles, Grid, Button } from '@material-ui/core';
import { ILicense } from '../../services/checkout.services';
import Loading from '../Common/Loading';
import StripeCheckout  from './StripeCheckout';
import LicenseSelected from './License/Selected';

const useStyles = makeStyles((theme) => ({
  provider: { 
    maxWidth: 600, 
    padding: 15, 
    position: 'relative', 
  },
  accept: { 
    width: 350, 
    color: 'white',
    backgroundColor: '#ff6315', 
    borderRadius: 25, 
    '&:hover': { backgroundColor: 'white', color: '#ff6315' },
    [theme.breakpoints.down("xs")]: { 
      width: '100%', 
      '&:hover': { backgroundColor: '#ff6315', color: 'white' },
    },
  },
  btn: { width: 350, borderRadius: 25, margin: '8px 0 0 0'  },
  contactUs: { padding: '10px 0' },
  link: { textDecoration: 'none', color: 'initial' },
}));

interface IProps {
  license: ILicense
  currency: "usd"|"cop"
  prevSection: () => void
  nextSection: () => void
  setCurrency: (val: "usd"|"cop") => void
  updateInfo: (data: {name: string, email: string, phone: string, entity: string}) => void
}

const StripeProvider: FC<IProps> = ({
  license, 
  currency,
  updateInfo, 
  prevSection, 
  nextSection, 
  setCurrency,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const publishableKey = process.env.REACT_APP_PUBLISHABLE_KEY ?? "";
  const stripePromise = loadStripe(publishableKey);
  const [loading, setLoading] = React.useState(false);

  return (
    <Grid className={classes.provider} container justifyContent="center">
      {loading && (<Loading white loading />)}

      <Grid container justifyContent="center">
        <LicenseSelected license={license} currency={currency} setCurrency={setCurrency} />
      </Grid>


      {currency === "cop" && (
        <Grid className={classes.contactUs} container justifyContent="center" alignItems="flex-end">
          <a className={classes.link} href="https://o-lab.app/es/pagos-colombia/">
            <Button className={classes.accept} variant="contained">
              {t("Contáctanos")}
            </Button>
          </a>
        </Grid>
      )}

      {currency === "usd" && (
        <Elements stripe={stripePromise}>
          <StripeCheckout
            license={license}
            currency={currency}
            updateInfo={updateInfo}
            nextSection={nextSection}
            updateLoading={setLoading}
          />
        </Elements>
      )}

      <Button className={classes.btn} variant="contained" onClick={prevSection}>
        {t("Volver")}
      </Button>
    </Grid>
  )
}

export default StripeProvider;
