import { IEntityCode } from "dto/entity-code.dto";

export const entityCodeInit: IEntityCode = {
  uuid: "",
  code: "",
  date: "",
  name: "",
  state: 0,
  users: [],
  entity_id: "",
  entity_name: "",
  permissions: [],
  permissions_survey: [], 
}
