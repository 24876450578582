import { EmailActionType } from "./actions";
import { AppThunkAction } from "store/types";
import { IContact, IEmail, INewMessages } from "dto/email.dto";

export * from "./actions";
export * from "./constants";

export interface EmailState {
  emails: IEmail[];
  contacts: IContact[];
  newMessages: INewMessages;
  emailCurrent: IEmail;
  emailsLoading: boolean;
  contactsLoading: boolean;
  dialogDateBirth: boolean;
  emailNoteLoading: boolean;
}

export type Action = AppThunkAction<EmailActionType>
