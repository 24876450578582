import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  correct: { color: "#50c988", },
  incorrect: { color: "#f25b5b", },
}));

interface IProps {
  correct?: boolean;
  feedback?: boolean;
}

const FeedbackText: FC<IProps> = ({ correct, feedback }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      {!!feedback && !!correct && (
        <Typography className={classes.correct} noWrap>
          <b>{t("Respuesta Correcta")}</b>
        </Typography>
      )}
      {!!feedback && !correct && (
        <Typography className={classes.incorrect} noWrap>
          <b>{t("Respuesta Incorrecta")}</b>
        </Typography>
      )}
    </>
  )
}

export default FeedbackText;
