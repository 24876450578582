const pad = (num: string, totalChars: number) => {
  const pad = "0";
  num = num + "0";
  while (num.length < totalChars) {
    num = pad + num;
  }
  return num;
};

const changeColor = (color: string, ratio: number, darker: boolean) => {
  color = color.replace(/^\s*|\s*$/, "");
  color = color.replace(
    /^#?([a-f0-9])([a-f0-9])([a-f0-9])$/i,
    "#$1$1$2$2$3$3"
  );
  let difference = Math.round(ratio * 256) * (darker ? -1 : 1),
    rgb = color.match(
      new RegExp(
        "^rgba?\\(\\s*" +
          "(\\d|[1-9]\\d|1\\d{2}|2[0-4][0-9]|25[0-5])" +
          "\\s*,\\s*" +
          "(\\d|[1-9]\\d|1\\d{2}|2[0-4][0-9]|25[0-5])" +
          "\\s*,\\s*" +
          "(\\d|[1-9]\\d|1\\d{2}|2[0-4][0-9]|25[0-5])" +
          "(?:\\s*,\\s*" +
          "(0|1|0?\\.\\d+))?" +
          "\\s*\\)$",
        "i"
      )
    ),
    alpha = !!rgb && rgb[4] != null ? rgb[4] : null,
    decimal = !!rgb
      ? [rgb[1], rgb[2], rgb[3]]
      : color
        .replace(
          /^#?([a-f0-9][a-f0-9])([a-f0-9][a-f0-9])([a-f0-9][a-f0-9])/i,
          function () {
            return (
              parseInt(arguments[1], 16) + "," +
              parseInt(arguments[2], 16) + "," +
              parseInt(arguments[3], 16)
            );
          }
        )
        .split(/,/);
  
    return !!rgb
    ? "rgb" + (alpha !== null ? "a" : "") + "(" +
      Math[darker ? "max" : "min"](parseInt(decimal[0], 10) + difference, darker ? 0 : 255) + ", " +
      Math[darker ? "max" : "min"](parseInt(decimal[1], 10) + difference, darker ? 0 : 255) + ", " +
      Math[darker ? "max" : "min"](parseInt(decimal[2], 10) + difference, darker ? 0 : 255) +
      (alpha !== null ? ", " + alpha : "") + ")"
    :
      [
        "#",
        pad(
          Math[darker ? "max" : "min"](
            parseInt(decimal[0], 10) + difference,
            darker ? 0 : 255
          ).toString(16),
          2
        ),
        pad(
          Math[darker ? "max" : "min"](
            parseInt(decimal[1], 10) + difference,
            darker ? 0 : 255
          ).toString(16),
          2
        ),
        pad(
          Math[darker ? "max" : "min"](
            parseInt(decimal[2], 10) + difference,
            darker ? 0 : 255
          ).toString(16),
          2
        ),
      ].join("");
};

export const lighterColor = (color: string, ratio: number) => {
  return changeColor(color, ratio, false);
};

export const darkerColor = (color: string, ratio: number) => {
  return changeColor(color, ratio, true);
};

export const hexadecimalToRgba = (hex: string) => {
  let c: any;
  if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
    c = hex.substring(1).split('');
    if (c.length === 3){
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return "rgba(" + [(c>>16)&255, (c>>8)&255, c&255].join(",") + ", 1)";
  }
  throw new Error("Bad Hex");
}
