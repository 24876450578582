import React, { FC, useState } from "react";
import { IUser } from "dto/user.dto";
import { ICountry } from "dto/country.dto";
import { IEntityCode } from "dto/entity-code.dto";
import { userInit } from "initials";
import { entityCodeInit } from "initials/entity-code.init";
import * as utils from "utils/entity-code.utils";
import EntityCodeForm from "./EntityCodeForm";
import { messageError } from "components/Common/Toast";
import UserTypeTest from "./UserTypeTest";
import EntityCodeSuccess from "./EntityCodeSuccess";
import RegisterEmailForm from "./RegisterEmailForm";
import RegisterEmailVerify from "./RegisterEmailVerify";

interface IProps {
  countries: ICountry[];
  isNewUser: boolean;
  entityCodeView: number;
  verifiedEmailError: boolean;
  verifiedEmail: (email: string) => void;
  registerWithEmail: (email: string, password: string, u: IUser, c?: IEntityCode) => void;
  updateLoadingLocal: (loading: boolean) => void;
  updateEntityCodeView: (view: number) => void;
  updateVerifiedEmailError: (value: boolean) => void;
}

const RegisterWithEmail: FC<IProps> = (props) => {
  const [user, setUser] = useState(userInit);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [entityCode, setEntityCode] = useState(entityCodeInit);

  const updateUser = (name: string, value: string) => setUser({ ...user, [name]: value });

  const openUserTypeTest = () => props.updateEntityCodeView(1);
  const openEntityCodeForm = () => props.updateEntityCodeView(2);
  const openEntityCodeSuccess = () => props.updateEntityCodeView(3);

  const updateEntityCodeCode = (code: string) => setEntityCode({ ...entityCode, code });

  const sendEntityCode = async () => {
    props.updateLoadingLocal(true);
    const data = await utils.getEntityCodeByCode(entityCode.code);
    if (!!data) {
      setUser({ 
        ...user, 
        sponsor: data.entity_name,
        codeCourse: data.code,
        permissions: data.permissions,
      });
      setEntityCode(data);
      openEntityCodeSuccess();
    } else messageError(("El código ingresado no es válido")); 
    props.updateLoadingLocal(false);
  }

  const registerWithEmail = async () => {
    props.updateLoadingLocal(true);
    const permissions = [
      {
        moduleName: "introducción_nnyanzxnwcv8ok1evmduvw",
        laboratoryName: "explorao-lab_qhb5pp8j3jcsl2raf1mkjd",
      }
    ];
    props.registerWithEmail(
      email, 
      password, 
      { ...user, sponsor: "Nuevos_Usuarios", permissions },
    );
    props.updateLoadingLocal(false);
  }
  const registerWithEmailAndEntityCode = () => {
    props.registerWithEmail(email, password, user, entityCode);
  }

  return !!props.isNewUser && props.entityCodeView === 3 ? (
    <EntityCodeSuccess 
      entityCode={entityCode}
      registerWithEmail={registerWithEmailAndEntityCode} 
    />
  ) : !!props.isNewUser && props.entityCodeView === 2 ? (
    <EntityCodeForm
      entityCode={entityCode?.code}
      sendEntityCode={sendEntityCode} 
      updateEntityCode={updateEntityCodeCode}
      registerWithEmail={registerWithEmail}
    /> 
  ) : !!props.isNewUser && props.entityCodeView === 1 ? (
    <UserTypeTest 
      userType={user?.userType} 
      updateUserType={updateUser} 
      openNextStep={openEntityCodeForm}
    />
  ) : !!props.isNewUser ? (
    <RegisterEmailForm 
      user={user}
      email={email}
      password={password}
      countries={props.countries}
      updateUser={setUser}
      openNextStep={openUserTypeTest}
      updateLoadingLocal={props.updateLoadingLocal}
    />
  ) : (
    <RegisterEmailVerify 
      email={email}
      password={password}
      verifiedEmailError={props.verifiedEmailError}
      updateEmail={setEmail} 
      verifiedEmail={props.verifiedEmail}
      updatePassword={setPassword} 
      updateVerifiedEmailError={props.updateVerifiedEmailError}
    />
  );
}

export default RegisterWithEmail;
