import React, { FC, useState } from "react";
import clsx from "clsx";
import { object, string } from "yup";
import { useTranslation } from "react-i18next";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";
import { IUser } from "dto/user.dto";
import { userInit } from "initials";
import * as utils from "utils";
import { getAge } from "utils";
import FormButton from "components/Form/FormButton";
import { messageError } from "components/Common/Toast";
import {
  Grid,
  Select, 
  MenuItem, 
  TextField,
  Typography, 
  FormControl, 
  FormHelperText,
} from "@material-ui/core";
import { ICountry } from "dto/country.dto";

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    "& .MuiInputBase-root ": { 
      textAlign: "left",
      borderRadius: 8, 
      "& .MuiInputBase-input": { 
        padding: "10.5px 14px",
        "&:disabled": { color: "black" }
      },
    }
  },
  h5: { color: "#5f5f5f", fontWeight: 700 },
  disabled: { color: "#aaa" },
}));

interface IInitialValues {
  nit: string;
  age: string;
  sex: string;
  name: string;
  email: string;
  phone: string;
  country: string;
}
interface IProps {
  phone: string;
  loading: boolean;
  userData: IUser;
  countries: ICountry[];
  repeatLoading: boolean;
  registerUser: (user: IUser) => void;
  updateLoadingLocal: (loading: boolean) => void;
}

const RegisterPhoneForm: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [errorNit, setErrorNit] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  
  const initialValues: IInitialValues = { 
    nit: props.userData.nit,
    age: !!props.userData.age ? props.userData.age.toString() : "",
    sex: props.userData.sex,  
    name: props.userData.name,
    email: props.userData.email,
    phone: props.phone,
    country: ""
  }
  const validationSchema = object().shape({
    nit: string().required(t("Campo requerido")),
    age: string().required(t("Campo requerido")),
    sex: string().required(t("Campo requerido")),
    country: string().required(t("Campo requerido")),
    name: string().required(t("Campo requerido")),
    email: string().required(t("Campo requerido")).email("example@example.com"),
    phone: string().required(t("Campo requerido")),
  });
  const onSubmit = async (values: IInitialValues) => {
    const user: IUser = {
      ...userInit,
      nit: values.nit,
      age: Number(getAge(values?.age)),
      rol: "Estudiante",
      sex: values.sex,
      name: values.name,
      admin: false,
      email: values.email,
      phone: props.phone,
    }
    if (user.age < 7 || user.age <= 0 || user.age === -1){
      return messageError("Edad minima 7 años")
    }
    props.updateLoadingLocal(true);
    const repeatNit = await utils.verifiedRepeatNit(user.nit);
    const repeatEmail = await utils.checkRepeatEmail(user.email);
    if (!repeatNit && !repeatEmail) props.registerUser(user);
    else { 
      setErrorNit(repeatNit); 
      setErrorEmail(repeatEmail); 
    }
    props.updateLoadingLocal(false);
  }

  const onFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = (e.target as HTMLInputElement).name
    if (name === "nit") setErrorNit(false);
    if (name === "email") setErrorEmail(false);
  }
  const onKeyPress = (e: {key: string, preventDefault: () => void}) => {
    const reg = new RegExp('^[0-9]+$');
    if (!reg.test(e.key)) e.preventDefault();
  }

  return (
    <Formik 
      initialValues={initialValues} 
      validationSchema={validationSchema} 
      onSubmit={onSubmit}
    >
      {({errors, touched, values}) => (
        <Form className={classes.form}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography 
                className={classes.h5} 
                align="center" 
                variant="h5"
                gutterBottom
              >
                {t("Completa tus datos")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Field 
                name="nit" 
                as={TextField}
                size="small"
                variant="outlined"
                placeholder={t("ID")}
                onFocus={onFocus}
                error={(errors.nit && touched.nit) || errorNit}
                helperText={(touched.nit && !!errors.nit) ? errors.nit 
                  : errorNit && t("Existe un usuario con esa identificación")
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Field 
                name="name" 
                as={TextField}
                size="small"
                variant="outlined"
                placeholder={t("Nombre y apellido")}
                error={errors.name && touched.name}
                helperText={touched.name && errors.name}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Field 
                name="email"
                as={TextField}
                size="small"
                variant="outlined"
                placeholder={t("Correo electrónico")}
                onFocus={onFocus}
                error={(errors.email && touched.email) || errorEmail}
                helperText={(touched.email && !!errors.email) ? errors.email 
                  : errorEmail && t("Existe un usuario con ese correo electrónico")
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Field 
                name="age"
                as={TextField}
                size="small"
                variant="outlined"
                placeholder={t("Edad")}
                onKeyPress={onKeyPress}
                type="date"
                error={errors.age && touched.age}
                helperText={touched.age && errors.age}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl 
                variant="outlined" 
                error={!!errors.sex && !!touched.sex}
                fullWidth
              >
                <Field 
                  className={clsx(!values.sex && classes.disabled)}
                  as={Select}
                  name="sex"
                  fullWidth
                  displayEmpty
                >
                  {utils.genders.map((gender) => (
                    <MenuItem 
                      key={gender.value} 
                      value={gender.value}
                      // disabled={gender?.disabled}
                    >
                      {t(gender.label)}
                    </MenuItem>
                  ))}
                </Field>
                {touched.sex && <FormHelperText>{errors.sex}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl 
                variant="outlined" 
                error={!!errors.country && !!touched.country}
                fullWidth
              >
                <Field 
                  className={clsx(!values.country && classes.disabled)}
                  as={Select}
                  name="country" 
                  displayEmpty
                >
                  {props.countries.map((count) => (
                    <MenuItem key={count.value} value={count.value}>
                      {t(count.label)}
                    </MenuItem>
                  ))}
                </Field>
                {touched.country && <FormHelperText>{errors.country}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormButton 
                type="submit"
                primary
                disabled={props.loading || props.repeatLoading}
                fullWidth
              >
                {t("Continuar")}
              </FormButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default RegisterPhoneForm;