import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Card, CardMedia, CardContent, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  card: { '& .MuiCardContent-root': { padding: 15 } },
}));

const DonationInfo = () => {
  const classes  = useStyles();
  const { t } = useTranslation();

  return (
    <Card className={classes.card}>
      <CardMedia alt="" image="/images/donation_01.png" height="240" component="img" />
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          {t("¡Cerremos la brecha juntos!")}
        </Typography>
        <Typography align="justify" variant="body2" gutterBottom>
          {t("El 100% de cada donación va directamente a una persona/escuela/comunidad específica que espera nuestro kit #educacióndigitalparatodos que los equipa con tecnología, capacitación a profesores y nuestro LMS y aplicación de aprendizaje fuera de línea O-lab lleno de cursos complementarios, vocacionales y STEAM creados en colaboración con maestros e instituciones locales en todo el mundo.")}           
        </Typography>
        <Typography variant="body2">
          {t("¡Te mantendremos informado sobre el impacto de tu donación! #somoselorigen")}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default DonationInfo;
