import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Grid, Button } from '@material-ui/core';
import { ILicense, IProject } from '../../../services/checkout.services';
import ServicesTable    from './ServicesTable';
import ServicesCustom   from '../Service/ServicesCustom';
import ServicesOptional from '../Service/ServicesOptional';

const useStyles = makeStyles((theme) => ({
  service: { 
    padding: '15px 5px', 
    [theme.breakpoints.down("xs")]: { display: 'none' },
  },
  content: { marginBottom: 30 },
  button: { width: 350, borderRadius: 25 },
  link: { textDecoration: 'none', color: 'initial' },
}));

interface IProps {
  projects: IProject[]
  selectService: (value: ILicense) => void
  openContactUs: () => void
  openServiceOptional: () => void
}

const Service: FC<IProps> = ({projects, selectService, openContactUs, openServiceOptional}) => {
  const classes  = useStyles();
  const { t } = useTranslation();

  return (
    <Grid className={classes.service} container justifyContent="center">
      <Grid className={classes.content} container justifyContent="center">
        <ServicesTable projects={projects} selectService={selectService} />
        <ServicesCustom openContactUs={openContactUs} />
        <ServicesOptional openServiceOptional={openServiceOptional} />
      </Grid>

      <a className={classes.link} href="https://www.originlearningfund.org/o-lab">
        <Button className={classes.button} variant="contained">
          {t("Volver")}
        </Button>
      </a>
    </Grid>
  )
}

export default Service;
