import React, { FC } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { ISurveyCongratulations } from "dto/survey.dto";
import FormButton from "components/Form/FormButton";

const useStyles = makeStyles((theme) => ({
  content: {
    gap: 20,
    height: "calc(100% - 110px)",
    padding: 32,
    textAlign: "center",
    alignItems: "center" ,
  },
  image: { 
    height: "calc(100% - 64px)",
    justifyContent: "center", 
    "& img": { maxWidth: "100%", maxHeight: "100%" },
  },
}));

interface IProps {
  congratulations: ISurveyCongratulations;
  onClose: () => void;
}

const DialogCongratulationsContent: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid className={classes.content} container>
      <Grid className={classes.image} container>
        <img src={props.congratulations?.image_url} alt="" />
      </Grid>
      <Grid item xs={12}>
        <FormButton primary minWidth={296} onClick={props.onClose}>
          {t("Continuar")}
        </FormButton>
      </Grid>
    </Grid>
  )
}

export default DialogCongratulationsContent;
