const svgImage = (a: string) => `
<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_831_1565)">
    <path
      d="M25.0096 4.00024H14.9807C14.7156 4.00024 14.4613 4.10558 14.2738 4.29307C14.0863 4.48057 13.981 4.73487 13.981 5.00003C13.981 5.26519 14.0863 5.51949 14.2738 5.70698C14.4613 5.89448 14.7156 5.99981 14.9807 5.99981H15.9633V31.2806C15.9633 33.8869 17.7629 36.0002 19.9796 36.0002C22.1964 36.0002 23.996 33.8869 23.996 31.2806V5.99981H25.0096C25.2723 5.99619 25.5231 5.88926 25.7076 5.70215C25.8921 5.51505 25.9956 5.26282 25.9956 5.00003C25.9956 4.73724 25.8921 4.48501 25.7076 4.29791C25.5231 4.1108 25.2723 4.00387 25.0096 4.00024Z"
      fill="#CDCDCD"
    />
    <path
      d="M29.7879 35.9589H10.2025C9.08795 35.9589 8.01902 35.5161 7.23089 34.728C6.44277 33.9398 6 32.8709 6 31.7563C5.99952 31.2038 6.10797 30.6567 6.31916 30.1462C6.53034 29.6356 6.84012 29.1718 7.23077 28.7811L20.0107 16.0011L32.7665 28.7845C33.5529 29.5722 33.9943 30.6399 33.9938 31.7529C33.9938 32.8684 33.5507 33.9382 32.7619 34.727C31.9732 35.5157 30.9034 35.9589 29.7879 35.9589Z"
      fill="#CDCDCD"
    />
    <path
      d="M17.9802 5.99982V20.8587L8.64429 30.2015C8.33716 30.5098 8.12821 30.9021 8.0438 31.329C7.95939 31.7559 8.00329 32.1982 8.16998 32.6002C8.33667 33.0022 8.61867 33.3457 8.98041 33.5876C9.34214 33.8295 9.76742 33.9588 10.2026 33.9593H19.6281L19.7901 33.9938C19.9353 34.0145 20.0827 34.0145 20.228 33.9938L20.3796 33.9696H29.7879C30.2237 33.9679 30.6493 33.8374 31.0111 33.5945C31.373 33.3515 31.6549 33.007 31.8214 32.6043C31.988 32.2016 32.0317 31.7586 31.9472 31.331C31.8626 30.9035 31.6535 30.5105 31.3462 30.2015L22.0103 20.838V5.99982H17.9802Z"
      fill="white"
    />
    <path
      d="M29.9948 31.0013C29.9966 30.9807 29.9966 30.9599 29.9948 30.9393V30.9117C29.9676 30.6593 29.8442 30.4272 29.6501 30.2635L23.6962 24.2959C23.6004 24.197 23.4849 24.1193 23.3573 24.0677C23.2296 24.016 23.0926 23.9916 22.955 23.9959H17.2321H17.1218C16.9734 23.9794 16.8232 23.9965 16.6823 24.046C16.5415 24.0956 16.4136 24.1763 16.3082 24.2821L10.4061 30.2015C10.2542 30.3103 10.1362 30.4599 10.0658 30.633C9.99542 30.8061 9.97548 30.9956 10.0083 31.1796C10.0411 31.3635 10.1254 31.5344 10.2513 31.6725C10.3772 31.8105 10.5396 31.9101 10.7198 31.9597L10.7887 31.977C10.8825 31.9907 10.9777 31.9907 11.0714 31.977H28.9261C29.069 31.9893 29.2129 31.97 29.3475 31.9205C29.4821 31.8711 29.6043 31.7927 29.7053 31.6908C29.888 31.5075 29.9919 31.2601 29.9948 31.0013Z"
      fill="${a}"
    />
    <path
      d="M15.3256 29.7154C15.2322 29.8089 15.1212 29.883 14.999 29.9336C14.8769 29.9842 14.7459 30.0103 14.6137 30.0103C14.4815 30.0103 14.3506 29.9842 14.2284 29.9336C14.1063 29.883 13.9953 29.8089 13.9018 29.7154C13.8083 29.6219 13.7342 29.5109 13.6836 29.3888C13.633 29.2666 13.6069 29.1357 13.6069 29.0035C13.6069 28.8713 13.633 28.7403 13.6836 28.6182C13.7342 28.496 13.8083 28.385 13.9018 28.2915L15.9186 26.2747C16.0121 26.1813 16.1231 26.1071 16.2452 26.0565C16.3674 26.0059 16.4983 25.9799 16.6305 25.9799C16.7627 25.9799 16.8937 26.0059 17.0158 26.0565C17.138 26.1071 17.249 26.1813 17.3424 26.2747C17.4359 26.3682 17.5101 26.4792 17.5607 26.6014C17.6113 26.7235 17.6373 26.8544 17.6373 26.9867C17.6373 27.1189 17.6113 27.2498 17.5607 27.3719C17.5101 27.4941 17.4359 27.6051 17.3424 27.6986L15.3256 29.7154Z"
      fill="#ebfaff"
    />
  </g>
  <defs>
    <clipPath id="clip0_831_1565">
      <rect width="27.9904" height="32" fill="white" transform="translate(6 4)"/>
    </clipPath>
  </defs>
</svg>
`;

export const IconHomeSvg = (color?: string) => {
  const a = !!color ? color : "#ff6300";
  // const b = "#ffa770" : "#ebfaff";
  return `data:image/svg+xml;charset=UTF-8;base64,${btoa(svgImage(a))}`;
};

export default IconHomeSvg;
