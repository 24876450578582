import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import FormButton from "components/Form/FormButton";
import TermsConditions from "../TermsConditions";

const useStyles = makeStyles((theme) => ({
  title: { color: "#5f5f5f", fontSize: 40, fontWeight: 700 },
  subtitle: { 
    color: "#8d8d8d", 
    fontSize: 18, 
    fontWeight: 500,
    marginBottom: 20,
  },
  body1: { fontWeight: 700, lineHeight: 0.4 },
}));

interface IProps {
  setSignIn: (value: number) => void
}

const MenuSignIn: FC<IProps> = ({ setSignIn }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const openSignInEmail = () => setSignIn(1);
  const openSignInPhone = () => setSignIn(2);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography className={classes.title} align="center">
          {t("¡Bienvenido de nuevo!")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormButton primary onClick={openSignInEmail} fullWidth>
          {t("Iniciar sesión con email")}
        </FormButton>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.body1} align="center">
          {t("o")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormButton primary onClick={openSignInPhone} fullWidth>
          {t("Iniciar sesión con teléfono")}
        </FormButton>
      </Grid>
      <Grid item xs={12}>
        <TermsConditions />
      </Grid>
    </Grid>
  )
}

export default MenuSignIn;
