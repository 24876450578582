import { ProfileActionType, ProfileState } from './types';
import { SECTION } from './types';

export const PROFILE_INITIAL_STATE: ProfileState = {
  section: 0,
}

export const profile = (prevState = PROFILE_INITIAL_STATE, action: ProfileActionType) => {
  switch (action.type) {
    case SECTION: return { ...prevState, section: action.payload }
    
    default: return prevState
  }
}
