import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Grid, Paper, Button, Typography } from '@material-ui/core';
import { servicesOpt } from '../Constants';

const useStyles = makeStyles((theme) => ({
  optional: { 
    maxWidth: 280, 
    [theme.breakpoints.down("xs")]: { maxWidth: '100%' },
  },
  header: {
    color: 'white',
    width: '100%',
    padding: 15,
    borderRadius: '10px 10px 0 0',
    backgroundColor: '#481870', 
  },
  content: { 
    height: 237, 
    padding: '15px 10px', 
    position: 'relative',
    backgroundColor: 'white',
    '& .MuiTypography-root': { width: '100%', fontWeight: 500 },
  },
  image: { position: 'absolute', right: 0, bottom: 0, width: 125 },
  button: {
    color: 'white',
    borderRadius: '0 0 10px 10px',
    backgroundColor: '#481870',
    '&:hover': { backgroundColor: 'white', color: '#481870' },
  },
}));

interface IProps {
  openServiceOptional: () => void
}

const Optional: FC<IProps> = ({openServiceOptional}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid className={classes.optional} container justifyContent="flex-end">
      <Paper className={classes.header}>
        <Typography align="center" variant="body1" noWrap>
          {t("Servicios adicionales (opcional)")}
        </Typography>
      </Paper>
      <Grid className={classes.content} container alignItems="flex-start">
        <Grid container>
          {servicesOpt.map((name, i) => (
            <Typography key={i} variant="body2" display="block" noWrap>
              {t(name)}
            </Typography>
          ))}
        </Grid>
        <img className={classes.image} src="/gif/payment_01.gif" alt="" />
      </Grid>
      <Button 
        className={classes.button} 
        variant="contained" 
        onClick={openServiceOptional} 
        fullWidth
      >
        {t("Ver")}
      </Button>
    </Grid>
  )
}

export default Optional;
