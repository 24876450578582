import * as types from "./types";
import { CountryActionType, CountryState } from "./types";

const INITIAL_STATE: CountryState = {
  countries: [],
  countriesLoading: false,
}

export const country = (prevState = INITIAL_STATE, action: CountryActionType) => {
  switch (action.type) {
    case types.COUNTRIES: 
      return { ...prevState, countries: action.payload }
    case types.COUNTRIES_LOADING: 
      return { ...prevState, countriesLoading: action.payload }

    default: 
      return prevState
  }
}
