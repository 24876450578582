import React, { FC, useState } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { IUser } from "dto/user.dto";
import { IEntity } from "dto/entity.dto";
import { ICountry } from "dto/country.dto";
import * as utils from "utils";
import Loading from "components/Common/Loading";
import FieldSex from "./ProfileInformation/FieldSex";
import InfoField from "./ProfileInformation/InfoField";
import FieldDate from "./ProfileInformation/FieldDate";
import JoinAnEntity from "./ProfileInformation/JoinAnEntity";
import DialogEntityCode from "./ProfileInformation/DialogEntityCode";
import { IGender } from "dto/gender.dto";

const useStyles = makeStyles(() => ({
  info: { 
    width: 820, 
    position: "relative",
    maxWidth: "100%", 
  },
}));

interface IField {
  id: string;
  edit: string;
  image: string;
  label: string;
  title: string;
  value: string;
}

interface IProps {
  user: IUser;
  entity: IEntity;
  fbauth: string;
  genders: IGender[];
  countries: ICountry[];
  authLoading: boolean;
  languageAudio: number;
  profileLoading: boolean;
  updateProfile: (user: IUser, callback: () => void) => void;
  passwordReset: (email: string, callback: () => void) => void;
  sendEntityCode: (code: string) => void;
  changeLanguageAudio: (lang: number) => void;
}

const ProfileInformation: FC<IProps> = (props) => {
  const classes = useStyles();
  const { user, countries } = props;
  const [dialogEntityCode, setDialogEntityCode] = useState(false);
  const nationality = utils.getNationalityLabel(countries, user.nationality);

  const fields: IField[] = [
    { 
      id: "phone", 
      edit: "",
      image: "/img/profile/phone.svg", 
      label: !!user?.phone ? user.phone : "N/A",
      title: "Teléfono",
      value: user?.phone,
    },
    { 
      id: "nationality", 
      edit: "",
      image: "/img/profile/nationality.svg", 
      label: nationality,
      title: "País",
      value: user?.nationality,
    },
  ];

  const openDialogEntityCode = () => setDialogEntityCode(true);
  const closeDialogEntityCode = () => setDialogEntityCode(false);

  const updateProfile = (name: string, value: string, c: () => void) => {
    props.updateProfile({ ...user, [name]: value }, c);
  }

  return (
    <Grid className={classes.info} container>
      <Grid container spacing={4}>
        {!!user?.sponsor && user?.sponsor !== "Nuevos_Usuarios" ? (
          <Grid item xs={12} sm={6}>
            <InfoField 
              image="/img/profile/sponsor.svg"
              label={user?.sponsor}
              title="Organización"
            />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <JoinAnEntity 
              sendEntityCode={props.sendEntityCode}
              openDialogEntityCode={openDialogEntityCode}
            />
          </Grid>
        )}
        {fields.map((field, key) => (
          <Grid key={key} item xs={12} sm={6}>
            <InfoField 
              image={field.image} 
              label={field.label} 
              title={field.title}
            />
          </Grid>
        ))}
        <Grid item xs={12} sm={6}>
          <FieldDate 
            value={user?.date ?? ""} 
            updateProfile={updateProfile} 
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InfoField 
            image="/img/profile/email.svg"
            label={!!user?.email ? user.email : "N/A"}
            title="Email"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldSex 
            value={user?.sex}
            genders={props.genders}
            updateProfile={updateProfile}
          />
        </Grid>
      </Grid>

      <Loading loading={props.profileLoading} white />

      <DialogEntityCode 
        open={dialogEntityCode}
        entity={props.entity}
        sponsor={!!user?.sponsor}
        profileLoading={props.profileLoading}
        onClose={closeDialogEntityCode}
        sendEntityCode={props.sendEntityCode}
      />
    </Grid>
  )
}

export default ProfileInformation;
