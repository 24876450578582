import React from "react";
import { Close } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { Typography, IconButton, Grid } from "@material-ui/core";
import { State } from "store/types";
import { history } from "configureStore";
import LayoutOlab from "layouts/LayoutOlab";
import TermsServiceES from "components/Common/TermsServiceES";
import TermsServiceEN from "components/Common/TermsServiceEN";

const useStyles = makeStyles(() => ({
  page: {
    height: "calc(100vh - 60px)",
    position: "relative",
    marginTop: 60,
    overflowY: "auto",
  },
  header: { 
    padding: "8px 24px",
    alignItems: "center", 
    justifyContent: "space-between", 
  },
}));

const TermsServicePage = () => {
  const classes = useStyles();
  const user = useSelector((state: State) => state.user.user);
  const languageCode = useSelector((state: State) => state.layout.language.code);

  const onClose = () => {
    if (!!user.nit) history.push('/home');
    else history.push('/');
  }

  return (
    <Grid container>
      <LayoutOlab />
      <Grid className={classes.page} container>
        <Grid className={classes.header} container>
          {(languageCode === "es") && (
            <Typography variant="h5">Términos de servicio</Typography>
          )}
          {(languageCode !== "es") && (
            <Typography variant="h5">Terms of service</Typography>
          )}
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Grid>
        {(languageCode === "es") && (<TermsServiceES />)}
        {(languageCode !== "es") && (<TermsServiceEN />)}
      </Grid>
    </Grid>
  )
}

export default TermsServicePage;
