import React, { FC } from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import Background  from '../../components/Common/Background';

const useStyles = makeStyles(() => ({
  description: { position: 'relative', overflow: 'auto', height: 'calc(100vh - 60px)' },
  content: {
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    position: 'relative',
    padding: '50px 70px',
    height: '100%',
  },
  title: { maxWidth: 600, marginBottom: 30 },
  text: { marginBottom: 30 },
  img: { height: 80 },
}));

interface IProps { }

const Description: FC<IProps> = () => {
  const classes  = useStyles();

  return (
    <Grid className={classes.description} container>
      <Background backgroundImage="/images/description_background.svg" />
      <Grid className={classes.content} item container xs={8} alignItems="center">
        <Grid item>
          <Typography className={classes.title} variant="h2">
            <b>¡Únete a la comunidad de O-lab!</b>
          </Typography>
          <Typography variant="subtitle1" align="justify" gutterBottom>
            <b>O-lab</b> ha sido desarrollado por <b>Fundación El Origen.</b> Somos 
            una organización sin fines de lucro con la misión de brindar acceso a la
            educación digital para todos. Al adquirir una licencia, todos los ingresos se
            destinan para que escuelas y comunidades vulnerables puedan acceder
            de manera gratuita a la educación digital.
          </Typography>
          <Typography className={classes.text} variant="h5">
            <b>#SomosElOrigen</b>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Description;
