import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { State } from "store/types";
import { auth } from "store/modules/auth/reducer";
import { user } from "store/modules/user/reducer";
import { home } from "store/modules/home/reducer";
import { note } from "store/modules/note/reducer";
import { email } from "store/modules/email/reducer";
import { entity } from "store/modules/entity/reducer";
import { gender } from "store/modules/gender/reducer";
import { layout } from "store/modules/layout/reducer";
import { module } from "store/modules/module/reducer";
import { survey } from "store/modules/survey/reducer";
import { content } from "store/modules/content/reducer";
import { country } from "store/modules/country/reducer";
import { explore } from "store/modules/explore/reducer";
import { profile } from "store/modules/profile/reducer";
import { checkout } from "store/modules/checkout/reducer";
import { challenger } from "store/modules/challenger/reducer";
import { laboratory } from "store/modules/laboratory/reducer";
import { anonymousUser } from "store/modules/anonymousUser/reducer";

const combinedReducer = combineReducers<State>({
  auth,
  user,
  home,
  note,
  email,
  entity,
  gender,
  layout,
  module,
  survey,
  content,
  country,
  explore,
  profile,
  checkout,
  challenger,
  laboratory,
  anonymousUser,
  firebase: firebaseReducer
});

export type RootState = ReturnType<typeof combinedReducer>

export default combinedReducer;
