import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, Grid, Button } from "@material-ui/core";
import ButtonLanguage from "./ButtonLanguage";

const useStyles = makeStyles((theme) => ({
	appBar: { backgroundColor: '#ff6315', minHeight: '60px' },
	toolbar: {
		minHeight: '60px !important',
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down("xs")]: { padding: '0 10px' }, 
	},
  img: { maxHeight: 40 },
  btn: { 
    color: '#ff6315', 
    marginLeft: 15,
    borderRadius: 25, 
    backgroundColor: 'white', 
  },
}));

const LayoutLanding = () => {
  const classes = useStyles();

  return (
    <AppBar className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Grid container alignItems="center">
          <Grid item container xs={6}>
            <img className={classes.img} src="/images/landing_logo.svg" alt="" />
          </Grid>
          <Grid item container justifyContent="flex-end" alignItems="center" xs={6}>
            <Button className={classes.btn} variant="contained">
              Iniciar sesión
            </Button>
            <Button className={classes.btn} variant="contained">
              Crear cuenta
            </Button>
            <ButtonLanguage />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export default LayoutLanding;
