import Thunk from "redux-thunk";
import { enableBatching } from "redux-batched-actions";
import { composeWithDevTools } from "redux-devtools-extension";
import { applyMiddleware, createStore, AnyAction } from "redux";
import { State } from "./types";
import reducers from "./reducers";

const bindMiddleware = (middleware: any) => {
  if (process.env.NODE_ENV !== "production") {
	  return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

const ThunkMiddleware = Thunk.withExtraArgument({});

const middleware = [ThunkMiddleware];

const enhancer: any = bindMiddleware(middleware);

export const store: any = createStore<State, AnyAction, null, null>(
  enableBatching<State>(reducers), 
  enhancer
);
