import { createTheme } from "@material-ui/core/styles";

export const theme = createTheme({
  typography: {
    fontFamily: [
      "Montserrat",
      "Nunito",
      "Roboto",
      "Helvetica Neue",
      "Arial",
      "sans-serif",
    ].join(","),
    button: {
      fontWeight: 700,
    },
  },
  palette: {
    primary: {
      main: "#481870",
    },
    secondary: {
      main: "#f96332",
    },
    background: {
      default: "#e6e6e6",
      paper: "#e6e6e6",
    },
  },
});
