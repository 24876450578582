import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import FormTypography from "components/Form/FormTypography";
import clsx from "clsx";
import { TypeLanguageCode } from "dto/layout.dto";

const useStyles = makeStyles((theme) => ({
  language: { gap: 12 },
  button: {
    color: "#8d8d8d",
    border: "2px solid #f0f0f0",
    fontSize: 18,
    minWidth: 240,
    fontWeight: 500,
    borderRadius: 8,
    textTransform: "initial",
    justifyContent: "flex-start",
    "& .MuiButton-label": { "& .MuiButton-startIcon": { marginLeft: 0 } },
    [theme.breakpoints.down("xs")]: { width: "100%" },
  },
  select: {
    color: "#0aa9e2",
    borderColor: "#0aa9e2",
    backgroundColor: "#ebfaff",
    "&:hover": { backgroundColor: "#ebfaff" },
  },
}));

interface IProps {
  languageCode: string;
  updateLanguage: (code: TypeLanguageCode) => void;
}

const ProfileLanguage: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const code = props.languageCode;

  const selectLanguageEs = () => props.updateLanguage("es");
  const selectLanguageUs = () => props.updateLanguage("en");
  const selectLanguageAr = () => props.updateLanguage("ar");

  return (
    <Grid className={classes.language} container>
      <Grid item xs={12}>
        <FormTypography fontSize={18}>
          {`${t("Configura el idioma de esta plataforma")}.`}
        </FormTypography>
      </Grid>
      <Grid item xs={12}>
        <Button
          className={clsx(classes.button, code === 'es' && classes.select)}
          variant="outlined"
          startIcon={<img src="/img/profile/spain.svg" alt="" />}
          onClick={selectLanguageEs}
        >
          {t("Español")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          className={clsx(classes.button, code === 'en' && classes.select)}
          variant="outlined"
          startIcon={<img src="/img/profile/united_states.svg" alt="" />}
          onClick={selectLanguageUs}
        >
          {t("Inglés")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          className={clsx(classes.button, code === 'ar' && classes.select)}
          variant="outlined"
          startIcon={<img width={32} src="/img/profile/saudi-26825_640.png" alt="" />}
          onClick={selectLanguageAr}
        >
          {t("Árabe")}
        </Button>
      </Grid>
    </Grid>
  )
}

export default ProfileLanguage;
