import Entity from "services/entity.services";
import { Action } from "./types";
import * as types from "./types";
import { IEntity } from "dto/entity.dto";
import { entityInit } from "initials/entity.init";

export const getEntityByName = (sponsor: string): Action => async (dispatch) => {
  dispatch({ type: types.ENTITY_LOADING, payload: true });
  try {
    const db = await Entity.get();
    const entity = db?.filter(({name}) => (name === sponsor))[0];
    if (!!entity?.nit) dispatch({ type: types.ENTITY, payload: entity });
    else dispatch({ type: types.ENTITY, payload: entityInit });
  } catch (error) {
    dispatch({ type: types.ENTITY, payload: entityInit });
    console.log("Error Get Entity By Name: ", error)
  }
  dispatch({ type: types.ENTITY_LOADING, payload: false });
}

export const setEntity = (value: IEntity): Action => (dispatch) => {
  dispatch({ type: types.ENTITY, payload: value });
}
