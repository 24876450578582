import React, { FC } from "react";
import clsx from "clsx";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1300,
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
  },
  image: { 
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: 'white', 
    "& img": { width: 400, maxWidth: "100%", maxHeight: "100%" },
  },
  book: { padding: 20 },
  white: { backgroundColor: "rgba(255, 255, 255, 0.8)" },
}));

interface IProps {
  book?:  boolean;
  white?: boolean;
  loading?: boolean;
}

const Loading: FC<IProps> = ({ book, white, loading }) => {
  const classes = useStyles();

  return (
    <>
      {!!loading && (
        <Grid className={clsx(classes.root, book && classes.book)} container>
          <Grid className={clsx(classes.image, white && classes.white)} container>
            <img src="/gif/tubes.gif" alt="Espera un momento está cargando" />
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default Loading;
