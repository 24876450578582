import React, { FC } from "react";
import clsx from "clsx";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Loading from "components/Common/Loading";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 960 + 40,
    height: 540 + 50 + 40,
    margin: 8,
    padding: 20,
    maxWidth: "calc(100% - 16px)",
    maxHeight: "calc(100% - 16px)",
    borderRadius: 20,
    [theme.breakpoints.down("xs")]: {
      width: "100%", 
      height: "100%",
      margin: 0,
      padding: 0,
      maxWidth: "100%",
      maxHeight: "100%",
      borderRadius: 0,
    },
    "&.zoom": { 
      width: "100%", 
      height: "100%", 
      margin: 0, 
      padding: 0, 
      maxWidth: "100%",
      maxHeight: "100%",
      borderRadius: 0, 
    },
  },
  book: { 
    width: 960,
    height: 540 + 50, 
    position: "relative",
    maxWidth: "100%",
    maxHeight: "100%",
    "&.zoom": { width: "100%", height: "100%" },
    [theme.breakpoints.down("sm")]: { width: "100%" },
    [theme.breakpoints.down("xs")]: { width: "100%", height: "100%" },
  },
}));

interface IProps {
  zoom?: boolean;
  loading?: boolean;
  backgroundColor?: string;
}

const Notebook: FC<IProps> = (props) => {
  const classes = useStyles();
  const classRoot = clsx(classes.root, !!props.zoom && "zoom");
  const classBook = clsx(classes.book, props.zoom && "zoom");
  const backgroundColor = !!props.backgroundColor ? props.backgroundColor : "#ff6300";

  return (
    <Grid className={classRoot} container style={{ backgroundColor }}>
      <Grid className={classBook} item>
        {props.children}

        <Loading loading={props.loading} />
      </Grid>
    </Grid>
  )
}

export default Notebook;
