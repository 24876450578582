import React, { FC, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { TypeUserAnonymous } from "dto/user-anonymous.dto";
import { questionInit, questionPreviewInit } from "initials";
import FormButton from "components/Form/FormButton";
import RadioButton from "components/Question/RadioButton";
import FormTypography from "components/Form/FormTypography";
import QuestionBubble from "components/Question/QuestionInformation/QuestionBubble";

const useStyles = makeStyles((theme) => ({
  test: { height: "100%" },
  content: { 
    gap: 12, 
    height: "fit-content", 
  },
}));

interface IProps {
  userType?: TypeUserAnonymous;
  openNextStep: () => void;
  updateUserType: (name: string, value: string) => void
}

const UserTypeTest: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [preview, setPreview] = useState(questionPreviewInit);
  const [question, setQuestion] = useState(questionInit);
  const options = [
    { label: "Estudio", value: "student" },
    { label: "Trabajo", value: "employee" },
    { label: "Estoy buscando estudiar o trabajar", value: "none" },
  ];

  useEffect(() => {
    setPreview({ ...preview, image_header: "/gif/question/ola_tablet.gif" });
    setQuestion({ 
      ...question,
      subtitle: "Opción múltiple",
      description: "Única respuesta",
      header_color: "#ffebde",
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectAnswer = (value: string) => {
    props.updateUserType("userType", value);
  }

  return (
    <Grid className={classes.test} container>
      <Grid className={classes.content} container>
        <QuestionBubble 
          preview={preview} 
          question={question} 
        />
        <FormTypography fontSize={14} fontWeight={700}>
          {t("Actualmente, yo...")}
        </FormTypography>
        {options.map(({ label, value }, key) => (
          <RadioButton 
            key={key}
            label={label}
            value={value}
            checked={value === props.userType}
            onChange={selectAnswer} 
          />
        ))}
      </Grid>

      <Grid container alignItems="flex-end">
        <FormButton primary fullWidth onClick={props.openNextStep}>
          {t("Continuar")}
        </FormButton>
      </Grid>
    </Grid>
  )
}

export default UserTypeTest;
