const svgImage = (color: string) => `
<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path 
    d="M16.6413 17.6863L16.6276 17.6692L16.6133 17.6525L13.4728 14.0054L16.6133 10.3583L16.6276 10.3417L16.6413 10.3246C17.207 9.61756 17.3372 8.39208 16.3904 7.63467C15.6885 7.07314 14.4757 6.94076 13.7171 7.86512L9.55854 12.6963C8.89284 13.405 8.89175 14.5393 9.55528 15.2494L13.7142 20.1422C14.4726 21.0705 15.6876 20.9385 16.3904 20.3762C17.3372 19.6188 17.207 18.3933 16.6413 17.6863Z" 
    fill="${color}"
    stroke="${color}" 
    stroke-width="2.42424"
  />
</svg>
`;

export const BackSvg = (color?: string) => {
  const a = !!color ? color : "#ffffff";
  return `data:image/svg+xml;charset=UTF-8;base64,${btoa(svgImage(a))}`;
};

export default BackSvg;
