import { CheckoutActionType } from './actions';
import { AppThunkAction } from '../../../types';
import { ILicense, IProject } from '../../../../services/checkout.services';

export * from './constants';
export * from './actions';

export interface CheckoutState {
  loading:         boolean
  licenses:        ILicense[]
  projects:        IProject[]
  clientSecret:    string
  licensesLoading: boolean
}

export type Action = AppThunkAction<CheckoutActionType>
