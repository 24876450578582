import firebase from 'firebase';
import 'firebase/auth';
import fbAdmin  from "services/fb-admin.services";
import User from "services/user.services";
import { IUser } from "dto/user.dto";
import Entity from "services/entity.services";
import { Action, LOADING, LICENSES, LICENSES_LOADING } from './types';
import { IEntity } from 'dto/entity.dto';

export const getLicenses = (): Action => async (dispatch) => {
  dispatch({type: LICENSES_LOADING, payload: true});
  try {
    dispatch({type: LICENSES, payload: []});
    dispatch({type: LICENSES_LOADING, payload: false});
  } catch (error) { dispatch({type: LICENSES_LOADING, payload: false}); }
}

export const createUserEntity = (
  user: IUser, 
  entity: IEntity, 
  password: string, 
  callback: () => void
): Action => async (dispatch) => {
  try {
    dispatch({type: LOADING, payload: true}); 
    const emailTrim = user.email.trim();
    const verified = await fbAdmin.verifiedEmail(emailTrim);
    const uidentity = new Date().getTime().toString();

    if (!!verified && !!verified.verified) {
      const result = await firebase.auth().createUserWithEmailAndPassword(emailTrim, password);
      if (!!result.user) {
        await User.post({...user, nit: result.user.uid});
        await Entity.post({...entity, nit: uidentity});
        callback();
      }
    }
    dispatch({type: LOADING, payload: false}); 
  } catch (error) { 
    dispatch({type: LOADING, payload: false}); 
  }
}
