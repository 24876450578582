import { useEffect, useState } from "react";

const useRecorder = () => {
  const [recorder, setRecorder] = useState(null);
  const [audioURL, setAudioURL] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);

  useEffect(() => {
    if (recorder === null) {
      // @ts-ignore
      if (isRecording) requestRecorder().then(setRecorder, console.error);
      return;
    };

    // @ts-ignore
    if (isRecording) recorder.start();
    // @ts-ignore
    else recorder.stop();

    // @ts-ignore
    const handleData = (e) => {
      setAudioURL(URL.createObjectURL(e.data));
      // @ts-ignore
      if (e.data.size > 0) setRecordedChunks([e.data]);
    };

    // @ts-ignore
    if (!!recorder && !!audioURL) recorder.stream.getAudioTracks();

    // @ts-ignore
    recorder.addEventListener("dataavailable", handleData);

    // @ts-ignore
    return () => recorder.removeEventListener("dataavailable", handleData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recorder, isRecording]);

  const requestRecorder = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    // @ts-ignore
    return new MediaRecorder(stream, {
      mimeType: "audio/webm",
    });
  }

  const stopRecording = () => setIsRecording(false);
  const startRecording = () => setIsRecording(true);
  
  return [
    audioURL,
    // recorder,
    isRecording,
    recordedChunks,
    setAudioURL,
    // setRecordedChunks,
    stopRecording,
    startRecording,
  ];
}

export default useRecorder;
