import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { makeStyles } from '@material-ui/core';
import ToastMessage from './ToastMessage';

const useStyles = makeStyles(() => ({
  toast: { 
    position: 'relative',
    zIndex: 1401,
    '& .Toastify': {
      zIndex: 1401,
      '& .Toastify__toast-container': {
        '& .Toastify__toast': {
          '& .Toastify__toast-body': {
            display: 'flex',
            alignItems: 'center',
            '& .MuiSvgIcon-root': { 
              marginRight: '5px' 
            }
          }
        }
      }
    }
  },
  icon: { marginRight: '5px' },
}));

export const messageSuccess = (message: string) => (
  toast.success(
    <ToastMessage success message={message} />,
    {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }
  )
);

export const messageInfo = (message: string) => (
  toast.info(
    <ToastMessage info message={message} />,
    {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }
  )
);

export const messageWarn = (message: string) => (
  toast.warn(
    <ToastMessage warn message={message} />,
    {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }
  )
);

export const messageError = (message: string) => {
  return (
    toast.error(
      <ToastMessage error message={message} />,
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    )
  )
}

const ToastDialog = () => {
  const classes = useStyles();

  return (
    <div className={classes.toast}>
      <ToastContainer 
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  )
}

export default ToastDialog;
