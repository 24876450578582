import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const useStyles = makeStyles(() => ({
  caption: { 
    color: "#929292",
    fontWeight: 500 ,
    "& a": { color: "#ff6300", textDecoration: "none" },
  },
}));

const TermsConditions = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const i18nextLng = localStorage.getItem("i18nextLng");
  const service = !!i18nextLng && i18nextLng !== "es" ? (
    "/survey/public/10974053631684206087280" 
  ) : ( 
    "/survey/public/10974053631684205972493"
  );
  const privacy = !!i18nextLng && i18nextLng !== "es" ? (
    "/survey/public/10974053631684206028344"
  ) : (
    "/survey/public/10974053631684205576623"
  );

  return (
    <Typography 
      className={classes.caption} 
      align="center" 
      display="block" 
      variant="caption"
    >
      {`${t("Al continuar, estás indicando que aceptas nuestros")} `}
      <Link to={service} rel="noopener noreferrer" target="_blank">
        {t("Términos de servicio")}
      </Link>
      {" "}{t("y nuestra")}{" "}
      <Link to={privacy} rel="noopener noreferrer" target="_blank">
        {t("Política de privacidad")}.
      </Link>
    </Typography>
  );
};

export default TermsConditions;
