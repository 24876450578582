import React, { FC, useEffect } from "react";
import { Dialog } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ISurveyCongratulations } from "dto/survey.dto";
import DialogCongratulationsHeader from "./DialogCongratulationsHeader";
import DialogCongratulationsContent from "./DialogCongratulationsContent";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    height: "100%",
    maxWidth: 580,
    maxHeight: 580,
    borderRadius: 32,
    backgroundColor: "white",
  },
}));

interface IProps {
  open: boolean;
  zoom: boolean;
  buttonText?: string;
  buttonColor?: string;
  congratulations: ISurveyCongratulations;
  onClose: () => void
  setSuccessAudio: (a: HTMLAudioElement | undefined) => void;
}

const DialogCongratulations: FC<IProps> = (props) => {
  const classes = useStyles();
  const audio = new Audio("/audio/unit_finish.wav");
  
  useEffect(() => { 
    if (!!props.open && audio) {
      audio.play();
      props.setSuccessAudio(audio);
    } else props.setSuccessAudio(undefined);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  return (
    <Dialog open={props.open} classes={{ paper: classes.paper }} fullScreen>
      <DialogCongratulationsHeader congratulations={props.congratulations} />
      <DialogCongratulationsContent 
        congratulations={props.congratulations} 
        onClose={props.onClose} 
      />
    </Dialog>    
  )
}

export default DialogCongratulations;
