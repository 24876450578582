import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ITask } from "dto/user.dto";
import { IModule } from "dto/module.dto";
import { IChallenger } from "dto/challenger.dto";
import { ILaboratory } from "dto/laboratory.dto";
import { State } from "store/types";
import * as utils from "utils";
import { history } from "configureStore";
import * as actions from "store/actions";
import { challengerInit, moduleInit } from "initials";
import PageRoot from "components/Pages/PageRoot";
import Challenger from "components/Challenger/Challenger";
import ModulesList from "components/Laboratory/ModulesList";
import { messageError } from "components/Common/Toast";
import DialogWithoutSponsor from "components/Explore/DialogWithoutSponsor";

const ExploreLaboratoryPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector((state: State) => state.user.user);
  const laboratory = useSelector((state: State) => state.laboratory.laboratoryFree);
  const taskLoading = useSelector((state: State) => state.user.taskLoading);
  const anonymousUser = useSelector((s: State) => s.anonymousUser.anonymousUser);
  const dialogSuccess = useSelector((state: State) => state.home.modalSuccess);
  const { isEmpty, isLoaded } = useSelector((state: State) => state.firebase.auth);
  const anonymous = localStorage.getItem("anonymousUser") ?? "";
  const [zoom, setZoom] = useState(false);
  const [module, setModule] = useState(moduleInit);
  const [challenger, setChallenger] = useState(challengerInit);
  const [dialogWithoutSponsor, setDialogWithoutSponsor] = useState(false);
  const notebook = !!module?.name && !!challenger?.name;
  const tasks = !!user?.tasks?.length
    ? user?.tasks
    : !!anonymousUser?.tasks?.length
    ? anonymousUser?.tasks 
    : [];

  useEffect(() => { 
    if (!!laboratory?.name) {
      dispatch(actions.analytics("access_laboratory_free", {}));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => { 
    if (!!isEmpty && !!isLoaded && !anonymous) history.push("/");
  }, [isEmpty, isLoaded, anonymous]);

  useEffect(() => { 
    if (!laboratory?.name) history.push("/explore");
  }, [laboratory]);

  const closeZoom = () => setZoom(false);
  const toogleZoom = () => setZoom((v) => !v);
  const onClickBack = () => {
    dispatch(actions.deselectLaboratoryFree());
    history.push("/explore");
  }
  const openDialogSuccess = () => dispatch(actions.openModalSuccess());

  const selectModule = async (value: IModule) => {
    if (value?.challenges?.length) setModule(value);
    else messageError(t("El módulo no tiene unidades"));
  }
  const deselectModule = () => {
    setModule(moduleInit);
    deselectChallenger();
    !!zoom && closeZoom();
  }
  const selectChallenger = (value: IChallenger) => {
    const challengerNew = utils.getChallengerWithTasks(
      laboratory.name, 
      module, 
      tasks
    );
    if (
      value?.name === challengerNew?.name && 
      value?.questions?.length === challengerNew?.questions?.length
    ) {
      setChallenger(challengerNew);
    } else setChallenger({ ...value, selected: true });
  }
  const deselectChallenger = () => setChallenger(challengerInit);

  const createTaskCallback = (next?: IChallenger, type?: string) => {
    if (!!next) {
      setChallenger(next);
    } else if (type === "finished") {
      deselectModule();
      dispatch(actions.analytics("perform_laboratory_module"));
    } else deselectChallenger();
  }
  const saveUserTask = (data: ITask) => {
    const task: ITask = {
      ...data,
      date: new Date().getTime().toString(),
      module: module?.name,
      laboratory: laboratory?.name,
      challenger: challenger?.name,
    }
    if (!!user?.nit && !!challenger.selected) {
      dispatch(actions.updateUserTask(task, tasks, deselectChallenger));
    } else if (!!user?.nit) {
      dispatch(actions.createUserTask({
        task, 
        tasks, 
        module,
        challenger,
        laboratory: laboratory,
        createTaskCallback,
      }));
    } else if (!!anonymousUser?.uuid && !!challenger.selected) {
      dispatch(actions.updateUserAnonymousTask(task, tasks, deselectChallenger));
    } else if (!!anonymousUser?.uuid) {
      dispatch(actions.createUserAnonymousTask({
        task, 
        tasks, 
        module,
        challenger, 
        laboratory: laboratory,
        createTaskCallback,
      }));
    }
  }

  const closeDialogWithoutSponsor = () => setDialogWithoutSponsor(false);
  const openDialogWithoutSponsor = (index: number) => {
    const questionsTotal = challenger?.questions?.length ?? 0;
    setDialogWithoutSponsor(
      !user?.sponsor &&
      questionsTotal > 2 && 
      questionsTotal - 1 === index
    );
  }

  const generateLaboratoryCertificate = (laboratory: ILaboratory) => {
    dispatch(actions.generateLaboratoryCertificate(laboratory));
  }

  const handleAnalytics = (name: string, params?: { [key: string]: string }) => {
    dispatch(actions.analytics(name, params));
  }

  return (
    <PageRoot 
      zoom={zoom} 
      background="/img/background/entorno_b1.png"
      hideButtonLanguage={!!user?.nit}
    >
      {!!notebook ? (
        <Challenger 
          zoom={zoom} 
          tasks={tasks}
          module={module} 
          challenger={challenger}
          laboratory={laboratory}
          taskLoading={taskLoading}
          dialogSuccess={dialogSuccess}
          languageAudio={laboratory?.language ?? ""}
          toogleZoom={toogleZoom}
          saveUserTask={saveUserTask}
          handleAnalytics={handleAnalytics}
          openDialogSuccess={openDialogSuccess}
          deselectChallenger={deselectChallenger}
          updateQuestionIndex={openDialogWithoutSponsor}
        />
      ) : (
        <ModulesList 
          tasks={tasks}
          laboratory={laboratory}
          userCurrent={user}
          moduleCurrent={module}
          onClickBack={onClickBack}
          selectModule={selectModule}
          deselectModule={deselectModule}
          selectChallenger={selectChallenger}
          generateLaboratoryCertificate={generateLaboratoryCertificate}
        />
      )}
      <DialogWithoutSponsor 
        open={dialogWithoutSponsor} 
        onClose={closeDialogWithoutSponsor} 
      />
    </PageRoot>
  )
}

export default ExploreLaboratoryPage;
