import { IChallenger } from "dto/challenger.dto";
import { AppThunkAction } from "store/types";
import { ChallengerActionType } from "./actions";

export * from "./actions";
export * from "./constants";

export interface ChallengerState {
  challenger: IChallenger;
  challengerFree: IChallenger;
}

export type Action = AppThunkAction<ChallengerActionType>;
