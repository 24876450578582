import React from 'react';
import { Typography, DialogContent } from '@material-ui/core';

const PrivacyPolicyEN = () => {
  return (
    <DialogContent>
      <Typography variant="h5">O-lab</Typography>
      <Typography variant="body2" display="block" align="justify" gutterBottom>
        Is a platform, web, and mobile application
        (collectively, the "Platforms") that contains online and
        offline training courses and programs created by public
        and private institutions for users of the O-lab web and
        mobile application. Fundación El Origen registered under NIT 900900464-0 in
        Bogotá - Colombia, who participates as the licensee of the O-lab app in Colombia,
        is the data processor of your personal information. Your collection of personal
        information is defined in the following Privacy Policy.<br /><br />
      </Typography>

      <Typography variant="h5">Privacy Policy</Typography>
      <Typography variant="body2" display="block" align="justify" gutterBottom>
        Fundación El Origen has been entrusted by parents and traditional community
        authorities to provide safe and educational content. We take this trust seriously
        and recognize the importance of privacy concerns, especially in the digital
        environment. In this Privacy Policy, the term "personal information" means any
        information collected by us or provided by you that, with reasonable efforts, can
        be used to identify you, which may include first and last name, email address,
        and your location or relationship to the community or educational institution to
        which it belongs and to which it has been given access for sponsorship purposes.
        If you choose to use the O-lab Platforms, then you agree to the collection and
        use of information in connection with this policy. The personal information we
        collect is used to provide and improve the service and provide more opportunities
        according to your requirements.<br /><br />
      </Typography>

      <Typography variant="h5">Our Privacy Policy is based on the following principles</Typography>
      <Typography variant="body2" display="block" align="justify" gutterBottom>
        You are free to choose whether or not to participate in any activity on our
        Platforms that involves submitting personal information, such as your name,
        location, phone number, evidence of activities, or other information that can be
        used to identify or contact an individual and be it online or offline. We will ask
        you for this information expressly. Most of the activities on our platforms do not
        require you to submit personal information. We use the information you provide
        to us and that which is automatically collected from your devices, such as a
        computer's Internet Protocol address, a mobile device identifier, operating
        system, or general location, such as zip code, to support internal operations for
        that we can understand and monitor the use of our platforms and improve the
        performance of the platforms. The third parties we use to help with the Platforms
        must follow this Privacy Policy. ESPECIALLY FOR CHILDREN UNDER 13 YEARS
        OLD. Our collection of personal information on platforms that are directed at
        children under the age of 13 ("Children's Platforms") is intended to follow the
        principles of the POLICY FOR THE TREATMENT AND PROTECTION OF PERSONAL
        DATA, from Colombia Designed to protect online privacy of users. We do not
        make public the personal information collected from children nor do we allow
        children to do so on our platforms. We do not condition a child's participation in
        any activity or service on the disclosure of more personal information than is
        reasonably necessary to participate in an activity.<br /><br />
      </Typography>

      <Typography variant="h5">What Information We Collect</Typography>
      <Typography variant="body2" display="block" align="justify" gutterBottom>
        Personally, identifiable information is provided directly by the user or by third
        parties. We collect the personally identifiable information that you provide us
        when you create an account, update or change account information, complete a
        survey, and/or participate in content offered or other services on our Platforms.
        We may use personally identifiable information to answer your questions,
        provide you services and/or content that is offered specifically to your selections,
        send you updates on the content that is offered or other events.<br /><br />
        Account Registration: If you register for an account on our Platforms, you may be
        asked to provide Personally Identifiable Information, such as your name and
        geographic location.<br /><br />
        Updates: O-lab may offer you the possibility of receiving updates by email or
        through messages in parts of the O-lab. app website. To subscribe to these
        services, you will be asked to provide us with Personally Identifiable Information
        such as your name or email address. Courses: O-lab offers modules and courses
        where you can share comments, tasks, achievements, and photographic evidence
        of the activities carried out.<br /><br />
        When you register, you will be able to participate in the “challenge” activities of
        these modules and courses, you will be asked to register and/or to provide us
        with personally identifiable information, such as your name and the community
        or educational institution to which you are attached. Remember that the
        information you publish in the activities will be available to the public and mainly
        to the entities that created them. We also collect challenge data, such as
        responses to the quizzes that appear, individual quizzes, assignments, tests, and
        surveys. You should not include personally identifiable information or other
        information of a personal or confidential nature, whether related to you or
        another person.<br /><br />
        Participation in the content that is offered. O-lab offers users the opportunity to
        participate in content offered on or through the Platforms. If you wish to
        participate in content that is offered, you will be asked to provide us with certain
        information necessary to carry out that content that is offered. This information
        may include, among other things, your name and email address.<br /><br />
      </Typography>

      <Typography variant="h5">Information uses</Typography>
      <Typography variant="body2" display="block" align="justify" gutterBottom>
        We use information that is automatically collected to support the internal
        operations of the Platforms. Some of this information is collected through
        third-party analytics tools. Internal operations include the following: To monitor
        metrics such as the total number of visitors, achievements, activities carried out,
        failures, and traffic, this in order to inform partner educational institutions and
        sponsors about the progress and impact of the program on users who have been
        sponsored. To understand which activities and programs are most popular; To
        generally understand the location of our users (for example, zip code
        information); To recognize the browsers used to visit our Sites, so that the
        content is adapted to the corresponding browser; and To diagnose and fix
        technology problems. How to access or delete your Data Parents can contact us
        (at olab@fundacionelorigen.org ) to review, update or delete any personal
        information about their children that we have collected and choose not to collect
        additional personal information from their children.<br /><br />
        If you wish to delete your account in O-lab, you can do so by sending an email to
        olab@fundacionelorigen.org. If you delete your account, you will delete your
        profile, including any achievements, and you will no longer be able to access such
        Data. We will not be able to restore your account once it has been deleted.
        Deletion of your account may not happen immediately. Residual copies of your
        Personal Information may remain on backup storage media for a short time
        thereafter. In addition, we may store certain Data in our internal systems, and
        we may use it in our Business Services. In addition, we may retain Data as
        required by applicable laws and we will not delete Data that is not Personal
        Information, that is, that has already been anonymized. Security of the
        information O-lab considers information security a priority. O-lab implements
        systems, applications, and procedures to secure your personal information to
        minimize the risks of theft, damage, loss of information, unauthorized access, or
        use of information that is not authorized. However, these measures do not allow
        to provide absolute security. Therefore, although O-lab goes to great lengths to
        protect your personal information, we cannot guarantee, and you cannot
        reasonably hope that the O-lab databases are immune to bad actions,
        malfunctions, shortcuts, or illegal accesses, or other types 
        of misuse or abuse.<br /><br />
      </Typography>

      <Typography variant="h5">Control of information with workers</Typography>
      <Typography variant="body2" display="block" align="justify" gutterBottom>
        All persons who have this access are subject to strict contractual confidentiality
        obligations and may be penalized and fired if they do not comply with these
        obligations. Special circumstances. We may access, preserve and share your
        information when we believe in good faith that it is necessary to:<br />
        (i) respond to a legal request (such as a search warrant, court order or subpoena) 
        or take action because we believe it is required or advisable under the law;<br />
        (ii) detect, prevent and address fraud and other illegal activities;<br />
        (iii) respond to your customer service requests;<br />
        (iv) limit our legal liability or protect the rights, property, or
        personal safety of ourselves, any user of our Platforms, a third party, or the
        general public, including as part of investigations. Commercial Transfer. If we
        sell or transfer part or all of O-lab or our assets to another organization (for
        example, in the course of a transaction such as a merger, acquisition,
        bankruptcy, dissolution, liquidation), your personal information and any other
        information collected from through our platforms may be among the items sold
        or transferred. The buyer or the assignee must comply with the commitments we
        have made in this Privacy Policy. Safety notice. In the unlikely event that we
        believe that the security of your personal information in our possession or control
        may have been compromised, we may attempt to notify you of that development.
        If notification is appropriate, we endeavor to do so as soon as possible under the
        circumstances and, to the extent that you have provided us with your email
        address, we may notify you by email. You agree to our use of email as a means of
        such notification. Service Providers We contract with third parties to provide
        certain services to facilitate the operations of our Platforms ("service providers"),
        such as to manage analytical information about the use of our Platforms or to
        process donations. Our service providers will have access to your information
        only when it is reasonably necessary to provide the services for which they were
        contracted. They will be required to comply with the terms of this Privacy Policy
        when handling personal information.<br /><br />
      </Typography>

      <Typography variant="h5">Other websites and services</Typography>
      <Typography variant="body2" display="block" align="justify" gutterBottom>
        Our Platforms may link to other websites or applications, including content
        offered by third parties that we do not control and whose privacy and data
        collection practices may differ from ours ("Third Party Platforms"). We are not
        responsible for the practices used by any website or service linked to or from our
        Platforms, including the information or content they contain. When you access
        through our link to Third Party Platforms, you are bound by their terms of use
        and privacy policies rather than ours. You should review these other website
        privacy policies regarding any personal information they may collect about you.
        O-lab is operated by a third party, a licensee. The licensee has entered into an
        agreement with us to operate the Platforms and to sell and ship products related
        to O-lab educational programs.<br /><br />
      </Typography>

      <Typography variant="h5">Changes to the Privacy Policy</Typography>
      <Typography variant="body2" display="block" align="justify" gutterBottom>
        O-lab may change the conditions of this Privacy Policy. Considerable changes will
        take place 30 days after O-lab has published notifications at the beginning of the
        website with the new Privacy Policy. Other types of changes will take place 7
        days after the initial posting on the page. However, if O-lab modifies its Privacy
        Policy to comply with legal requirements, such changes will be effective
        immediately after initial posting or as required. You agree to be bound by any
        changes made to the conditions of this Privacy Policy. In order to continue using
        the Services, acceptance of the changes made must be indicated. If you do not
        agree with any of the modified conditions, you should avoid continuing to use the
        Platforms<br /><br />
      </Typography>
    </DialogContent>
  )
}

export default PrivacyPolicyEN;
