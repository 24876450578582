import firebase from "firebase/app";
import "firebase/analytics";
import { Action } from "./types";

export const analytics =
  (name: string, params?: { [key: string]: string }): Action =>
  (d, getState) => {
    const userCurrent = getState().user.user;
    const entityCurrent = getState().entity.entity;
    const paramsNew: { [key: string]: string } = {
      ...params,
      app: "App Web",
      date: new Date().toString(),
      user_nit: userCurrent?.nit ?? "",
      user_name: userCurrent?.name ?? "",
      entity_nit: entityCurrent?.nit ?? "",
      entity_name: entityCurrent?.name ?? "",
    };
    firebase.analytics().logEvent(name, paramsNew);
  };
