import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import FormButton from "components/Form/FormButton";
import { useTranslation } from "react-i18next";
import { IEntityCode } from "dto/entity-code.dto";

const useStyles = makeStyles(() => ({
  success: { textAlign: "center" },
  h5: { 
    color: "#5f5f5f", 
    fontWeight: 700,
  },
  subtitle: { 
    color: "#929292", 
    fontSize: 18, 
    fontWeight: 500, 
    marginBottom: 16,
  },
  image: { maxWidth: 250, maxHeight: 150 },
}));

interface IProps {
  entityCode: IEntityCode;
  registerWithEmail: () => void;
}

const EntityCodeSuccess: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const logo = !!props.entityCode?.entity_logo_url 
    ? props.entityCode.entity_logo_url
    : "/img/profile/success.svg";
  const name = props.entityCode?.entity_name;

  return (
    <Grid className={classes.success} container spacing={2}>
      <Grid item xs={12}>
        <img className={classes.image} src={logo} alt="" />
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.h5} variant="h5">
          {t("¡Ya casi terminamos!")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.subtitle}>
          {t("Ahora haces parte de la organización")}{" "}{name},
          {" "}{t("ya tienes acceso a su contenido y comunidad en O-lab")}.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormButton primary onClick={props.registerWithEmail} fullWidth>
          {t("Continuar")}
        </FormButton>
      </Grid>
    </Grid>
  )
}

export default EntityCodeSuccess;
