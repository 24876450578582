import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useElements, useStripe, CardElement } from '@stripe/react-stripe-js';
import { Formik, Form, Field } from 'formik';
import { object, string } from 'yup';
import { makeStyles, Grid, Button, TextField, Typography } from '@material-ui/core';
import { ILicense } from '../../services/checkout.services';
import { getClientSecret } from '../../utils';
import { messageError, messageSuccess } from '../Common/Toast';

const useStyles = makeStyles((theme) => ({
  form: { padding: 4 },
  field: { 
    '& .MuiInputBase-root': { backgroundColor: 'white' },
    [theme.breakpoints.down("xs")]: { padding: '0 !important' },
  },
  cardElement: {
    backgroundColor: 'white',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '10px 14px',
    borderRadius: 5,
  },
  btn: { 
    width: 350,
    color: 'white',
    borderRadius: 25,
    backgroundColor: '#ff6315',
    '&:hover': { backgroundColor: 'white', color: '#ff6315' },
    [theme.breakpoints.down("xs")]: { 
      width: '100%', 
      '&:hover': { backgroundColor: '#ff6315', color: 'white' },
    },
  },
  text: {
    color: 'grey',
    fontSize: 9,
    '& b': { color: '#56708c', fontSize: 15 },
  },
}));

interface IInitValues {name: string, email: string, phone: string, entity: string}
interface IProps {
  license: ILicense
  currency: "usd"|"cop"
  nextSection: () => void
  updateInfo: (data: IInitValues) => void
  updateLoading: (value: boolean) => void
}

const StripeCheckout: FC<IProps> = ({license, currency, updateInfo, nextSection, updateLoading}) => {
  const classes  = useStyles();
  const elements = useElements();
  const stripe   = useStripe();
  const { t }    = useTranslation();

  const initValue = {name: '', email: '', phone: '', entity: ''}
  const validations = object().shape({
    name:   string().required(t('Campo requerido')),
    email:  string().required(('Campo requerido')).email('example@example.com'),
    phone:  string().required(t('Campo requerido')),
    entity: string().required(t('Campo requerido')),
  });

  const onSubmit = async (values: IInitValues) => {
    updateLoading(true);
    if (!stripe || !elements) return updateLoading(false);
    const cardElement = elements.getElement(CardElement);
    if (cardElement == null) return updateLoading(false);

    try {
      const billing_details = {name: values.name, email: values.email, phone: values.phone}
      const paymentMethod = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details,
      });
      if (paymentMethod.error) {
        messageError(paymentMethod.error.message ?? "");
        updateLoading(false);
        return;
      }

      const description = `Compra de un ${license.name.toLowerCase()} por un ${license.amount.name.toLowerCase()}`;
      const clientSecret = await getClientSecret(license, currency, description);
      
      const { error } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethod.paymentMethod.id
      });
      if (error) {
        messageError(error.message ?? "");
        updateLoading(false);
        return;
      }
      nextSection();
      updateInfo(values);
      messageSuccess("Compra realizada exitosamente");
    } catch (e) { 
      updateLoading(false); 
    }
    updateLoading(false);
  };

  return (
    <Formik initialValues={initValue} validationSchema={validations} onSubmit={onSubmit}>
      {({errors, touched}) => (
        <Form className={classes.form}>
          <Grid container spacing={1}>
            <Grid className={classes.field} item sm={6} xs={12}>
              <Field 
                name="name" 
                as={TextField}
                size="small"
                label={t("Nombre del titular")}
                variant="outlined"
                error={errors.name && touched.name}
                helperText={touched.name && errors.name}
                fullWidth
              />
            </Grid>
            <Grid className={classes.field} item sm={6} xs={12}>
              <Field 
                name="entity" 
                as={TextField}
                size="small"
                label={t("Nombre de organización")}
                variant="outlined"
                error={errors.entity && touched.entity}
                helperText={touched.entity && errors.entity}
                fullWidth
              />
            </Grid>
            <Grid className={classes.field} item sm={6} xs={12}>
              <Field 
                name="email" 
                as={TextField}
                type="email"
                size="small"
                label={t("E-mail")}
                variant="outlined"
                error={errors.email && touched.email}
                helperText={touched.email && errors.email}
                fullWidth
              />
            </Grid>
            <Grid className={classes.field} item sm={6} xs={12}>
              <Field 
                name="phone" 
                as={TextField}
                size="small"
                label={t("Teléfono")}
                variant="outlined"
                error={errors.phone && touched.phone}
                helperText={touched.phone && errors.phone}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <CardElement
                className={classes.cardElement}
                options={{
                  style: {
                    base: {
                      fontSize: '16px',
                      '::placeholder': { color: 'rgba(0, 0, 0, 0.4)' },
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.text}>
                Secure Payment Powered by <b>stripe</b>
              </Typography>
            </Grid>
            <Grid item container justifyContent="center" xs={12}>
              <Button className={classes.btn} variant="contained" type="submit">
                {t("Comprar ahora")}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default StripeCheckout;
