import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import FormButton from "components/Form/FormButton";
import FormInputCode from "components/Form/FormInputCode";

const useStyles = makeStyles(() => ({
  h5: { color: "#5f5f5f", fontWeight: 700, marginBottom: 12, },
  subtitle: { 
    color: "#929292", 
    fontSize: 18, 
    fontWeight: 500, 
    marginBottom: 32,
  },
  code: { marginBottom: 32, },
}));

interface IProps {
  entityCode: string;
  sendEntityCode: () => void;
  updateEntityCode: (code: string) => void;
}

const EntityCodeForm: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid item xs={12}>
      <Typography className={classes.h5} variant="h5">
        {t("¿Tienes un código organizacional?")}
      </Typography>
      <Typography className={classes.subtitle}>
        {t("Si tu organización te compartió un código de O-Lab ingrésalo en el siguiente campo, de lo contrario salta este paso")}.
      </Typography>
      <Grid className={classes.code} item xs={12}>
        <FormInputCode onChange={props.updateEntityCode} />
      </Grid>
      <FormButton 
        primary 
        disabled={props.entityCode?.length !== 6}
        fullWidth 
        onClick={props.sendEntityCode}
      >
        {t("Unirme a mi organización")}
      </FormButton>
    </Grid>
  )
}

export default EntityCodeForm;
