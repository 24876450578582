import { Action } from "./types";
import * as types from "./types";

export const openModalSuccess  = (): Action => (dispatch) => {
  dispatch({type: types.MODAL_SUCCESS, payload: true})
}

export const closeModalSuccess  = (): Action => (dispatch) => {
  dispatch({type: types.MODAL_SUCCESS, payload: false})
}

export const changePageZoom = (): Action => (dispatch, getState) => {
  const zoom = getState().home.zoom;
  dispatch({type: types.ZOOM, payload: !zoom})
}
export const closePageZoom = (): Action => (dispatch) => {
  dispatch({type: types.ZOOM, payload: false})
}

export const openDialogTutorial  = (): Action => (dispatch) => {
  dispatch({type: types.DIALOG_TUTORIAL, payload: true})
}
export const closeDialogTutorial  = (): Action => (dispatch) => {
  dispatch({type: types.DIALOG_TUTORIAL, payload: false})
}

export const selectBackground = (url: string): Action => (dispatch) => {
  dispatch({type: types.BACKGROUND, payload: url})
}

export const resetDataHome = (): Action => (dispatch) => {
  dispatch({type: types.ZOOM,            payload: false});
  dispatch({type: types.BACKGROUND,      payload: ""});
  dispatch({type: types.MODAL_SUCCESS,   payload: false});
  dispatch({type: types.DIALOG_TUTORIAL, payload: false});
}
