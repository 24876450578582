import { ISurvey } from "dto/survey.dto";
import { AppThunkAction } from "store/types";
import { SurveyActionType } from "./actions";

export * from "./actions";
export * from "./constants";

export interface SurveyState {
  surveys: ISurvey[];
  surveyPublic: ISurvey;
  surveyCurrent: ISurvey;
  surveyLoading: boolean;
  surveysLoading: boolean;
  saveAnswerLoading: boolean;
  surveyPublicLoading: boolean;
  dialogCongratulations: boolean;
}

export type Action = AppThunkAction<SurveyActionType>