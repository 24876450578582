import { projectsInit } from "services/checkout.services";
import { CheckoutState, CheckoutActionType } from "./types";
import { LOADING, LICENSES, CLIENT_SECRET, LICENSES_LOADING } from "./types";

const INITIAL_STATE: CheckoutState = {
  loading: false,
  licenses: [],
  projects: projectsInit,
  clientSecret: "",
  licensesLoading: false,
}

export const checkout = (prevState = INITIAL_STATE, action: CheckoutActionType) => {
  switch (action.type) {
    case LOADING: 
      return { ...prevState, loading: action.payload }
    case LICENSES: 
      return { ...prevState, licenses: action.payload }
    case CLIENT_SECRET: 
      return { ...prevState, clientSecret: action.payload }
    case LICENSES_LOADING: 
      return { ...prevState, licensesLoading: action.payload }

    default: 
      return prevState;
  }
}
