import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import { IUser } from "dto/user.dto";
import { IEntity } from "dto/entity.dto";
import { IGender } from "dto/gender.dto";
import { ICountry } from "dto/country.dto";
import { TypeLanguageCode } from "dto/layout.dto";
import ProfileMenu from "components/Profile/ProfileMenu";
import ProfileLanguage from "components/Profile/ProfileLanguage";
import ProfilePassword from "components/Profile/ProfilePassword";
import ProfileInformation from "components/Profile/ProfileInformation";

const useStyles = makeStyles((theme) => ({
  profile: {
    gap: 32,
    padding: "28px 128px",
    [theme.breakpoints.down("md")]: { padding: "28px 64px" },
    [theme.breakpoints.down("sm")]: { padding: "28px 32px" },
    [theme.breakpoints.down("xs")]: { padding: "16px 16px", gap: 16 },
  },
  menu: { 
    width: 320, 
    minHeight: 320, 
    maxHeight: 356, 
    [theme.breakpoints.down("xs")]: { width: "100%" },
  },
  data: { 
    maxWidth: "calc(100% - 352px)",
    [theme.breakpoints.down("xs")]: { maxWidth: "100%" },
  },
  title: { color: "#5f5f5f", fontSize: 40, fontWeight: 700 },
}));

interface IProps {
  user: IUser;
  fbauth: string;
  entity: IEntity;
  genders: IGender[];
  section: number;
  countries: ICountry[];
  authLoading: boolean;
  photoLoading: boolean;
  languageCode: string;
  languageAudio: number;
  profileLoading: boolean;
  passwordLoading: boolean;
  logOut: () => void;
  updatePhoto: (data: File) => void;
  selectSection: (data: number) => void;
  updateProfile: (user: IUser, callback: () => void) => void;
  passwordReset: (email: string, callback: () => void) => void;
  changePassword: (password: string) => void;
  sendEntityCode: (code: string) => void;
  updateLanguage: (code: TypeLanguageCode) => void;
  changeLanguageAudio: (lang: number) => void;
}

const Profile: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid className={classes.profile} container>
      <Grid item xs={12}>
        <Typography className={classes.title}>
          {t("Configuración")}
        </Typography>
      </Grid>
      <Grid className={classes.menu} item>
        <ProfileMenu {...props} />
      </Grid>
      <Grid className={classes.data} item>
        {props.section === 0 ? (
          <ProfileInformation user={props.user}
            entity={props.entity}
            fbauth={props.fbauth}
            genders={props.genders}
            countries={props.countries}
            authLoading={props.authLoading}
            languageAudio={props.languageAudio}
            profileLoading={props.profileLoading}
            updateProfile={props.updateProfile}
            passwordReset={props.passwordReset}
            sendEntityCode={props.sendEntityCode}
            changeLanguageAudio={props.changeLanguageAudio} 
          />
        ) : props.section === 1 ? (
          <></>
        ) : props.section === 2 ? (
          <ProfilePassword loading={props.passwordLoading} {...props} />
        ) : props.section === 3 ? (
          <ProfileLanguage 
            languageCode={props.languageCode}
            updateLanguage={props.updateLanguage}
          />
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  )
}

export default Profile;
