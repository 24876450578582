import React, { FC, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Avatar, Button, Grid } from "@material-ui/core";
import { ISurvey } from "dto/survey.dto";
import FormTypography from "components/Form/FormTypography";

const useStyles = makeStyles(() => ({
  button: {
    width: 300,
    border: "4px solid #f0f0f0",
    padding: 0,
    textAlign: "initial",
    boxShadow: "0px 3px 0px 0px #f0f0f0",
    borderRadius: 16,
    textTransform: "initial",
    backgroundColor: "white",
    "&:hover": { backgroundColor: "white" },
  },
  header: { width: 80, height: 80 },
  avatar: { 
    width: 80, 
    height: 80,
    borderRadius: "0 16px 16px 0",
  },
  content: {
    width: "calc(100% - 80px)",
    height: 80,
    padding: "0 16px",
    alignItems: "center",
  },
}));

interface IProps {
  survey: ISurvey;
  selectSurvey: (survey: ISurvey) => void;
}

const SurveyButton: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [hover, setHover] = useState(false); 
  const play = props.survey?.name?.length > 20 && hover;

  const selectSurvey = () => props.selectSurvey(props.survey);

  return (
    <Button 
      className={classes.button} 
      onClick={selectSurvey} 
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Grid className={classes.content} container>
        <Grid item xs={12}>
          <FormTypography fontWeight={700} marquee play={play}>
            {props.survey?.name}
          </FormTypography>
          <FormTypography color="#8d8d8d" variant="body2">
            {`${props.survey?.questions?.length ?? 0} ${t("Actividades")}`}
          </FormTypography>
        </Grid>
      </Grid>
      <Grid container className={classes.header}>
        <Avatar 
          className={classes.avatar} 
          src={props.survey?.image_url} 
          alt="" 
        />
      </Grid>
    </Button>
  )
}

export default SurveyButton;
