import * as types from "./types";
import { ContentActionType, ContentState } from "./types";

const INITIAL_STATE: ContentState = {
  contents: [],
  contentsFree: [],
  contentsLoading: false,
  contentsFreeLoading: false,
}

export const content = (prevState = INITIAL_STATE, action: ContentActionType) => {
  switch (action.type) {
    case types.CONTENTS: 
      return { ...prevState, contents: action.payload };
    case types.CONTENTS_LOADING: 
      return { ...prevState, contentsLoading: action.payload };
    case types.CONTENTS_FREE: 
      return { ...prevState, contentsFree: action.payload }
    case types.CONTENTS_FREE_LOADING: 
      return { ...prevState, contentsFreeLoading: action.payload }

    default: 
      return prevState;
  }
}
