import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  look: {
    top: 0,
    bottom: 0,
    cursor: "no-drop",
    padding: "16px 24px",
    position: "absolute",
    alignItems: "center",
    backgroundColor: "#00000040",
  },
  image: { 
    width: 84, 
    height: 84,
    alignItems: "center",
    justifyContent: "center",
  },
}));

interface IProps {
  look?: boolean;
  finished?: boolean;
}

const ChallengerButtonLook: FC<IProps> = (props) => {
  const classes = useStyles();
  const image = !!props.finished ? "/img/icons/lock_success.svg" : "/img/icons/lock.svg";

  return (
    <>
      {props.look && (
        <Grid className={classes.look} container>
          <Grid className={classes.image} container>
            <Avatar src={image} alt="" />
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default ChallengerButtonLook;
