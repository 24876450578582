import { 
  IQuestion,
  IQuestionFile,
  IQuestionAnswer,
  IQuestionPreview,
  IPopUpPropertiesPreview,
  IFeedbackPropertiesPreview,
} from "dto/question.dto";

export const popUpPropertiesPreviewInit: IPopUpPropertiesPreview = { image: "" }

export const feedbackPropertiesPreviewInit: IFeedbackPropertiesPreview = {
  complete_image: "/gif/default/question/congratulation_complete_image_default.gif",
  complete_audio_fl: "",
  complete_audio_sl: "",
  incomplete_image: "/gif/default/question/congratulation_incomplete_image_default.gif",
  incomplete_audio_fl: "",
  incomplete_audio_sl: "",
}

export const questionInit: IQuestion = {
  id: "",
  type: 0,
  audio: "",
  title: "",
  audio_wa: "",
  question: "",
  subtitle: "",
  description: "",
  header_color: "",
  image_header: "",
  image_optional: "",
  background_color: "",
}
export const questionPreviewInit: IQuestionPreview = {
  type: 0,
  audio: "",
  audio_wa: "",
  image_header: "",
  image_optional: "",
}

export const questionFilesInit: IQuestionFile[] = [];
export const questionAnswersInit: IQuestionAnswer[] = [];

export const questionSubtitles = [
  {
    type: 0,
    description: "Única respuesta",
    subtitle_header: "Seleccione la respuesta correcta",
    description_header: "Opción múltiple",
  },
  {
    type: 2,
    description: "Arrastrar y soltar",
    subtitle_header: "Arrastra la respuesta correcta",
    description_header: "Completa la oración",
  },
  {
    type: 3,
    description: "Seleccionar texto",
    subtitle_header: "Selecciona la respuesta correcta",
    description_header: "Completa la oración",
  },
  {
    type: 4,
    description: "Respuesta abierta",
    subtitle_header: "Escribe tu respuesta",
    description_header: "Respuesta libre",
  },
  {
    type: 5,
    description: "Sopa de letras",
    subtitle_header: "Encuentra todas las palabras",
    description_header: "Sopa de letras",
  },
  {
    type: 6,
    description: "Opción múltiple",
    subtitle_header: "Seleccione la respuesta correcta",
    description_header: "Opción múltiple",
  },
  {
    type: 7,
    description: "Con imágenes",
    subtitle_header: "Selecciona la respuesta correcta",
    description_header: "Única respuesta",
  },
  {
    type: 8,
    description: "Respuesta abierta corta",
    subtitle_header: "Escribe tu respuesta",
    description_header: "Respuesta libre",
  },
  {
    type: 9,
    description: "Respuesta abierta con evidencia",
    subtitle_header: "Escribe tu respuesta",
    description_header: "Respuesta libre",
  },
  {
    type: 13,
    description: "Verdadero o falso",
    subtitle_header: "Seleccionar verdadero o falso",
    description_header: "Verdadero y falso",
  },
  {
    type: 14,
    description: "Múltiples respuestas",
    subtitle_header: "Seleccione la respuesta correcta",
    description_header: "Opción múltiple",
  },
  {
    type: 15,
    description: "Lección de texto",
    subtitle_header: "Lectura",
    description_header: "Toma notas",
  },
  {
    type: 16,
    description: "Con evidencia",
    subtitle_header: "Escribe tu respuesta",
    description_header: "Respuesta libre",
  },
  {
    type: 17,
    description: "Descargar archivo",
    subtitle_header: "Descargar archivo",
    description_header: "Descargar archivo",
  },
  {
    type: 18,
    description: "Predeterminado",
    subtitle_header: "Escribe tu respuesta",
    description_header: "Respuesta libre",
  },
  {
    type: 19,
    description: "Vídeo vertical",
    subtitle_header: "Escribe tu respuesta",
    description_header: "Respuesta libre",
  },
  {
    type: 20,
    description: "Imagen o GIF",
    subtitle_header: "Escribe tu respuesta",
    description_header: "Respuesta libre",
  },
  {
    type: 21,
    description: "PDF",
    subtitle_header: "PDF",
    description_header: "PDF",
  },
  {
    type: 22,
    description: "Type fragment",
    subtitle_header: "Type fragment",
    description_header: "Type fragment",
  },
];
