import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Grid } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  background: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0, 
    overflow: "hidden",
    position: "absolute", 
    justifyContent: "center",
  },
  avatar: { width: "100%", height: "100%", borderRadius: 0 }
}));

interface IProps {
  gif?: boolean;
  image?: string;
}

const BackgroundImage: FC<IProps> = (props) => {
  const classes = useStyles();
  const background = !!props.image 
    ? props.image 
    : !!props.gif 
    ? "/img/background/entorno_b1.png" 
    : "/img/background.svg";

  return (
    <Grid className={classes.background} container>
      <Avatar className={classes.avatar} src={background} alt="" />
    </Grid>
  )
}

export default BackgroundImage;
