import React, { FC } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { adjustBrightness } from "utils";

const useStyles = makeStyles(() => ({
  root: { position: "relative", },
  bar: {
    height: 10,
    opacity: 0.4,
    borderRadius: 25,
    backgroundColor: "#ff6300",
  },
  progress: { 
    width: 0,
    height: 10,
    position: "absolute",
    borderRadius: 25,
    backgroundImage: "linear-gradient(180deg, #ff6300 50%, #e05700 50%)",
  },
}));

interface IProps {
  max: number;
  value: number;
  color?: string;
  secondary?: string;
}

const ProgressBar: FC<IProps> = (props) => {
  const classes = useStyles();
  const max = !!props.max ? props.max : 1;
  const color = !!props.color ? props.color : "#ff6300";
  const progress = !!props.max ? ((props.value / max) * 100) : 0;
  const secondary = !!props.secondary ? props.secondary : adjustBrightness(color, 30);

  return (
    <Grid className={classes.root} container>
      <Grid className={classes.bar} container style={{ backgroundColor: color }} />
      <Grid 
        className={classes.progress} 
        container 
        style={{ 
          width: `${progress}%`,
          backgroundImage: `linear-gradient(180deg, ${color} 50%, ${secondary} 50%)`,
        }} 
      />
    </Grid>
  )
}

export default ProgressBar;
