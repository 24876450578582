import { moduleInit } from "initials";
import { ModuleActionType, ModuleState } from "./types";
import * as types from "./types";

export const INITIAL_STATE: ModuleState = {
  module: moduleInit,
  moduleFree: moduleInit,
};

export const module = (prevState = INITIAL_STATE, action: ModuleActionType) => {
  switch (action.type) {
    case types.MODULE:
	    return { ...prevState, module: action.payload };
    case types.MODULE_FREE:
	    return { ...prevState, moduleFree: action.payload };
    
    default:
      return prevState;
  }
}
