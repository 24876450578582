import { messageError } from '../components/Common/Toast';
import Checkout, { ILicense } from '../services/checkout.services';

export const getClientSecret = async (project: ILicense, currency: "usd"|"cop", description: string) => {
  try {
    const clientSecret = await Checkout.postPayment(project, currency, description);
    return clientSecret;
  } catch (e: any) {
    console.log('Error: getClientSecret');
    messageError(e.message);
  }
}

export const donationClientSecret = async (amount: number, currency: string, description: string) => {
  try {
    const clientSecret = await Checkout.postDonation(amount, currency, description);
    return clientSecret;
  } catch (e) {
    console.log('Error: getClientSecret')
  }
}
