import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { history } from "configureStore";
import FormButton from "components/Form/FormButton";
import FormTypography from "components/Form/FormTypography";
import BackgroundImage from "components/Common/BackgroundImage";

const useStyles = makeStyles((theme) => ({
  page: { 
    height: "100%", 
    position: "relative", 
    backgroundColor: "white",
  },
  content: { 
    position: "relative", 
    alignItems: "center",
    padding: "0 128px",
    [theme.breakpoints.down("md")]: { padding: "0 64px" },
    [theme.breakpoints.down("sm")]: { padding: "0 32px" },
    [theme.breakpoints.down("xs")]: { padding: "0 16px" },
  },
}));

const Page404 = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const redirectHome = () => history.push("/home");

  return (
    <Grid className={classes.page} container>
      <BackgroundImage image="/img/background_error.png" />
      <Grid className={classes.content} container>
        <Grid item>
          <FormTypography fontSize={64} fontWeight={700}>
            404
          </FormTypography>
          <FormTypography variant="h5" fontWeight={700}>
            {t("Página no encontrada")}
          </FormTypography>
          <FormTypography color="#8d8d8d" fontSize={18} gutterBottom>
            {t("Esta página no existe o ha sido removida")}
          </FormTypography>
          <FormButton primary minWidth={265} onClick={redirectHome}>
            {t("Volver al inicio")}
          </FormButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Page404;
