import React, { FC } from "react";
import { makeStyles } from "@material-ui/core";
import { soundClick } from "utils";

const useStyles = makeStyles((theme) => ({
  image: { 
    cursor: "pointer", 
    maxWidth: "100%",
    maxHeight: 50,
    [theme.breakpoints.down("xs")]: { maxWidth: 80 },
  },
}));

interface IProps {
  topBarColor?: boolean;
  organizationImage?: string;
  onClick?: () => void;
}

const OrganizationImage: FC<IProps> = (props) => {
  const classes = useStyles();
  const organizationImage = 
    !!props.organizationImage 
    ? props.organizationImage 
    : props.topBarColor 
    ? "/img/logos/olab_white.svg" 
    : "/img/logos/olab.svg";

  return (
    <img
		  className={classes.image}
		  src={organizationImage}
		  alt="Logo de la organización"
		  onClick={props.onClick}
      onMouseDown={soundClick}
		/>
  )
}

export default OrganizationImage;
