import React, { FC } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ITemplate, ITemplateItem } from "dto/template.dto";
import TemplateStage from "components/Question/Template/TemplateStage";

const useStyles = makeStyles((theme) => ({
  type23: { 
    height: "100%", 
    overflow: "auto",
    alignItems: "center",
    justifyContent: "center",
    "&::-webkit-scrollbar": { width: 0, height: 0 },
    "&::-webkit-scrollbar-track": { backgroundColor: "white" },
    '&::-webkit-scrollbar-thumb': { backgroundColor: "#c1c1c1", borderRadius: 4 },
  },
}));

interface IProps {
  templates: ITemplate[];
  templateIndex: number;
  getTemplateSkipLogic: (templateItem: ITemplateItem) => void;
}

const Type23: FC<IProps> = (props) => {
  const classes = useStyles();
  const templates: ITemplate[] = !!props.templates?.length
    ? props.templates.map((template) => (template))
    : [];

  return (
    <Grid className={classes.type23} container>
      <TemplateStage 
        template={templates[props.templateIndex]} 
        selectTemplateItem={props.getTemplateSkipLogic} 
      />
    </Grid>
  )
}

export default Type23;
