const svgImage = (c1: String) => `
  <svg width="72" height="62" viewBox="0 0 72 62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M36 0L71.507 61.5H0.492958L36 0Z" fill="${c1}"/>
  </svg>
`;

export const SvgTriangle = (c1?: string) => {
  const a = !!c1 ? c1 : "#ff6300";
  return `data:image/svg+xml;charset=UTF-8;base64,${btoa(svgImage(a))}`;
};

export default SvgTriangle;
