import { IModule } from "dto/module.dto";
import { IChallenger } from "dto/challenger.dto";
import { ITask, IUser } from "dto/user.dto";
import { ILaboratory, ILaboratoryCongratulations } from "dto/laboratory.dto";
import { tasksInit } from "initials/task.init";
import { moduleCongratulationsInit, moduleInit } from "initials";
import * as utils from "utils";

export const getModulesFiles = async (laboratoryName: string, modules: IModule[]) => {
  try {
    const data: IModule[] = [];
    for (let i = 0; i < modules?.length; i++) {
      const module = await getModuleFiles(laboratoryName, modules[i]);
      data.push(module);
    }
    return data;
  } catch (error) {
    return modules ?? [];
  }
}
export const getModulesEnabled = (laboratory: ILaboratory, user: IUser) => {
  const modules = laboratory?.modules ?? [];
  const permissions = user?.permissions ?? [];

  if (!!modules?.length && !!permissions.length) {
    const enabled = modules?.filter((module) => (
      getModuleEnabled(laboratory?.name, module?.name, user)
    ));
    return enabled;
  } else return [];
}
export const getModulesWithQuestions = (laboratory: ILaboratory) => {
  const modules = laboratory?.modules ?? [];
  if (laboratory?.update_date > new Date("01/31/2024").getTime()) {
    const modulesNew = modules?.map((m) => {
      const challenges = m?.challenges?.map((c) => {
        const questions = c?.questions?.filter((q) => (q.type !== 19 && q.type !== 24));
        return { ...c, questions };
      });
      return { ...m, challenges: challenges?.filter((c) => (!!c?.questions?.length)) }
    });
    return modulesNew?.filter((m) => (m?.challenges?.length));
  } else {
    const modulesNew = modules?.map((m) => {
      const challenges = m?.challenges?.filter((c) => (!!c?.questions?.length));
      return { ...m, challenges }
    });
    return modulesNew?.filter((m) => (m?.challenges?.length));
  }
}

export const gatModuleRate = (
  laboratoryName: string, 
  module: IModule,
  tasks: ITask[],
  unrounded?: boolean,
) => {
  let rate = 0;
  const moduleTasks = getModuleTasks(laboratoryName, module, tasks);
  
  for (let i = 0; i < moduleTasks.length; i++) {
    rate = rate + moduleTasks[i].rate;
  }

  if (!!module?.challenges?.length && !!unrounded) {
    return rate / module.challenges.length;
  } else if (!!module?.challenges?.length) {
    return Math.round(rate / module.challenges.length);
  } else return 0;
}
export const getModuleProgress = (laboratoryName: string, module: IModule, tasks: ITask[]) => {
  if (!!module?.challenges?.length) {
    const challenges = module?.challenges ?? [];
    const challengesAux: IChallenger[] = [];
    for (let i = 0; i < challenges?.length; i++) {
      const tasksAux = tasks.filter((t) => (
        t?.laboratory === laboratoryName &&
        t?.module === module?.name &&
        t?.challenger === challenges[i]?.name
      ));
      if (!!tasksAux?.length) challengesAux.push(challenges[i]);
    }
    const percentage = (challengesAux?.length / challenges?.length);
    return { progress: challengesAux?.length, percentage };
  } else return { progress: 0, percentage: 0 };
}
export const getModuleCongratulations = async (laboratoryName: string, module: IModule) => {
  try {
    const congratulations: ILaboratoryCongratulations = {
      complete_image: await utils.downloadFile(
        `${laboratoryName}/${module.name}`, 
        module?.congratulations?.complete_image,
        moduleCongratulationsInit?.complete_image,
      ),
      complete_audio_fl: await utils.downloadFile(
        `${laboratoryName}/${module.name}`, 
        module?.congratulations?.complete_audio_fl,
        moduleCongratulationsInit?.complete_audio_fl,
      ), 
      complete_audio_sl: await utils.downloadFile(
        `${laboratoryName}/${module.name}`, 
        module?.congratulations?.complete_audio_sl,
        moduleCongratulationsInit?.complete_audio_sl,
      ),
      complete_text_one: !!module?.congratulations?.complete_text_one 
        ? module.congratulations.complete_text_one
        : moduleCongratulationsInit?.complete_text_one,
      complete_text_two: !!module?.congratulations?.complete_text_two 
        ? module.congratulations.complete_text_two
        : moduleCongratulationsInit?.complete_text_two,
      incomplete_image: await utils.downloadFile(
        `${laboratoryName}/${module.name}`, 
        module?.congratulations?.incomplete_image,
        moduleCongratulationsInit?.incomplete_image,
      ),
      incomplete_audio_fl: await utils.downloadFile(
        `${laboratoryName}/${module.name}`, 
        module?.congratulations?.incomplete_audio_fl,
        moduleCongratulationsInit?.incomplete_audio_fl,
      ),
      incomplete_audio_sl: await utils.downloadFile(
        `${laboratoryName}/${module.name}`, 
        module?.congratulations?.incomplete_audio_sl,
        moduleCongratulationsInit?.incomplete_audio_sl,
      ),
      incomplete_text_one: !!module?.congratulations?.incomplete_text_one 
        ? module.congratulations.incomplete_text_one
        : moduleCongratulationsInit?.incomplete_text_one,
      incomplete_text_two: !!module?.congratulations?.incomplete_text_two 
        ? module.congratulations.incomplete_text_two
        : moduleCongratulationsInit?.incomplete_text_two,
    }
    return congratulations;
  } catch (error) {
    return moduleCongratulationsInit;
  }
}

export const getModuleUnlocked = (laboratory: ILaboratory, module: IModule, tasks: ITask[]) => {
  if (laboratory?.modules?.length) {
    if (!!laboratory?.modules_blocked  || !!laboratory?.sequential_mode) {
      let rate = 0;
      let percentage = 0;
      let moduleWithTasks = { tasks: tasksInit, module: moduleInit };
      const modules = laboratory.modules;
      for (let i = 0; i < modules?.length; i++) {
        const tasksAux = getModuleTasks(laboratory?.name, modules[i], tasks);
        if (!!tasksAux?.length) {
          rate = gatModuleRate(laboratory?.name, modules[i], tasks);
          percentage = getModuleProgress(laboratory?.name, modules[i], tasks).percentage;
          moduleWithTasks = { tasks: tasksAux, module: modules[i] };
        }
      }
      const modulesBlockedSequential = 
      !!laboratory?.modules_blocked 
        && !!laboratory?.sequential_mode
        && (rate < laboratory?.modules_rate_min || percentage < 1);
      const modulesBlocked = 
        !!laboratory?.modules_blocked 
          && (rate < laboratory?.modules_rate_min || percentage < 1);
      if (!!moduleWithTasks?.tasks?.length) {
        if (modulesBlockedSequential) {
          return moduleWithTasks?.module?.name === module?.name;
        } else if (modulesBlocked) {
          const modulePercentage = getModuleProgress(laboratory?.name, module, tasks).percentage;
          return moduleWithTasks?.module?.name === module?.name || modulePercentage >= 1;
        } else if (!!laboratory?.sequential_mode && percentage < 1) {
          return moduleWithTasks?.module?.name === module?.name;
        } else if (!!laboratory?.sequential_mode) {
          const moduleNext = getModuleNext(laboratory, moduleWithTasks?.module);
          if (!!moduleNext) return moduleNext?.name === module?.name
          else return true;
        } else if (!!laboratory?.modules_blocked) {
          const moduleNext = getModuleNext(laboratory, moduleWithTasks?.module);
          const modulePercentage = getModuleProgress(laboratory?.name, module, tasks).percentage;
          return moduleNext?.name === module?.name || modulePercentage >= 1;
        } else return true;
      } else return modules[0]?.name === module?.name;
    } else return true;
  } else return false;
}

const getModuleNext = (laboratory: ILaboratory, moduleCurrent: IModule) => {
  let index = 0;
  const modules = laboratory?.modules ?? [];
  for (let i = 0; i < modules.length; i++) {
    if (modules[i]?.name === moduleCurrent?.name) index = i
  }
  return modules[index + 1];
}
const getModuleFiles = async (laboratoryName: string, module: IModule) => {
  try {
    const background_image_url = await utils.downloadFile(
      `${laboratoryName}/${module?.name}`,
      module?.background_image,
    );
    const background_video_url = await utils.downloadFile(
      `${laboratoryName}/${module?.name}`,
      module?.background_video,
    );
    return { 
      ...module, 
      background_image_url, 
      background_video_url, 
    }
  } catch (error) {
    return module;
  }
}
const getModuleTasks = (laboratoryName: string, module: IModule, tasks: ITask[]) => {
  const tasksAux: ITask[] = [];
  const challenges = module?.challenges ?? [];
  for (let i = 0; i < challenges.length; i++) {
    let taskAux: ITask | undefined = undefined;
    for (let j = 0; j < tasks?.length; j++) {
      if (
        tasks[j].laboratory === laboratoryName &&
        tasks[j].module === module?.name &&
        tasks[j].challenger === challenges[i]?.name
      ) {
        taskAux = tasks[j];
      }
    }
    if (!!taskAux) tasksAux.push(taskAux);
  }
  return tasksAux;
}
const getModuleEnabled = (laboratoryName: string, moduleName: string, user: IUser) => {
  const permissions = user?.permissions?.filter((p) => (
    p.laboratoryName === laboratoryName &&
    p.moduleName === moduleName
  ));
  return !!permissions?.length;
}
