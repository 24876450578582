import React, { FC } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	topBar: {
    height: 70,
    zIndex: 1,
    padding: "0 128px",
    alignItems: "center",
    // borderBottom: "2px solid #f0f0f0",
    justifyContent: "space-between",
    backgroundColor: "white",
    [theme.breakpoints.down("md")]: { padding: "0 64px" },
    [theme.breakpoints.down("sm")]: { padding: "0 32px" },
    [theme.breakpoints.down("xs")]: { padding: "0 16px" },
  },
}));

interface IProps {
  zoom?: boolean;
  children: React.ReactNode;
  topBarColor?: string;
  borderBottom?: number | string;
}

const TopBarRoot: FC<IProps> = (props) => {
  const classes = useStyles();
  const style = { 
    borderBottom: props.borderBottom,
    backgroundColor: props.topBarColor,
  }

  return (
    <>
      {!props.zoom && (
        <Grid className={classes.topBar} style={style} container>
          {props.children}
        </Grid>
      )}
    </>
  )
}

export default TopBarRoot;
