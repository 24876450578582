import { NoteActionType, NoteState } from './types';
import { NOTES, BLOGS, NOTES_MAIN, NOTE_LOADING, BLOGS_LOADING, NOTES_LOADING } from './types';

export const NOTE_INITIAL_STATE: NoteState = {
  notes:        [],
  blogs:        [],
  notesMain:    [],
  noteLoading:  false,
  notesLoading: false,
  blogsLoading: false,
}

export const note = (prevState = NOTE_INITIAL_STATE, action: NoteActionType) => {
  switch (action.type) {
    case NOTES:         return { ...prevState, notes:        action.payload }
    case BLOGS:         return { ...prevState, blogs:        action.payload }
    case NOTES_MAIN:    return { ...prevState, notesMain:    action.payload }
    case NOTE_LOADING:  return { ...prevState, noteLoading:  action.payload }
    case BLOGS_LOADING: return { ...prevState, blogsLoading: action.payload }
    case NOTES_LOADING: return { ...prevState, notesLoading: action.payload }

    default: return prevState
  }
}