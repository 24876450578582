import { IUser } from "dto/user.dto";
import { AppThunkAction } from "store/types";
import { UserActionType } from "./actions";

export * from "./constants";
export * from "./actions";

export interface UserState {
  user: IUser
  users: IUser[]
  userLoading: boolean;
  noteLoading: boolean;
  taskLoading: boolean;
  usersLoading: boolean;
  photoLoading: boolean;
  userIsLoaded: boolean;
  languageAudio: number;
  profileLoading: boolean;
  passwordLoading: boolean;
}

export type Action = AppThunkAction<UserActionType>
