import React, { FC } from "react";
import { Grid } from "@material-ui/core";
import ReactPlayer from "react-player";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  background: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0, 
    overflow: "hidden",
    position: "absolute", 
    justifyContent: "center",
  },
}));

interface IProps {
  video: string;
  audio?: boolean;
  autoplay?: boolean;
}

const BackgroundVideo: FC<IProps> = (props) => {
  const classes = useStyles();

  return (
    <Grid className={classes.background} container>
      <ReactPlayer 
        url={props.video}
        loop
        width="100%"
        height="auto"
        volume={!!props.audio ? 1 : 0}
        playing={!!props.autoplay}
      />
    </Grid>
  )
}

export default BackgroundVideo;
