import { AppThunkAction } from "store/types";
import { ILaboratory } from "dto/laboratory.dto";
import { LaboratoryActionType } from "./actions";

export * from "./actions";
export * from "./constants";

export interface LaboratoryState {
  labs: ILaboratory[];
  laboratory: ILaboratory;
  labsLoading: boolean;
  laboratories: ILaboratory[];
  laboratoryFree: ILaboratory;
  laboratoryLoading: boolean;
  certificateLoading: boolean;
}

export type Action = AppThunkAction<LaboratoryActionType>
