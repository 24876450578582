import React, { FC } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { ISurveyCongratulations } from "dto/survey.dto";
import FormTypography from "components/Form/FormTypography";

const useStyles = makeStyles((theme) => ({
  header: {
    height: 110,
    border: "2px solid #f0f0f0",
    padding: "8px 32px",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

interface IProps {
  congratulations: ISurveyCongratulations;
}

const DialogCongratulationsHeader: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid className={classes.header} container>
      <Grid item xs={12}>
        <FormTypography align="center" variant="h5" fontWeight={700}>
          {t(props.congratulations?.text_one)}
        </FormTypography>
        <FormTypography align="center" color="#8d8d8d">
          {t(props.congratulations?.text_two)}
        </FormTypography>
      </Grid>
    </Grid>
  )
}

export default DialogCongratulationsHeader;
