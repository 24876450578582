import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Grid, Avatar, Button } from "@material-ui/core";
import { ITask } from "dto/user.dto";
import { IModule } from "dto/module.dto";
import * as utils from "utils";
import ProgressBar from "components/Common/ProgressBar";
import FormTypography from "components/Form/FormTypography";
import { messageError } from "components/Common/Toast";
import { ILaboratory } from "dto/laboratory.dto";

const useStyles = makeStyles(() => ({
  button: {
    width: 500,
    height: "fit-content",
    cursor: "pointer",
    border: "3px solid var(--grey-grey-300, #F0F0F0)",
    display: "flex",
    padding: 0,
    position: "relative",
    textAlign: "initial",
    boxShadow: "0px 3px 0px 0px #F0F0F0",
    borderRadius: 18,
    textTransform: "initial",
    backgroundColor: "white",
    "&:hover": { backgroundColor: "white", },
  },
  avatar: {
    width: 108,
    height: 108,
    borderRadius: "0 16px 16px 0",
  },
  content: { width: "calc(100% - 108px)", padding: "12px 16px" },
  avatarMask: {
    right: 0,
    width: 108,
    height: 108,
    opacity: 0.5,
    position: "absolute",
    alignItems: "center",
    borderRadius: "0 16px 16px 0",
    justifyContent: "center",
    backgroundColor: "black",
  },
}));

interface IProps {
  tasks: ITask[];
  module: IModule;
  laboratory: ILaboratory;
  selectModule: (module: IModule) => void;
}

const ModuleButton: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const image = props.module?.imageUrl ?? "";
  const challenges = props.module?.challenges ?? [];
  const displayName = props.module?.displayName ?? props.module?.name;
  const moduleLocked = !utils.getModuleUnlocked(props.laboratory, props.module, props.tasks);
  const displayNamePlay = displayName?.length > 25;
  const progress = utils.getModuleProgress(
    props.laboratory?.name, 
    props.module, 
    props.tasks,
  ).progress;
  const percentage = utils.getModuleProgress(
    props.laboratory?.name, 
    props.module, 
    props.tasks,
  ).percentage * 100;

  const selectModule = () => {
    if (!!props.module?.challenges?.length) {
      props.selectModule(props.module);
    } else messageError(t("El módulo no tiene unidades"));
  }

  return (
    <Button 
      className={classes.button} 
      onClick={selectModule} 
      onMouseDown={utils.soundClick}
      disabled={!!moduleLocked}
    >
      <Grid className={classes.content} container>
        <Grid item xs={12}>
          <FormTypography variant="h6" fontWeight={700} marquee play={displayNamePlay}>
            {displayName}
          </FormTypography>
        </Grid>

        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <FormTypography color="#8d8d8d">
              {`${challenges?.length} ${t("unidades")}`}
            </FormTypography>
            <FormTypography color="#8d8d8d">
              {Math.round(percentage)}%
            </FormTypography>
          </Grid>
          {(!!moduleLocked) ? (
            <ProgressBar max={1} color="#fcfcfc" value={1} secondary="#f4f4f4" />
          ) : (
            <ProgressBar 
              max={challenges?.length ?? 1} 
              color={props.module?.primaryColor} 
              value={progress}
            />
          )}
        </Grid>
      </Grid>
      
      <Avatar className={classes.avatar} src={image} alt="" />
      {(!!moduleLocked) && (
        <Grid className={classes.avatarMask} container>
          {percentage >= 1 ? (
            <img src="/img/icons/lock_success.svg" alt="" width={32} />
          ) : (
            <img src="/img/icons/lock.svg" alt="" width={32} />
          )}
        </Grid>
      )}
    </Button>
  )
}

export default ModuleButton;