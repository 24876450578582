import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Grid, Button } from '@material-ui/core';
import { IAmount, ILicense } from '../../../services/checkout.services';
import LicenseList from './LicenseList';

const useStyles = makeStyles((theme) => ({
  license: { position: 'relative', padding: '30px 18px' },
  btn: { 
    width: 350, 
    borderRadius: 25,
    [theme.breakpoints.down("xs")]: { width: '100%' },
  },
  image: { 
    position: 'absolute', 
    right: 0, 
    bottom: 0, 
    [theme.breakpoints.down("xs")]: { display: 'none' },
  },
}));

interface IProps {
  license: ILicense
  currency: "usd"|"cop"
  setCurrency: (val: "usd"|"cop") => void
  selectLicense: (price: IAmount) => void
  deselectService: () => void
}

const License: FC<IProps> = ({license, currency, setCurrency, selectLicense, deselectService}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid className={classes.license} container justifyContent="center">
      <LicenseList 
        license={license} 
        currency={currency}
        setCurrency={setCurrency}
        selectLicense={selectLicense} 
      />

      <Button className={classes.btn} variant="contained" onClick={deselectService}>
        {t("Volver")}
      </Button>
      
      <img className={classes.image} src="/images/checkout_avatar.svg" alt="" />
    </Grid>
  )
}

export default License;
