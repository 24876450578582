import React, { FC } from "react";
import { IChallenger } from "dto/challenger.dto";
import { ILaboratoryCongratulations } from "dto/laboratory.dto";
import * as utils from "utils/content.utils";
import SuccessHeader from "./SuccessHeader";
import SuccessContent from "./SuccessContent";

interface IProps {
  rate: number;
  challenger: IChallenger;
  languageAudio: string;
  challengerNext: IChallenger;
  congratulations: ILaboratoryCongratulations;
  saveUserTask: () => void;
}

const ChallengerCongratulations: FC<IProps> = (props) => {
  const audioSrc = props.rate > 2 && props.languageAudio === "1"
    ? props.congratulations?.complete_audio_sl
    : props.rate > 2 && props.languageAudio !== "1" 
    ? props.congratulations?.complete_audio_fl 
    : props.languageAudio === "1" 
    ? props.congratulations?.incomplete_audio_sl
    : props.congratulations?.incomplete_audio_fl;
  const subtitle = props.rate > 2 ? "" : "Unidad no completada";

  return (
    <>
      <SuccessHeader 
        rate={props.rate} 
        subtitle={subtitle}
        primaryColor={props.challenger?.primary_color}
        congratulations={props.congratulations} 
      />
      <SuccessContent
        rate={props.rate}
        primaryColor={props.challenger?.primary_color}
        congratulations={props.congratulations}
        challengerNextName={utils.getDisplayName(props.challengerNext)}
        saveUserTask={props.saveUserTask} 
      />
      <audio src={audioSrc} autoPlay>
        Your browser does not support the <code>audio</code> element.
      </audio>
    </>
  )
}

export default ChallengerCongratulations;
