import React, { FC, MouseEvent, useRef, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { IUser } from "dto/user.dto";
import { history } from "configureStore";
import { IconSettingSvg } from "./IconSettingSvg";
import { 
  Grow,
  Paper,
  Popper,
  Avatar, 
  Divider, 
  MenuItem, 
  MenuList, 
  IconButton, 
  Typography,
  ClickAwayListener, 
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  button: {
    width: 40,
    height: 40, 
    padding: 2,
    borderRadius: 8,
    "& .MuiAvatar-root": { width: "100%", height: "100%", borderRadius: 0 },
    "&:disabled": { "& .MuiAvatar-root": { filter: "grayscale(1)" }, },
    [theme.breakpoints.down("xs")]: { width: 35, height: 35, },
  },
  select: {
    border: "2px solid #0aa9e2",
    backgroundColor: "#ebfaff",
    "&:hover": { backgroundColor: "#ebfaff" },
    "&:disabled": { borderColor: "grey" },
  },
  paper: {
    top: 4,
    border: "2px solid #f0f0f0",
    minWidth: 200,
    position: "relative",
    boxShadow: "none",
    borderRadius: 16,
    backgroundColor: "white"
  },
  menu: { padding: 0 },
  divider: { height: 2, backgroundColor: "#f0f0f0" },
  menuitem: {
    height: 50,
    "& .MuiAvatar-root": { 
      width: 32, 
      height: 32, 
      marginRight: 8,
      borderRadius: 0, 
    },
    "& .MuiTypography-root": {
      color: "#8d8d8d",
      fontSize: 18,
      fontWeight: 500,
    },
  },
}));

interface IProps {
  user: IUser;
  disabled?: boolean;
  iconColor?: string;
  laboratoryOpen: boolean;
  logout: () => void;
  openPageProfile: () => void;
  openDialogLeaveLaboratory: (url: string) => void;
}

const ButtonLayoutProfile: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [popper, setPopper] = useState(false);
  const location = history.location.pathname;
  const className = clsx(classes.button, location === "/profile" && classes.select);

  const onClickButtonProfile = () => {
    if (!!props.user?.nit) togglePopper();
    else props.openPageProfile();
  }

  const togglePopper = () => setPopper((v) => !v);
  const closePopper = (e: MouseEvent<EventTarget>) => {
		if (anchorRef?.current?.contains(e.target as HTMLElement)) return;
		setPopper(false);
  };
  
  const openProfile = (e: MouseEvent<EventTarget>) => {
    closePopper(e);
    props.openPageProfile();
  }
  const logout = (e: MouseEvent<EventTarget>) => { 
    props.logout(); 
    closePopper(e); 
    history.push("/");
  }

  return (
    <>
      <IconButton
        className={className} 
        ref={anchorRef}
        onClick={onClickButtonProfile}
        disabled={props.disabled}
      >
        <Avatar src={IconSettingSvg(props.iconColor)} alt="" />
        {/* <Avatar src="/img/layout/settings.svg" alt="" /> */}
      </IconButton>
      <Popper
        open={popper} 
        role={undefined} 
        anchorEl={anchorRef.current} 
        transition 
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps} 
            style={{ 
              transformOrigin: placement === "bottom" ? "center top" : "center bottom" 
            }}
          >
            <Paper className={classes.paper}>
              <ClickAwayListener onClickAway={closePopper}>
                <MenuList className={classes.menu} autoFocusItem={popper}>
                  <MenuItem className={classes.menuitem} onClick={openProfile}>
                    <Avatar src="/img/layout/settings_icon.svg" alt="" />
                    <Typography>{t("Configuración")}</Typography>
                  </MenuItem>
                  <Divider className={classes.divider} />
                  <MenuItem className={classes.menuitem} onClick={logout}>
                    <Avatar src="/img/layout/logout.svg" alt="" />
                    <Typography>{t("Cerrar sesión")}</Typography>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default ButtonLayoutProfile;
