import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, Grid } from "@material-ui/core";
import FormTypography from "components/Form/FormTypography";
import { useTranslation } from "react-i18next";
import FormButton from "components/Form/FormButton";

const useStyles = makeStyles(() => ({
  dialog: {
    "& .MuiDialog-paper": {
      padding: "24px 32px",
      borderRadius: 16,
      backgroundColor: "white",
    },
  },
}));

interface IProps {
  open: boolean;
  onClose: () => void;
  openPageSelected: () => void;
}

const DialogLeaveLaboratory: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog 
      className={classes.dialog} 
      open={props.open} 
      maxWidth="xs"
      onClose={props.onClose}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img src="/img/warning.svg" alt="" />
        </Grid>
        <Grid item xs={12}>
          <FormTypography variant="h5" fontWeight={700} gutterBottom>
            {t("¿Salir del laboratorio?")}
          </FormTypography>
          <FormTypography fontSize={18}>
            {t("Si te sales del laboratorio perderás el progreso guardado hasta el momento")}
          </FormTypography>
        </Grid>
        <Grid item xs={6}>
          <FormButton minWidth={180} onClick={props.onClose}>
            {t("Cancelar")}
          </FormButton>
        </Grid>
        <Grid item container justifyContent="flex-end" xs={6}>
          <FormButton 
            color="white"
            minWidth={180}
            boxShadow="0px 4px 0px 0px #D80909"
            borderColor="#e02424"
            backgroundColor="#e02424" 
            onClick={props.openPageSelected}
          >
            {t("Salir")}
          </FormButton>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default DialogLeaveLaboratory;
