import AnonymousUser from "services/user-anonymous.services";
import { ITask } from "dto/user.dto";
import { IModule } from "dto/module.dto";
import { IChallenger } from "dto/challenger.dto";
import { IUserAnonymous, IUserAnonymousRequest } from "dto/user-anonymous.dto";
import { Action } from "./types";
import { history } from "configureStore";
import * as utils from "utils";
import * as types from "./types";
import * as actions from "store/actions";
import { ILaboratory } from "dto/laboratory.dto";

export const setAnonymousUser = (user: IUserAnonymous): Action => (dispatch) => {
  dispatch({ type: types.ANONYMOUS_USER, payload: user });
}

export const createAnonymousUser  = (
  request: IUserAnonymous,
  callback?: (tasks: ITask[]) => void,
): Action => async (dispatch) => {
  dispatch({ type: types.ANONYMOUS_USER_LOADING, payload: true });
  try {
    const data = {
      date: request.date,
      tasks: request.tasks,
      birthday: request.birthday,
      userType: request.userType,
      personalityTests: request.personalityTests,
    }
    const user = await AnonymousUser.post(data);
    localStorage.setItem("anonymousUser", JSON.stringify({ 
      uuid: user?.uuid,
      userType: user?.userType,
    }));
    dispatch(setAnonymousUser(user));
    !!callback && callback(user?.tasks ?? []);
    history.push("/home");
  } catch (e) { 
    console.log("Error Create Anonymous User"); 
  }
  dispatch({ type: types.ANONYMOUS_USER_LOADING, payload: false });
}

export const updateAnonymousUser  = (
  request: IUserAnonymousRequest,
  callback?: () => void,
): Action => async (dispatch, getState) => {
  dispatch({ type: types.ANONYMOUS_USER_LOADING, payload: true });
  try {
    const anonymousUser = getState().anonymousUser.anonymousUser;
    await AnonymousUser.update(anonymousUser.uuid, request);
    localStorage.setItem("anonymousUser", JSON.stringify({ 
      uuid: anonymousUser.uuid,
      userType: request?.userType ?? "",
    }));
    dispatch(setAnonymousUser({ ...anonymousUser, ...request }));
    !!callback && callback();
  } catch (e) { 
    console.log("Error Update Anonymous User"); 
  }
  dispatch({ type: types.ANONYMOUS_USER_LOADING, payload: false });
}

export const createUserAnonymousTask = ({
  task, 
  tasks, 
  module,
  challenger, 
  laboratory, 
  createTaskCallback, 
} : {
  task: ITask;
  tasks: ITask[];
  module: IModule;
  challenger: IChallenger;
  laboratory: ILaboratory;
  createTaskCallback?: (next?: IChallenger, type?: string, task?: ITask) => void;
}): Action => async (dispatch, getState) => {
  dispatch(actions.setTaskLoading(true));
  try {
    const userAnonymous = getState().anonymousUser.anonymousUser;
    const path = `users_anonymous/${userAnonymous?.uuid}/tasks`;
    const tasksNew = await utils.getCreateTask(path, task, tasks);
    const challengerBlocked = 
      !!laboratory?.challenges_blocked 
      && laboratory?.challenges_rate_min > task?.rate;
    await AnonymousUser.update(userAnonymous.uuid, { tasks: tasksNew });
    dispatch(setAnonymousUser({ ...userAnonymous, tasks: tasksNew }));
    const next = utils.getChallengerNextWithTasks(
      laboratory?.name,
      module,
      challenger,
      tasksNew,
      challengerBlocked,
    );
    !!createTaskCallback && createTaskCallback(next.challenger, next.type, task);
    dispatch(actions.analytics("perform_laboratory_challenger", { user_anonymous: "true" }));
    dispatch(actions.closeModalSuccess());
  } catch (error) { 
    console.log("Error Create User Anonymous Task: ", error);
  }
  dispatch(actions.setTaskLoading(false));
}
export const updateUserAnonymousTask  = (
  task: ITask, 
  tasks: ITask[],
  updateTaskCallback?: () => void,
): Action => async (dispatch, getState) => {
  dispatch(actions.setTaskLoading(true));
  try {
    const userAnonymous = getState().anonymousUser.anonymousUser;
    const path = `users_anonymous/${userAnonymous?.uuid}/tasks`;
    const tasksNew = await utils.getUpdateTask(path, task, tasks);
    await AnonymousUser.update(userAnonymous.uuid, { tasks: tasksNew });
    dispatch(setAnonymousUser({ ...userAnonymous, tasks: tasksNew }));
    !!updateTaskCallback && updateTaskCallback();
    dispatch(actions.analytics("perform_laboratory_challenger", { user_anonymous: "true" }));
    dispatch(actions.closeModalSuccess());
  } catch (error) {
    console.log("Error Update User Anonymous Task: ", error); 
  }
  dispatch(actions.setTaskLoading(false));
}
export const createUserAnonymousWithTasks = ({
  task,
  tasks,
  module,
  challenger, 
  laboratory, 
  createTaskCallback,
} : {
  task: ITask;
  tasks: ITask[];
  module: IModule;
  challenger: IChallenger;
  laboratory: ILaboratory;
  createTaskCallback?: (next?: IChallenger, type?: string, task?: ITask) => void;
}): Action => async (dispatch) => {
  dispatch(actions.setTaskLoading(true));
  try {
    const request: IUserAnonymousRequest = {
      date: new Date(),
      tasks: [],
      birthday: "0000-00-00",
      userType: "",
    };
    const userAnonymous = await AnonymousUser.post(request);
    localStorage.setItem("anonymousUser", JSON.stringify({ 
      uuid: userAnonymous?.uuid,
      userType: userAnonymous?.userType,
    }));
    const path = `users_anonymous/${userAnonymous?.uuid}/tasks`;
    const tasksNew = await utils.getCreateTask(path, task, tasks);
    const challengerBlocked = 
      !!laboratory?.challenges_blocked 
      && laboratory?.challenges_rate_min > task?.rate;
    await AnonymousUser.update(userAnonymous.uuid, { tasks: tasksNew });
    dispatch(setAnonymousUser({ ...userAnonymous, tasks: tasksNew }));
    const next = utils.getChallengerNextWithTasks(
      laboratory?.name,
      module,
      challenger,
      tasksNew,
      challengerBlocked,
    );
    !!createTaskCallback && createTaskCallback(next.challenger, next.type, task);
    dispatch(actions.analytics("perform_laboratory_challenger", { user_anonymous: "true" }));
    dispatch(actions.closeModalSuccess());
  } catch (e) { 
    console.log("Error Create Anonymous User", e);
  }
  dispatch(actions.setTaskLoading(false));
}

export const saveAnonymousUserTask  = (
  task: ITask, 
  callback?: (tasks: ITask[]) => void
): Action => async (dispatch, getState) => {
  dispatch(actions.setTaskLoading(true));
  try {
    const anonymousUser = getState().anonymousUser.anonymousUser;
    const tasks = [...anonymousUser?.tasks ?? [], task];
    await AnonymousUser.update(anonymousUser.uuid, { tasks });
    dispatch(setAnonymousUser({ ...anonymousUser, tasks }));
    !!callback && callback(tasks);
    dispatch(actions.closeModalSuccess());
  } catch (e) {
    console.log("Error Save User Anonymous Task: ", e);
  }
  dispatch(actions.setTaskLoading(false));
}


