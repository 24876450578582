import { IUser } from "dto/user.dto";

export const userInit: IUser = {
  nit: "",
  age: 0,
  rol: "",
  sex: "",
  name: "",
  admin: false,
  email: "",
  grade: "",
  phone: "",
  photo: "",
  tasks: [],
  sponsor: "",
  community: "",
  institute: "",
  photo_url: "",
  codeCourse: "",
  nationality: "",
  permissions: [],
  permissions_survey: [],
}

export const genders = [
  {
    id: "masculino",
    label: "Masculino",
    value: "masculino",
  },
  {
    id: "femenino",
    label: "Femenino",
    value: "femenino",
  },
  {
    id: "otro",
    label: "Otro",
    value: "otro",
  }
]
