export const genders = [
  { label: "Masculino", value: "Hombre" },
  { label: "Femenino", value: "Mujer" },
  { label: "Otro", value: "otro" },
  { label: "Prefiero no responder", value: "noresponde" },
];

export const alphabetSoupInit = [
  ["","","","","","","",""],
  ["","","","","","","",""],
  ["","","","","","","",""],
  ["","","","","","","",""],
  ["","","","","","","",""],
  ["","","","","","","",""],
];

export function getAge(dateString:string) {
  let today = new Date();
  let birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  let m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
	  age--;
  }
  return age;
}