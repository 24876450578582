import React, { FC } from "react";
import useImage from "use-image";
import { Image } from "react-konva";
import { ITemplateItem } from "dto/template.dto";
import { SvgShape01 } from "./Svg/SvgShape01";
import { SvgShape02 } from "./Svg/SvgShape02";
import { SvgShape03 } from "./Svg/SvgShape03";

interface IProps {
  templateItem: ITemplateItem;
}

const TemplateItemShapeVariousColor: FC<IProps> = (props) => {
  const colors = props.templateItem?.colors ?? [];

  const getImage = () => {
    switch (props.templateItem.type_shape) {
      case "shape_01":
        return SvgShape01(colors[0], colors[1], colors[2], colors[3]);
      case "shape_02":
        return SvgShape02(colors[0], colors[1], colors[2], colors[3]);
      case "shape_03":
        return SvgShape03(colors[0], colors[1], colors[2]);
      default:
        return "";
    }
  };
  const [image] = useImage(getImage(), "anonymous");

  return (
    <Image
      x={props.templateItem.x}
      y={props.templateItem.y}
      id={props.templateItem.id}
      image={image}
      width={props.templateItem.width}
      height={props.templateItem.height}
      scaleX={props.templateItem?.scaleX}
      scaleY={props.templateItem?.scaleY}
    />
  );
};

export default TemplateItemShapeVariousColor;
