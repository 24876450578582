import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Grid, Button } from '@material-ui/core';
import { FormControl, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { IAmount, ILicense, amountInit } from '../../../services/checkout.services';
import LicenseCard from './LicenseCard';

const useStyles = makeStyles((theme) => ({
  license: { 
    marginBottom: 15, 
    [theme.breakpoints.down("xs")]: { width: '100%' },
  },
  currency: { 
    margin: '7px 0',
    '& .MuiRadio-colorSecondary.Mui-checked': {
      color: '#ff6315',
    },
    '& .MuiTypography-root': { fontWeight: 'bold' },
  },
  btn: { 
    width: 350, 
    color: 'white',
    borderRadius: 25, 
    backgroundColor: '#ff6315',
    '&:hover': { backgroundColor: 'white', color: '#ff6315' },
  },
}));

interface IProps {
  license: ILicense
  currency: "usd"|"cop"
  setCurrency: (val: "usd"|"cop") => void
  selectLicense: (price: IAmount) => void
}

const List: FC<IProps> = ({license, currency, setCurrency, selectLicense}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [price, setPrice] = React.useState<IAmount>(amountInit);

  React.useEffect(() => {
    setPrice({
      uid:  license.amounts[0].uid,
      usd:  license.amounts[0].usd,
      cop:  license.amounts[0].cop,
      name: license.amounts[0].name, 
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [license]);

  // const saveLicense = () => {};
  const saveLicense = () => selectLicense(price);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "usd" || e.target.value === "cop") setCurrency(e.target.value);
  };

  return (
    <Grid className={classes.license} container justifyContent="center">
      {license.amounts.map((amount, i) => (
        <LicenseCard 
          key={i}
          price={amount}
          license={license}
          selected={price.uid === amount.uid}
          currency={currency}
          setPrice={setPrice}
        />
      ))}

      <Grid className={classes.currency} container justifyContent="center">
        <FormControl>
          <RadioGroup value={currency} onChange={onChange} row>
            <FormControlLabel value="usd" control={<Radio />} label="USD" />
            <FormControlLabel value="cop" control={<Radio />} label="COP" />
          </RadioGroup>
        </FormControl>
      </Grid>

      <Button className={classes.btn} variant="contained" onClick={saveLicense}> 
        {t("Aceptar")}
      </Button>
    </Grid>
  )
}

export default List;
