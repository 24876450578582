const svgImage = (c1: String) => `
  <svg width="68" height="54" viewBox="0 0 68 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M44.065 38.0883C49.5826 35.8083 52.2046 29.4927 49.9018 23.9751C47.6218 18.4574 41.3062 15.8355 35.7886 18.1155C30.271 20.3955 27.649 26.7339 29.9518 32.2287C32.2318 37.7463 38.5474 40.3683 44.065 38.0655"
      fill="${c1}"
    />
    <path
      d="M39.5506 44.1303C30.5446 44.1303 23.2486 36.8342 23.2486 27.8282C23.2486 18.8222 30.5446 11.5262 39.5506 11.5262C48.5566 11.5262 55.8526 18.8222 55.8526 27.8282C55.8526 36.8342 48.5566 44.1303 39.5506 44.1303ZM66.9334 25.571C66.9334 25.571 66.8422 25.3887 66.751 25.3887C61.3474 17.6366 40.0978 9.08663 37.111 7.92382C36.7462 7.83262 36.4726 7.55903 36.313 7.28543L36.2218 7.19421C36.0394 6.82941 36.0394 6.4646 36.313 6.1226C36.8602 5.393 37.2022 4.50382 37.0426 3.43222C36.8602 1.72221 35.515 0.285761 33.805 0.10336C31.5478 -0.17024 29.6554 1.63102 29.6554 3.79702V4.43542C29.6554 8.67623 26.2354 12.005 22.0858 12.005C18.301 12.005 15.2458 9.31462 14.6074 5.71222C13.7866 2.20101 10.5489 -0.329837 6.76414 0.0349631C3.16173 0.308563 0.19775 3.27257 0.0153493 6.87497C-0.258251 11.207 3.16173 14.9006 7.40254 14.9006H8.20055C11.8942 14.9006 14.8582 17.8646 14.8582 21.5582C14.8582 25.2518 12.0766 27.965 8.63376 28.1246C6.83256 28.3982 5.39616 29.9258 5.39616 31.8182C5.39616 33.893 7.01495 35.5119 9.08975 35.5119H9.45456C9.81936 35.5119 10.093 35.603 10.3438 35.8766C10.6174 36.2414 10.6173 36.6062 10.6173 36.6062C10.6173 36.6062 10.7085 37.8603 10.8909 39.1371C10.9821 40.2999 11.8714 47.7783 12.0538 49.3971C12.2362 51.5631 13.855 53.3643 15.9298 53.8203C19.6234 54.6411 25.3006 52.3839 25.3006 52.3839L41.6026 45.9998C57.7222 39.0686 64.5622 32.3199 66.4546 30.1539C66.637 29.9715 66.8194 29.6979 67.0018 29.5155C67.3666 28.9683 67.549 28.2615 67.549 27.5319C67.549 26.8023 67.2754 26.1866 66.9106 25.6394"
      fill="${c1}"
    />
  </svg>
`;

export const SvgOLab01 = (c1?: string) => {
  const a = !!c1 ? c1 : "#ff6300";
  return `data:image/svg+xml;charset=UTF-8;base64,${btoa(svgImage(a))}`;
};

export default SvgOLab01;
