import React, { FC, useState } from "react";
import * as init from "initials/user-anonymous.init";
import { history } from "configureStore";
import Formulario from "./Formulario";
import { IUserAnonymous } from "dto/user-anonymous.dto";

type TypeView = "main" | "secondary"| "freeLabs";

interface IProps {
  zoom: boolean;
  toogleZoom: () => void;
  updateBackground: (background: string) => void;
  createAnonymousUser: (user: IUserAnonymous) => void;
}

const Test: FC<IProps> = (props) => {
  const [view, setView] = useState<TypeView>("main");
  const [mainIndex, setMainIndex] = useState(0);
  const [userIndex, setUserIndex] = useState(0);
  const [mainAnswers, setMainAnswers] = useState(["", "", ""]);
  const [userAnswers, setUserAnswers] = useState([""]);
  const [userPreviews, setUserPreviews] = useState(init.initPreviews);
  const [userQuestions, setUserQuestions] = useState(init.initQuestions);
  const mainQuestionNumber = mainIndex + 1;
  const userQuestionNumber = userIndex + 1;
  const mainQuestionsTotal = mainAnswers?.length ?? 0;
  const userQuestionsTotal = userAnswers?.length ?? 0;

  const prevQuestionMain = () => {
    if (mainIndex > 0) setMainIndex(mainIndex - 1)
    else history.push("/anonymous-user");
  }
  const nextQuestionMain = () => {
    if (mainIndex < init.mainQuestions.length - 1) {
      setMainIndex(mainIndex + 1)
    } else {
      if (mainAnswers[mainIndex] === "Trabajo") {
        setUserAnswers(["", "", "", "", ""]);
        setUserPreviews(init.employeesPreviews);
        setUserQuestions(init.employeesQuestions);
      } else {
        setUserAnswers([""]);
        setUserPreviews(init.studentsPreviews);
        setUserQuestions(init.studentsQuestions);
      }
      setView("secondary");
    }
  }
  const selectAnswerMain = (answer: string) => {
    const array: string[] = [];
    for (let i = 0; i < mainAnswers.length; i++) {
      if (mainIndex !== i) array.push(mainAnswers[i]);
      else array.push(answer);
    } 
    setMainAnswers(array);
  }

  const prevQuestionUser = () => {
    if (userIndex > 0) setUserIndex(userIndex - 1);
    else setView("main");
    const anonymous_user = localStorage.getItem("anonymous_user");
    if (!!anonymous_user) localStorage.removeItem("anonymous_user");
  }
  const nextQuestionUser = () => {
    if (userIndex < userQuestions.length - 1) {
      setUserIndex(userIndex + 1)
    } else {
      const date = mainAnswers[0];
      const userType = mainAnswers[2] === "Estudio" ? "student" 
      : mainAnswers[2] === "Trabajo" ? "employee" : "none";
      props.createAnonymousUser({
        date: new Date(),
        uuid: "",
        tasks: [],
        userType,
        birthday: date,
        personalityTests: userAnswers,
      });
    }
  }
  const selectAnswerUser = (answer: string) => {
    const array: string[] = [];
    for (let i = 0; i < userAnswers.length; i++) {
      if (userIndex !== i) array.push(userAnswers[i]);
      else array.push(answer);
    } 
    setUserAnswers(array);
  }

  return (
    view === "main" ? (
      <Formulario
        zoom={props.zoom}
        total={init.mainQuestions.length}
        answer={mainAnswers[mainIndex]}
        preview={init.mainPreviews[mainIndex]}
        question={init.mainQuestions[mainIndex]}
        questionNumber={mainQuestionNumber}
        questionsTotal={mainQuestionsTotal}
        toogleZoom={props.toogleZoom}
        prevQuestion={prevQuestionMain}
        nextQuestion={nextQuestionMain}
        selectAnswer={selectAnswerMain}
        updateBackground={props.updateBackground}
      />
    ) : view === "secondary" ? (
      <Formulario
        zoom={props.zoom}
        total={userQuestions.length}
        answer={userAnswers[userIndex]}
        preview={userPreviews[userIndex]}
        question={userQuestions[userIndex]}
        questionNumber={userQuestionNumber}
        questionsTotal={userQuestionsTotal}
        toogleZoom={props.toogleZoom}
        prevQuestion={prevQuestionUser}
        nextQuestion={nextQuestionUser}
        selectAnswer={selectAnswerUser}
        updateBackground={props.updateBackground}
      />
    ) : (
     <></>
    )
  )
}

export default Test;
