import React, { FC, useState } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { State } from "store/types";
import { IModule } from "dto/module.dto";
import { ILaboratory } from "dto/laboratory.dto";
import { IChallenger } from "dto/challenger.dto";
import { ITask, IUser } from "dto/user.dto";
import * as utils from "utils/laboratory.utils";
import Loading from "components/Common/Loading";
import ModuleButton from "components/Laboratory/ModuleButton";
import LaboratoryInfo from "./LaboratoryInfo";
import FormButtonIcon from "components/Form/FormButtonIcon";
import DialogChallenges from "components/Laboratory/DialogChallenges";
import DialogLaboratoryCompleted from "components/Laboratory/DialogLaboratoryCompleted";
import DialogLaboratoryCertificate from "components/Laboratory/DialogLaboratoryCertificate";

const useStyles = makeStyles((theme) => ({
  modules: { 
    height: "100%", 
    padding: "32px 128px 16px",
    position: "relative",
    [theme.breakpoints.down("md")]: { padding: "32px 64px 16px" },
    [theme.breakpoints.down("sm")]: { padding: "32px 32px 16px" },
    [theme.breakpoints.down("xs")]: { padding: "32px 16px 16px" },
  },
  header: {  position: "relative", justifyContent: "center" },
  content: { 
    height: "calc(100% - 124px)",
    padding: 16,
    overflow: "auto",
    alignItems: "flex-start",
    "&::-webkit-scrollbar": { width: 0 },
  },
  back: {
    top: 0,
    left: 0,
    zIndex: 2,
    position: "absolute",
  },
}));

interface IProps {
  tasks: ITask[];
  userCurrent: IUser;
  laboratory: ILaboratory;
  moduleCurrent: IModule;
  onClickBack?: () => void;
  selectModule: (module: IModule) => void;
  deselectModule: () => void;
  selectChallenger: (challenger: IChallenger) => void;
  generateLaboratoryCertificate: (laboratory: ILaboratory) => void;
  toogleBackgroundVideoAutoplay?: () => void;
}

const ModulesList: FC<IProps> = (props) => {
  const classes = useStyles();
  const user = useSelector((s: State) => s.user.user);
  const entity = useSelector((s: State) => s.entity.entity);
  const certificateLoading = useSelector((s: State) => s.laboratory.certificateLoading);
  const [dialogLaboratoryCompleted, setDialogLaboratoryCompleted] = useState(false);
  const [dialogLaboratoryCertificate, setDialogLaboratoryCertificate] = useState(false);

  const openDialogLaboratoryCompleted = () => setDialogLaboratoryCompleted(true);
  const closeDialogLaboratoryCompleted = () => setDialogLaboratoryCompleted(false);

  const openDialogLaboratoryCertificate = () => setDialogLaboratoryCertificate(true);
  const closeDialogLaboratoryCertificate = () => setDialogLaboratoryCertificate(false);

  const selectModule = (module: IModule) => {
    const max = !!props.laboratory?.modules?.length ? props.laboratory.modules.length : 100;
    const progress = utils.getLaboratoryProgress(props.laboratory, props.tasks).progress;
    const percentage = Math.round(((progress / max) * 100) ?? 0);
    if (!!props.laboratory?.not_repeat && percentage >= 100) {
      openDialogLaboratoryCompleted();
    } else props.selectModule(module);
  }

  const generateLaboratoryCertificate = () => {
    if (!!props.laboratory?.certificate_uuid) {
      openDialogLaboratoryCertificate();
    } else props.generateLaboratoryCertificate(props.laboratory);
  }

  return (
    <Grid className={classes.modules} container>
      <Grid className={classes.header} item container>
        {!!props.onClickBack && ( 
          <Grid className={classes.back} item>
            <FormButtonIcon 
              image="/img/home/back.svg" 
              onClick={props.onClickBack}
            />
          </Grid>
        )}
        <LaboratoryInfo
          tasks={props.tasks}
          laboratory={props.laboratory}
          toogleBackgroundVideoAutoplay={props.toogleBackgroundVideoAutoplay}
          generateLaboratoryCertificate={generateLaboratoryCertificate}
        />
      </Grid>
      <Grid className={classes.content} item container>
        <Grid container spacing={3}>
          {props.laboratory?.modules?.map((module, i) => (
            <Grid key={i} item container justifyContent="center">
              <ModuleButton 
                tasks={props.tasks}
                module={module}
                laboratory={props.laboratory}
                selectModule={selectModule}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>

      <DialogChallenges 
        open={!!props.moduleCurrent?.name}
        tasks={props.tasks}
        laboratory={props.laboratory}
        moduleCurrent={props.moduleCurrent}
        onClose={props.deselectModule} 
        selectChallenger={props.selectChallenger}
      />
      <DialogLaboratoryCompleted 
        open={dialogLaboratoryCompleted}
        loading={certificateLoading}
        onClose={closeDialogLaboratoryCompleted} 
        generateLaboratoryCertificate={generateLaboratoryCertificate}
      />
      <DialogLaboratoryCertificate 
        open={dialogLaboratoryCertificate}
        userName={user?.name}
        entityName={entity?.name}
        laboratoryName={props.laboratory?.displayName}
        certificateUuid={props.laboratory?.certificate_uuid ?? ""}
        onClose={closeDialogLaboratoryCertificate}
      />

      <Loading loading={certificateLoading} white />
    </Grid>
  )
}

export default ModulesList;
