import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "store/types";
import { history } from "configureStore";
import * as actions from "store/actions";
import SignIn from "components/Login/SignIn/SignIn";
import PageLoginCarrusel from "components/Pages/PageLoginCarrusel";

const SignInPage = () => {
  const dispatch = useDispatch();
  const user = useSelector((s: State) => s.user.user);
  const confirm = useSelector((s: State) => s.auth.confirm);
  const loading = useSelector((s: State) => s.auth.loading);
  const confirmCode = useSelector((s: State) => s.auth.confirmCode);

  useEffect(() => { if (!!user?.nit) history.push("/home") }, [user]);

  const passwordReset = (email: string, callback: () => void) => {
    dispatch(actions.passwordReset(email, callback));
  }

  const signInWithEmail = (email: string, password: string) => {
    dispatch(actions.signInWithEmail(email, password));
  }

  const sendPhoneNumber = (phoneNumber: string, reCAPTCHA: any) => {
    dispatch(actions.fbPhoneNumber(phoneNumber, reCAPTCHA))
  }
  const changePhoneNumber = () => dispatch(actions.changePhoneNumber());
  const sendCodeVerification = (code: string) => {
    dispatch(actions.sendCodeVerification(code));
  }

  return (
    <PageLoginCarrusel>
      <SignIn
        confirm={confirm}  
        loading={loading}
        confirmCode={confirmCode}
        passwordReset={passwordReset}
        signInWithEmail={signInWithEmail} 
        sendPhoneNumber={sendPhoneNumber}
        changePhoneNumber={changePhoneNumber}
        sendCodeVerification={sendCodeVerification}
      />
    </PageLoginCarrusel>
  )
}

export default SignInPage;
