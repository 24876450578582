import React, { FC } from "react";
import { Text } from "react-konva";
import { ITemplateItem } from "dto/template.dto";

interface IProps {
  userName?: string;
  entityName?: string;
  templateItem: ITemplateItem;
  laboratoryName?: string;
}

const TemplateItemTextReadOnly: FC<IProps> = (props) => {
  const text = props.templateItem?.type_shape === "user" && !!props.userName 
    ? props.userName 
    : props.templateItem?.type_shape === "entity" && !!props.entityName 
    ? props.entityName 
    : props.templateItem?.type_shape === "laboratory" && !!props.laboratoryName 
    ? props.laboratoryName 
    : props.templateItem?.text;

  return (
    <Text
      x={props.templateItem.x}
      y={props.templateItem.y}
      id={props.templateItem.id}
      fill={props.templateItem?.fill}
      text={text}
      align={props.templateItem.align}
      width={props.templateItem?.width}
      fontSize={props.templateItem?.font_size}
      fontStyle={props.templateItem?.font_style}
      fontFamily={props.templateItem?.font_family}
      textDecoration={props.templateItem?.text_decoration}
    />
  );
};

export default TemplateItemTextReadOnly;
