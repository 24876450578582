import React, { FC, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, IconButton, Avatar } from "@material-ui/core";
import { IUser } from "dto/user.dto";
import { ICountry } from "dto/country.dto";
import { IEntityCode } from "dto/entity-code.dto";
import { history } from "configureStore";
import Loading from "components/Common/Loading";
import CreateAccountMenu from "./CreateAccountMenu";
import RegisterWithEmail from "./RegisterWithEmail";
import RegisterWithPhone from "./RegisterWithPhone";

const useStyles = makeStyles((theme) => ({
  account: { 
    height: "100%",
    position: "relative",
    backgroundColor: "white", 
  },
  header: {
    padding: "60px 76px 0",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: { padding: "60px 52px 0" },
    [theme.breakpoints.down("sm")]: { padding: "60px 32px 0" },
    [theme.breakpoints.down("xs")]: { padding: "20px 12px 0" },
  },
  back: {
    left: 76,
    padding: 0,
    outline: "0 !important",
    position: "absolute",
    borderRadius: 12,
    "& .MuiAvatar-root": { width: "100%", height: 40, borderRadius: 4 },
    [theme.breakpoints.down("md")]: {  left: 52, },
    [theme.breakpoints.down("sm")]: {  left: 32, },
    [theme.breakpoints.down("xs")]: {  left: 12, },
  },
  content: { 
    height: "calc(100% - 127px)", 
    padding: "32px 80px",
    alignItems: "center",
    [theme.breakpoints.down("md")]: { padding: "32px 64px" },
    [theme.breakpoints.down("sm")]: { padding: "32px 32px" },
    [theme.breakpoints.down("xs")]: { padding: "32px 16px" },
  },
}));

interface IProps {
  confirm: any;
  loading: boolean;
  countries: ICountry[];
  isNewUser: boolean;
  codeVerification: string;
  verifiedEmailError: boolean;
  verifiedEmail: (email: string) => void;
  verifiedPhone: (phoneNumber: string, reCAPTCHA: any) => void;
  closeEmailForm: () => void;
  changePhoneNumber: () => void;
  registerWithEmail: (email: string, password: string, u: IUser, c?: IEntityCode) => void;
  registerWithPhone: (code: string, user: IUser) => void;
  changeCodeVerification: (code: string) => void;
  updateVerifiedEmailError: (value: boolean) => void;
}

// type Type = MENU: 0 | EMAIL: 1 | PHONE: 2;
const CreateAccount: FC<IProps> = (props) => {
  const classes = useStyles();
  const [view, setView] = useState(0);
  const [loadingLocal, setLoadingLocal] = useState(false);
  const [entityCodeView, setEntityCodeView] = useState(0);

  const closeRegister = () => {
    if (view === 1 && !!props.isNewUser && entityCodeView === 2) {
      setEntityCodeView(1);
    } else if (view === 1 && !!props.isNewUser && entityCodeView === 1) {
      setEntityCodeView(0);
    } else if (view === 1 && !!props.isNewUser) {
      props.closeEmailForm();
    } else if (view === 1) {
      setView(0);
    } else history.push("/");
  }

  return (
    <Grid className={classes.account} container>
      <Grid className={classes.header} container>
        {entityCodeView !== 2 && (
          <IconButton className={classes.back} onClick={closeRegister}>
            <Avatar src="/img/login/back.png" alt="" />
          </IconButton>
        )}
        <img src="/img/logos/olab.svg" alt="" width={150} />
      </Grid>
      <Grid className={classes.content} container>
        {view === 1 ? (
          <RegisterWithEmail
            countries={props.countries}
            isNewUser={props.isNewUser}  
            entityCodeView={entityCodeView}
            verifiedEmailError={props.verifiedEmailError}
            verifiedEmail={props.verifiedEmail}
            registerWithEmail={props.registerWithEmail}
            updateLoadingLocal={setLoadingLocal}
            updateEntityCodeView={setEntityCodeView}
            updateVerifiedEmailError={props.updateVerifiedEmailError}
          />
        ) : view === 2 ? (
          <RegisterWithPhone
            code={props.codeVerification} 
            confirm={props.confirm}
            loading={props.loading}
            countries={props.countries}
            repeatLoading={loadingLocal}
            changeCode={props.changeCodeVerification}
            verifiedPhone={props.verifiedPhone}
            registerWithPhone={props.registerWithPhone}
            updateRepeatLoading={setLoadingLocal}
          />
        ) : (
          <CreateAccountMenu selectView={setView} />
        )}
      </Grid>

      <Loading loading={props.loading || loadingLocal} white />
    </Grid>
  )
}

export default CreateAccount;
