import { AppThunkAction } from "store/types";
import { HomeActionType } from "./actions";

export * from "./constants";
export * from "./actions";
export interface HomeState {
  zoom: boolean;
  background: string;
  modalSuccess: boolean;
  dialogTutorial: boolean;
}
  
export type Action = AppThunkAction<HomeActionType>;
  
