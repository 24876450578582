import React, { FC, CSSProperties } from "react";
import BounceLoader from "react-spinners/BounceLoader";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, IconButton, Tooltip } from "@material-ui/core";
import { Cancel, Lens, Save, Stop } from "@material-ui/icons";
import useRecorder from "./useRecorder";
import AudioPlayer from "./AudioPlayer";

const useStyles = makeStyles(() => ({
  record: { padding: 8 },
  button: { borderRadius: 25 },
}));

interface IProps {
  uploadFile: (file: File) => void;
  closeAudioRecord: () => void;
}

const AudioRecord: FC<IProps> = ({ uploadFile, closeAudioRecord }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const exit = t("Salir de la grabación");
  const save = t("Guardar grabación");
  const stop = t("Detener grabación");
  const start = t("Iniciar grabación");

  let [
    audioURL,
    // recorder,
    isRecording,
    recordedChunks,
    setAudioURL,
    // setRecordedChunks,
    stopRecording,
    startRecording,
  ] = useRecorder();

  const override: CSSProperties = {
    margin: "0 auto",
    display: "block",
    borderColor: "red",
  };

  const handleUploadFile = () => {
    // @ts-ignore
    const blob = new Blob(recordedChunks, { type: "audio/webm" });
    const name = new Date().getTime().toString();

    // @ts-ignore
    if (!!recordedChunks && recordedChunks.length > 0) {
      const audio = new File([blob], `${name}audio.wav`, {
        type: "audio/webm",
      });
      // @ts-ignore
      setAudioURL("");
      uploadFile(audio);
    }
  };

  return (
    <Grid className={classes.record} container>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item container justifyContent="center" xs={2}>
          {!isRecording ? (
            <Tooltip title={start}>
              {/* @ts-ignore */}
              <IconButton size="small" onClick={startRecording}>
                <Lens fontSize="large" />
              </IconButton>
            </Tooltip>
          ) : (
            <BounceLoader 
              size={40} 
              color="#ff6315"
              loading={!!isRecording} 
              cssOverride={override} 
            />
          )}
        </Grid>

        {!isRecording && !audioURL && (
          <Grid item container justifyContent="center" xs={2}>
            <Tooltip title={exit}>
              <IconButton size="small" onClick={closeAudioRecord}>
                <Cancel fontSize="large" />
              </IconButton>
            </Tooltip>
          </Grid>
        )}

        {!!isRecording && (
          <Grid item container justifyContent="center" xs={2}>
            <Tooltip title={stop}>
              {/* @ts-ignore */}
              <IconButton size="small" onClick={stopRecording}>
                <Stop fontSize="large" />
              </IconButton>
            </Tooltip>
          </Grid>
        )}

        {!isRecording && !!audioURL && (
          <Grid item container justifyContent="center" xs={2}>
            <Tooltip title={save}>
              <IconButton size="small" onClick={handleUploadFile}>
                <Save fontSize="large" />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
        
        <Grid item xs={8}>
          {/* @ts-ignore */}
          <AudioPlayer src={audioURL} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AudioRecord;
