import React, { FC, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { TypeLanguageCode } from "dto/layout.dto";
import { State } from "store/types";
import * as actions from "store/actions";
import ButtonLanguageMenu from "./ButtonLanguageMenu";

const useStyles = makeStyles((theme) => ({
  button: {
    color: "#a9a9a9",
    padding: 4,
    minWidth: 0,
    fontSize: 18,
    textTransform: "lowercase",
    "& img": { fontSize: 28 },
    "&:hover": { backgroundColor: "transparent" },
    "&:active": { backgroundColor: "transparent" },
  },
}));

interface IProps {
  primary?: boolean;
}

const ButtonLanguage: FC<IProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const language = useSelector((state: State) => state.layout.language);
  const [languageMenu, setLanguageMenu] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const updateLanguage = (code: TypeLanguageCode) => {
    dispatch(actions.updateLanguage(code));
  }
  
  const toggleLanguageMenu = () => setLanguageMenu((v) => !v);
  const closeLanguageMenu = (e: React.MouseEvent<EventTarget>) => {
		if (anchorRef.current && anchorRef.current.contains(e.target as HTMLElement)) return;
		setLanguageMenu(false);
  };

  return (
    <>
      <Button 
        className={classes.button} 
        ref={anchorRef}
        onClick={toggleLanguageMenu}
        aria-label={t("Seleccionar idioma")}
      >
        <Grid container  alignItems="center">
          <ReactCountryFlag countryCode={language.flag} svg />
        </Grid>
      </Button>
      <ButtonLanguageMenu 
        open={languageMenu} 
        anchorRef={anchorRef} 
        updateLanguage={updateLanguage}
        closeLanguageMenu={closeLanguageMenu} 
        toggleLanguageMenu={toggleLanguageMenu}
      />
    </>
  )
}

export default ButtonLanguage;
