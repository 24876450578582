import axios from "axios";
import { ILaboratory } from "dto/laboratory.dto";

const url = process.env.REACT_APP_BACKEND_URL;
const route = `${url}/api`;

class Laboratory {
  async getLaboratoryByKey (key: string): Promise<ILaboratory | undefined> {
    try {
      const { data } = await axios.get(`${route}/laboratory/${key}`);
      return data[0];
    } catch (error) {
      console.log("Services Get Laboratory By Key: ", error);
      return undefined;
    }
  };

  async getLaboratoryByName (name: string): Promise<ILaboratory | undefined> {
    try {
      const { data } = await axios.get(`${route}/laboratory/name/${name}`);
      return data[0];
    } catch (error) {
      return undefined;
    }
  }

  async generateLaboratoryCertificate(userName: string, entityName: string, laboratoryName: string) {
    const response = await axios.post(
      `${route}/reports/organization/laboratory/jsreport/certificate`,
      {
        user_name: userName,
        entity_name: entityName,
        laboratory_name: laboratoryName,
      },
      {
        responseType: "blob",
      }
    );
    if (response.status === 200) {
      const pdfUrl = URL.createObjectURL(response.data);
      window.open(pdfUrl, "_blank");
    }
  }
}

const laboratory = new Laboratory();
const LaboratoryClass = Object.freeze(laboratory);
export default LaboratoryClass;
