import firebase from "firebase/app";
import "firebase/database";
import { IEmail, IEmailRequest } from "dto/email.dto";

class Email {
  async post (email: IEmail) {
    await firebase.database().ref(`emails/${email.uid}/`).set(email);
    return true;
  };

  async update (email: IEmail, request: IEmailRequest) {
    await firebase.database().ref(`emails/${email.uid}/`).update(request);
    return true;
  };
}

const email = new Email();
const EmailClass = Object.freeze(email);
export default EmailClass;

