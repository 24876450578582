import React from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import { questions } from './Constants';

const useStyles = makeStyles(() => ({
  questions: { 
    padding: '50px 100px', 
    backgroundColor: '#ff6315',
  },
  accordion: { marginBottom: 10 },
  icon: { color: '#ff6315' }
}));


const Questions = () => {
  const classes  = useStyles();

  return (
    <Grid className={classes.questions} container justifyContent="center">
      <Grid item xs={12}>
        <Typography align="center" variant="h3" style={{color: 'white', marginBottom: 30}}>
          <b>Respondemos tus dudas</b>
        </Typography>
      </Grid>
     
      <Grid item xs={12}>
        {questions.map((question) => (
          <Accordion className={classes.accordion} key={question.id}>
            <AccordionSummary expandIcon={<AddCircle className={classes.icon} />}>
              <Typography>{question.name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                malesuada lacus ex, sit amet blandit leo lobortis eget.
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
    </Grid>
  )
}

export default Questions;
