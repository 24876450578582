import { Action } from "./types";
import * as types from "./types";
import { IChallenger } from "dto/challenger.dto";
import { challengerInit } from "initials";

export const selectChallenger = (value: IChallenger): Action => (dispatch) => {
  dispatch({ type: types.CHALLENGER, payload: value });
}
export const deselectChallenger = (): Action => (dispatch) => {
  dispatch({ type: types.CHALLENGER, payload: challengerInit });
}

export const selectChallengerFree = (value: IChallenger): Action => (dispatch) => {
  dispatch({ type: types.CHALLENGER_FREE, payload: value });
}
export const deselectChallengerFree = (): Action => (dispatch) => {
  dispatch(selectChallengerFree(challengerInit));
}
