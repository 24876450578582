import { IChallenger } from "dto/challenger.dto";
import { ILaboratoryCongratulations } from "dto/laboratory.dto";

export const challengerCongratulationsInit: ILaboratoryCongratulations = {
  complete_image: "/gif/default/challenger/congratulations_complete_image_default.gif",
  complete_audio_fl: "/audio/default/challenger/congratulations_complete_audio_default.mp3",
  complete_audio_sl: "/audio/default/challenger/congratulations_complete_audio_default.mp3",
  complete_text_one: "¡Unidad terminada!",
  complete_text_two: "Avanza a la siguiente unidad",
  incomplete_image: "/gif/default/challenger/congratulations_incomplete_image_default.gif",
  incomplete_audio_fl: "/audio/default/challenger/congratulations_incomplete_audio_default.mp3",
  incomplete_audio_sl: "/audio/default/challenger/congratulations_incomplete_audio_default.mp3",
  incomplete_text_one: "¡Oops!",
  incomplete_text_two: "Vuelve a intentarlo",
}

export const challengerInit: IChallenger = {
  name: "",
  audio: "",
  image: "",
  steps: [],
  audio_wa:"",
  concepts: "",
  objective: "",
  questions: [],
  description: "",
  displayName: "",
  hide_ratings: false,
  congratulations: challengerCongratulationsInit,
  progress_bar_color: "#ff6315",
}
