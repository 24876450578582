import { ISurvey } from "dto/survey.dto";
import { AppThunkAction } from "store/types";
import { ExploreActionType } from "./actions";

export * from "./actions";
export * from "./constants";

export interface ExploreState {
  surveyFree: ISurvey;
}

export type Action = AppThunkAction<ExploreActionType>;
