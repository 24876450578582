const svgImage = (a: string) => `
<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_831_1628)">
    <path
      d="M11.9319 23.8298L16.1671 28.065L8.96312 35.269C8.49678 35.7353 7.86427 35.9973 7.20476 35.9973C6.54524 35.9973 5.91274 35.7353 5.44639 35.269L4.73038 34.553C4.26404 34.0866 4.00204 33.4541 4.00204 32.7946C4.00204 32.1351 4.26404 31.5026 4.73038 31.0362L11.9343 23.8323L11.9319 23.8298Z"
      fill="#CDCDCD"
    />
    <path
      d="M24.0073 27.9785C30.6287 27.9785 35.9965 22.6107 35.9965 15.9892C35.9965 9.36776 30.6287 4 24.0073 4C17.3858 4 12.0181 9.36776 12.0181 15.9892C12.0181 22.6107 17.3858 27.9785 24.0073 27.9785Z"
      fill="#CDCDCD"
    />
    <path
      d="M24.0073 25.9602C29.4989 25.9602 33.9507 21.5084 33.9507 16.0168C33.9507 10.5252 29.4989 6.07343 24.0073 6.07343C18.5158 6.07343 14.064 10.5252 14.064 16.0168C14.064 21.5084 18.5158 25.9602 24.0073 25.9602Z"
      fill="white"
    />
    <path
      d="M24.0073 23.9625C28.3975 23.9625 31.9565 20.4036 31.9565 16.0133C31.9565 11.6231 28.3975 8.06415 24.0073 8.06415C19.6171 8.06415 16.0581 11.6231 16.0581 16.0133C16.0581 20.4036 19.6171 23.9625 24.0073 23.9625Z"
      fill="${a}"
    />
    <path
      d="M20.0843 15.1867C20.2453 14.422 20.6265 13.721 21.181 13.1702C21.7354 12.6194 22.4389 12.2429 23.2047 12.087C23.43 12.0425 23.6329 11.9215 23.7792 11.7444C23.9254 11.5674 24.006 11.3453 24.0072 11.1157V11.0675C24.0073 10.9219 23.9752 10.778 23.9132 10.6463C23.8512 10.5145 23.7609 10.3981 23.6487 10.3053C23.5365 10.2125 23.4051 10.1456 23.2641 10.1095C23.123 10.0733 22.9757 10.0688 22.8327 10.0962C21.6664 10.3293 20.5949 10.9018 19.7529 11.742C18.9109 12.5821 18.336 13.6523 18.1004 14.8182C18.0731 14.9609 18.0775 15.1079 18.1135 15.2487C18.1494 15.3895 18.216 15.5207 18.3084 15.6328C18.4008 15.745 18.5168 15.8353 18.6482 15.8975C18.7795 15.9597 18.9229 15.9922 19.0682 15.9927H19.113C19.3433 15.992 19.5661 15.9111 19.7434 15.7641C19.9206 15.617 20.0411 15.4129 20.0843 15.1867Z"
      fill="#EBFAFF"
    />
    <path
      d="M19.0063 19.9604C19.5542 19.9604 19.9983 19.5163 19.9983 18.9685C19.9983 18.4206 19.5542 17.9765 19.0063 17.9765C18.4585 17.9765 18.0144 18.4206 18.0144 18.9685C18.0144 19.5163 18.4585 19.9604 19.0063 19.9604Z"
      fill="#EBFAFF"
    />
  </g>
  <defs>
    <clipPath id="clip0_831_1628">
      <rect width="31.9965" height="32" fill="white" transform="translate(4 4)"/>
    </clipPath> 
  </defs>
</svg>
`;

export const IconExploreSvg = (color?: string) => {
  const a = !!color ? color : "#97d9f0";
  return `data:image/svg+xml;charset=UTF-8;base64,${btoa(svgImage(a))}`;
};

export default IconExploreSvg;
