import firebase from "firebase";
import "firebase/auth";
import User from "services/user.services";
import fbAdmin from "services/fb-admin.services";
import FbAdmin from "services/fb-admin.services";
// import UserAnonymous from "services/user-anonymous.services";
import { IUser } from "dto/user.dto";
import { Action } from "./types";
import { history } from "configureStore";
import * as types from "./types";
import * as utils from "utils";
import * as actions from "store/actions";
import { authInit } from "initials/auth.init";
import { IEntityCode } from "dto/entity-code.dto";
import { messageError } from "components/Common/Toast";

export const getAuthentication = (): Action => async (dispatch, getState) => {
  const uuiddb = localStorage.getItem("uiddb") ?? "";
  const fbAuth = getState().firebase.auth;
  const isEmpty = getState()?.firebase?.auth?.isEmpty;
  const isLoaded = getState()?.firebase?.auth?.isLoaded;
  const anonymousUser = localStorage.getItem("anonymousUser");

  dispatch({ type: types.AUTHENTICATION_LOADING, payload: true });
  try {
    if (!isEmpty && !!isLoaded) {
      const userStorage = await utils.getUserByStorage(fbAuth, uuiddb);
      if (userStorage?.user_status === 1) {
        messageError(("Usuario no habilitado"));
        dispatch(handleLogout()); 
        return dispatch({ type: types.LOADING, payload: false });
      }
      if (!!userStorage?.nit) {
        dispatch(actions.setUserCurrent(userStorage));
        dispatch(actions.getEntityByName(userStorage?.sponsor));
      } else {
        const user = await utils.getUserByProvider(fbAuth);
        if (!user?.nit) {
          messageError(("Usuario no encontrado"));
          dispatch(handleLogout()); 
          return dispatch({ type: types.LOADING, payload: false });
        }
        if (user?.user_status === 1) {
          messageError(("Usuario no habilitado"));
          dispatch(handleLogout()); 
          return dispatch({ type: types.LOADING, payload: false });
        }
        dispatch(actions.saveUserCurrent(user));
        dispatch(actions.getEntityByName(user?.sponsor));
      }
    } else if (!!isEmpty && !!isLoaded && !!anonymousUser) {
      // const anonymous = JSON.parse(anonymousUser);
      // const data = await UserAnonymous.get();
      // const data = await UserAnonymous.getAnonymousUserByUuid(anonymous?.uuid);
      // if (!!data) dispatch(actions.setAnonymousUser(data));
      // else {
      //   history.push("/");
      //   localStorage.removeItem("anonymousUser");
      // }
    } 
  } catch (error) {
    console.log("Error Get Authentication: ", error)
  }
  dispatch({ type: types.AUTHENTICATION_LOADING, payload: false });
}

export const generateNewPassword = (): Action => async () => {
  // const email = "";
  // const password = "";
  // await firebase.auth().createUserWithEmailAndPassword(email, password).then(() => {
  //   console.log("user created successfully");
  // }).catch(() => {
  //   console.log("error creating user");
  // });
}

export const getAuth = (): Action => async (dispatch, getState) => {
  try {
    const fbAuth = getState().firebase.auth;
    const auth = {
      isEmpty: fbAuth.isEmpty,
      isLoaded: fbAuth.isLoaded,
      uid: fbAuth.uid,
      data: { 
        providerId: fbAuth.providerData[0].providerId, 
        uid: fbAuth.providerData[0].uid,
      }
    }
    dispatch({type: types.AUTH, payload: auth})
  } 
  catch (e) { 
    console.log('ERROR Firebase Phone Number'); 
    dispatch({type: types.LOADING, payload: false})
  }
}

export function getCurrentDate(): number {
  const currentTimeStamp: number = Date.now();
  return currentTimeStamp;
}

export const verifiedEmail = (email: string): Action => async (dispatch) => {
  dispatch({ type: types.LOADING, payload: true });
  try {
    const emailTrim = email.trim();
    const resp = await FbAdmin.verifiedEmail(emailTrim);
    if (!!resp?.verified) await dispatch(setIsNewUser(true));
    else {
      dispatch(setVerifiedEmailError(true));
      messageError(("El usuario ya está registrado"));
    }
  } 
  catch (e) { }
  dispatch({ type: types.LOADING, payload: false });
}
export const registerWithEmail = (
  email: string, 
  password: string, 
  data: IUser,
  entityCode?: IEntityCode,
): Action => async (dispatch) => {
  dispatch({ type: types.LOADING, payload: true });
  const emailTrim = email.trim();
  await firebase.auth()
  .createUserWithEmailAndPassword(emailTrim, password)
  .then(async ({ user }) => {
    if (!!user?.uid) {
      const userNew: IUser = { ...data, nit: user.uid }
      await dispatch(actions.registerUser(userNew));
      if (!!entityCode?.uuid) {
        await dispatch(actions.addUserToUsersGroup(entityCode, userNew));
      }
      dispatch(setIsNewUser(false));
      localStorage.removeItem("anonymousUser");
    }
  }).catch(async (e) => {
    if (e.code === "auth/email-already-in-use") {
      messageError(("Correo electrónico ya está siendo utilizada por otra cuenta"))
    }
  });

  dispatch({type: types.LOADING, payload: false});
}
export const signInWithEmail = (email: string, password: string): Action => async (dispatch) => {
  dispatch({type: types.LOADING, payload: true});
  try {
    const user = await User.getUserByEmail(email);
    if (!user?.nit) {
      messageError(("Usuario no encontrado"));
      return dispatch({ type: types.LOADING, payload: false });
    }
    if (user?.user_status === 1) {
      messageError(("Usuario no habilitado"));
      return dispatch({ type: types.LOADING, payload: false });
    }
    const emailAux = email.trim();
    const emailVerified = await fbAdmin.verifiedEmail(emailAux);
    if (!!emailVerified?.verified) {
      messageError(("Usuario no registrado"));
      return dispatch({ type: types.LOADING, payload: false });
    }
    await firebase.auth().signInWithEmailAndPassword(emailAux, password).then(async () => {
      const last_active_date: number = getCurrentDate();
      await User.patchUserByNit(user?.nit, { last_active_date });
      localStorage.removeItem("anonymousUser");
      dispatch({ type: types.LOADING, payload: false });
      dispatch(actions.analytics("login"));
    }).catch(async (e) => {
      if (e.code === "auth/wrong-password") {
        messageError(("Contraseña invalida"));
      } else if (e.code === "auth/too-many-requests") {
        messageError(("Acceso desactivado temporalmente debido a muchos intentos fallidos"))
      }
    });
  } catch (e) { }
  dispatch({ type: types.LOADING, payload: false }); 
}
export const passwordReset = (email: string, callback: () => void): Action => async (dispatch) => {
  dispatch({ type: types.LOADING, payload: true });
  firebase.auth().languageCode = 'en';
  const emailTrim = email.trim();
  await firebase.auth().sendPasswordResetEmail(emailTrim).then(() => {
    callback();
    dispatch({type: types.LOADING, payload: false});
  }).catch((_error) => {
    dispatch({type: types.LOADING, payload: false});
  });
}

export const verifiedPhone = (
  phone: string, 
  reCAPTCHA: firebase.auth.ApplicationVerifier
): Action => async (dispatch) => {
  dispatch({type: types.LOADING, payload: true});
  try {
    const resp = await FbAdmin.verifiedPhone(phone);
    if (!!resp && !!resp.verified) {
      firebase.auth().languageCode = 'en';
      await firebase.auth().signInWithPhoneNumber(phone, reCAPTCHA).then((confirm) => {
        dispatch(setIsNewUser(true));
        dispatch({type: types.CONFIRM, payload: confirm});
      }).catch((_e) => { dispatch({type: types.LOADING, payload: false}); });
    }
    else messageError(("El usuario ya está registrado"));
    dispatch({type: types.LOADING, payload: false});
  } catch (e) { 
    dispatch({type: types.LOADING, payload: false});
  }
}
export const registerWithPhone = (code: string, data: IUser): Action => async (dispatch, getState) => {
  dispatch({type: types.LOADING, payload: true});
  const confirm = getState().auth.confirm;
  if (!!confirm) {
    await confirm.confirm(code).then(async () => {
      await dispatch(actions.registerUser(data));
      dispatch(setIsNewUser(false));
      localStorage.removeItem("anonymousUser");
    }).catch((e: any) => {
      if (e.code === "auth/invalid-verification-code") {
        messageError(("El código de verificación no es válido")); 
        dispatch(changeCodeVerification(""));
      }
    });
  }
  dispatch({type: types.LOADING, payload: false});
}
export const fbPhoneNumber = (
  phone: string, 
  reCAPTCHA: firebase.auth.ApplicationVerifier
): Action => async (dispatch) => {
  dispatch({type: types.LOADING, payload: true})
  try {
    const resp = await FbAdmin.verifiedPhone(phone);
    if (!!resp && !resp.verified) {
      firebase.auth().languageCode = 'en';
      await firebase.auth().signInWithPhoneNumber(phone, reCAPTCHA).then((confirm) => {
        dispatch({type: types.CONFIRM, payload: confirm})
      }).catch((_e) => {});
    } else messageError(("El usuario no está registrado"));
    dispatch({type: types.LOADING, payload: false})
  } catch (e) { 
    dispatch({type: types.LOADING, payload: false})
  }
}
export const sendCodeVerification = (code: string): Action => async (dispatch, getState) => {
  dispatch({type: types.LOADING, payload: true});
  const confirm = getState().auth.confirm;
  if (!!confirm) {
    await confirm.confirm(code).then((result) => {
      localStorage.removeItem("anonymousUser");
      dispatch({ type: types.CONFIRM_CODE, payload: result });
      dispatch(actions.analytics("login", {}));
    }).catch((e: any) => {
      if (e.code === "auth/invalid-verification-code") {
        messageError(("El código de verificación no es válido")); 
      }
    });
  }
  dispatch({type: types.LOADING, payload: false});
}
export const changePhoneNumber = (): Action => async (dispatch) => {
  dispatch({type: types.CONFIRM, payload: null})
}
export const changeCodeVerification = (code: string): Action => async (dispatch) => {
  dispatch({type: types.CODE_VERIFICATION, payload: code})
}

export const setIsNewUser = (value: boolean): Action => (dispatch) => {
  dispatch({type: types.IS_NEW_USER, payload: value});
}
export const setVerifiedEmailError  = (value: boolean): Action => (dispatch) => {
  dispatch({ type: types.VERIFIED_EMAIL_ERROR, payload: value });
}

export const resetDataAuth = (): Action => async (dispatch) => {
  dispatch({type: types.AUTH, payload: authInit});
  dispatch({type: types.CONFIRM, payload: null});
  dispatch({type: types.LOADING, payload: false});
  dispatch({type: types.IS_NEW_USER, payload: false});
  dispatch({type: types.CONFIRM_CODE, payload: null});
  dispatch({type: types.CONFIRM_EMAIL, payload: null});
  dispatch({type: types.CODE_VERIFICATION, payload: ""});
}

export const handleLogout = (): Action => async (dispatch) => {
  try {
    dispatch({type: types.LOADING_LOGOUT, payload: true});
    await firebase.auth().signOut();
    localStorage.removeItem("uid");
    localStorage.removeItem("uidfb");
    localStorage.removeItem("uiddb");
    localStorage.removeItem("uuidfb");
    localStorage.removeItem("uuiddb");
    localStorage.removeItem("authBy");
    localStorage.removeItem("userCurrent");
    localStorage.removeItem("anonymousUser");
    dispatch(actions.analytics("logout", {}));
    await dispatch(actions.resetDataUser());
    history.push("/");
    window.location.reload();
    dispatch({type: types.LOADING_LOGOUT, payload: false});
  } catch (e) { console.log("Error Firebase Logout") }
}

