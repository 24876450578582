import React, { FC } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  img: { 
    maxWidth: "70%", 
  },
}));

interface IProps {
  image: string;
}

const ImageOptional: FC<IProps> = ({ image }) => {
  const classes = useStyles();

  return (
    <>
      {!!image && (
        <Grid item container justifyContent="center">
          <img className={classes.img} src={image} alt="" />
        </Grid>
      )}
    </>
  )
}

export default ImageOptional;
