import axios from "axios";
import { ICertificate } from "dto/certificate.dto";

const url = process.env.REACT_APP_BACKEND_URL;
const route = `${url}/api`;

class Certificate {
  async get(): Promise<ICertificate[]> {
    const { data } = await axios.get(`${route}/static/certificates`);
    return data?.data ?? [];
  }
}

const certificate = new Certificate();
const CertificateClass = Object.freeze(certificate);
export default CertificateClass;
