import React from 'react';
import { Typography, DialogContent } from '@material-ui/core';

const PrivacyPolicyES = () => {
  return (
    <DialogContent>
      <Typography variant="h5">O-lab</Typography>
      <Typography variant="body2" display="block" align="justify" gutterBottom>
        Es un plataforma y aplicación móvil (en conjunto, las "Plataformas") que contiene 
        cursos y programas de formación en línea y fuera de línea creados por instituciones 
        públicas y privadas para los usuarios de la aplicación móvil O-lab. <br />
        Fundación El Origen registrada bajo el NIT 900900464-0 en Bogotá - Colombia quien
        participa como licenciatario de la app O-lab en Colombia, es el controlador de datos de tu
        información personal. Su recopilación de información personal está definida en la siguiente
        Política de privacidad.<br /><br />
      </Typography>

      <Typography variant="h5">Política de privacidad</Typography>
      <Typography variant="body2" display="block" align="justify" gutterBottom>
        Fundación El Origen ha sido confiado por los padres y autoridades tradicionales de
        comunidades para proporcionar contenido seguro y educativo. Nos tomamos en serio esta
        confianza y reconocemos la importancia de las preocupaciones de privacidad, especialmente
        en el entorno digital.<br />
        En esta Política de privacidad, el término "información personal" significa cualquier
        información recopilada por nosotros o proporcionada por usted que, con esfuerzos
        razonables, puede usarse para identificarlo, que puede incluir nombre y apellido, dirección de
        correo electrónico y su ubicación o relación con la comunidad o institución educativa a la que
        pertenece y a la que con fines de patrocinio se le ha dado acceso.<br />
        Si elige utilizar las Plataformas O-lab, entonces acepta la recopilación y el uso de
        información en relación con esta política. La información personal que recopilamos se utiliza
        para proporcionar y mejorar el servicio y proveer más oportunidades acorde con sus
        requerimientos.<br /><br />
      </Typography>

      <Typography variant="h5">
        Nuestra Política de privacidad se basa en los siguientes principios
      </Typography>
      <Typography variant="body2" display="block" align="justify" gutterBottom>
        Usted es libre de elegir participar o no en cualquier actividad en nuestras Plataformas que
        implique el envío de información personal, como su nombre, ubicación, número de teléfono,
        evidencia de actividades u otra información que pueda usarse para identificar o contactar a un
        individuo, ya sea en línea o fuera de línea. Le pediremos dicha información expresamente. La
        mayoría de las actividades en nuestras plataformas no requieren que envíe información
        personal.<br /><br />
        Utilizamos la información que nos proporciona y la que se recopila automáticamente de su
        dispositivo, como la dirección de Protocolo de Internet de una computadora, un identificador
        de dispositivo móvil, sistema operativo o ubicación general, como el código postal, para
        respaldar las operaciones internas para que podamos entender y monitorear el uso de nuestras
        plataformas y mejorar el rendimiento de las plataformas.<br /><br />
        Los terceros que utilizamos para ayudar con las Plataformas deben seguir esta Política de
        privacidad. ESPECIALMENTE PARA NIÑOS MENORES DE 13 AÑOS.<br /><br />
        Nuestra recopilación de información personal sobre plataformas que están dirigidas a niños
        menores de 13 años ("Plataformas para niños") está destinada a seguir los principios de la
        POLÍTICA DE TRATAMIENTO Y PROTECCIÓN DE DATOS PERSONALES, de
        Colombia Diseñada para proteger la privacidad en línea de lo usuarios.<br /><br />
        No hacemos pública la información personal recopilada de los niños ni permitimos que los
        niños lo hagan en nuestras plataformas. No condicionamos la participación de un niño en
        ninguna actividad o servicio en la divulgación de más información personal de la que sea
        razonablemente necesaria para participar en una actividad.<br /><br />
      </Typography>

      <Typography variant="h5">¿Qué Información Recolectamos?</Typography>
      <Typography variant="body2" display="block" align="justify" gutterBottom>
        Información de identificación personal proporcionada directamente por el usuario o por
        terceros. Recopilamos la información de identificación personal que nos proporcionas cuando
        creas una cuenta, actualizas o cambias la información de esta, completas una encuesta, y/o
        participas en contenido que se ofrece u otros servicios de nuestras Plataformas. Podemos usar
        la información de identificación personal para responder a tus preguntas, proporcionarte
        servicios y/o contenido que se ofrece específico a tus selecciones, enviarte actualizaciones 
        del contenido que se ofrece u otros eventos.<br /><br />
        Registro de cuenta: Si te registras para tener una cuenta en nuestras Plataformas, es posible
        que se te pida que proporciones Información de identificación personal, como tu nombre y
        ubicación geográfica.<br /><br />
        Actualizaciones: O-lab puede ofrecerte la posibilidad de recibir actualizaciones por email o
        mediante mensajes en partes de la página web O-lab.app. Para suscribirte a esos servicios, se
        te pedirá que nos proporciones Información Personal Identificable como puede ser tu nombre
        o dirección de correo electrónico.<br /><br />
        Cursos: O-lab ofrece módulos y cursos donde puedes compartir comentarios, tareas, logros y
        evidencias fotográficas de las actividades realizadas. Al registrarte podrás participar en las
        actividades “retos” de estos módulos y cursos, se te pedirá que te registres y/o que nos
        proporciones Información de identificación personal, como tu nombre y comunidad o
        institución educativa a la que estás adscrito. Recuerda que la información que publicas en las
        actividades estará a disposición pública y principalmente de las entidades que las crearon.
        También recopilamos datos de los retos, como respuestas de los cuestionarios que aparecen,
        cuestionarios individuales, tareas, exámenes y encuestas. No debes incluir información de
        identificación personal u otro tipo de información de índole personal o confidencial, ya sea
        relacionada a ti o a otra persona, en estas actividades “retos”.<br /><br />
        Participación en el contenido que se ofrece. O-lab ofrece a los usuarios la oportunidad de
        participar en contenido que se ofrece en o a través de las Plataformas. Si deseas participar en
        contenido que se ofrece, se te pedirá que nos proporciones cierta información necesaria para
        llevar a cabo ese contenido que se ofrece. Esta información puede incluir, entre otras cosas, tu
        nombre y dirección de correo electrónico.<br /><br />
      </Typography>

      <Typography variant="h5">Usos de la información</Typography>
      <Typography variant="body2" display="block" align="justify" gutterBottom>
        Utilizamos la información que se recopila automáticamente para respaldar las operaciones
        internas de las Plataformas. Parte de esta información se recopila a través de herramientas de
        análisis de terceros. Las operaciones internas incluyen lo siguiente:<br /><br />
        Para monitorear métricas como el número total de visitantes, logros alcanzados, actividades
        realizadas, falencias, y el tráfico, esto con el fin de informar a las instituciones educativas
        aliadas y patrocinadores sobre el progreso e impacto del programa en las usuarios que han
        sido patrocinados.<br /><br />
        Para entender qué actividades y programas son más populares;<br /><br />
        Para comprender en general la ubicación de nuestros usuarios (por ejemplo, información de
        código postal);<br /><br />
        Para reconocer los navegadores utilizados para visitar nuestros Sitios, de modo que el
        contenido se adapte al navegador correspondiente; y<br /><br />
        Para diagnosticar y solucionar problemas tecnológicos.<br /><br />
      </Typography>

      <Typography variant="h5">¿Cómo acceder a tus Datos o borrarlos?</Typography>
      <Typography variant="body2" display="block" align="justify" gutterBottom>
        Los padres pueden comunicarse con nosotros (en olab@fundacionelorigen.org) para revisar,
        actualizar o eliminar cualquier información personal de sus hijos que hayamos recopilado y
        elegir que no recopilemos información personal adicional de sus hijos.<br /><br />
        Si desea eliminar su cuenta en O-lab, puede hacerlo al enviar un correo electrónico a
        olab@fundacionelorigen.org. Si elimina su cuenta, eliminará su perfil, incluyendo los logros
        alcanzados, y usted ya no podrá acceder a dichos Datos. No podremos restaurar su cuenta una
        vez que haya sido eliminada. Es posible que la eliminación de su cuenta no ocurra de
        inmediato. Copias residuales de su Información personal pueden permanecer en medios de
        almacenamiento de copias de seguridad por poco tiempo después de entonces.<br /><br />
        Además, podemos almacenar ciertos Datos en nuestros sistemas internos, y podríamos
        usarlos en nuestros Servicios para empresas. Asimismo, podemos retener Datos según lo
        requieran las leyes aplicables y no eliminaremos los Datos que no sean Información personal,
        es decir, que ya hayan sido anonimizados.<br /><br />
      </Typography>
        
      <Typography variant="h5">Seguridad de la información</Typography>
      <Typography variant="body2" display="block" align="justify" gutterBottom>
        O-lab considera que la seguridad de la información es prioridad. O-lab implementa sistemas,
        aplicaciones y procedimientos para asegurar su información personal para minimizar los
        riesgos de robo, daño, pérdida de información, accesos no autorizados o uso de la
        información que no esté autorizado. Sin embargo, estas medidas no permiten proveer
        absoluta seguridad. Por lo tanto, a pesar de que O-lab realiza un gran esfuerzo para proteger
        su información personal, nosotros no podemos garantizar, y usted razonablemente no puede
        esperar, que las bases de datos de O-lab sean inmunes a malas acciones, mal funcionamiento,
        atajos o accesos ilegales u otro tipo de mal uso o abuso.<br /><br />
      </Typography>

      <Typography variant="h5">Control de la información con los trabajadores</Typography>
      <Typography variant="body2" display="block" align="justify" gutterBottom>
        Todas las personas que tengan este acceso están sujetas a estrictas obligaciones contractuales
        de confidencialidad y pueden ser sancionados y despedidos si no cumplen con estas
        obligaciones.<br /><br />
        <b>Circunstancias especiales.</b> Podemos acceder, preservar y compartir su información cuando
        creemos de buena fe que es necesario: (i) responder a una solicitud legal (como una orden de
        allanamiento, orden judicial o citación) o tomar medidas porque lo creemos es requerido o
        aconsejable según la ley; (ii) detectar, prevenir y abordar el fraude y otras actividades
        ilegales; (iii) responder a sus solicitudes de servicio al cliente; o (iv) limitar nuestra
        responsabilidad legal o proteger los derechos, la propiedad o la seguridad personal de
        nosotros mismos, cualquier usuario de nuestras Plataformas, un tercero o el público en
        general, incluso como parte de las investigaciones.<br /><br />
        <b>Transferencia Comercial.</b> Si vendemos o transferimos parte o la totalidad de O-lab o
        nuestros activos a otra organización (por ejemplo, en el curso de una transacción como una
        fusión, adquisición, quiebra, disolución, liquidación), su información personal y cualquier
        otra información recopilada a través de nuestras plataformas puede estar entre los artículos
        vendidos o transferidos. El comprador o el cesionario deberán cumplir los compromisos que
        hemos contraído en esta Política de privacidad.<br /><br />
        <b>Aviso de seguridad.</b> En el caso improbable de que creamos que la seguridad de su
        información personal en nuestra posesión o control puede haber sido comprometida, podemos
        tratar de notificarle sobre ese desarrollo. Si una notificación es apropiada, 
        nos esforzamos por hacerlo tan pronto como sea posible bajo las circunstancias y,
        en la medida en que nos haya proporcionado su dirección de correo electrónico, podemos 
        notificarle por correo electrónico. Usted acepta nuestro uso del correo electrónico 
        como medio de dicha notificación.<br /><br />
      </Typography>
    
      <Typography variant="h5">Proveedores de servicio</Typography>
      <Typography variant="body2" display="block" align="justify" gutterBottom>
        Contratamos a terceros para proporcionar ciertos servicios para facilitar las operaciones de
        nuestras Plataformas ("proveedores de servicios"), como para administrar la información
        analítica sobre el uso de nuestras Plataformas o para procesar donaciones. Nuestros
        proveedores de servicios tendrán acceso a su información sólo cuando sea razonablemente
        necesario para proporcionar los servicios para los que fueron contratados. Se les exigirá que
        cumplan con los términos de esta Política de privacidad en el manejo de la información
        personal.<br/><br/>
      </Typography>

      <Typography variant="h5">Otros sitios web y servicios</Typography>
      <Typography variant="body2" display="block" align="justify" gutterBottom>
        Nuestras Plataformas pueden vincularse a otros sitios web o aplicaciones, incluido el
        contenido ofrecido por terceros que no controlamos y cuyas prácticas de privacidad y
        recopilación de datos pueden diferir de las nuestras ("Plataformas de terceros"). No somos
        responsables de las prácticas empleadas por ningún sitio web o servicio vinculado a nuestras
        Plataformas o desde ellas, incluida la información o el contenido que contienen.<br /><br />
        Cuando accede a través de nuestro enlace a Plataformas de terceros, queda sujeto a sus
        términos de uso y políticas de privacidad en lugar de a los nuestros. Debe revisar estas otras
        políticas de privacidad del sitio web con respecto a cualquier información personal que
        puedan recopilar sobre usted.<br /><br />
        O-lab es operada por un tercero, un licenciatario. El titular de la licencia ha firmado un
        acuerdo con nosotros para operar las Plataformas y vender y enviar productos relacionados
        con los programas educativos de O-lab.<br /><br />
      </Typography>

      <Typography variant="h5">Cambios en la Política de Privacidad</Typography>
      <Typography variant="body2" display="block" align="justify" gutterBottom>
        O-lab puede cambiar las condiciones de esta Política de Privacidad. Cambios considerables
        se llevarán a cabo 30 días después de que O-lab haya publicado notificaciones en el inicio de
        la página web con la nueva Política de Privacidad.<br />
        Otros tipos de cambios se llevarán a cabo 7 días luego de la publicación inicial en la página.
        Sin embargo, sí O-lab modifica su Política de Privacidad para cumplir con requerimientos
        legales, dichos cambios se harán efectivos inmediatamente después de la publicación inicial o
        según sea requerido. Usted acepta estar limitado por cualquier cambio realizado en las
        condiciones de esta Política de Privacidad. Para continuar utilizando los Servicios se deberá
        indicar aceptación frente a los cambios realizados. Si usted no está de acuerdo con alguna de
        las condiciones modificadas, debe evitar seguir utilizando las Plataformas.
      </Typography>
    </DialogContent>
  )
}

export default PrivacyPolicyES;
