import axios from "axios";
import { IGender } from "dto/gender.dto";

const url = process.env.REACT_APP_BACKEND_URL;
const route = `${url}/api`;

class Gender {
  async get(): Promise<IGender[]> {
    const { data } = await axios.get(`${route}/static/genders`);
    return data?.data ?? [];
  }
}

const gender = new Gender();
const GenderClass = Object.freeze(gender);
export default GenderClass;
