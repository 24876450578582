import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Grid, Button, Typography } from '@material-ui/core';
import { CheckCircleOutline } from '@material-ui/icons';
import { ILicense } from '../../services/checkout.services';

const useStyles = makeStyles((theme) => ({
  thankyou: { maxWidth: 500, minHeight: 500, padding: 15 },
  content: { height: 'calc(100% - 40px)' },
  icon: { color: 'green', fontSize: 150 },
  btn: { 
    width: 350,
    color: 'white',
    borderRadius: 25,
    backgroundColor: '#ff6315',
    '&:hover': { backgroundColor: 'white', color: '#ff6315' },
    [theme.breakpoints.down("xs")]: { width: '100%' },
  },
}));

interface IProps {
  license: ILicense
  nextStep: () => void
}

const ThankYou: FC<IProps> = ({license, nextStep}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid className={classes.thankyou} justifyContent="center" container>
      <Grid className={classes.content} container alignItems="center">
        <Grid container justifyContent="center">
          <Typography variant="h4" align="center" gutterBottom>
            {t("Compra de licencia")} {t(license.name)}, {t("por un período de un")} {t(license.amount.name)} {t("se realizó con éxito")}.
          </Typography>
          <CheckCircleOutline className={classes.icon} />
        </Grid>
      </Grid>
      
      <Button className={classes.btn} variant="contained" onClick={nextStep}>
        {t("Continuar")}
      </Button>
    </Grid>
  )
}

export default ThankYou;
