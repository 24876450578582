import React, { FC } from "react";
import { Avatar, Dialog, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import WithoutSponsor from "../Home/WithoutSponsor";

const useStyles = makeStyles(() => ({
  dialog: {
    "& .MuiDialog-paper": {
      height: "100%",
      padding: "0 32px",
      position: "relative",
      borderRadius: 32,
      backgroundColor: "white",
    },
  },
  close: {
    top: 16,
    right: 16,
    padding: 0,
    position: "absolute",
    "& .MuiAvatar-root": { width: 32, height: 32 },
  },
}));

interface IProps {
  open: boolean;
  onClose: () => void;
}

const DialogWithoutSponsor: FC<IProps> = (props) => {
  const classes = useStyles();

  return (
    <Dialog className={classes.dialog} open={props.open} onClose={props.onClose}>
      <IconButton className={classes.close} onClick={props.onClose}>
        <Avatar src="/img/explore/close.svg" alt="" />
      </IconButton>
      <WithoutSponsor />
    </Dialog>
  )
}

export default DialogWithoutSponsor;
