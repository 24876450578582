import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Grid, Typography, IconButton } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  root: {
    padding: "12px 16px",
    border: "2.6px solid #f0f0f0",
    alignItems: "center",
    borderRadius: 16,
  },
  avatar: {
    width: 60,
    height: 60,
    marginRight: 16,
    borderRadius: 16,
  },
  data: { 
    width: "calc(100% - 76px)", 
    alignItems: "center",
    "&.data_edit": { width: "calc(100% - 116px)" },
  },
  h6: {  color: "#5f5f5f", fontWeight: 700, },
  body1: { color: "#8d8d8d", fontWeight: 500 },
  edit: { 
    padding: 0,
    marginLeft: 8,
    "& .MuiAvatar-root": { width: 32,  height: 32, },
  },
}));

interface IProps {
  edit?: boolean;
  image: string;
  label: string;
  title: string;
  onClick?: () => void;
}

const InfoField: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid className={classes.root} container>
      <Avatar className={classes.avatar} src={props.image} alt="" />
      <Grid className={clsx(classes.data, props.edit && "data_edit")} container>
        <Grid item xs={12}>
          <Typography className={classes.body1} noWrap>
            {t(props.title)}
          </Typography>
          <Typography className={classes.h6} variant="h6" noWrap>
            {t(props.label)}
          </Typography>
        </Grid>
      </Grid>
      {props.edit && (
        <IconButton className={classes.edit} onClick={props.onClick}>
          <Avatar src="/img/profile/edit_field.svg" alt="" />
        </IconButton>
      )}
    </Grid>
  )
}

export default InfoField;
