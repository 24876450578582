import axios from "axios";
import firebase from "firebase/app";
import "firebase/storage";

class Files {
  async deleteFile({ ref }: { ref: string }) {
    const storage = firebase.storage().ref(ref);
    await storage.delete().then().catch((d) => console.log("Ops error!", d));
  };

  async uploadFile(ref: string, file: File) {
    const storage = firebase.storage().ref(ref);
    await storage.put(file).then().catch();
  };

  async downloadFile ({ ref }: { ref: string }) {
    const data = await firebase.storage().ref(ref).getDownloadURL().then().catch();
    return data;
  };

  async downloadFileAny(name: string, path: string) {
    await axios({
      url: path,
      method: "GET",
      responseType: "blob"
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }
}
  
const files = new Files();
const FilesClass = Object.freeze(files);
export default FilesClass;
  