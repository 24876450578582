import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Grid } from "@material-ui/core";
import { IUser } from "dto/user.dto";
import { ILicense, licenseInit, amountInit, IAmount } from "services/checkout.services";
import { State } from "store/types";
import * as actions from "store/actions";
import Loading from "components/Common/Loading";
import LayoutOlab from "layouts/LayoutOlab";
import Background from "components/Common/Background";
import ContactUs from "../ContactUs";
import Additional from "../Additional";
import PayStepTwo from "../License/PayStepTwo";
import PayStepSix from "../PayStepSix";
import PayStepFour from "../PayStepFour";
import PayStepFive from "../PayStepFive";
import PayStepThree from "../PayStepThree";
import PayStepOne from "./PayStepOne";
import PayStepOneMobile from "./PayStepOneMobile";
import { IEntity } from "dto/entity.dto";

const useStyles = makeStyles(() => ({
  root: { height: "100vh", position: "relative" },
  page: { 
    height: "calc(100% - 60px)", 
    position: "relative", 
    marginTop: 60,
  },
  content: {
    height: "100%",
    position: "relative", 
    overflow: "auto",
    alignItems: "center",
    "&::-webkit-scrollbar": { width: 8 },
    "&::-webkit-scrollbar-track": { backgroundColor: "white" },
    "&::-webkit-scrollbar-thumb": { backgroundColor: "#c1c1c1", borderRadius: 8 },
  },
}));

interface IProps {}

const PlansPrices: FC<IProps> = () => {
  const classes  = useStyles();
  const dispatch = useDispatch();
  const loading         = useSelector((state: State) => state.checkout.loading);
  const projects        = useSelector((state: State) => state.checkout.projects);
  const licensesLoading = useSelector((state: State) => state.checkout.licensesLoading);
  const [step, setStep] = React.useState("SERVICE");
  const [info, setInfo] = React.useState({name: "", email: "", phone: "", entity: ""});
  const [license, setLicense] = React.useState(licenseInit);
  const [currency, setCurrency] = React.useState<"usd"|"cop">("usd");

  const createUserEntity = (
    user: IUser, 
    entity: IEntity, 
    password: string, 
    callback: () => void
  ) => {
    dispatch(actions.createUserEntity(user, entity, password, callback));
  }

  const selectService = (license: ILicense) => { 
    setStep("LICENSE"); 
    setLicense({...license, amount: amountInit}); 
  }
  const deselectService = () => { setStep("SERVICE"); setLicense(licenseInit); }

  const selectLicense = (amount: IAmount) => {
    setStep("STRIPE");
    setLicense({...license, amount});
  }
  const deselectLicense = () => {
    setStep("LICENSE");
    setLicense({...license, amount: amountInit});
  }

  const openServiceOptional = () => setStep("OPTIONAL");
  const closeOptional = () => setStep("SERVICE");

  const openContactUs = () => setStep("CONTACT_US");
  const closeContactUs = () => setStep("SERVICE");

  const openStepSix  = () => setStep("STEP_SIX");
  const openStepFour = () => setStep("STEP_FOUR");
  const openStepFive = () => setStep("STEP_FIVE");

  return (
    <Grid className={classes.root} container>
      <LayoutOlab />

      <Grid className={classes.page} container>
        <Background />

        <Grid className={classes.content} container>
          <Grid container justifyContent="center">
            {step === "SERVICE" && !license.name && (
              <>
                <PayStepOne
                  projects={projects}
                  selectService={selectService}
                  openContactUs={openContactUs}
                  openServiceOptional={openServiceOptional} 
                />
                <PayStepOneMobile
                  projects={projects}
                  selectService={selectService}
                  openContactUs={openContactUs} 
                  openServiceOptional={openServiceOptional} 
                />
              </>
            )}
            {step === "LICENSE" && !!license.name && !license.amount.uid && (
              <PayStepTwo 
                license={license}
                currency={currency}
                setCurrency={setCurrency}
                selectLicense={selectLicense} 
                deselectService={deselectService} 
              />
            )}
            {step === "STRIPE" && !!license.name && !!license.amount.uid && (
              <PayStepThree 
                license={license}
                currency={currency}
                updateInfo={setInfo}
                prevSection={deselectLicense}
                nextSection={openStepFour}
                setCurrency={setCurrency}
              />
            )}
            {step === "STEP_FOUR" && (<PayStepFour license={license} nextStep={openStepFive} />)}
            {step === "STEP_FIVE" && (
              <PayStepFive 
                info={info} 
                loading={loading} 
                nextStep={openStepSix}
                createUserEntity={createUserEntity} 
              />
            )}
            {step === "STEP_SIX" && ( <PayStepSix /> )}

            {step === "OPTIONAL" && (
              <Additional closeOptional={closeOptional} openContactUs={openContactUs} />
            )}
            {step === "CONTACT_US" && (<ContactUs closeContactUs={closeContactUs} />)}
          </Grid>
        </Grid>
      </Grid>

      {licensesLoading && (<Loading loading />)}
    </Grid>
  )
}

export default PlansPrices;
