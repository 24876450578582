import React, { FC, MouseEventHandler } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "@material-ui/core";
import { soundClick } from "utils";
import FormTypography from "./FormTypography";

const useStyles = makeStyles(() => ({
  button: {
    color: "#8d8d8d",
    border: "2px solid #f0f0f0",
    outline: "0 !important",
    maxWidth: "100%",
    boxShadow: "0px 2px 0px 0px #f0f0f0",
    borderRadius: 12,
    textTransform: "inherit",
    backgroundColor: "white",
    "& input": { display: "none" },
    "&:focus": { outline: "unset" },
    "&:hover": { boxShadow: "0px 2px 0px 0px #f0f0f0", backgroundColor: "white" },
    "&:disabled": { 
      color: "#cdcdcd !important",
      border: "2px solid #f0f0f0",
      padding: "8px 16px",
      cursor: "no-drop",
      boxShadow: "0px 2px 0px 0px #f0f0f0",
      borderRadius: 12,
      pointerEvents: "initial",
      backgroundColor: "#f4f4f4",
      "&:hover": { boxShadow: "0px 2px 0px 0px #f0f0f0", backgroundColor: "#f4f4f4" },
    },
  },
  primary: {
    color: "white",
    border: 0,
    padding: "8px 16px",
    boxShadow: "0px 4px 0px 0px #db5410",
    marginBottom: 4,
    backgroundColor: "#ff6300",
    "&:hover": { boxShadow: "0px 4px 0px 0px #db5410", backgroundColor: "#ff6300" },
  },
}));

interface IProps {
  type?: "button" | "reset" | "submit";
  color?: string;
  title?: string;
  border?: number | string;
  primary?: boolean;
  padding?: number | string;
  disabled?: boolean;
  maxWidth?: number | string;
  minWidth?: number | string;
  component?: "label";
  fullWidth?: boolean;
  startIcon?: JSX.Element;
  boxShadow?: string;
  borderColor?: string;
  marginBottom?: number | string;
  backgroundColor?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const FormButton: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const buttonClass = clsx(classes.button, !!props.primary && classes.primary);

  const style = { 
    color: props.color,
    border: props.border,
    padding: props.padding,
    maxWidth: props.maxWidth,
    minWidth: props.minWidth, 
    boxShadow: props.boxShadow,
    borderColor: props.borderColor,
    marginBottom: props.marginBottom,
    backgroundColor: props.backgroundColor,
  }

  return (
    <>
      {!!props.title && (
        <FormTypography color="#8d8d8d" variant="body1">
          {t(props.title)}
        </FormTypography>
      )}
      <Button
        className={buttonClass}
        type={props.type}
        style={style}
        variant="contained"
        onClick={props.onClick}
        disabled={props.disabled}
        component={props.component ?? "button"}
        fullWidth={props.fullWidth}
        startIcon={props.startIcon}
        onMouseDown={soundClick}
      >
        <Typography variant="body1" noWrap>
          <b>{props.children}</b>
        </Typography>
      </Button>
    </>
  );
};

export default FormButton;
