import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import RadioButton from "components/Question/RadioButton";

const useStyles = makeStyles(() => ({
  type00: { 
    height: "100%", 
    padding: 16,
    alignItems: "center", 
  },
}));

interface IProps {
  answer: string;
  answers: string[];
  notFeedback?: boolean;
  answerRating: boolean;
  viewFeedback: boolean;
  selectAnswer: (answer: string) => void;
}

const Type00: FC<IProps> = (props) => {
  const classes = useStyles();
  const { answer, viewFeedback, selectAnswer } = props;

  const onChange = (value: string) => selectAnswer(value);

  return (
    <Grid className={classes.type00} container>
      <Grid container spacing={1}>
        {props.answers.map((value, i) => (!!value &&
          <Grid key={i} item xs={12}>
            <RadioButton 
              label={value}
              value={value}
              checked={!!answer && answer === value}
              correct={props.answerRating}
              feedback={viewFeedback && !!answer && answer === value}
              disabled={viewFeedback}
              notFeedback={props.notFeedback}
              onChange={onChange}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

export default Type00;
