import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Grid, Paper, Typography, ButtonGroup, Button } from '@material-ui/core';
import { TableContainer, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import { CheckCircleOutline } from '@material-ui/icons';
import { amountInit, ILicense, IProject } from '../../../services/checkout.services';
import { servicesTable } from '../Constants';

const useStyles = makeStyles(() => ({
  service: { maxWidth: 610, marginRight: 15 },
  header: { paddingLeft: 245 },
  name: { 
    width: 121,
    padding: "0 5px",
    alignItems: "flex-end",
    "& .MuiPaper-root": {
      color: "white",
      width: "100%", 
      display: "flex",
      padding: "5px 10px",
      alignItems: "center",
      borderRadius: "10px 10px 0 0",
    },
    "& .MuiTypography-root": { width: 100, textTransform: "capitalize" },
    "&:nth-child(1)": {"& .MuiPaper-root": { backgroundColor: "#6789e5", height: 50 }},
    "&:nth-child(2)": {"& .MuiPaper-root": { backgroundColor: "#4ccc6a", height: 60 }},
    "&:nth-child(3)": {
      padding: "0 0 0 5px",
      "& .MuiPaper-root": { backgroundColor: "#eabf0a", height: 70 }
    },
  },
  table: { width: "auto", borderRadius: "10px 0 0 10px" },
  row: {
    width: 600,
    '& .MuiTableCell-root': {
      width: 121,
      border: 0,
      padding: 0,
      fontWeight: 'bold',
      fontSize: 15,
      borderRight: '1px solid #a5a5a5', 
      '&:last-child': { borderRight: 0 },
      '& .MuiSvgIcon-root': { color: '#5dc90a' },
    },
    '&:nth-of-type(odd)': { backgroundColor: '#e8e8e8' },
  },
  text: { width: 245, display: 'block', padding: '0 2px 0 6px', fontWeight: 700 },
  group: { 
    borderRadius: "0 0 10px 10px",
  },
  btn: {
    color: "white",
    padding: "6px 8px",
    backgroundColor: "#ff6300",
    "& .MuiButton-label": {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    "&:hover": { 
      color: "#ff6300",
      backgroundColor: "white", 
    },
    "&:last-child": { borderRadius: "0 0 10px 0" },
    "&:first-child": { borderRadius: "0 0 0 10px" },
  },
}));

interface IProps {
  projects: IProject[]
  selectService: (value: ILicense) => void
}

const ServicesTable: FC<IProps> = ({projects, selectService}) => {
  const classes  = useStyles();
  const { t } = useTranslation();

  return (
    <Grid className={classes.service} container>
      <Grid className={classes.header} container>
        {projects.map((project, i) => (
          <Grid key={i} className={classes.name} container>
            <Paper>
              <Typography variant="subtitle2">
                {t(project.name)}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>

      <Grid container>
        <TableContainer className={classes.table} component={Paper}>
          <Table size="small">
            <TableBody>
              <TableRow className={classes.row}>
                <TableCell align="left">
                  <Typography className={classes.text} variant="caption" noWrap>
                    {t("Almacenamiento")}
                  </Typography>
                </TableCell>
                <TableCell align="center" style={{padding: "4px 0"}}>2 GB</TableCell>
                <TableCell align="center" style={{padding: "4px 0"}}>5 GB</TableCell>
                <TableCell align="center" style={{padding: "4px 0"}}>10 GB</TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell align="left">
                  <Typography className={classes.text} variant="caption" noWrap>
                    {t("Usuarios finales")}
                  </Typography>
                </TableCell>
                <TableCell align="center" style={{padding: "4px 0"}}>1 - 30</TableCell>
                <TableCell align="center" style={{padding: "4px 0"}}>31 - 100</TableCell>
                <TableCell align="center" style={{padding: "4px 0"}}>101 - 500</TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell align="left">
                  <Typography className={classes.text} variant="caption" noWrap>
                    {t("Usuarios administradores")}
                  </Typography>
                </TableCell>
                <TableCell align="center" style={{padding: "4px 0"}}>2</TableCell>
                <TableCell align="center" style={{padding: "4px 0"}}>2</TableCell>
                <TableCell align="center" style={{padding: "4px 0"}}>5</TableCell>
              </TableRow>
              {servicesTable.map((name, i) => (
                <TableRow key={i} className={classes.row}>
                  <TableCell align="left">
                    <Typography className={classes.text} variant="caption" noWrap>
                      {t(name)}
                    </Typography>
                  </TableCell>
                  <TableCell align="center" style={{padding: 0}}>
                    <CheckCircleOutline fontSize="small" />
                  </TableCell>
                  <TableCell align="center" style={{padding: 0}}>
                    <CheckCircleOutline fontSize="small" />
                  </TableCell>
                  <TableCell align="center" style={{padding: 0}}>
                    <CheckCircleOutline fontSize="small" />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      
      <Grid className={classes.header} container>
        <ButtonGroup className={classes.group} variant="contained" fullWidth>
          {projects.map((service) => (
            <Button 
              key={service.uid} 
              className={classes.btn}
              onClick={() => selectService({...service, amount: amountInit})}
            >
              {t("Ver")}
            </Button>
          ))}
        </ButtonGroup>
      </Grid>
    </Grid>
  )
}

export default ServicesTable;
