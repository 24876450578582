import React, { FC } from 'react';
import clsx from 'clsx';
import { makeStyles, Grid, Button } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  amounts: { marginBottom: 15 },
  amount: {
    '&:nth-child(1), &:nth-child(4), &:nth-child(7)': { paddingRight: 4 },
    '&:nth-child(2), &:nth-child(5), &:nth-child(8)': { padding: '0 4px 0' },
    '&:nth-child(3), &:nth-child(6), &:nth-child(9)': { paddingLeft: 4 },
    marginBottom: 8,
  },
  btn: { backgroundColor: '#d5d5d5', color: 'white' },
  set: { 
    color: 'white',
    backgroundColor: '#ff6315', 
    '&:hover': { backgroundColor: '#ff6315', color: 'white' },
  },
}));

interface IProps {
  amount: number
  amounts: number[]
  selectAmount: (amount: number) => void
}

const Amounts: FC<IProps> = ({amount, amounts, selectAmount}) => {
  const classes = useStyles();

  return (
    <Grid className={classes.amounts} container>
      {amounts.map((price) => (
        <Grid key={price} className={classes.amount} item xs={4}>
          <Button
            className={clsx(classes.btn, amount === price && classes.set)} 
            variant="contained"
            onClick={() => selectAmount(price)}
            fullWidth
          >
            ${price}
          </Button>
        </Grid>
      ))}
    </Grid>
  )
}

export default Amounts;
