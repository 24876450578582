import { ICountry } from "dto/country.dto";
import { AppThunkAction } from "store/types";
import { CountryActionType } from "./actions";

export * from "./actions";
export * from "./constants";

export interface CountryState {
  countries: ICountry[];
  countriesLoading: boolean;
}

export type Action = AppThunkAction<CountryActionType>
