const svgImage = (c1: String) => `
  <svg width="74" height="70" viewBox="0 0 74 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M74 70L35.8065 36L0 70V0H74V70Z" fill="${c1}"/>
  </svg>
`;

export const SvgShape05 = (c1?: string) => {
  const a = !!c1 ? c1 : "#ff6300";
  return `data:image/svg+xml;charset=UTF-8;base64,${btoa(svgImage(a))}`;
};

export default SvgShape05;
