import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import FormButton from "components/Form/FormButton";
import FormTypography from "components/Form/FormTypography";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 32,
    borderRadius: 16,
    backgroundColor: "white",
  },
  content: { gap: 20 },
}));

interface IProps {
  open: boolean;
  title: string;
  subtitle: string;
  onClose: () => void;
  onClickAccept?: () => void;
}

const DialogWarning: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog classes={{paper: classes.paper}} open={props.open}>
      <Grid className={classes.content} container>
        <Grid item xs={12}>
          <img src="/img/warning.svg" alt="" />
        </Grid>
        <Grid item xs={12}>
          <FormTypography variant="h5" fontWeight={700}>
            {t(props.title)}
          </FormTypography>
        </Grid>
        <Grid item xs={12}>
          <FormTypography fontSize={18}>
            {t(props.subtitle)}
          </FormTypography>
        </Grid>
        <Grid item container justifyContent="space-between">
          <FormButton maxWidth={180} fullWidth onClick={props.onClose}>
            {t("Cancelar")}
          </FormButton>
          <FormButton 
            color="white" 
            maxWidth={180} 
            fullWidth
            boxShadow="0px 4px 0px 0px #D80909"
            marginBottom={4}
            borderColor="#e02424"
            backgroundColor="#e02424"
            onClick={props.onClickAccept}
          >
            {t("Continuar")}
          </FormButton>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default DialogWarning;
