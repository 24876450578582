import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { IPopUpProperties, IPopUpPropertiesPreview } from "dto/question.dto";
import FormButton from "components/Form/FormButton";
import FormTypography from "components/Form/FormTypography";

const useStyles = makeStyles(() => ({
  paper: {
    gap: 12,
    width: "100%",
    border: "3px solid #f0f0f0",
    padding: 32,
    maxWidth: 500,
    borderRadius: 16,
    backgroundColor: "white",
  },
  image: {
    height: 120,
    alignItems: "center",
    justifyContent: "center",
    "& img": { maxWidth: "100%", maxHeight: "100%" },
  },
}));

interface IProps {
  open: boolean;
  properties?: IPopUpProperties;
  propertiesPreview?: IPopUpPropertiesPreview;
  onClose: () => void;
}

const DialogPopUp: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const text = !!props.properties?.text ? props.properties.text : t("Texto");
  const image = !!props.propertiesPreview?.image 
    ? props.propertiesPreview.image 
    : "/img/icons/success.svg";
  const title = !!props.properties?.title ? props.properties.title : t("Título");
  const buttonText = !!props.properties?.button_text 
    ? props.properties.button_text 
    : t("Abrir link");

  return (
    <Dialog 
      open={props.open} 
      classes={{ paper: classes.paper }} 
      maxWidth="xs" 
      onClose={props.onClose}
    >
      <Grid className={classes.image} container>
        <img src={image} alt="" />
      </Grid>
      <Grid item xs={12}>
        <FormTypography align="center" fontSize={24} fontWeight={700}>
          {title}
        </FormTypography>
        <FormTypography align="center" fontSize={18} fontWeight={400}>
          {text}
        </FormTypography>
      </Grid>
      <Grid container justifyContent="center">
        <a href={props.properties?.link} rel="noopener noreferrer" target="_blank">
          <FormButton primary minWidth={200} onClick={props.onClose}>
            {(buttonText)}
          </FormButton>
        </a>
      </Grid>
    </Dialog>
  )
}

export default DialogPopUp;
