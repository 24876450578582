const svgImage = (c1: String) => `
  <svg width="604" height="64" viewBox="0 0 604 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M-28.0901 51.93C38.4699 2.46998 130 14.32 217.5 37.87C305 61.42 378.75 -0.680005 458.19 0.259995C537.63 1.17 585.9 43.4 629.59 46.56V150.21H-28.0901V51.93Z"
      fill="${c1}"
    />
  </svg>
`;

export const SvgShape08 = (c1?: string) => {
  const a = !!c1 ? c1 : "#ff6300";
  return `data:image/svg+xml;charset=UTF-8;base64,${btoa(svgImage(a))}`;
};

export default SvgShape08;
