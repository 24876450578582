import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Grid, Typography, Button } from '@material-ui/core';
import { Phone, Email } from '@material-ui/icons';
import ContactUsForm from './ContactUsForm';

const useStyles = makeStyles((theme) => ({
  contact: { 
    padding: '15px 30px',
    [theme.breakpoints.down("xs")]: { padding: 15 },
  },
  form: { 
    maxWidth: 400, 
    paddingLeft: 15, 
    [theme.breakpoints.down("xs")]: { padding: 0, maxWidth: '100%' },
  },
  bttn: { borderRadius: 25 },
  info: { 
    paddingRight: 15, 
    justifyContent: 'flex-end',
    [theme.breakpoints.down("xs")]: { justifyContent: 'center', marginBottom: 15 },
  },
  item: { marginBottom: 30 },
  icon: { fontSize: 40, color: '#ff6300', marginRight: 5 },
}));

interface IProps {
  closeContactUs: () => void
}

const ContactUs: FC<IProps> = ({closeContactUs}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid className={classes.contact} container justifyContent="center">
      <Grid className={classes.info} item container alignItems="center" sm={6}>
        <Grid item>
          <Grid className={classes.item} container alignItems="center">
            <Phone className={classes.icon} />
            <Grid item>
              <Typography>{t("Número de teléfono")}</Typography>
              <Typography>+13186528096</Typography>
            </Grid>
          </Grid>

          <Grid container alignItems="center">
            <Email className={classes.icon} />
            <Grid item>
              <Typography>{t("E-mail")}</Typography>
              <Typography>olab@fundacionelorigen.org</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container sm={6}>
        <Grid className={classes.form} item>
          <Typography variant="h4" display="block" align="center">
            <b>{t("Contáctanos")}</b>
          </Typography>
          <ContactUsForm />
          <Button className={classes.bttn} variant="contained" onClick={closeContactUs} fullWidth>
            {t("Volver")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ContactUs;
