import { ICertificate } from "dto/certificate.dto";

export const certificateInit: ICertificate = {
  name: "",
  uuid: "",
  templates: [],
  created_by: {},
  entity_nit: "",
  entity_name: "",
  creation_date: 0,
};