export const languaje_UR = {
  translation: {
    Curso:"Course",
    "¡Curso completado!":"Course completed!",
    "¡Unidad terminada!":"Unit finished!",
    "¡Módulo completado!":"Module completed!",
    "Curso no completado":"Course not completed",
    "Unidad no completada":"Unit not completed",
    "Módulo no completado":"Module not completed",
    "Dale continuar para avanzar":"Click continue to advance",
    "Avanza a la siguiente unidad":"Advance to the next unit",
    "Has desbloqueado la siguiente unidad":"You have unlocked the next unit",
    "Ya has completado el curso satisfactoriamente":"You have already completed the course satisfactorily",
    Estudio:"Study",
    Trabajo:"Job",
    Startup:"Startup",
    unidades:"units",
    Posgrado:"Postgraduate",
    Comercial:"Comercial",
    Liderazgo:"Leadership",
    Tecnología:"Technology",
    Freelancer:"Freelancer",
    Empresario:"Businessman",
    Operaciones:"Operations",
    Emprendimiento:"Entrepreneurship",
    "Director / VP":"Director / VP",
    "Lider de equipo":"Team leader",
    "Entidad pública":"Public entity",    
    "Empresa privada":"Private company",
    "Básica primaria":"Elementary school",
    "Recursos humanos":"Human Resources",
    "Básica secundaria":"Basic high school",
    "Miembro de equipo":"Team member",
    "Educación superior":"Higher education",
    "Actualmente, yo...":"Actually me...",
    "Gestión de equipos":"Team management",
    "Conseguir un empleo":"Getting a job",
    "Habilidades blandas":"Soft skills",
    "Gestión de proyectos":"Project management",
    "Conocimiento general":"General knowledge",
    "Escoge tus intereses":"Choose your interests",
    "Desarrollo de carrera":"Career development",
    "Institución educativa":"Educational institution",
    "Responsabilidad social":"Social responsability",
    "Orientación vocacional":"Vocational orientation",
    "¿Qué te describe mejor?":"What describes you best?",
    "Ninguna de las anteriores":"None of the above",
    "Entidad sin ánimo de lucro":"Non-profit entity",
    "Marketing y comunicaciones":"Marketing and communications",
    "¿A qué área laboral perteneces?":"What area of work do you belong to?",
    "¿Cuál es tu nivel de educación?":"What is your level of education?",
    "¿Cuál es tu fecha de nacimiento?":"What is your birth date?",
    "Estoy buscando estudiar o trabajar":"I am looking to study or work",
    "¿En qué tipo de organización trabajas?":"What type of organization do you work for?",
    "¿Cuántos integrantes tiene tu organización?":"How many members does your organization have?",
    
    Idioma:"Language",
    Ingles:"English",
    Español:"Spanish",
    "¡Hola!":"Hello!",
    "¡Todo listo!":"All ready!",
    "Súper administrador":"Super admin",
    "¡Bienvenido a O-lab!":"Welcome to O-lab!",
    "¡Ya casi terminamos!":"We are almost done!",
    "Crea tu perfil ahora":"Create your profile now",
    "¿Salir del laboratorio?":"Get out of the lab?",
    "¿Tienes un código organizacional?":"Do you have an organizational code?",
    "Configura el idioma de esta plataforma":"Set the language of this platform",
    "Si te sales del laboratorio perderás el progreso guardado hasta el momento":"If you leave the laboratory you will lose the progress saved so far",
    "Te recomendamos crear una nueva cuenta para tener tu propio perfil, además de guardar el progreso de tus contenidos, compartir en la comunidad, chatear y mucho más":"We recommend that you create a new account to have your own profile, as well as save the progress of your content, share in the community, chat and much more",
    
    Hola:"Hello",
    Omitir:"Skip",
    Explora:"Explore",
    Comenzar:"Start",
    "Ver curso":"See course",
    "Todo listo":"All ready",
    "Busca labs":"Search labs",
    "¡Empecemos!":"Let us begin!",
    "Elige un país":"Choose a country",
    "Elige tu país":"Choose your country",
    "Ver formulario":"See form",
    "Solicitar demo":"Request demo",
    "Nombre completo":"Full name",
    "Respuesta única":"Single answer",
    "Crea una cuenta":"Create an account",
    "Elige tu género":"Choose your gender",
    "Ya tengo cuenta":"I already have an account",
    "Crear cuenta con":"Create account with",
    "Llena los campos":"Fill in the fields",
    "Ingresa tu correo":"Enter your email",
    "Ingresa tu nombre":"Enter your name",
    "Antes de comenzar":"Before starting",
    "Bienvenido a O-lab":"Welcome to O-lab",
    "Completa tus datos":"Complete your data",
    "Múltiple respuesta":"Multiple answer",
    "Ya casi terminamos":"We are almost done",
    "Edad minima 7 años":"Minimum age 7 years",
    "Mínimo 6 caracteres":"Min 6 characters",
    "Repite tu contraseña":"Repeat your password",
    "Prefiero no responder":"Prefer not to answer",
    "Saltar, no tengo código":"Skip, I have no code",
    "Unirme a mi organización":"Join my organization",
    "Fecha de nacimiento incorrecta":"Wrong date of birth",
    "Labs libres ofrecidos por O-lab":"Free labs offered by O-lab",
    "Tienes un código organizacional":"Do you have an organizational code",
    "El código ingresado no es válido":"The code entered is invalid",
    "Ahora haces parte de la organización":"Now you are part of the organization",
    "Necesitamos saber un poco más sobre ti":"We need to know a little more about you",
    "Personaliza O-lab para tu organización":"Customize O-lab for your organization",
    "Todavía no perteneces a una organización":"You don't belong to an organization yet",
    "ya tienes acceso a su contenido y comunidad en O-lab":"you already have access to its content and community at O-lab",
    "Lleva tus entrenamientos, inducciones, comunicaciones y más al siguiente nivel":"Take your trainings, inductions, communications and more to the next level",
    "Si todavía no tienes el código organizacional, después lo puedes ingresar desde tu perfil":"If you still do not have the organizational code, you can enter it later from your profile",
    "Responde este corto cuestionario para poder empezar a explorar el contenido gratutito ofrecido por O-lab":"Take this short quiz to start exploring the free content offered by O-lab",
    "Si tu organización te compartió un código de O-Lab ingrésalo en el siguiente campo, de lo contrario salta este paso":"If your organization shared an O-Lab code with you, enter it in the next field, otherwise skip this step",
    
    o:"or",
    País:"Country",
    Falso:"False",
    Ninguno:"None",
    Primaria:"Primary",
    Verdadero:"True",
    Finalizar:"Finalize",
    Incorrecta:"Incorrect",
    Formulario: "Form",
    Secundaria:"High school",
    "Idioma urdu":"Urdu language",
    "Cargar audio":"Upload sound",
    "Grabar audio":"Record audio",
    "Continúa asi":"Keep it up",
    "Configuración":"Settings",
    "Editar género":"Edit gender",
    "Cargar imagen":"Upload image",
    "¡Felicidades!": "Congratulations!",
    "Idioma inglés":"English language",
    "Idioma tagalo":"Tagalog language",
    "Idioma español":"Spanish language",
    "Idioma francés":"French language",
    "Felicitaciones":"Congratulations",
    "Notificaciones":"Notifications",
    "Opción múltiple":"Multiple choice",
    "No te descuides":"Don't neglect",
    "Nueva contraseña":"New password",
    "Verdadero y falso":"True and false",
    "Guardar grabación":"Save recording",
    "Detener grabación":"Stop recording",
    "Iniciar grabación":"Start recording",
    "Respuesta Correcta":"Correct Answer",
    "Seleccionar idioma":"Select language",
    "Fecha de nacimiento":"Birthdate",
    "Vuelve a intentarlo":"Try again",
    "Respuesta Incorrecta":"Incorrect Answer",
    "Información personal":"Personal information",
    "Salir de la grabación":"Exit recording",
    "¡Bienvenido de nuevo!":"Welcome back!",
    "Terminaste esta encuesta":"You finished this survey",
    "Iniciar sesión con email":"Sign in with email",
    "Haga clic para regístrate":"Click to sign up",
    "Haga clic para ingresar a":"Click to enter",
    "Editar fecha de nacimiento":"Edit date of birth",
    "Confirmar nueva contraseña":"Confirm new password",
    "Iniciar sesión con teléfono":"Sign in with phone",
    "Encuentra todas las palabras":"Find all the words",
    "Seleccionar verdadero o falso":"Select true or false",
    "Haga clic para iniciar sesión":"Click to login",
    "Escribe tu contraseña de acceso":"Write your access password",
    "Seleccione la respuesta correcta":"Select the correct answer",
    "Haga clic para ingresar al tutorial":"Click to enter the tutorial",
    "Haga clic para volver al menú anterior":"Click to return to the previous menu",
    "Haga clic para seleccionar la respuesta":"Click to select answer",
    "Escribe tu correo electrónico de acceso":"Write your access email",
    "Haga clic para restablecer la contraseña":"Click to reset password",
    "Haga clic para iniciar sesión con el email":"Click to login with email",
    "Haga clic para ingresar a la vista de blog":"Click to enter blog view",
    "Haga clic para ingresar a la vista de notas":"Click to enter note view",
    "Haga clic para regresar al listado de módulo":"Click to return to module listing",
    "Haga clic para ingresar al buzón de mensajes":"Click to enter the mailbox",
    "Haga clic para iniciar sesión con el teléfono":"Click to login with phone",
    "Haga clic para regresar a la lección anterior":"Click to return to the previous lesson",
    "Haga clic para regresar a la pregunta anterior":"Click to return to the previous question",
    "Haga clic para continuar a la lección siguiente":"Click to continue to the next lesson",
    "Haga clic para reproducir el audio de la lección":"Click to play the audio of the lesson",
    "Haga clic para continuar a la pregunta siguiente":"Click to continue to the next question",
    "Haga clic para reproducir el audio de la pregunta":"Click to play the audio of the question",
    "Haga clic para regresar al listado de laboratorios":"Click to return to the list of laboratories",
    
    "Cargar archivo":"Upload file",
    "Guardar y salir":"Save and exit",
    "Educación continua":"Continuing education",
    "Aceleración escolar":"School acceleration",
    "Repetir formulario":"Repeat form",
    "Formulario privado":"Private form",
    "Finalizar formulario":"Finish form",
    "Página no encontrada":"Page not Found",
    "Educación vocacional":"Vocational education",
    "Formulario no encontrado":"Form not found",
    "Educación complementaria":"Complementary education",
    "No tienes laboratorios habilitados":"You do not have enabled laboratories",
    "El formulario es privado, verifique el acceso":"The form is private, check access",
    "Debes finalizar primero los módulos anteriores":"You must finish the previous modules first",
    "El formulario solicitado no existe, verifique el acceso":"The requested form does not exist, check access",
    "Lo sentimos, no se pudo encontrar la página que solicitó, vuelva a la página de inicio":"we' re sorry the page you requested could not be found please go back to the homepage",

    "Ante cualquier duda puedes contactar a tu docente":"If you have any questions, you can contact your teacher",
    "¡Hola! Te damos la bienvenida a la versión web de la aplicación O-lab":"Hi there! We welcome you to the web version of the O-lab application",
    "Los laboratorios son tu espacio de trabajo en los que realizarás actividades":"The laboratories are your workspace where you will carry out activities",
    "Aquí podrás ver la sección en la que te encuentras dentro de la aplicación web":"Here you can see the section in which you are within the web application",
    "Los módulos son secciones en las que ingresarás para aprender y realizar actividades":"Modules are sections that you will enter to learn and perform activities",
    "En este espacio encontrarás material de estudio que está dividido por unidades, lecciones y actividades":"In this space you will find study material that is divided by units, lessons and activities",
    "En esta sección puedes cambiar tus datos personales, escoger una foto de perfil y revisar el progreso de tus cursos":"In this section you can change your personal data, choose a profile photo and check the progress of your courses",
    "Este es un espacio público en el que encontraras material de estudio realizado y compartido por los estudiantes de O-Lab":"This is a public space where you will find study material made and shared by O-Lab students",
    "En esta sección se registran todo tipo de apuntes que quieras guardar, pueden ser posteriormente publicadas en el apartado de blog si así lo desea":"In this section all kinds of notes that you want to save are recorded, they can be later published in the blog section if you wish",

    //---------------------------------------------------------------------------------------------//
    
    "a"             :"کو",
    "El"            :"دی",
    "OK"            :"ٹھیک ہے",
    "Yo"            :"میں",
    "ID"            :"آئی ڈی",
    "año"           :"سال",
    "Año"           :"سال",
    "mes"           :"مہینہ",
    "Mes"           :"مہینہ",
    "Ver"           :"دیکھیں",
    "Reto"          :"چیلنج",
    "Para"          :"کے لیے۔ ",
    "Edad"          :"عمر۔",
    "Sexo"          :"سیکس۔",
    "Otro"          :"دیگر",
    "Mayo"          :"مئی",
    "Blog"          :"بلاگ",
    "años"          :"سال",
    "Nota"          :"نوٹ",
    "Otros"         :"دوسرے",
    "Dudas"         :"شک",
    "Donar"         :"عطیہ کریں۔",
    "Pesos"         :"پیسوس",
    "Notas"         :"نوٹس",
    "Buzón"         :"میل باکس",
    "Nuevo"         :"نئی",
    "Salir"         :"باہر نکل جاو",
    "Grado"         :"ڈگری",
    "Retos"         :"چیلنجز۔",
    "Enero"         :"جنوری۔",
    "Marzo"         :"مارچ۔",
    "Abril"         :"اپریل۔",
    "Junio"         :"جون",
    "Julio"         :"جولائی۔",
    "Nombre"        :"نام",
    "Buscar"        :"تلاش کریں۔",
    "Asunto"        :"مضمون",
    "Cerrar"        :"بہت کریب",
    "Unidad"        :"یونٹ",
    "Módulo"        :"ماڈیول",
    "Enviar"        :"بھیجیں",
    "Editar"        :"ترمیم",
    "Volver"        :"واپسی۔",
    "Agosto"        :"اگست۔",
    "Género"        :"صنف",
    "Título"        :"اہلیت",
    "Perfil"        :"پروفائل",
    "Inicio"        :"آغاز",
    "Cursos"        :"کورسز",
    "E-mail"        :"ای میل۔",
    "Dólares"       :"ڈالرز",
    "Mensual"       :"ماہانہ",
    "Mensaje"       :"پیغام",
    "Aceptar"       :"قبول کریں۔",
    "Público"       :"عوام",
    "Privado"       :"نجی",
    "Lección"       :"سبق",
    "Repetir"       :"دہرائیں",
    "Docente"       :"استاد",
    "Guardar"       :"رکھیں۔",
    "Módulos"       :"ماڈیولز",
    "Febrero"       :"فروری۔",
    "Octubre"       :"اکتوبر۔",
    "Cancelar"      :"منسوخ کریں",
    "Unidades"      :"یونٹس",
    "Explicar"      :"وضاحت کریں۔",
    "Enviados"      :"بھیجا۔",
    "Lenguaje"      :"زبان",
    "Teléfono"      :"ٹیلی فون۔",
    "Femenino"      :"نسائی۔",
    "Eliminar"      :"دور",
    "Reenviar"      :"دوبارہ بھیجیں",
    "Mensajes"      :"پیغامات",
    "Tutorial"      :"ٹیوٹوریل",
    "Licencia"      :"لائسنس",
    "Usuarios"      :"صارفین",
    "usuarios"      :"صارفین",
    "Respuesta"     :"جواب",
    "Comunidad"     :"برادری",
    "Noviembre"     :"نومبر۔",
    "Diciembre"     :"دسمبر۔",
    "Recibidos"     :"موصول ہوا۔",
    "Responder"     :"جواب",
    "Compartir"     :"بانٹیں",
    "Continuar"     :"جاری رہے",
    "Masculino"     :"مرد",
    "Siguiente"     :"اگلے",
    "finalizar"     :"حتمی شکل دینا",
    "Resultados"    :"نتائج",
    "Septiembre"    :"ستمبر",
    "Regístrate"    :"سائن اپ",
    "Bienvenido"    :"خوش آمدید",
    "Materiales"    :"مواد",
    "Contraseña"    :"پاس ورڈ",
    "Destacados"    :"جھلکیاں۔",
    "Actualizar"    :"اپ ڈیٹ کرنے کے لیے۔",
    "Estudiante"    :"طالب علم",
    "Respuestas"    :"جوابات",
    "Laboratorio"   :"لیبارٹری۔",
    "Descripción"   :"تفصیل",
    "Institución"   :"ادارہ",
    "Felicidades"   :"مبارک ہو",
    "Actividades"   :"سرگرمیاں",
    "Comentarios"   :"تبصرے",
    "Contáctanos"   :"ہم سے رابطہ کریں۔",
    "Laboratorios"  :"لیبارٹریز۔",
    "Calificación"  :"قابلیت",
    "Nacionalidad"  :"قومیت",
    "Organización"  :"تنظیم",
    "Administrador" :"مینیجر",
    "Almacenamiento":"ذخیرہ",

    "Ver todos"               :"تمام دیکھیں",
    "Ver todas"               :"تمام دیکھیں",
    "Mis blogs"               :"میرے بلاگز۔",
    "Mis notas"               :"میرے نوٹ۔",
    "y nuestra"               :"اور ہمارا",
    "Enviar nota"             :"نوٹ بھیجیں۔",
    "Crear cuenta"            :"اکاؤنٹ بنائیں۔",
    "Guardar nota"            :"نوٹ محفوظ کریں۔",
    "Bienvenido a"            :"میں خوش آمدید",
    "Ver unidades"            :"یونٹس دیکھیں",
    "Cerrar sesión"           :"سائن آف۔",
    "Publicado por"           :"کی طرف سے شائع",
    "Mensaje nuevo"           :"نیا پیغام",
    "Primera lengua"          :"مادری زبان",
    "Segunda lengua"          :"دوسری زبان",
    "Iniciar sesión"          :"لاگ ان کریں",
    "Enviar mensaje"          :"پیغام بھیجیں",
    "Blog principal"          :"مین بلاگ۔",
    "Compartir nota"          :"نوٹ شیئر کریں۔",
    "Regístrate con"          :"کے ساتھ سائن اپ کریں۔",
    "Campo requerido"         :"مطلوب خانہ",
    "Subir evidencia"         :"ثبوت اپ لوڈ کریں۔",
    "Actualizar nota"         :"نوٹ اپ ڈیٹ کریں۔",
    "Única respuesta"         :"صرف جواب دیں۔",
    "del laboratorio"         :"آپ نے لیب کا",
    "Respuesta libre"         :"مفت جواب",
    "RESPUESTA LIBRE"         :"مفت جواب",
    "Guardar cambios"         :"تبدیلیاں محفوظ کرو",
    "Verificar código"        :"کوڈ چیک کریں۔",
    "Enviar respuesta"        :"جواب دیں",
    "Siguiente noticia"       :"اگلی خبر۔",
    "Actualizar Perfil"       :"پروفائل اپ ڈیٹ کریں۔",
    "Has completado un"       :"مکمل کر لیا ہے۔",
    "Cambiar contraseña"      :"پاس ورڈ تبدیل کریں",
    "Correo electrónico"      :"ای میل۔",
    "Perfil actualizado"      :"اپ ڈیٹ شدہ پروفائل",
    "Contraseña invalida"     :"غلط پاسورڈ",
    "Personalizar avatar"     :"اوتار کو حسب ضرورت بنائیں۔",
    "Objetivos pedagógicos"   :"تدریسی مقاصد",
    "Restablecer contraseña"  :"پاس ورڈ بحال کریں۔",
    "Ciudad/País"             :"شہر ملک",
    "Comprar ahora"           :"ابھی خریدیں",
    "Aplicación web"          :"ویب ایپلیکیشن",
    "Plan grande"             :"بڑا منصوبہ",
    "Donación segura"         :"محفوظ عطیہ",
    "Plan mediano"            :"چھوٹا منصوبہ",
    "Plan pequeño"            :"درمیانی منصوبہ",
    "Usuarios finales"        :"آخر صارفین",
    "usuarios finales"        :"اصل استعمال کنندہs",
    "de almacenamiento"       :"ذخیرہ",
    "Completar registro"      :"مکمل رجسٹریشن",
    "Confirmar contraseña"    :"پاس ورڈ کی تصدیق کریں۔",
    "Servicios adicionales"   :"اضافی خدمات",
    "Asistencia pedagógica"   :"تدریسی معاونت",
    "Plan personalizado"      :"ذاتی منصوبہ",
    "Cantidad personalizada"  :"حسب ضرورت رقم",
    "Usuarios administradores":"ایڈمن صارفین",
    "Voluntariado corporativo":"کارپوریٹ رضاکارانہ",
    "usuarios administradores":"منتظم صارفین",

    "Sopa de letras"                  :"حروف تہجی کا سوپ۔",
    "Nombre de unidad"                :"یونٹ کا نام",
    "Código del curso"                :"کورس کوڈ۔",
    "Título de la nota"               :"نوٹ عنوان۔",
    "Nombre y apellido"               :"نام اور خاندانی نام",
    "Número de teléfono"              :"فون نمبر",
    "Iconos de posición"              :"پوزیشن شبیہیں",
    "Iniciar sesión con"              :"کے ساتھ لاگ ان",
    "Completa la oración"             :"جملہ مکمل کریں",
    "Compartir como blog"             :"بلاگ کے طور پر شیئر کریں۔",
    "Términos de servicio"            :"سروس کی شرائط",
    "Escribe tu respuesta"            :"اپنا جواب لکھیں۔",
    "Máximo 50 carácteres"            :"زیادہ سے زیادہ 50 حروف۔",
    "Código de verificación"          :"تصدیقی کوڈ",
    "Política de privacidad"          :"رازداری کی پالیسی",
    "Encuentra las palabras"          :"الفاظ تلاش کریں۔",
    "Seleccionar una imagen"          :"ایک تصویر منتخب کریں۔",
    "Dar una vez"                     :"ایک بار دیں۔",
    "Ver en línea"                    :"آن لائن دیکھئے",
    "Escribe aquí"                    :"یہاں لکھو",
    "Validar edad"                    :"عمر کی توثیق کریں۔",
    "Sin realizar"                    :"کالعدم",
    "Nuevo mensaje"                   :"نیا پیغام",
    "Doblaje de voz"                  :"وائس اوور",
    "Ver resultados"                  :"نتائج دیکھیں",
    "Progreso total"                  :"مجموعی ترقی",
    "Buzón de mensajes"               :"انباکس",
    "Buscar por nombre"               :"نام سے تلاش کریں۔",
    "Número de tarjeta"               :"کارڈ نمبر",
    "No hay actividades"              :"کوئی سرگرمیاں نہیں۔",
    "Compra de licencia"              :"لائسنس کی خریداری",
    "Nombre del titular"              :"مالک کا نام",
    "Editar laboratorio"              :"لیب میں ترمیم کریں۔",
    "Diseño de contenidos"            :"مواد ڈیزائن",
    "Completa tu registro"            :"اپنی رجسٹریشن مکمل کریں۔",
    "Agrega tu información"           :"اپنی معلومات شامل کریں۔",
    "Nombre de organización"          :"تنظیم کا نام",
    "Descargar certificado"           :"سرٹیفکیٹ ڈاؤن لوڈ کریں۔",
    "Estudiantes registrados"         :"رجسٹرڈ طلباء",
    "Comprar/renovar licencia"        :"لائسنس خریدیں/تجدید کریں۔",
    "Condiciones del servicio"        :"سروس کی شرائط۔",
    "Nota enviada exitosamente"       :"نوٹ کامیابی کے ساتھ بھیجا گیا۔",
    "Nota guardada exitosamente"      :"نوٹ کامیابی سے محفوظ ہو گیا۔",
    "Nota eliminada exitosamente"     :"نوٹ کامیابی سے ہٹا دیا گیا۔",
    "Correo enviado exitosamente"     :"میل کامیابی کے ساتھ بھیجا گیا۔",
    "Organización/Escuela/Empresa"    :"تنظیم/اسکول/کمپنی",
    "Nota compartida exitosamente"    :"نوٹ کامیابی کے ساتھ شیئر کیا گیا۔",
    "Verificar correo electrónico"    :"ای میل کی تصدیق کریں۔",
    "Nota actualizada exitosamente"   :"نوٹ کامیابی سے اپ ڈیٹ ہوا۔",
    "Soporte técnico virtual"         :"ورچوئل تکنیکی مدد",
    "Plataforma web personalizable"   :"مرضی کے مطابق ویب پلیٹ فارم",
    "Aplicación móvil personalizable" :"مرضی کے مطابق موبائل ایپ",
    "Servicios adicionales (opcional)":"اضافی خدمات (اختیاری)",
    
    "Enviar a un amigo"                           :"کسی دوست کو بھیجیں۔",
    "Guardar y enviar nota"                       :"محفوظ کریں اور نوٹ بھیجیں۔",
    "Validar y guardar edad"                      :"عمر کی توثیق کریں اور بچائیں۔",
    "Acceder con el teléfono"                     :"فون کے ساتھ رسائی۔",
    "Has completado el módulo"                    :"آپ نے ماڈیول مکمل کر لیا ہے۔",
    "Has completado la unidad"                    :"آپ نے یونٹ مکمل کر لیا ہے۔",
    "El módulo no tiene retos"                    :"ماڈیول میں کوئی چیلنج نہیں ہے۔",
    "Configuración de tu cuenta"                  :"اپنا اکاؤنٹ ترتیب دے رہا ہے۔",
    "Envía un mensaje o llama a"                  :"ایک پیغام یا کال بھیجیں۔",
    "Regístrate con tu teléfono"                  :"اپنے فون سے سائن اپ کریں۔",
    "La edad debe ser mayor a 0"                  : "عمر 0 سے زیادہ ہونی چاہیے۔",
    "Escribe aquí tu comentario"                  :"اپنا تبصرہ یہاں لکھیں۔",
    "Verificar número de teléfono"                :"فون نمبر کی تصدیق کریں۔",
    "El usuario ya está registrado"               :"صارف پہلے ہی رجسٹرڈ ہے۔",
    "El usuario no está registrado"               :"صارف رجسٹرڈ نہیں ہے۔",
    "Ingresa tu número de teléfono"               :"اپنا فون نمبر درج کریں۔",
    "Envia un correo electrónico a"               :"کو ای میل بھیجیں۔",
    "Arrastra la respuesta correcta"              :"صحیح جواب گھسیٹیں۔",
    "Las contraseñas deben coincidir"             :"پاس ورڈز مماثل ہونا چاہیے",
    "El laboratorio no tiene módulos"             :"لیب میں کوئی ماڈیول نہیں ہے۔",
    "Selecciona la respuesta correcta"            :"صحیح جواب منتخب کریں۔",
    "Acceder con el correo electrónico"           :"ای میل کے ساتھ رسائی۔",
    "No estás habilitado para ingresar"           :"آپ داخل ہونے کے قابل نہیں ہیں۔",
    "¿Deseas comunicarte con un tutor?"           :"کیا آپ کسی استاد کے ساتھ بات چیت کرنا چاہتے ہیں؟",
    "Existe un usuario con ese teléfono"          :"اس فون کے ساتھ ایک صارف ہے۔",
    "Regístrate con tu correo electrónico"        :"اپنے ای میل کے ساتھ سائن اپ کریں۔",
    "Nota guardada y enviada exitosamente"        :"نوٹ محفوظ ہو گیا اور کامیابی کے ساتھ بھیجا گیا۔ ",
    "para que puedas cambiar tu contraseña"       :"تاکہ آپ اپنا پاس ورڈ تبدیل کر سکیں۔",
    "La unidad siguiente no tiene lecciones"      :"اگلے یونٹ میں کوئی سبق نہیں ہے۔",
    "El código de verificación no es válido"      :"توثیقی کوڈ غلط ہے۔",
    "¡Lo hacemos por ti!"                         :"ہم آپ کے لئے کرتے ہیں!",
    "por un período de un"                        :"ایک مدت کے لیے",
    "se realizó con éxito"                        :"کامیابی سے کیا گیا تھا",
    "¡Gracias por tu ayuda!"                      :"آپ کی مدد کے لئے شکریہ!",
    "La donación fue exitosa"                     :"عطیہ کامیاب رہا۔",
    "Elige cómo quieres ayudar"                   :"منتخب کریں کہ آپ کس طرح مدد کرنا چاہتے ہیں۔",
    "Gracias por tu donación de"                  :"آپ کے عطیہ کے لیے آپ کا شکریہ",
    "¡Cerremos la brecha juntos!"                 :"آئیے مل کر خلا کو پر کریں!",
    "El valor de la donación es $0"               :"عطیہ کی قیمت $0 ہے۔",
    "Reportes de impacto específicos"             :"مخصوص اثرات کی رپورٹس",
    "Sistema de gestión de proyectos"             :"پروجیکٹ مینجمنٹ سسٹم",
    "Asistencia técnica y de soporte"             :"تکنیکی مدد اور مدد",
    "Análisis y reportes automáticos"             :"خودکار تجزیہ اور رپورٹس",
    "Mensajes de audio personalizables"           :"حسب ضرورت آڈیو پیغامات",
    "Plataforma de gestión de contenido"          :"مواد کے انتظام کا پلیٹ فارم",
    "Diseño y animación de avatares y más"        :"متحرک تصاویر، عکاسی اور مزید",
    "Funciones de seguimiento y evaluación"       :"نگرانی اور تشخیص کی خصوصیات",
    "Sistema de gestión de aprendizaje (LMS)"     :"لرننگ مینجمنٹ سسٹم (LMS)",
    "Asistencia en coordinación de proyectos"     :"پیشہ ورانہ پروجیکٹ مدد",
    "Iniciar sesión con tu correo electrónico"    :"اپنے ای میل کے ساتھ سائن ان کریں۔",
    "Existe un usuario con esa identificación"    :"اس شناخت کے ساتھ ایک صارف ہے۔",
    "Institución educativa o comunidad afiliada"  :"تعلیمی ادارہ یا اس سے وابستہ کمیونٹی۔",
    "Existe un usuario con ese correo electrónico":"اس ای میل کے ساتھ ایک صارف ہے۔",
    
    "Al continuar, estás indicando que aceptas nuestros":"جاری رکھ کر ، آپ اشارہ کر رہے ہیں کہ آپ ہماری بات کو قبول کرتے ہیں۔",
    "Puedes acceder y ver los contenidos que se ofrecen":"آپ پیش کردہ مواد تک رسائی اور دیکھ سکتے ہیں۔",
    "Ingresa el código de 6 digitos que enviamos al numero":"6 ہندسوں کا کوڈ درج کریں جو ہم نے نمبر پر بھیجا ہے۔",
    "de estudiantes registrados ha terminado el laboratorio":"رجسٹرڈ طلباء میں سے لیب ختم کر چکے ہیں۔",
    "Se enviará un mensaje con un link al correo electrónico":"ای میل کے لنک کے ساتھ ایک پیغام بھیجا جائے گا۔",
    "Debes ser mayor de edad para poder acceder a esta sección":"اس سیکشن تک رسائی کے لیے آپ کا قانونی عمر ہونا ضروری ہے۔",
    "Mensaje enviado exitosamente, revisa tu correo electrónico":"پیغام کامیابی کے ساتھ بھیجا گیا ، اپنا ای میل چیک کریں۔",
    "Correo electrónico ya está siendo utilizada por otra cuenta":"ای میل پہلے ہی کسی دوسرے اکاؤنٹ کے ذریعے استعمال ہو رہا ہے۔",
    "Acceso desactivado temporalmente debido a muchos intentos fallidos":"بہت سی ناکام کوششوں کی وجہ سے رسائی عارضی طور پر غیر فعال ہے۔",
    "¡Te mantendremos informado sobre el impacto de tu donación! #somoselorigen":"ہم آپ کو آپ کے عطیہ کے اثرات سے باخبر رکھیں گے! #wearetheorigin",
    "Estos mensajes son monitoreados para garantizar la seguridad de nuestros usuarios":"ہمارے صارفین کی حفاظت کو یقینی بنانے کے لیے ان پیغامات کی نگرانی کی جاتی ہے۔",
    "Esta sección solo esta habilitada para mayores de edad, por favor valida tu edad para continuar":"یہ سیکشن صرف بالغوں کے لیے فعال ہے ، براہ کرم جاری رکھنے کے لیے اپنی عمر کی توثیق کریں۔",
    "Con o-lab es posible personalizar los requerimientos de todas las instituciones, entidades educativas u organizaciones. Con todas las características y funciones que tus beneficiarios o educadores necesitan para presentar todos los temas, conferencias, cursos y más.":"او-لیب کے ذریعے تمام اداروں، تعلیمی اداروں یا تنظیموں کی ضروریات کو اپنی مرضی کے مطابق بنانا ممکن ہے۔ ان تمام خصوصیات اور افعال کے ساتھ جن کی آپ کے گرانٹیز یا اساتذہ کو تمام موضوعات، کانفرنسز، کورسز اور مزید پیش کرنے کی ضرورت ہے۔",
    "El 100% de cada donación va directamente a una persona/escuela/comunidad específica que espera nuestro kit #educacióndigitalparatodos que los equipa con tecnología, capacitación a profesores y nuestro LMS y aplicación de aprendizaje fuera de línea O-lab lleno de cursos complementarios, vocacionales y STEAM creados en colaboración con maestros e instituciones locales en todo el mundo.":"ہر عطیہ کا 100% براہِ راست ایک مخصوص فرد/اسکول/کمیونٹی کو جاتا ہے جو ہماری #digitaleducationforall کٹ کا انتظار کر رہا ہے جو انہیں ٹیکنالوجی، تربیت اور ہماری LMS اور آف لائن لرننگ ایپ O-lab کے ساتھ مل کر تیار کردہ تکمیلی، پیشہ ورانہ اور STEAM کورسز سے لیس کرتی ہے۔ دنیا بھر میں مقامی اساتذہ اور ادارے۔",
    "Si presionas Verificar número de teléfono se enviará un SMS. Se aplicarán las tarifas de mensaje y datos":"اگر آپ فون نمبر کی تصدیق کریں دبائیں گے تو ایک ایس ایم ایس بھیجا جائے گا۔ پیغام اور ڈیٹا کی شرحیں لاگو ہوں گی۔",
    "Se enviará un mensaje a la dirección de correo electrónico que has ingresado para que puedas cambiar tu contraseña.":"آپ کے داخل کردہ ای میل پتے پر ایک پیغام بھیجا جائے گا تاکہ آپ اپنا پاس ورڈ تبدیل کر سکیں۔",
  }
};

export default languaje_UR;