import Content from "services/content.services";
import { IUser } from "dto/user.dto";
import { IContent } from "dto/content.dto";
import { IChallenger } from "dto/challenger.dto";
import { ILaboratory } from "dto/laboratory.dto";
import { contentInit } from "initials/content.init";
import { TypeUserAnonymous } from "dto/user-anonymous.dto";
import * as utils from "utils/laboratory.utils";

export const getContents = async (user: IUser) => {
  try {
    const { surveys, laboratories } = await Content.get(user?.nit);
    const labs = await utils.getLaboratories(laboratories, user);
    const contents: IContent[] = [...labs, ...surveys];
    return contents;
  } catch(e) { 
    console.log("Error Utils Get Contents: ", e);
    return [];
  }
}

export const filterContents = (contentsdb: IContent[], text: string) => {
  if (!!text) {
    const contentsdbAux = contentsdb.filter((t) => {
      const content = t ?? contentInit;
      const { name, displayName } = content;
      const search = text?.normalize("NFD")?.replace(/[\u0300-\u036f]/g, "")?.toLowerCase();

      if (!!displayName) {
        return displayName
          ?.normalize("NFD")
          ?.replace(/[\u0300-\u036f]/g, "")
          ?.toLowerCase()
          ?.includes(search);
      } else {
        return name
          ?.normalize("NFD")
          ?.replace(/[\u0300-\u036f]/g, "")
          ?.toLowerCase()
          ?.includes(search);
      }
    });
    return contentsdbAux;
  } else return contentsdb;
};

export const getContentsExplore = (userType: TypeUserAnonymous, data: IContent[]) => {
  const contents = data.filter(
    (c) => getContentsBytags(c.tags, userType)
  );
  return contents ?? [];
}

export const getDisplayName = (content: IChallenger | ILaboratory) => {
  return !!content?.displayName 
    ? content.displayName 
    : !!content?.name 
    ? content?.name 
    : "";
}

const getContentsBytags = (tags: string[], tag: string) => {
  const content = tags.filter((t) => (t === tag));
  return !!content?.length;
}
