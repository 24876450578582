import React, { FC } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import Courses      from './Courses';
import Questions    from './Questions';
import Description  from './Description';
import Information  from './Information';
import Presentation from './Presentation';
import { IUser } from "dto/user.dto";


const useStyles = makeStyles(() => ({
  landing: {
    position: 'relative',
    maxHeight: 'calc(100% - 60px)',
    height: 'fit-content', 
    overflow: 'auto',
    marginTop: 60,
  },
}));

interface IProps {
  user: IUser
}

const Landing: FC<IProps> = ({user}) => {
  const classes  = useStyles();

  return (
    <Grid className={classes.landing} container>
      <Presentation user={user} />
      <Courses />
      <Questions />
      <Description />
      <Information />
    </Grid>
  )
}

export default Landing;
