import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ISurvey } from "dto/survey.dto";
import { ILaboratory } from "dto/laboratory.dto";
import { State } from "store/types";
import { history } from "configureStore";
import * as actions from "store/actions";
import PageLayout from "components/Pages/PageLayout";
import HomeCarousel from "components/Home/HomeCarousel";
import NotPermissions from "components/Home/NotPermissions";
import WithoutSponsor from "components/Home/WithoutSponsor";
import { messageError } from "components/Common/Toast";

const Home = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector((state: State) => state.user.user);
  const fbauth = useSelector((state: State) => state.firebase.auth);
  const entity = useSelector((s: State) => s.entity.entity);
  const contents = useSelector((state: State) => state.content.contents);
  const contentsLoading = useSelector((state: State) => state.content.contentsLoading);
  const anonymousUser = localStorage.getItem("anonymousUser");
  const userAnonymous = JSON.parse(anonymousUser ?? "{}");
  const auth = !!fbauth?.isEmpty && !!fbauth?.isLoaded && !userAnonymous?.uuid;
  const test = 
    !!fbauth?.isEmpty
    && !!fbauth?.isLoaded 
    && !!userAnonymous?.uuid 
    && !userAnonymous?.userType;
  const tasks = user?.tasks ?? [];

  useEffect(() => { 
    dispatch(actions.deselectModule());
    dispatch(actions.selectBackground(""));
    dispatch(actions.deselectLaboratory());
    dispatch(actions.selectOrganizationImage(""));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  useEffect(() => { 
    if (!!auth) history.push("/");
    else if (!!test) history.push("/anonymous-user");
  }, [auth, test]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { getContents(); }, [user?.nit, user?.permissions]);

  const getContents = () => {
    if (
      !!user?.nit &&
      (!!user?.permissions?.length || !!user?.permissions_survey?.length) &&
      !contents.length
    ) {
      dispatch(actions.getContents()); 
    }
  }

  const selectSurvey = (survey: ISurvey) => {
    dispatch(actions.setSurveyCurrent(survey));
    history.push(`/survey/${survey.uuid}`);
  }

  const selectContent = (content: ISurvey | ILaboratory) => {
    if (content.type === "survey") selectSurvey(content);
    else selectLaboratory(content);
  }

  const selectLaboratory = (laboratory: ILaboratory) => {
    if (!!laboratory?.modules?.length) {
      dispatch(actions.selectLaboratory(laboratory));
      history.push(`/laboratory/${laboratory.key}`);
    } else messageError(t("El laboratorio no tiene módulos"));
  }

  const generateLaboratoryCertificate = (laboratory: ILaboratory) => {
    dispatch(actions.generateLaboratoryCertificate(laboratory));
  }

  return (
    <PageLayout 
      loading={contentsLoading && !!user?.sponsor}
      backgroundGif={!!user?.sponsor}
      backgroundImage={entity?.background_image_url}
      hideButtonLanguage={!!user?.nit}
    >
      {!user?.sponsor ? (
        <WithoutSponsor />
      ) : !!contents?.length ? (
        <HomeCarousel
          user={user}
          tasks={tasks} 
          contents={contents}
          entityName={entity?.name}
          selectSurvey={selectSurvey}
          selectContent={selectContent}
          generateLaboratoryCertificate={generateLaboratoryCertificate}
        />
      ) : (
        <NotPermissions />
      )}
    </PageLayout>
  );
};

export default Home;
