export const USER = "[USER] USER";
export const USERS = "[USER] USER_LIST";
export const USER_LOADING = "[USER] USER_LOADING";
export const NOTE_LOADING = "[USER] NOTE_LOADING";
export const TASK_LOADING = "[USER] TASK_LOADING";
export const USERS_LOADING = "[USER] USERS_LOADING";
export const PHOTO_LOADING = "[USER] PHOTO_LOADING";
export const USER_IS_LOADED = "[USER] USER_IS_LOADED";
export const LANGUAGE_AUDIO = "[USER] LANGUAGE_AUDIO";
export const PROFILE_LOADING = "[USER] PROFILE_LOADING";
export const PASSWORD_LOADING = "[USER] PASSWORD_LOADING";
