import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, TextField } from "@material-ui/core";
import { IQuestion } from "dto/question.dto";

const useStyles = makeStyles(() => ({
  type04: { 
    height: "100%", 
    padding: 16, 
    position: "relative", 
    alignItems: "center",
  },
  text: { "& .MuiInputBase-root": { borderRadius: 16 } },
}));

interface IProps {
  answer: string;
  question: IQuestion;
  selectAnswer: (t: string) => void;
}

const Type04: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { answer, question, selectAnswer } = props;
  const errorType08 = question.type === 8 && answer.length > 50;

  const onChageAnswer = (e: React.ChangeEvent<HTMLInputElement>) => {
    selectAnswer(e.target.value);
  }

  return (
    <Grid className={classes.type04} container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {!question.hide_text && (
            <Grid container justifyContent="flex-end">
              {question.type === 8 && (
                <Typography display="block" align="right" color="textSecondary">
                  {t("Máximo 50 carácteres")}
                </Typography>
              )}
            </Grid>
          )}

          <TextField
            className={classes.text}
            size="small"
            error={errorType08}
            value={answer}
            minRows={5}
            variant="outlined"
            onChange={onChageAnswer}
            autoFocus
            fullWidth
            multiline
            helperText={errorType08 && t("Máximo 50 carácteres")}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Type04;
