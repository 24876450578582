import React, { FC, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Divider, Grid, Typography } from "@material-ui/core";
import { IQuestion, IQuestionPreview } from "dto/question.dto";

const useStyles = makeStyles((theme) => ({
  type20: { height: "100%" },
  header: { 
    color: "white", 
    height: 60, 
    padding: "0 16px",
    alignItems: "center",
  },
  title: { fontSize: 40, fontWeight: 700 },
  content: { 
    height: "calc(100% - 60px)",
    [theme.breakpoints.down("xs")]: { overflow: "auto" } 
  },
  information: { 
    height: "100%",
    flexWrap: "initial", 
    [theme.breakpoints.down("xs")]: {  height: "fit-content", flexWrap: "inherit" } 
  },
  image: {
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    "& img": { maxWidth: "100%", maxHeight: "100%" },
    [theme.breakpoints.down("xs")]: { width: "100%", height: "fit-content" }
  },
  texto: {
    height: "100%",
    padding: "0 16px",
    overflow: "auto",
    "&::-webkit-scrollbar": { width: 8 },
    "&::-webkit-scrollbar-track": { backgroundColor: "white" },
    "&::-webkit-scrollbar-thumb": { backgroundColor: "#c1c1c1", borderRadius: 8 },
    [theme.breakpoints.down("xs")]: {  width: "100%", height: "fit-content" }
  },
  book: {  
    height: "100%",
    "& img": { height: "100%" },
    [theme.breakpoints.down("xs")]: { display: "none" }, 
  },
  divider: { 
    width: 2, 
    backgroundColor: "#f0f0f0",
    [theme.breakpoints.down("xs")]: { display: "none" }, 
  },
  circular: { color: "#ff6300" }
}));

interface IProps {
  book?: boolean;
  preview: IQuestionPreview;
  question: IQuestion;
  headerColor: string;
}

const Type20: FC<IProps> = (props) => {
  const classes = useStyles();
  const { headerColor: backgroundColor } = props;
  const [image, setImage] = useState("");

  useEffect(() => {
    setImage("");
    const timer = setTimeout(() => { setImage(props.preview.image_header); }, 500);
    
    return () => clearTimeout(timer);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.preview]);

  const getQuestionText = (html: string) => {
    return <span dangerouslySetInnerHTML={{__html: html}} />;
  }

  return (
    <Grid className={classes.type20} container>
      <Grid className={classes.header} container style={{ backgroundColor }}>
        <Typography className={classes.title} noWrap>
          {props.question.title}
        </Typography>
      </Grid>

      <Grid className={classes.content} container>
        <Grid className={classes.information} container>
          <Grid className={classes.image} container>
            {!!image ? (
              <img src={image} alt="" />
            ) : (
              <CircularProgress className={classes.circular} />
            )}
          </Grid>

          <Divider className={classes.divider} orientation="vertical" flexItem />

          <Grid className={classes.texto} container>
            <Typography align="justify">
              {getQuestionText(props.question.question)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Type20;
