import React, { FC, useEffect } from "react";
import { Field } from "formik";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  field: { 
    "& .MuiInputBase-root": {
      borderRadius: 8,
      "&:after": {borderBottom: "2px solid #f0f0f0"},
      "&:before": { borderBottom: "2px solid #f0f0f0" },
      "&:hover": { "&:before": { borderColor: "#f0f0f0" }, },
      "&.Mui-focused": { "&:before": { borderColor: "#f0f0f0" } },
      "& .MuiInputBase-input": {
        color: "#cdcdcd",
        padding: "12px 16px",
        fontSize: 18,
        fontWeight: 500,
      },
      "&.Mui-error": { 
        "&:after": { borderColor: "#f44336" },
        "&:before": { borderColor: "#f44336" },
      },
    },
  },
}));

interface IProps{
  value: string | Date;
  error?: string;
  touched?: boolean;
  selectAnswer: (answer: string) => void;
}

const FieldDate: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const error = !!props.error && !!props.touched;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { selectAnswer(); }, [props.value, props.error]);

  const selectAnswer = () => {
    if (!props.error && !!props.value) {
      props.selectAnswer(String(props.value));
    } else props.selectAnswer("");
  }

  return (
    <Field
      className={classes.field}
      as={TextField}
      name="date"
      size="small" 
      type="date"
      error={error}
      autoFocus
      fullWidth 
      helperText={error && t(props.error ?? "")}
    />
  )
}

export default FieldDate;
