import firebase from "firebase";
import User from "services/user.services";
import { IUser, ITask, IUserRequest } from "dto/user.dto";
import { userInit } from "initials";
import * as actions from "store/actions";
import * as types from "./types"
import { Action } from "./types";
import { messageError } from "components/Common/Toast";
import * as utils from "utils";
import { IEntity } from "dto/entity.dto";
import { IModule } from "dto/module.dto";
import { IChallenger } from "dto/challenger.dto";
import { ILaboratory } from "dto/laboratory.dto";

export const setTaskLoading = (value: boolean): Action => (dispatch) => {
  dispatch({type: types.TASK_LOADING, payload: value});
}

export const getUsers = (): Action => async (dispatch) => {
  dispatch({ type: types.USERS_LOADING, payload: true });
  try {
    const data = await User.get();
    const users = Object.keys(data).map((key) => data[key]);
    dispatch({ type: types.USERS, payload: users });
  } catch(e) {}
  dispatch({ type: types.USERS_LOADING, payload: false });
}
export const setUsers = (users: IUser[]): Action => (dispatch) => {
  dispatch({ type: types.USERS, payload: users });
}

export const registerUser = (user: IUser): Action => async (dispatch) => {
  try {
    await User.post(user);
  } catch(e) { 
    dispatch({type: types.USER_LOADING, payload: false}); 
  }
}

export const setUserCurrent = (user: IUser): Action => (dispatch) => {
  const db = user?.tasks ?? [];
  const tasks = Object.keys(db).map((key: any) => db[key]);
  dispatch({ type: types.USER, payload: { ...user, tasks }});
  dispatch({ type: types.USER_LOADING, payload: false });
  dispatch({ type: types.PROFILE_LOADING, payload: false })
}
export const saveUserCurrent = (user: IUser): Action => (dispatch, getState) => {
  const fbAuth = getState().firebase.auth;
  localStorage.setItem("uidfb", fbAuth.uid);
  localStorage.setItem("uiddb", user.nit);
  localStorage.setItem("uuiddb", user.nit);
  localStorage.setItem("uuidfb", fbAuth.uid);
  dispatch(setUserCurrent(user));
}
export const resetUserCurrent = (): Action => (dispatch) => {
  dispatch(setUserCurrent(userInit));
}

export const updateProfile =
  (request: IUser, callback: () => void): Action =>
  async (dispatch, getState) => {
    dispatch({ type: types.PROFILE_LOADING, payload: true });
    try {
      const user = getState().user.user;
      await User.update(request.nit, request);
      callback();
      dispatch(setUserCurrent(request));
      if (user?.date !== request?.date) {
        dispatch(actions.analytics("update_user_date_of_birth", {}));
      }
      if (user?.sex !== request?.sex) {
        dispatch(actions.analytics("update_user_gender", {}));
      }
    } catch (e) { }
    dispatch({ type: types.PROFILE_LOADING, payload: false });
  };

export const updateProfilePhoto =
  (file: File, callback: () => void): Action =>
  async (dispatch, getState) => {
    dispatch({ type: types.PHOTO_LOADING, payload: true });
    const user = await getState().user.user;
    try {
      await utils.uploadFile(`/photosProfiles/${user.nit}`, file, "profile_photo");
      const photo_url = await utils.downloadFile( `photosProfiles/${user.nit}`, "profile_photo");
      await User.update(user.nit, { photo: "profile_photo", photo_url });
      await dispatch(setUserCurrent({ ...user, photo: "profile_photo", photo_url }));
      await callback();
      dispatch(actions.analytics("update_user_profile_photo", {}));
    } catch (e) {}
    dispatch({ type: types.PHOTO_LOADING, payload: false });
  };


export const createUserTask = ({
  task, 
  tasks, 
  module,
  challenger, 
  laboratory, 
  createTaskCallback, 
} : {
  task: ITask;
  tasks: ITask[];
  module: IModule;
  challenger: IChallenger;
  laboratory: ILaboratory;
  createTaskCallback?: (next?: IChallenger, type?: string, task?: ITask) => void;
}) : Action => async (dispatch, getState) => {
  dispatch(setTaskLoading(true));
  try {
    const user = getState().user.user;
    const path = `users/${user.nit}/tasks`;
    const tasksNew = await utils.getCreateTask(path, task, tasks);
    const challengerBlocked = 
      !!laboratory?.challenges_blocked 
      && laboratory?.challenges_rate_min > task?.rate;

    await User.update(user.nit, { tasks: tasksNew });
    dispatch(setUserCurrent({ ...user, tasks: tasksNew }));
    const next = utils.getChallengerNextWithTasks(
      laboratory?.name,
      module,
      challenger,
      tasksNew,
      challengerBlocked,
    );
    !!createTaskCallback && createTaskCallback(next.challenger, next.type, task);
    dispatch(actions.analytics("perform_laboratory_challenger"));
    dispatch(actions.closeModalSuccess());
  } catch (error) { 
    console.log("Error Create User Task: ", error);
  }
  dispatch(setTaskLoading(false));
}
export const updateUserTask  = (
  task: ITask, 
  tasks: ITask[],
  updateTaskCallback?: () => void,
) : Action => async (dispatch, getState) => {
  dispatch(setTaskLoading(true));
  try {
    const user = getState().user?.user;
    const path = `users/${user?.nit}/tasks`;
    const tasksNew = await utils.getUpdateTask(path, task, tasks);
    await User.update(user.nit, { tasks: tasksNew });
    dispatch(setUserCurrent({...user, tasks: tasksNew}));
    !!updateTaskCallback && updateTaskCallback();
    dispatch(actions.analytics("perform_laboratory_challenger"));
    dispatch(actions.closeModalSuccess());
  } catch (error) {
    console.log("Error Update User Task: ", error); 
  }
  dispatch(setTaskLoading(false));
}

export const updateUserDate = (
  date: string, 
  age: number, 
  callback: () => void,
): Action => async (dispatch, getState) => {
  dispatch({type: types.PROFILE_LOADING, payload: true});
  const user = await getState().user.user;
  try {
    await User.update(user.nit, {date: date, age: age});
    const userNew = await User.getUserByNit(user.nit);
    callback();
    dispatch(setUserCurrent(userNew));
    dispatch({type: types.PROFILE_LOADING, payload: false});
  } catch(e) { dispatch({type: types.PROFILE_LOADING, payload: false}) }
}

export const uploadFileStorage = (
  file: File, 
  url: string, 
  name: string,
): Action => async () => {
  const storage = firebase.storage().ref(url);
  if (file !== undefined) {
    await storage.put(file).then((d) => {
      if (d.metadata.name === name && d.state === "success") {}
    }).catch(() => {});
  }
};

export const changeLanguageAudio = (lang: number): Action => async (dispatch) => {
  localStorage.setItem("language", lang.toString());
  dispatch({type: types.LANGUAGE_AUDIO, payload: lang})
}

export const changePassword = (email: string, password: string): Action => async (dispatch) => {
  dispatch({ type: types.PASSWORD_LOADING, payload: true });
  try {
    await User.changePasswordByEmail(email,password)
    dispatch(actions.analytics("update_user_password"));
    window.location.reload()
  } catch (error) {
    messageError("Tu contraseña no pudo ser actualizada");
  }
  dispatch({ type: types.PASSWORD_LOADING, payload: false });
}

export const updateUserSponsorByEntityCode = (
  user: IUser,
  code: string,
  entity: IEntity,
): Action => async (dispatch) => {
  dispatch({ type: types.PROFILE_LOADING, payload: true });
  try {
    const entityCode = await utils.getEntityCodeByCode(code);
    if (!!entityCode) {
      const permissions = utils.getPermissionsNewToUser(user, entityCode?.permissions);
      const request: IUserRequest = {
        sponsor: entityCode?.entity_name ?? "",
        codeCourse: entityCode?.code ?? "", 
        permissions,
      }
      const userNew: IUser = { ...user, ...request }
      await User.update(user.nit, request);
      dispatch(setUserCurrent(userNew));
      dispatch(actions.setEntity({ 
        ...entity, 
        nit: entityCode?.entity_id,
        name: entityCode?.entity_name,
        logo_url: entityCode?.entity_logo_url, 
      }));
      await dispatch(actions.addUserToUsersGroup(entityCode, userNew));
    } else messageError(("El código ingresado no es válido")); 
  } catch (error) {
    console.log("Error Send Entity Code: ", error);
  }
  dispatch({ type: types.PROFILE_LOADING, payload: false });
}

export const resetDataUser = (): Action => (dispatch) => {
  dispatch({ type: types.USER, payload: userInit });
  dispatch({ type: types.USER_LOADING, payload: false });
  dispatch({ type: types.NOTE_LOADING, payload: false });
  dispatch({ type: types.TASK_LOADING, payload: false });
  dispatch({ type: types.USERS_LOADING, payload: false });
  dispatch({ type: types.PHOTO_LOADING, payload: false });
  dispatch({ type: types.LANGUAGE_AUDIO, payload: 0 });
  dispatch({ type: types.PROFILE_LOADING, payload: false });
}
