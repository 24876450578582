import React, { useEffect } from "react";
import { Router } from "react-router-dom";
import { useFirebase } from "react-redux-firebase";
import { RouterSwitch } from "react-typesafe-routes";
import { Grid, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import "i18n/i18n";
import { State } from "store/types";
import { router } from "Router";
import { history } from "configureStore";
import { withRoot } from "withRoot";
import * as actions from "store/actions";
import Toast from "components/Common/Toast";
import Loading from "components/Common/Loading";

const useStyles = makeStyles(() => ({
  root: {
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
  },
}));

const App = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const firebase = useFirebase();
  const fbAuth = useSelector((state: State) => state.firebase.auth);
  const entityLoading = useSelector((s: State) => s.entity.entityLoading);
  const loadingLogout = useSelector((s: State) => s.auth.loadingLogout);
  const authenticationLoading = useSelector((s: State) => s.auth.authenticationLoading);
  const loading =
    !fbAuth.isLoaded || loadingLogout || entityLoading || authenticationLoading;
  const location = history.location.pathname;

  useEffect(() => { 
    const language = localStorage.getItem("language");
    dispatch(actions.changeLanguageAudio(Number(language)));
    dispatch(actions.getLanguage());

    dispatch(actions.getGenders());
    dispatch(actions.getCountries());

    firebase.watchEvent("value", "emails", "");
    return () => { 
      firebase.unWatchEvent("value", "emails", ""); 
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { getAuthentication(); }, [fbAuth]);

  const getAuthentication = () => {
    if (
      location !== "/plans" && 
      location !== "/landing" &&
      location !== "/donation" &&
      location !== "/terms-service" &&
      location !== "/privacy-policy" &&
      location !== "/plans-checkout"
    ) {
      dispatch(actions.getAuthentication());
    }
  }

  return (
    <Router history={history}>
      <Grid className={classes.root} container>
        <Loading loading={loading && location !== "/checkout"} />
        
        {!loading && (
          <Grid container>
            <RouterSwitch router={router} />
          </Grid>
        )}
        
        <Toast />
      </Grid>
    </Router>
  );
}

export default withRoot(App);
