import { IContact, IEmail, INewMessages } from "dto/email.dto";

export const emailInit: IEmail = {
  uid: "",
  date: "",
  unread: false,
  receiver: "",
  featured: [],
  messages: [],
  created_by: "",
}

export const contactInit: IContact = { 
  nit: "", 
  name: "", 
  photo: "", 
};

export const newMessagesInit: INewMessages = {
  loading: false,
  messages: 0,
}
