import React, { FC } from "react";
import { LocationOn } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({ 
  marker: { 
    top: -50,
    left: -25,
    color: "#ff6300", 
    fontSize: 50,
    position: "absolute"
  } 
}));

interface IProps {
  lat: number;
  lng: number; 
}

const Marker: FC<IProps> = () => {
  const classes = useStyles();

  return <LocationOn className={classes.marker} />
}

export default Marker;
