import React, { FC } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { IUser } from "dto/user.dto";
import { IEntity } from "dto/entity.dto";
import { IUserAnonymous } from "dto/user-anonymous.dto";
import { history } from "configureStore";
import ButtonLayout from "layouts/ButtonLayout";
import ButtonLanguage from "./ButtonLanguage";
import { IconHomeSvg } from "./IconHomeSvg";
import { IconExploreSvg } from "./IconExploreSvg";
import ButtonLayoutProfile from "layouts/ButtonLayoutProfile";

const useStyles = makeStyles((theme) => ({
  layaut: { 
    gap: 32, 
    width: "fit-content",
    [theme.breakpoints.down("md")]: { gap: 16 },
    [theme.breakpoints.down("sm")]: { gap: 8 },
    [theme.breakpoints.down("xs")]: { gap: 4 },
  },
  button: {
    width: 42, 
    height: 42, 
    padding: 2,
    borderRadius: 8,
    "& .MuiAvatar-root": { width: "100%", height: "100%", borderRadius: 0 },
    "&:disabled": { "& .MuiAvatar-root": { filter: "grayscale(1)" }, },
  },
  select: {
    border: "2px solid #0aa9e2",
    backgroundColor: "#ebfaff",
    "&:hover": { backgroundColor: "#ebfaff" },
    "&:disabled": { borderColor: "grey" },
  },
  paper: {
    top: 4,
    border: "2px solid #f0f0f0",
    minWidth: 200,
    position: "relative",
    boxShadow: "none",
    borderRadius: 16,
    backgroundColor: "white"
  },
  menu: { padding: 0 },
  divider: { height: 2, backgroundColor: "#f0f0f0" },
  menuitem: {
    height: 50,
    "& .MuiAvatar-root": { 
      width: 32, 
      height: 32, 
      marginRight: 8,
      borderRadius: 0, 
    },
    "& .MuiTypography-root": {
      color: "#8d8d8d",
      fontSize: 18,
      fontWeight: 500,
    },
  },
}));

interface IProps {
  user: IUser;
  entity: IEntity;
  anonymousUser: IUserAnonymous;
  laboratoryOpen: boolean;
  hideButtonLanguage?: boolean;
  logout: () => void;
  redirectHome: () => void;
  openDialogLeaveLaboratory: (url: string) => void;
}

const ButtonsLayout: FC<IProps> = (props) => {
  const classes = useStyles();
  const location = history.location.pathname;
  const home = location === "/home" || 
    location === "/anonymous-user/test" || 
    location.includes("/laboratory");
  const explore = location.includes("explore");
  const disabled = !props.user?.nit && !props.anonymousUser?.userType;

  const openPageProfile = () => {
    if (!!props.laboratoryOpen && location !== "/profile") {
      props.openDialogLeaveLaboratory("/profile");
    } else if (location !== "/profile") {
      history.push("/profile");
    }
  }

  const openPageExplore = () => {
    if (!!props.laboratoryOpen && !location?.includes("/explore")) {
      props.openDialogLeaveLaboratory("/explore");
    } else if (!location?.includes("/explore")) {
      history.push("/explore");
    }
  }

  return (
    <Grid className={classes.layaut} container>
      <ButtonLayout image="/img/layout/blog.svg" disabled />
      <ButtonLayout image="/img/layout/notes.svg" disabled />
      <ButtonLayout 
        // image="/img/layout/home.svg"
        image={IconHomeSvg(props.entity?.icon_color)}
        checked={home}
        onClick={props.redirectHome}
        disabled={disabled}
      />
      <ButtonLayout 
        // image="/img/layout/explore.svg"
        image={IconExploreSvg(props.entity?.icon_color)}
        checked={explore}
        onClick={openPageExplore}
        disabled={disabled}
      />
      <ButtonLayoutProfile 
        user={props.user} 
        disabled={disabled}
        iconColor={props.entity?.icon_color}
        logout={props.logout}
        openPageProfile={openPageProfile}
        laboratoryOpen={props.laboratoryOpen}
        openDialogLeaveLaboratory={props.openDialogLeaveLaboratory}
      />
      {!props.hideButtonLanguage && <ButtonLanguage primary />}
    </Grid>
  )
}

export default ButtonsLayout;
