import Certificate from "services/certificate.services";
import { certificateInit } from "initials/certificate.init";

export const getCertificateByUuid = async (uuid: string) => {
  try {
    const certificates = await Certificate.get();
    if (!!uuid && !!certificates?.length) {
      const certificate = certificates?.filter((c) => (c.uuid === uuid))[0];
      return certificate ?? certificateInit;
    } else return certificateInit;
  } catch (error) {
    return certificateInit;
  }
};
