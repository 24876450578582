import React, { FC, useState } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ISurvey } from "dto/survey.dto";
import { IQuestion, IQuestionAnswer, IQuestionFile } from "dto/question.dto";
import QuestionNotebook from "components/Question/QuestionNotebook";
import DialogCongratulations from "components/Survey/DialogCongratulations";

const useStyles = makeStyles(() => ({
  survey: { 
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
}));

interface IProps {
  zoom: boolean;
  survey: ISurvey;
  questions: IQuestion[];
  languageAudio: string;
  saveAnswerLoading: boolean;
  dialogCongratulations: boolean;
  onClickZoom: () => void;
  closeSurveyBook: () => void;
  handleAnalytics: (name: string, params?: { [key: string]: string; }) => void;
  saveSurveyAnswers: (questions: IQuestion[]) => void;
  updateQuestionIndex?: (index: number) => void;
  closeCongratulations: () => void;
}

const Survey: FC<IProps> = (props) => {
  const classes = useStyles();
  const [successAudio, setSuccessAudio] = useState<HTMLAudioElement | undefined>(undefined);
  const path= `surveys/${props.survey?.uuid}/questions`;

  const closeCongratulations = () => {
    props.closeSurveyBook();
    props.closeCongratulations();
    if (!!successAudio) successAudio.pause();
  }

  const saveQuestionsAnswers = (
    questions: IQuestion[], 
    files: IQuestionFile[],
    answers: IQuestionAnswer[], 
  ) => {
    const questionsNew: IQuestion[] = questions.map((item, i) => {
      const file = files[i];
      const answer = answers[i];
      if (item.type === 16 && !!file?.path) {
        return { 
          ...item, 
          answer: answer?.answer ?? "",
          file_name: files[i].file ?? "",
        }
      } else return { ...item, answer: answer?.answer }
    });
    props.saveSurveyAnswers(questionsNew);
  }

  return (
    <Grid className={classes.survey} container>
      <QuestionNotebook
        path={path}
        zoom={props.zoom}
        loading={props.saveAnswerLoading}
        questions={props.questions}
        headerColor={props.survey?.properties?.secondary_color}
        languageAudio={props.languageAudio}
        backgroundColor={props.survey?.properties?.primary_color}
        progressBarColor={props.survey?.properties?.tertiary_color}
        buttonNextColorText={props.survey?.properties?.primary_color}
        handleAnalytics={props.handleAnalytics}
        buttonZoomOnClick={props.onClickZoom}
        updateQuestionIndex={props.updateQuestionIndex}
        saveQuestionsAnswers={saveQuestionsAnswers}
        closeQuestionNotebook={props.closeSurveyBook}
      />

      <DialogCongratulations 
        open={props.dialogCongratulations}
        zoom={props.zoom}
        buttonText="Guardar y salir"
        buttonColor={props.survey?.properties?.button_color}
        congratulations={props.survey?.congratulations}
        onClose={closeCongratulations}
        setSuccessAudio={setSuccessAudio}
      />
    </Grid>
  )
}

export default Survey;
