import axios from "axios";
import { ICountry } from "dto/country.dto";

const url = process.env.REACT_APP_BACKEND_URL;
const route = `${url}/api`;

class Countries {
  async get(): Promise<ICountry[]> {
    const { data } = await axios.get(`${route}/static/countries`);
    return data?.data ?? [];
  }
}

const countries = new Countries();
const CountriesClass = Object.freeze(countries);
export default CountriesClass;
