import React, { FC } from "react";
import DateFns from "@date-io/date-fns";
import es from "date-fns/locale/es";
import { makeStyles } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

const useStyles = makeStyles(() => ({
  datePicker: {
    width: "100%",
    "& .MuiInputBase-root": { 
      color: "#5f5f5f",
      "& input": { 
        height: 32,
        padding: "0 8px 0 0", 
        fontSize: 20,
        fontWeight: 700,
      },
      "&:after": { display: "none" },
      "&:before": { display: "none" },
    },
  },
  dialogPicker: {
    "& .MuiDialogContent-root": {
      "& .MuiPickersBasePicker-container": {
        "& .MuiToolbar-root": {
          backgroundColor: "#ff6315",
          "& .MuiButton-root": {
            outline: 0,
            "& .MuiButton-label": {
              "& .MuiTypography-root": { color: "white" },
            },
          },
        },
        "& .MuiPickersBasePicker-pickerView": {
          "& .MuiPickersCalendar-transitionContainer": {
            "& .MuiPickersCalendar-week": {
              "& .MuiIconButton-root": {
                outline: 0,
                "&.MuiPickersDay-current": { color: "#ff6315" },
                "&.MuiPickersDay-daySelected": {
                  color: "white",
                  backgroundColor: "#ff6315",
                },
              },
            },
          },
          "& .MuiPickersYearSelection-container": {
            "& .MuiPickersYear-root": {
              "&:focus": { color: "#ff6315" },
              "&.MuiTypography-colorPrimary": { color: "#ff6315" },
            },
          },
        },
      },
    },
    "& .MuiDialogActions-root": {
      "& .MuiButton-root": { outline: 0, color: "#ff6315" },
    },
  },
}));

interface IProps {
  value: string;
  updateDate: (date: string) => void;
}

const PickerDate: FC<IProps> = (props) => {
  const classes = useStyles();
  const { value } = props;

  return (
    <MuiPickersUtilsProvider utils={DateFns} locale={es}>
      <DatePicker
        clearable
        className={classes.datePicker}
        value={value}
        format="dd/MM/yyyy"
        onChange={(date) => props.updateDate(String(date))}
        fullWidth
        DialogProps={{ className: classes.dialogPicker }}
        disableFuture
      />
    </MuiPickersUtilsProvider>
  );
};

export default PickerDate;
