import { UserActionType, UserState } from './types';
import * as types from "./types";
import { userInit } from 'initials';

export const USER_INITIAL_STATE: UserState = {
  user: userInit,
  users: [],
  userLoading: false,
  noteLoading: false,
  taskLoading: false,
  usersLoading: false,
  photoLoading: false,
  userIsLoaded: false,
  languageAudio: 0,
  profileLoading: false,
  passwordLoading: false,
}

export const user = (prevState = USER_INITIAL_STATE, action: UserActionType) => {
  switch (action.type) {
    case types.USER: 
      return { ...prevState, user: action.payload }
    case types.USERS: 
      return { ...prevState, users: action.payload }
    case types.USER_LOADING: 
      return { ...prevState, userLoading: action.payload }
    case types.NOTE_LOADING: 
      return { ...prevState, noteLoading: action.payload }
    case types.TASK_LOADING: 
      return { ...prevState, taskLoading: action.payload }
    case types.USERS_LOADING: 
      return { ...prevState, usersLoading: action.payload }
    case types.PHOTO_LOADING: 
      return { ...prevState, photoLoading: action.payload }
    case types.USER_IS_LOADED: 
      return { ...prevState, userIsLoaded: action.payload }
    case types.LANGUAGE_AUDIO: 
      return { ...prevState, languageAudio: action.payload }
    case types.PROFILE_LOADING: 
      return { ...prevState, profileLoading: action.payload }
    case types.PASSWORD_LOADING: 
      return { ...prevState, passwordLoading: action.payload }
    
      default: 
      return prevState
  }
}
