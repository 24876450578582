import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Avatar, Grid, IconButton, Typography } from "@material-ui/core";
import { history } from "configureStore";
import FormButton from "components/Form/FormButton";

const useStyles = makeStyles((theme) => ({
  welcome: { 
    height: "100%",
    position: "relative",
    backgroundColor: "white",
  },
  header: {
    padding: "60px 76px 0",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: { padding: "60px 52px 0" },
    [theme.breakpoints.down("sm")]: { padding: "60px 32px 0" },
    [theme.breakpoints.down("xs")]: { padding: "20px 12px 0" },
  },
  back: {
    left: 76,
    padding: 0,
    outline: "0 !important",
    position: "absolute",
    borderRadius: 12,
    "& .MuiAvatar-root": { width: "100%", height: 40, borderRadius: 4 },
    [theme.breakpoints.down("md")]: {  left: 52, },
    [theme.breakpoints.down("sm")]: {  left: 32, },
    [theme.breakpoints.down("xs")]: {  left: 12, },
  },
  content: { 
    height: "calc(100% - 127px)", 
    padding: "32px 80px",
    overflow: "auto",
    alignItems: "center",
    [theme.breakpoints.down("md")]: { padding: "32px 64px" },
    [theme.breakpoints.down("sm")]: { padding: "32px 32px" },
    [theme.breakpoints.down("xs")]: { padding: "32px 16px" },
  },
  h5: { color: "#5f5f5f", fontWeight: 700 },
  text: { fontSize: 18, color: "#8d8d8d", fontWeight: 500 },
}));


interface IProps {}

const Welcome: FC<IProps> = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const openLogin = () => history.push("/");
  const openTest = () => history.push("/anonymous-user/test");
  
  return (
	  <Grid className={classes.welcome} container>
      
      <Grid className={classes.header} container>
        <IconButton className={classes.back} onClick={openLogin}>
          <Avatar src="/img/login/back.png" alt="" />
        </IconButton>
        <img src="/img/logos/olab.svg" alt="" width={150} />
      </Grid>
      <Grid className={classes.content} container>
        <Grid item xs={12}>
          <Typography className={classes.h5} align="center" variant="h5">
            {t("Antes de comenzar")}
          </Typography>
          <Typography className={classes.text} align="center">
            {t("Necesitamos saber un poco más sobre ti")}.{" "}
            {t("Responde este corto cuestionario para poder empezar a explorar el contenido gratutito ofrecido por O-lab")}.
          </Typography>
        </Grid>
        <Grid item container alignItems="flex-end">
          <FormButton primary onClick={openTest} fullWidth>
            {t("Comenzar")}
          </FormButton>
        </Grid>
      </Grid>
	  </Grid>
  );
};

export default Welcome;
