import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import languaje_ES  from "./locales/es";
import languaje_EN  from "./locales/en";
import languaje_UR  from "./locales/ur";
import languaje_FR  from "./locales/fr";
import languaje_FIL from "./locales/fil";
import languaje_AR from "./locales/ar";

const resources = {
  es:  languaje_ES,
  en:  languaje_EN,
  ur:  languaje_UR,
  fr:  languaje_FR,
  fil: languaje_FIL,
  ar: languaje_AR,
};

i18n.use(initReactI18next).use(LanguageDetector).init({
  resources,
  keySeparator: false, 
  interpolation: {
    escapeValue: false
  },
});

export default i18n;
