import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, Grid } from "@material-ui/core";
import ButtonLanguage from "./ButtonLanguage";

const useStyles = makeStyles((theme) => ({
	appBar: { 
    backgroundColor: '#ff6315', 
    minHeight: '60px',
    [theme.breakpoints.down("xs")]: { boxShadow: 'none' }, 
  },
	toolbar: {
		minHeight: '60px !important',
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down("xs")]: { padding: '0 10px' }, 
	},
  img: { maxHeight: 40 },
}));

const LayoutOlab = () => {
  const classes = useStyles();

  return (
    <AppBar className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Grid container alignItems="center">
          <Grid item container xs={6}>
            <img className={classes.img} src="/images/landing_logo.svg" alt="" />
          </Grid>
          <Grid item container justifyContent="flex-end" alignItems="center" xs={6}>
            <ButtonLanguage />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default LayoutOlab;
