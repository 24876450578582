import React, { FC } from "react";
import { IModule } from "dto/module.dto";
import { ILaboratoryCongratulations } from "dto/laboratory.dto";
import SuccessHeader from "./SuccessHeader";
import SuccessContent from "./SuccessContent";

interface IProps {
  rate: number;
  module: IModule;
  languageAudio: string;
  congratulations: ILaboratoryCongratulations;
  saveUserTask: () => void;
}

const ModuleCongratulations: FC<IProps> = (props) => {
  const audioSrc = props.rate > 2 && props.languageAudio === "1"
    ? props.congratulations?.complete_audio_sl
    : props.rate > 2 && props.languageAudio !== "1" 
    ? props.congratulations?.complete_audio_fl 
    : props.languageAudio === "1" 
    ? props.congratulations?.incomplete_audio_sl
    : props.congratulations?.incomplete_audio_fl;
  const subtitle = props.rate > 2 ? "" : "Módulo no completado"

  return (
    <>
      <SuccessHeader 
        rate={props.rate} 
        subtitle={subtitle}
        primaryColor={props.module?.primaryColor}
        congratulations={props.congratulations} 
      />
      <SuccessContent
        rate={props.rate}
        primaryColor={props.module?.primaryColor}
        challengerLast
        congratulations={props.congratulations}
        saveUserTask={props.saveUserTask} 
      />
      <audio src={audioSrc} autoPlay>
        Your browser does not support the <code>audio</code> element.
      </audio>
    </>
  )
}

export default ModuleCongratulations;
