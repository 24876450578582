import React, { FC, useState } from "react";
import ReactPlayer from "react-player";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Avatar, Grid, Typography } from "@material-ui/core";
import { State } from "store/types";
import { ITask, IUser } from "dto/user.dto";
import { ILaboratory } from "dto/laboratory.dto";
import * as utils from "utils";
import ProgressBar from "components/Common/ProgressBar";
import DialogLaboratoryCompleted from "components/Laboratory/DialogLaboratoryCompleted";
import DialogLaboratoryCertificate from "components/Laboratory/DialogLaboratoryCertificate";

const useStyles = makeStyles(() => ({
  laboratory: {
    width: 300,
    cursor: "pointer",
    border: "4px solid #f0f0f0",
    borderRadius: 16,
    backgroundColor: "#f0f0f0",
  },
  header: {
    height: 100,
    overflow: "hidden",
    borderRadius: "16px 16px 0 0",
  },
  image: {
    width: "100%",
    height: "fit-content",
    borderRadius: "16px 16px 0 0",
  },
  content: {
    padding: "8px 16px",
    minHeight: 71,
    backgroundColor: "white",
  },
  body1: {
    color: "#5f5f5f",
    fontWeight: 700,
    lineHeight: 1,
  },
  caption: {
    color: "#5f5f5f",
    display: "block",
    fontWeight: 400,
  },
  footer: {
    gap: 12,
    height: 50,
    alignItems: "center",
    borderRadius: "0 0 16px 16px",
    justifyContent: "center",
    backgroundColor: "#ff5c00",
  },
  text: { color: "white", fontWeight: 700 },
  txtSlidercontainer: {
    overflow: "hidden"
  }
}));

interface IProps {
  user: IUser;
  tasks: ITask[];
  laboratory: ILaboratory;
  entityName: string;
  selectContent: (laboratory: ILaboratory) => void;
  generateLaboratoryCertificate: (laboratory: ILaboratory) => void;
}

const LaboratoryButton: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const laboratory = props.laboratory?.state === "private" 
    ? utils.getLaboratoryPrivate(props.laboratory, props.user)
    : utils.getLaboratoryPublic(props.laboratory);
  const certificateLoading = useSelector((s: State) => s.laboratory.certificateLoading);
  const [dialogLaboratoryCompleted, setDialogLaboratoryCompleted] = useState(false);
  const [dialogLaboratoryCertificate, setDialogLaboratoryCertificate] = useState(false);
  const text = t("Ver curso");
  const image = props.laboratory.imageUrl;
  const subtitle =`${props.laboratory?.modules?.length ?? 0} ${t("Módulos")}`;
  const displayName = !!props.laboratory?.displayName
    ? props.laboratory.displayName
    : !!props.laboratory?.name
    ? props.laboratory.name
    : "Contenido";
  const menuColor = props.laboratory?.menu_color;
  const backgroundImage = !!props.laboratory?.background_image_url
    ? props.laboratory?.background_image_url
    : "/img/background/entorno_b1.png";
  const backgroundVideo = !!props.laboratory?.background_video_url
    ? props.laboratory?.background_video_url
    : "";
  const max = !!laboratory?.modules?.length ? laboratory.modules.length : 1;
  const progress = utils.getLaboratoryProgress(laboratory, props.tasks).progress;
  const percentage = Math.round(((progress / max) * 100) ?? 0);

  const onClick = () => {
    if (!!props.laboratory?.not_repeat && percentage >= 100) {
      openDialogLaboratoryCompleted();
    } else props.selectContent(props.laboratory);
  }

  const openDialogLaboratoryCompleted = () => setDialogLaboratoryCompleted(true);
  const closeDialogLaboratoryCompleted = () => setDialogLaboratoryCompleted(false);

  const openDialogLaboratoryCertificate = () => setDialogLaboratoryCertificate(true);
  const closeDialogLaboratoryCertificate = () => setDialogLaboratoryCertificate(false);

  const generateLaboratoryCertificate = () => {
    if (!!props.laboratory?.certificate_uuid) {
      openDialogLaboratoryCertificate();
    } else props.generateLaboratoryCertificate(props.laboratory);
  }

  return (
    <>
      <Grid
        className={classes.laboratory}
        container
        onClick={onClick}
        onMouseDown={utils.soundClick}
      >
        <Grid className={classes.header} container>
          {!!backgroundVideo ? (
            <ReactPlayer
              url={backgroundVideo}
              loop
              width="100%"
              height="fit-content"
              volume={0}
              playing
            />
          ) : (
            <Avatar className={classes.image} src={backgroundImage} alt="" />
          )}
        </Grid>

        <Grid className={classes.content} container>
          <Grid item xs={12} className={classes.txtSlidercontainer} >
            <Typography className={classes.body1}>
              {t(displayName)}
            </Typography>
            <Grid container justifyContent="space-between">
              <Typography className={classes.caption} variant="caption">
                {subtitle}
              </Typography>
              <Typography className={classes.caption} variant="caption">
                {Math.round(percentage ?? 0)}%
              </Typography>
            </Grid>
            <ProgressBar max={max} color={menuColor} value={progress} />
          </Grid>
        </Grid>

        <Grid 
          className={classes.footer} 
          container 
          style={{ backgroundColor: menuColor }}
        >
          <Avatar src={image} alt="" />
          <Typography className={classes.text}>
            {t(text)}
          </Typography>
        </Grid>
      </Grid>

      <DialogLaboratoryCompleted 
        open={dialogLaboratoryCompleted}
        loading={certificateLoading}
        onClose={closeDialogLaboratoryCompleted}
        generateLaboratoryCertificate={generateLaboratoryCertificate}
      />
      <DialogLaboratoryCertificate 
        open={dialogLaboratoryCertificate}
        userName={props.user?.name}
        entityName={props.entityName}
        laboratoryName={props.laboratory?.displayName}
        certificateUuid={props.laboratory?.certificate_uuid ?? ""}
        onClose={closeDialogLaboratoryCertificate}
      />
    </>
  )
}

export default LaboratoryButton;
