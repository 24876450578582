import React, { FC } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Avatar, IconButton } from "@material-ui/core";
import { soundQuestionMultiple } from "utils/audios.utils";
import { IQuestion, IQuestionPreview } from "dto/question.dto";

const useStyles = makeStyles(() => ({
  type07: { height: "100%" },
  content: { 
    height: "50%", 
    padding: 8, 
    alignItems: "center",
    justifyContent: "center",
  },
  button: { 
    width: 170,
    height: 170,
    border: "1px solid #707070",
    padding: 0, 
    maxWidth: "100%",
    borderRadius: 16,
    "&:disabled": { filter: "grayscale(1)", borderColor: "wheat" },
    "& .MuiIconButton-label": { height: "100%" },
    "&.zoom": { width: 240, height: 240, },
    "&.select": { 
      filter: "grayscale(0)", 
      border: "8px solid #f08d77", 
      borderRadius: 24, 
    },
    "&.correct": { filter: "grayscale(0)", borderColor: "#50c988" },
    "&.incorrect": { filter: "grayscale(0)", borderColor: "#f25b5b" },
  },
  avatar: {
    width: "100%",
    height: "100%",
    maxWidth: "100%",
    maxHeight: "100%",
    borderRadius: 16,
  },
}));

interface IProps {
  zoom?: boolean;
  answer: string;
  preview: IQuestionPreview;
  question: IQuestion;
  notFeedback?: boolean;
  answerRating: boolean;
  viewFeedback: boolean;
  selectAnswer: (answer: string) => void;
}

const Type07: FC<IProps> = (props) => {
  const classes = useStyles();
  const { answer, notFeedback, viewFeedback, answerRating } = props;
  const correct = viewFeedback && !notFeedback && !!answerRating;
  const incorrect = viewFeedback && !notFeedback && !answerRating;
  const answers = [
    { id: props.question["image_a"], url: props.preview["image_a"] },
    { id: props.question["image_b"], url: props.preview["image_b"] },
    { id: props.question["image_c"], url: props.preview["image_c"] },
    { id: props.question["image_d"], url: props.preview["image_d"] },
  ];


  return (
    <Grid className={classes.type07} container>
      {answers.map(({ id, url }, key) => (!!id && id !== "empty_image.jpg" && (
        <Grid className={classes.content} key={key} item container xs={6}>
          <IconButton 
            className={clsx(
              classes.button, 
              !!props.zoom && "zoom",
              answer === id && "select",
              correct && answer === id && "correct",
              incorrect && answer === id && "incorrect",
            )}
            onClick={() => props.selectAnswer(id)}
            disabled={viewFeedback}
            onMouseDown={soundQuestionMultiple}
          >
            <Avatar className={classes.avatar} alt="" src={url} />
          </IconButton>
        </Grid>
      )))}
    </Grid>
  )
}

export default Type07;


// import React, { FC } from "react";
// import clsx from "clsx";
// import { makeStyles } from "@material-ui/core/styles";
// import { Grid, Avatar, IconButton } from "@material-ui/core";
// import { soundQuestionMultiple } from "utils/audios";
// import { IQuestion, IQuestionPreview } from "dto/question.dto";

// const useStyles = makeStyles(() => ({
//   type07: { height: "100%" },
//   content: { 
//     height: "50%", 
//     padding: 8, 
//     alignItems: "center",
//     justifyContent: "center",
//   },
//   button: { 
//     width: 170,
//     height: 170,
//     border: "1px solid #707070",
//     padding: 0, 
//     maxWidth: "100%",
//     borderRadius: 16,
//     "&:disabled": { filter: "grayscale(1)", borderColor: "wheat" },
//     "& .MuiIconButton-label": { height: "100%" },
//     "&.zoom": { width: 240, height: 240, },
//     "&.select": { 
//       filter: "grayscale(0)", 
//       border: "8px solid #f08d77", 
//       borderRadius: 24, 
//     },
//     "&.correct": { filter: "grayscale(0)", borderColor: "#50c988" },
//     "&.incorrect": { filter: "grayscale(0)", borderColor: "#f25b5b" },
//   },
//   avatar: {
//     width: "100%",
//     height: "100%",
//     maxWidth: "100%",
//     maxHeight: "100%",
//     borderRadius: 16,
//   },
// }));

// interface IProps {
//   zoom: boolean;
//   answer: string;
//   answers: { id: string; url: string; image: string; }[];
//   preview: IQuestionPreview;
//   question: IQuestion;
//   notFeedback?: boolean;
//   answerRating: boolean;
//   viewFeedback: boolean;
//   selectAnswer: (answer: string) => void;
// }

// const Type07: FC<IProps> = (props) => {
//   const classes = useStyles();
//   const { zoom, answer, notFeedback, viewFeedback, answerRating } = props;
//   const answers = ["image_a", "image_b", "image_c", "image_a"];
//   const correct = viewFeedback && !notFeedback && !!answerRating;
//   const incorrect = viewFeedback && !notFeedback && !answerRating;


//   return (
//     <Grid className={classes.type07} container>
//       {answers.map((data, i) => (image !== "empty_image.jpg" && (

//       )))}
//     </Grid>
//   )
// }

// export default Type07;
