import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import Donation    from '../components/Donation/Donation';
import Information from '../components/Donation/Information' ;
import Background  from '../components/Common/Background';
import LayoutOrigin from '../layouts/LayoutOrigin' ;

const useStyles = makeStyles((theme) => ({
  page: {
    position: 'relative',
    height: 'calc(100vh - 60px)',
    marginTop: 60,
    backgroundColor: 'white',
    '& .background': { top: 'auto' },
  },
  content: { 
    height: 580, 
    padding: 30, 
    position: 'relative',
    overflow: 'auto',
    [theme.breakpoints.down("xs")]: { height: '100%', padding: 15 },
  },
  item: { 
    height: '100%', 
    maxWidth: 400,
    '&:nth-of-type(even)': { marginLeft: 15 },
    '&:nth-of-type(odd)': { marginRight: 15 },
    [theme.breakpoints.down("xs")]: {
      height: 'fit-content', 
      maxWidth: '100%', 
      margin: '0 0 15px !important',
    },
  },
}));

const DonationPage = () => {
  const classes  = useStyles();

  return (
    <Grid container>
      <LayoutOrigin />
      <Grid className={classes.page} container alignItems="center">
        <Background backgroundImage="/images/donation_02.png" />
        <Grid className={classes.content} container justify="center">
          <Grid className={classes.item} container>
            <Information />
          </Grid>
          <Grid className={classes.item} container>
            <Donation />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default DonationPage;
