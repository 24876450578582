import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import FormButton from "components/Form/FormButton";

const useStyles = makeStyles(() => ({
  join: {
    height: 76,
    padding: "0 16px",
    alignItems: "center",
    borderRadius: 16,
    justifyContent: "space-between",
    backgroundColor: "#ff6300",
  },
  h6: { 
    color: "white",
    width: "calc(100% - 260px)",
    fontWeight: 700, 
  },
}));

interface IProps {
  sendEntityCode: (code: string) => void;
  openDialogEntityCode: () => void;
}

const JoinAnEntity: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation ();

  return (
    <Grid className={classes.join} container>
      <Typography className={classes.h6} variant="h6" noWrap>
        {t("Todavía no perteneces a una organización")}
      </Typography>
      <FormButton 
        color="#ff6300"
        onClick={props.openDialogEntityCode}
        boxShadow="0px 4px 0px 0px #ffebde"
        borderColor="white"
      >
        {t("Unirme a mi organización")}
      </FormButton>
    </Grid>
  )
}

export default JoinAnEntity;
