import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { State } from "store/types";
import Carousel from "react-material-ui-carousel";

const useStyles = makeStyles((theme) => ({
  carousel: {
    height: "100%",
    minHeight: 620,
    "& .css-1f8sh1y": {
      height: "100% !important",
      "& .css-1l7c0cy": {
        "& div": { height: "100%" }
      }
    },
    "& .css-1abc02a": { display: "none" },
    "& .css-hn784z": { display: "none" },
  },
  item: {  
    height: "100%",
    justifyContent: "center",
    "& img": { maxWidth: "100%", maxHeight: "100%" },
  },
}));

const LoginCarrusel = () => {
  const classes = useStyles();
  const languageCode = useSelector((state: State) => state.layout.language.code);
  const en = !!languageCode && languageCode !== "es";

  const images = [
    { 
      color: "#ff6300",
      image: en ? "/img/login/explore.png" : "/img/login/explora.png", 
    },
    { 
      color: "#0aa9e2",
      image: en ? "/img/login/connect.png" : "/img/login/conecta.png", 
    },
    { 
      color: "#f7a500",
      image: en ? "/img/login/report.png" : "/img/login/reporta.png", 
    },
  ];

  return (
    <Carousel className={classes.carousel} stopAutoPlayOnHover={false}>
      {images.map(({ image, color }, key) => (
        <Grid 
          key={key} 
          className={classes.item} 
          container 
          style={{ backgroundColor: color }}
        >
          <img src={image} alt="" />
        </Grid>
      ))}
    </Carousel>
  )
}

export default LoginCarrusel;
