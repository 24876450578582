import { NoteActionType } from "./actions";
import { AppThunkAction } from "store/types";
import { INote } from "dto/note.dto";

export * from "./actions";
export * from "./constants";

export interface NoteState {
  notes: INote[];
  blogs: INote[];
  notesMain: INote[];
  noteLoading:  boolean;
  notesLoading: boolean;
  blogsLoading: boolean;
}

export type Action = AppThunkAction<NoteActionType>
