const svgImage = (c1: String) => `
  <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="72" height="72" fill="${c1}"/>
  </svg>
`;

export const SvgSquare = (c1?: string) => {
  const a = !!c1 ? c1 : "#ff6300";
  return `data:image/svg+xml;charset=UTF-8;base64,${btoa(svgImage(a))}`;
};

export default SvgSquare;
