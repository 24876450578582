export * from "./step.init";
export * from "./user.init";
export * from "./module.init";
export * from "./survey.init";
export * from "./question.init";
export * from "./challenger.init";
export * from "./laboratory.init";

export const months = [
  "Enero",
  "Febrero", 
  "Marzo", 
  "Abril", 
  "Mayo", 
  "Junio", 
  "Julio", 
  "Agosto", 
  "Septiembre", 
  "Octubre", 
  "Noviembre", 
  "Diciembre",
];

export const monthsMin = [
  "Ene",
  "Feb", 
  "Mar", 
  "Abr", 
  "May", 
  "Jun", 
  "Jul", 
  "Ago", 
  "Sep", 
  "Oct", 
  "Nov", 
  "Dic",
];

export const fileWithPathInit = { file: new File([], ""), path: "" };
