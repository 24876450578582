import Laboratory from "services/laboratory.services";
import * as utils from "utils";
import { Action } from "./types";
import * as types from "./types";
import * as actions from "store/actions";
import { ILaboratory } from "dto/laboratory.dto";
import { laboratoryInit } from "initials";

export const getLaboratoryPrivate = (key: string): Action => async (dispatch, getState) => {
  dispatch({ type: types.LABORATORY_LOADING, payload: true });
  try {
    const user = getState().user.user;
    const value: ILaboratory = await utils.getLaboratoryByKey(key);
    const laboratory = await utils.getLaboratoryPrivateWithFiles(value, user);
    dispatch(setLaboratoryPrivate(laboratory));
  } catch (e) {

  }
  dispatch({ type: types.LABORATORY_LOADING, payload: false });
}
export const setLaboratoryPrivate = (value: ILaboratory): Action => (dispatch) => {
  dispatch({ type: types.LABORATORY, payload: value });
  dispatch(actions.analytics("access_laboratory_private", {}));
}

export const selectLaboratory = (lab: ILaboratory): Action => async (dispatch, getState) => {
  dispatch({ type: types.LABORATORY_LOADING, payload: true });
  try {
    const user = getState().user.user;
    const laboratory = utils.getLaboratoryEnabled(lab, user);
    const modules = await utils.getModulesFiles(
      laboratory?.name ?? "",
      laboratory?.modules ?? [],
    );
    dispatch({ type: types.LABORATORY, payload: { ...laboratory, modules }});
  } catch (error) {
    dispatch({ type: types.LABORATORY, payload: lab });
  }
  dispatch({ type: types.LABORATORY_LOADING, payload: false });
}
export const deselectLaboratory = (): Action => (dispatch) => {
  dispatch(actions.deselectModule());
  dispatch(selectLaboratory(laboratoryInit));
}

export const selectLaboratoryFree = (value: ILaboratory): Action => (dispatch) => {
  dispatch({ type: types.LABORATORY_FREE, payload: value });
}
export const deselectLaboratoryFree = (): Action => (dispatch) => {
  dispatch({ type: types.LABORATORY_FREE, payload: laboratoryInit });
}

export const generateLaboratoryCertificate = (laboratory: ILaboratory): Action =>
async (dispatch, getState) => {
  dispatch({ type: types.CERTIFICATE_LOADING, payload: true });
  try {
    const user = getState().user?.user;
    const displayName = utils.getDisplayName(laboratory);
    if (!!user?.nit && !!laboratory?.sponsor) {
      await Laboratory.generateLaboratoryCertificate(
        user?.name, 
        laboratory.sponsor, 
        displayName,
      );
    } else if (!!user?.nit && !!user?.sponsor) {
      await Laboratory.generateLaboratoryCertificate(
        user?.name, 
        user.sponsor, 
        displayName,
      );
    } else {

    }
  } catch (error) {}
  dispatch({ type: types.CERTIFICATE_LOADING, payload: false });
};

export const resetDataLaboratory = (): Action => async (dispatch) => {
  dispatch({type: types.LABS, payload: []});
  dispatch({type: types.LABS_LOADING, payload: false});
  dispatch({type: types.LABORATORIES, payload: []});
}
