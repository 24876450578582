import React, { FC, Fragment, useState } from "react";
import { Field } from "formik";
import { IconButton, InputAdornment, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  body1: { 
    color: "#a9a9a9",
    fontWeight: 500,
  },
  field: { 
    "& .MuiInputBase-root": {
      borderRadius: 8,
      "& fieldset": { border: "2px solid #f0f0f0" },
      "& .MuiInputBase-input": {
        color: "#a9a9a9",
        padding: "12px 16px",
        fontSize: 18,
        fontWeight: 500,
        "&::placeholder": { color: "#cdcdcd" }
      },
      "&:hover": { "& fieldset": { borderColor: "#f0f0f0" } },
      "&.Mui-error": { 
        "& fieldset": { borderColor: "#f44336" },
        "&:hover": { "& fieldset": { borderColor: "#f44336" } },
      },
      "&.Mui-focused": { "& fieldset": { borderColor: "#f0f0f0" } },
    },
  },
}));

interface IProps {
  name: string;
  type?: "text" | "date" | "password";
  error?: string;
  title?: string;
  touched?: boolean;
  password?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onKeyPress?: React.KeyboardEventHandler<HTMLDivElement>;
}

const FormFieldAsTextField: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [password, setPassword] = useState(true);
  const type = props.type === "date" ? "date" 
    : props.type === "password" && password ? "password" : "text";

  const tooglePassword = () => setPassword((v) => !v);

  return (
    <Fragment>
      {props.title && (
        <Typography className={classes.body1}>
          {t(props.title)}
        </Typography>
      )}
      <Field 
        className={classes.field}
        as={TextField}
        name={props.name}
        size="small"
        type={type}
        error={!!props.error && !!props.touched}
        variant="outlined"
        disabled={props.disabled}
        autoFocus={props.autoFocus}
        fullWidth={props.fullWidth}
        helperText={!!props.touched && !!props.error && t(props.error)}
        placeholder={t(props.placeholder ?? "")}
        onFocus={props.onFocus}
        onKeyPress={props.onKeyPress}
        InputProps={
          props.type === "password" ? {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton size="small" onClick={tooglePassword}>
                  {!!password ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          } : {}
        }
      />
    </Fragment>
  )
}

export default FormFieldAsTextField;
