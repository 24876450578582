import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, Grid } from '@material-ui/core';
import { State } from '../store/types';
import Landing  from '../components/Landing/Landing';
import LayoutLanding  from '../layouts/LayoutLanding';

const useStyles = makeStyles((theme) => ({
  page: {
    backgroundColor: '#ffffff',
    position: 'relative',
    height: '100vh',
    [theme.breakpoints.down("sm")]: { height: 'fit-content' },
  },
}));

const LandingPage = () => {
  const classes  = useStyles();
  const user = useSelector((state: State) => state.user.user);

  // React.useEffect(() => { if (!!user.nit) history.push('/home') }, [user]);

  return (
    <Grid className={classes.page} container alignItems="center">
      <LayoutLanding />
      <Landing user={user} />
    </Grid>
  )
}

export default LandingPage;
