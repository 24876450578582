import React, { FC } from "react";
import { styled } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

const Audio = styled("audio")({ width: "100%", height: 40 });

interface IProps {
  src: string;
}

const AudioPlayer: FC<IProps> = ({ src }) => {
  return (
    <Grid container>
      <Audio src={src} controls />
    </Grid>
  );
}

export default AudioPlayer;
