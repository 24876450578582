import axios from "axios";

const url = process.env.REACT_APP_BACKEND_URL;
const route = `${url}/api`;

// const route = "http://localhost:5003/olab-46253/us-central1/app/api";

export interface ICheckout {
  name: string;
  email: string;
  phone: string;
  address: string;
  sponsor: string;
  nationality: string;
  countryCode: string;
}
export interface ILicense {
  uid: string;
  name: string;
  amount: IAmount;
  amounts: IAmount[];
  min_users: number;
  max_users: number;
  color_one: string;
  color_two: string;
}
export interface IDonation { 
  amount: number;
  currency: "USD"|"COP"; 
  subscription: boolean; 
}
export interface IAmount { 
  uid: string;
  cop: number; 
  usd: number; 
  name: string; 
};
export interface IProject {
  uid: string
  name: string
  amounts: IAmount[]
  color_one: string
  color_two: string
  min_users: number
  max_users: number
}

export const donationInit: IDonation = { 
  amount: 0, 
  currency: "USD", 
  subscription: false, 
}
export const amountInit: IAmount = { 
  uid: "", 
  cop: 0, 
  usd: 0, 
  name: "", 
};
export const licenseInit: ILicense = {
  uid: "",
  name: "",
  amount: amountInit,
  amounts: [],
  min_users: 0,
  max_users: 0,
  color_one: "",
  color_two: "",
}
export const projectsInit: IProject[] = [
  {
    uid: "prod_KzhCS2LZJF4Yu0",
    name: "Plan pequeño",
    amounts: [
      {
        uid: "price_1KJhnoJT4R6W55saMqleUZVr", 
        cop: 2800000, 
        usd: 1800, 
        name: "Año"
      },
    ],
    color_one: "#6789E5",
    color_two: "#5B79E8",
    min_users: 1,
    max_users: 30,
  },
  {
    uid: "prod_L5QbPvgL720B16",
    name: "Plan mediano",
    amounts: [
      {
        uid: "price_1KPFkmJT4R6W55saHYkqJvlu", 
        cop: 13600000,
        usd: 3600,
        name: "Año"
      },
    ],
    color_one: "#4CCC6A",
    color_two: "#34C65D",
    min_users: 31,
    max_users: 100,
  },
  {
    uid: "prod_L5QvRhJ43IijiT",
    name: "Plan grande",
    amounts: [
      {
        uid: "price_1KPG3JJT4R6W55sa4dccOdSS", 
        cop: 27200000,
        usd: 7500,
        name: "Año"
      },
    ],
    min_users: 101,
    max_users: 500,
    color_one: "#EABF0A",
    color_two: "#E2B700",
  }
];

class Checkout {
  async postPayment(req: ILicense, _currency: "usd"|"cop", description: string) {
    const { data } = await axios.post(`${route}/payment_intents`, {
      price: req.amount.uid,
      project: req.uid,
      currency: "usd",
      description,
    });
    return data;
  }

  async postDonation(amount: number, currency: string, description: string) {
    const { data } = await axios.post(`${route}/payment_intents/donation`, {
      amount, 
      currency,
      description,
    });
    return data;
  }
}

const checkout = new Checkout()
export default checkout;
