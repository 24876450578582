import React, { FC } from "react";
import { Rect } from "react-konva";

interface IProps {
  fill: string;
  width: number;
  height: number;
  cornerRadius?: number | number[];
}

const StageBackground: FC<IProps> = (props) => {
  return (
    <Rect
      x={0}
      y={0}
      fill={props.fill}
      width={props.width}
      height={props.height}
      cornerRadius={props.cornerRadius}
    />
  );
};

export default StageBackground;
