import React, { FC, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, Grid } from "@material-ui/core";
import { IEntity } from "dto/entity.dto";
import Loading from "components/Common/Loading";
import ToastDialog from "components/Common/ToastDialog";
import EntityCodeForm from "./EntityCodeForm";
import EntityCodeSuccess from "./EntityCodeSuccess";

const useStyles = makeStyles(() => ({
  dialog: {
    "& .MuiDialog-paper": { 
      padding: 60,
      maxWidth: 516,
      textAlign: "center",
      borderRadius: 32,
      backgroundColor: "white", 
    },
  },
  header: { 
    position: "relative",
    alignItems: "center", 
    marginBottom: 44,
    justifyContent: "center", 
  },
}));

interface IProps {
  open: boolean;
  entity: IEntity;
  sponsor: boolean;
  profileLoading: boolean;
  onClose: () => void;
  sendEntityCode: (code: string) => void;
}

const DialogEntityCode: FC<IProps> = (props) => {
  const classes = useStyles();
  const [entityCode, setEntityCode] = useState("");

  const sendEntityCode = () => {
    props.sendEntityCode(entityCode);
  }

  const onClose = () => {
    if (props.profileLoading) return;
    else props.onClose();
  }

  return (
    <Dialog className={classes.dialog} open={props.open} onClose={onClose}>
      <Grid className={classes.header} container>
        <img src="/img/logos/olab.svg" alt="" width={148} />
      </Grid>

      {props.sponsor ? (
        <EntityCodeSuccess entity={props.entity} onClose={onClose} />
      ) : (
        <EntityCodeForm 
          entityCode={entityCode}
          sendEntityCode={sendEntityCode}
          updateEntityCode={setEntityCode} 
        />
      )}

      <Loading loading={props.profileLoading} white />
      <ToastDialog />
    </Dialog>
  )
}

export default DialogEntityCode;
