export const languaje_AR = {
  translation: {
    Curso:"دورة",
    "¡Curso completado!":"اكتملت الدورة!",
    "¡Unidad terminada!":"انتهت الوحدة!",
    "¡Módulo completado!":"اكتملت الوحدة!",
    "Curso no completado":"الدورة لم تكتمل",
    "Unidad no completada":"الوحدة لم تكتمل",
    "Módulo no completado":"الوحدة لم تكتمل",
    "Dale continuar para avanzar":'انقر فوق "متابعة" للتقدم',
    "Avanza a la siguiente unidad":"التقدم إلى الوحدة التالية",
    "Has desbloqueado la siguiente unidad":"لقد قمت بفتح الوحدة التالية",
    "Ya has completado el curso satisfactoriamente":"لقد أكملت بالفعل الدورة بشكل مرضي",
    
    "Estudio": "أدرس",
    "Trabajo": "عمل",
    "Startup": "شركة ناشئة",
    "unidades": "وحدات",
    "Posgrado": "دراسات عُليا",
    "Comercial": "تجاري",
    "Liderazgo": "قيادة",
    "Tecnología": "تكنولوجيا",
    "Freelancer": "عمل حر",
    "Empresario": "رائد أعمال",
    "Operaciones": "عمليات",
    "Emprendimiento": "ريادة الأعمال",
    "Director / VP": "مدير / نائب الرئيس",
    "Lider de equipo": "قائد الفريق",
    "Entidad pública": "كيان عام",
    "Empresa privada": "شركة خاصة",
    "Básica primaria": "التعليم الابتدائي",
    "Recursos humanos": "الموارد البشرية",
    "Básica secundaria": "التعليم الثانوي",
    "Miembro de equipo": "عضو في الفريق",
    "Educación superior": "التعليم العالي",
    "Actualmente, yo...": "حالياً، أنا...",
    "Gestión de equipos": "إدارة الفرق",
    "Conseguir un empleo": "الحصول على وظيفة",
    "Habilidades blandas": "المهارات الناعمة",
    "Gestión de proyectos": "إدارة المشاريع",
    "Conocimiento general": "معرفة عامة",
    "Escoge tus intereses": "اختر اهتماماتك",
    "Desarrollo de carrera": "تطوير الحياة المهنية",
    "Institución educativa": "المؤسسة التعليمية",
    "Responsabilidad social": "المسؤولية الاجتماعية",
    "Orientación vocacional": "التوجيه المهني",
    "¿Qué te describe mejor?": "ما الذي يصفك بشكل أفضل؟",
    "Ninguna de las anteriores": "لا أحد من السابق ذكره",
    "Entidad sin ánimo de lucro": "كيان غير ربحي",
    "Marketing y comunicaciones": "التسويق والاتصالات",
    "¿A qué área laboral perteneces?": "إلى أي مجال عمل تنتمي؟",
    "¿Cuál es tu nivel de educación?": "ما هو مستوى تعليمك؟",
    "¿Cuál es tu fecha de nacimiento?": "ما هو تاريخ ميلادك؟",
    "Estoy buscando estudiar o trabajar": "أنا أبحث عن دراسة أو عمل",
    "¿En qué tipo de organización trabajas?": "في أي نوع من المؤسسات تعمل؟",
    "¿Cuántos integrantes tiene tu organización؟": "كم عدد أعضاء منظمتك؟",

    "Idioma": "اللغة",
    "Inglés": "الإنجليزية",
    "Español": "الإسبانية",
    "¡Hola!": "مرحبًا!",
    "¡Todo listo!": "كل شيء جاهز!",
    "Súper administrador": "مدير فائق",
    "¡Bienvenido a O-lab!": "مرحبًا بك في O-lab!",
    "¡Ya casi terminamos!": "نحن على وشك الانتهاء!",
    "Crea tu perfil ahora": "أنشئ ملفك الشخصي الآن",
    "¿Salir del laboratorio?": "هل تريد الخروج من المختبر؟",
    "¿Tienes un código organizacional?": "هل لديك رمز تنظيمي؟",
    "Configura el idioma de esta plataforma": "اضبط لغة هذه المنصة",
    "Si te sales del laboratorio perderás el progreso guardado hasta el momento": "إذا خرجت من المختبر، ستفقد التقدم الذي تم حفظه حتى الآن",
    "Te recomendamos crear una nueva cuenta para tener tu propio perfil، además de guardar el progreso de tus contenidos، compartir en la comunidad، chatear y mucho más": "نوصيك بإنشاء حساب جديد لديك ملف شخصي خاص بك، بالإضافة إلى حفظ تقدم محتواك ومشاركته في المجتمع والدردشة والمزيد",

    "Hola": "مرحبًا",
    "Omitir": "تخطي",
    "Explora": "استكشاف",
    "Comenzar": "بدء",
    "Ver curso": "عرض الدورة",
    "Todo listo": "كل شيء جاهز",
    "Busca labs": "ابحث عن المختبرات",
    "¡Empecemos!": "لنبدأ!",
    "Elige un país": "اختر بلدًا",
    "Elige tu país": "اختر بلدك",
    "Ver formulario": "عرض النموذج",
    "Solicitar demo": "طلب عرض توضيحي",
    "Nombre completo": "الاسم الكامل",
    "Respuesta única": "إجابة واحدة",
    "Crea una cuenta": "إنشاء حساب",
    "Elige tu género": "اختر جنسك",
    "Ya tengo cuenta": "لدي حساب بالفعل",
    "Crear cuenta con": "إنشاء حساب باستخدام",
    "Llena los campos": "املأ الحقول",
    "Ingresa tu correo": "أدخل بريدك الإلكتروني",
    "Ingresa tu nombre": "أدخل اسمك",
    "Antes de comenzar": "قبل البدء",
    "Bienvenido a O-lab": "مرحبًا بك في O-lab",
    "Completa tus datos": "أكمل بياناتك",
    "Múltiple respuesta": "إجابة متعددة",
    "Ya casi terminamos": "نحن على وشك الانتهاء",
    "Edad mínima 7 años": "الحد الأدنى للعمر 7 سنوات",
    "Mínimo 6 caracteres": "الحد الأدنى للحروف 6",
    "Repite tu contraseña": "أعد إدخال كلمة المرور",
    "Prefiero no responder": "أفضل عدم الرد",
    "Saltar, no tengo código": "تخطي، ليس لدي رمز",
    "Unirme a mi organización": "الانضمام إلى منظمتي",
    "Fecha de nacimiento incorrecta": "تاريخ الميلاد غير صحيح",
    "Labs libres ofrecidos por O-lab": "مختبرات مجانية يقدمها O-lab",
    "Tienes un código organizacional": "هل لديك رمز تنظيمي",
    "El código ingresado no es válido": "الرمز المدخل غير صالح",
    "Ahora haces parte de la organización": "الآن أنت جزء من المنظمة",
    "Necesitamos saber un poco más sobre ti": "نحتاج إلى معرفة المزيد عنك قليلاً",
    "Personaliza O-lab para tu organización": "قم بتخصيص O-lab لمنظمتك",
    "Todavía no perteneces a una organización": "لست بعد جزءًا من منظمة",
    "ya tienes acceso a su contenido y comunidad en O-lab": "لديك الآن الوصول إلى محتواها ومجتمعها في O-lab",
    "Lleva tus entrenamientos, inducciones, comunicaciones y más al siguiente nivel": "قم برفع تدريباتك ومقدماتك واتصالاتك والمزيد إلى المستوى التالي",
    "Si todavía no tienes el código organizacional, después lo puedes ingresar desde tu perfil": "إذا لم تكن لديك بعد رمز تنظيمي، يمكنك إدخاله لاحقًا من ملفك الشخصي",
    "Responde este corto cuestionario para poder empezar a explorar el contenido gratuito ofrecido por O-lab": "قم بالرد على هذا الاستبيان القصير لبدء استكشاف المحتوى الم",
    "Si tu organización te compartió un código de O-Lab ingrésalo en el siguiente campo, de lo contrario salta este paso": "إذا شاركت مؤسستك رمز O-Lab معك، فأدخله في الحقل التالي، وإلا قم بتخطي هذه الخطوة",

    "o": "أيضاً",
    "País": "دولة",
    "Falso": "غير صحيح",
    "Ninguno": "لا أحد",
    "Primaria": "أساسي",
    "Verdadero": "صحيح",
    "Finalizar": "إنهاء",
    "Incorrecta": "خاطئة",
    "Formulario": "نموذج",
    "Secundaria": "ثانوي",
    "Idioma urdu": "اللغة الأردية",
    "Cargar audio": "تحميل الصوت",
    "Grabar audio": "تسجيل الصوت",
    "Continúa asi": "استمر على هذا النحو",
    "Configuración": "الإعدادات",
    "Editar género": "تحرير الجنس",
    "Cargar imagen": "تحميل الصورة",
    "¡Felicidades!": "تهانينا!",
    "Idioma inglés": "اللغة الإنجليزية",
    "Idioma tagalo": "اللغة التاغالوغية",
    "Idioma español": "اللغة الإسبانية",
    "Idioma francés": "اللغة الفرنسية",
    "Felicitaciones": "تهانينا",
    "Notificaciones": "الإشعارات",
    "Opción múltiple": "اختيار متعدد",
    "No te descuides": "لا تكن مهملاً",
    "Nueva contraseña": "كلمة مرور جديدة",
    "Verdadero y falso": "صحيح أم خاطئ",
    "Guardar grabación": "حفظ التسجيل",
    "Detener grabación": "إيقاف التسجيل",
    "Iniciar grabación": "بدء التسجيل",
    "Respuesta Correcta": "إجابة صحيحة",
    "Seleccionar idioma": "اختيار اللغة",
    "Fecha de nacimiento": "تاريخ الميلاد",
    "Vuelve a intentarlo": "أعد المحاولة",
    "Respuesta Incorrecta": "إجابة خاطئة",
    "Información personal": "المعلومات الشخصية",
    "Salir de la grabación": "الخروج من التسجيل",
    "¡Bienvenido de nuevo!": "مرحبًا مجددًا!",
    "Terminaste esta encuesta": "لقد انتهيت من هذا الاستطلاع",
    "Iniciar sesión con email": "تسجيل الدخول بالبريد الإلكتروني",
    "Haga clic para regístrate": "انقر للتسجيل",
    "Haga clic para ingresar a": "Haga clic para ingresar a",
    "Editar fecha de nacimiento": "تحرير تاريخ الميلاد",
    "Confirmar nueva contraseña": "تأكيد كلمة المرور الجديدة",
    "Iniciar sesión con teléfono": "تسجيل الدخول برقم الهاتف",
    "Encuentra todas las palabras": "العثور على كل الكلمات",
    "Seleccionar verdadero o falso": "اختيار صحيح أم خاطئ",
    "Haga clic para iniciar sesión": "انقر للبدء في الجلسة",
    "Escribe tu contraseña de acceso": "اكتب كلمة المرور الخاصة بك",
    "Seleccione la respuesta correcta": "اختر الإجابة الصحيحة",
    "Haga clic para ingresar al tutorial": "انقر للدخول إلى البرنامج التعليمي",
    "Haga clic para volver al menú anterior": "انقر للعودة إلى القائمة السابقة",
    "Haga clic para seleccionar la respuesta": "انقر لاختيار الإجابة",
    "Escribe tu correo electrónico de acceso": "اكتب بريدك الإلكتروني",
    "Haga clic para restablecer la contraseña": "انقر لإعادة تعيين كلمة المرور",
    "Haga clic para iniciar sesión con el email": "انقر لتسجيل الدخول باستخدام البريد الإلكتروني",
    "Haga clic para ingresar a la vista de blog": "انقر للدخول إلى عرض المدونة",
    "Haga clic para ingresar a la vista de notas": "انقر للدخول إلى عرض الملاحظات",
    "Haga clic para regresar al listado de módulo": "انقر للعودة إلى قائمة الوحدات",
    "Haga clic para ingresar al buzón de mensajes": "انقر للدخول إلى صندوق البريد",
    "Haga clic para iniciar sesión con el teléfono": "انقر لتسجيل الدخول باستخدام الهاتف",
    "Haga clic para regresar a la lección anterior": "انقر للعودة إلى الدرس السابق",
    "Haga clic para regresar a la pregunta anterior": "انقر للعودة إلى السؤال السابق",
    "Haga clic para continuar a la lección siguiente": "انقر للانتقال إلى الدرس التالي",
    "Haga clic para reproducir el audio de la lección": "انقر لتشغيل الصوت للدرس",
    "Haga clic para continuar a la pregunta siguiente": "انقر للانتقال إلى السؤال التالي",
    "Haga clic para reproducir el audio de la pregunta": "انقر لتشغيل الصوت للسؤال",
    "Haga clic para regresar al listado de laboratorios": "انقر للعودة إلى قائمة المختبرات",

    "Cargar archivo": "تحميل الملف",
    "Guardar y salir": "حفظ والخروج",
    "Educación continua": "التعليم المستمر",
    "Aceleración escolar": "تسريع المدرسة",
    "Repetir formulario": "إعادة النموذج",
    "Formulario privado": "نموذج خاص",
    "Finalizar formulario": "إنهاء النموذج",
    "Página no encontrada": "الصفحة غير موجودة",
    "Educación vocacional": "التعليم المهني",
    "Formulario no encontrado": "النموذج غير موجود",
    "Educación complementaria": "التعليم التكميلي",
    "No tienes laboratorios habilitados": "ليس لديك مختبرات مفعلة",
    "El formulario es privado, verifique el acceso": "النموذج خاص، يرجى التحقق من الوصول",
    "Debes finalizar primero los módulos anteriores": "يجب عليك إنهاء الوحدات السابقة أولاً",
    "El formulario solicitado no existe, verifique el acceso": "النموذج المطلوب غير موجود، يرجى التحقق من الوصول",
    "Lo sentimos, no se pudo encontrar la página que solicitó, vuelva a la página de inicio": "نأسف، لم يتم العثور على الصفحة المطلوبة، يرجى العودة إلى الصفحة الرئيسية",

    "a": "ل",
    "El": "هو",
    "OK": "تمام",
    "Yo": "أنا",
    "ID": "ID",
    "año": "سنة",
    "Año": "عام",
    "mes": "شهر",
    "Mes": "شهر",
    "Ver": "عرض",
    "Blog": "مدونة",
    "años": "سنوات",
    "Nota": "ملاحظة",
    "Reto": "تحدي",
    "Para": "لـ",
    "Edad": "العمر",
    "Sexo": "الجنس",
    "Otro": "آخر",
    "Mayo": "مايو",
    "Retos": "تحديات",
    "Notas": "ملاحظات",
    "Buzón": "صندوق البريد",
    "Enero": "يناير",
    "Marzo": "مارس",
    "Abril": "أبريل",
    "Junio": "يونيو",
    "Julio": "يوليو",
    "Otros": "آخرون",
    "Dudas": "استفسارات",
    "Donar": "التبرع",
    "Pesos": "بيزو",
    "Nuevo": "جديد",
    "Salir": "خروج",
    "Grado": "صف دراسي",
    "Asunto": "موضوع",
    "Unidad": "وحدة",
    "Módulo": "وحدة دراسية",
    "Enviar": "إرسال",
    "Volver": "العودة",
    "Género": "جنس",
    "Título": "عنوان",
    "Perfil": "ملف شخصي",
    "Inicio": "الصفحة الرئيسية",
    "Nombre": "الاسم",
    "Buscar": "بحث",
    "Cerrar": "إغلاق",
    "Editar": "تحرير",
    "Agosto": "أغسطس",
    "Cursos": "دورات",
    "Dólares": "دولار",
    "Mensual": "شهرياً",
    "Mensaje": "رسالة",
    "Aceptar": "موافق",
    "Público": "عام",
    "Privado": "خاص",
    "Octubre": "أكتوبر",
    "Febrero": "فبراير",
    "Docente": "مدرس",
    "Lección": "درس",
    "Repetir": "كرر",
    "Guardar": "حفظ",
    "Módulos": "وحدات دراسية",
    "Reenviar": "إعادة إرسال",
    "Mensajes": "رسائل",
    "Tutorial": "درس تعليمي",
    "Eliminar": "حذف",
    "Unidades": "وحدات",
    "Cancelar": "إلغاء",
    "Explicar": "شرح",
    "Enviados": "مرسلة",
    "Lenguaje": "لغة",
    "Teléfono": "هاتف",
    "Femenino": "أنثى",
    "Licencia": "رخصة",
    "Usuarios": "مستخدمين",
    "usuarios": "مستخدمين",
    "Respuesta": "إجابة",
    "Noviembre": "نوفمبر",
    "Diciembre": "ديسمبر",
    "Comunidad": "مجتمع",
    "Recibidos": "مستلمة",
    "Responder": "الرد",
    "Compartir": "مشاركة",
    "Continuar": "متابعة",
    "Masculino": "ذكر",
    "Siguiente": "التالي",
    "finalizar": "إنهاء",
    "Resultados": "نتائج",
    "Septiembre": "سبتمبر",
    "Estudiante": "طالب",
    "Bienvenido": "مرحبًا",
    "Regístrate": "سجل",
    "Materiales": "مواد",
    "Contraseña": "كلمة المرور",
    "Destacados": "مميزة",
    "Actualizar": "تحديث",
    "Respuestas": "إجابات",
    "Contáctanos": "اتصل بنا",
    "Actividades": "أنشطة",
    "Laboratorio": "مختبر",
    "Descripción": "وصف",
    "Institución": "مؤسسة",
    "Felicidades": "تهانينا",
    "Comentarios": "تعليقات",
    "Calificación": "تقييم",
    "Laboratorios": "مختبرات",
    "Nacionalidad": "جنسية",
    "Organización": "منظمة",
    "Administrador": "مدير",
    "Almacenamiento": "تخزين",

    "E-mail": "البريد الإلكتروني",
    "Ver todos": "عرض الكل",
    "Ver todas": "عرض الكل",
    "Mis blogs": "مدوناتي",
    "Mis notas": "ملاحظاتي",
    "y nuestra": "و لدينا",
    "Enviar nota": "إرسال ملاحظة",
    "Crear cuenta": "إنشاء حساب",
    "Guardar nota": "حفظ الملاحظة",
    "Bienvenido a": "مرحبًا في",
    "Validar edad": "تحقق من العمر",
    "Ver unidades": "عرض الوحدات",
    "Sin realizar": "لم يتم القيام به بعد",
    "Escribe aquí": "اكتب هنا",
    "Cerrar sesión": "تسجيل الخروج",
    "Publicado por": "نشر بواسطة",
    "Mensaje nuevo": "رسالة جديدة",
    "Nuevo mensaje": "رسالة جديدة",
    "Ver resultados": "عرض النتائج",
    "Progreso total": "التقدم الكلي",
    "Regístrate con": "سجل الدخول باستخدام",
    "Primera lengua": "اللغة الأولى",
    "Segunda lengua": "اللغة الثانية",
    "Iniciar sesión": "تسجيل الدخول",
    "Enviar mensaje": "إرسال رسالة",
    "Blog principal": "المدونة الرئيسية",
    "Compartir nota": "مشاركة الملاحظة",
    "del laboratorio": "من المختبر",
    "Guardar cambios": "حفظ التغييرات",
    "Respuesta libre": "إجابة حرة",
    "RESPUESTA LIBRE": "إجابة حرة",
    "Campo requerido": "حقل مطلوب",
    "Subir evidencia": "تحميل الدليل",
    "Actualizar nota": "تحديث الملاحظة",
    "Única respuesta": "إجابة واحدة",
    "Verificar código": "التحقق من الرمز",
    "Enviar respuesta": "إرسال الإجابة",
    "Siguiente noticia": "الخبر التالي",
    "Actualizar Perfil": "تحديث الملف الشخصي",
    "Has completado un": "لقد أكملت",
    "Editar laboratorio": "تحرير المختبر",
    "Cambiar contraseña": "تغيير كلمة المرور",
    "Correo electrónico": "البريد الإلكتروني",
    "Perfil actualizado": "تم تحديث الملف الشخصي",
    "Contraseña invalida": "كلمة مرور غير صحيحة",
    "Personalizar avatar": "تخصيص الصورة الرمزية",
    "Objetivos pedagógicos": "الأهداف التعليمية",
    "Descargar certificado": "تنزيل الشهادة",
    "Restablecer contraseña": "إعادة تعيين كلمة المرور",
    "Estudiantes registrados": "الطلاب المسجلين",
    "Ciudad/País": "المدينة/الدولة",
    "Comprar ahora": "شراء الآن",
    "Aplicación web": "تطبيق الويب",
    "Plan grande": "خطة كبيرة",
    "Donación segura": "تبرع آمن",
    "Usuarios finales": "المستخدمين النهائيين",
    "usuarios finales": "المستخدمين النهائيين",
    "Plan mediano": "خطة متوسطة",
    "Plan pequeño": "خطة صغيرة",
    "de almacenamiento": "تخزين",
    "Completar registro": "استكمال التسجيل",
    "Confirmar contraseña": "تأكيد كلمة المرور",
    "Servicios adicionales": "خدمات إضافية",
    "Asistencia pedagógica": "الدعم التعليمي",
    "Plan personalizado": "خطة مخصصة",
    "Cantidad personalizada": "كمية مخصصة",
    "Usuarios administradores": "مديري المستخدمين",
    "Voluntariado corporativo": "عمل تطوعي للشركات",
    "usuarios administradores": "مديري المستخدمين",

    "Ver en línea": "عرض عبر الإنترنت",
    "Sopa de letras": "لعبة الكلمات المتقاطعة",
    "Nombre de unidad": "اسم الوحدة",
    "Código del curso": "رمز الدورة",
    "Título de la nota": "عنوان المذكرة",
    "Nombre y apellido": "الاسم واللقب",
    "Buzón de mensajes": "صندوق الرسائل",
    "Buscar por nombre": "البحث بالاسم",
    "Número de teléfono": "رقم الهاتف",
    "Iconos de posición": "رموز الموقع",
    "Iniciar sesión con": "تسجيل الدخول باستخدام",
    "Compartir como blog": "مشاركة كمدونة",
    "Completa la oración": "اكمل الجملة",
    "Escribe tu respuesta": "اكتب إجابتك",
    "Máximo 50 caracteres": "الحد الأقصى 50 حرفًا",
    "Términos de servicio": "شروط الخدمة",
    "Código de verificación": "رمز التحقق",
    "Política de privacidad": "سياسة الخصوصية",
    "Encuentra las palabras": "ابحث عن الكلمات",
    "Seleccionar una imagen": "اختيار صورة",
    "Dar una vez": "قدم مرة واحدة",
    "Doblaje de voz": "تسجيل صوتي مزدوج",
    "Número de tarjeta": "رقم البطاقة",
    "No hay actividades": "لا توجد أنشطة",
    "Compra de licencia": "شراء الترخيص",
    "Nombre del titular": "اسم صاحب البطاقة",
    "Diseño de contenidos": "تصميم المحتوى",
    "Completa tu registro": "استكمال التسجيل",
    "Agrega tu información": "أضف معلوماتك",
    "Nombre de organización": "اسم المنظمة",
    "Comprar/renovar licencia": "شراء/تجديد الترخيص",
    "Condiciones del servicio": "شروط الخدمة",
    "Nota enviada exitosamente": "تم إرسال المذكرة بنجاح",
    "Nota guardada exitosamente": "تم حفظ المذكرة بنجاح",
    "Nota eliminada exitosamente": "تم حذف المذكرة بنجاح",
    "Nota compartida exitosamente": "تم مشاركة المذكرة بنجاح",
    "Verificar correo electrónico": "التحقق من البريد الإلكتروني",
    "Organización/Escuela/Empresa": "المنظمة/المدرسة/الشركة",
    "Nota actualizada exitosamente": "تم تحديث المذكرة بنجاح",
    "Soporte técnico virtual": "الدعم الفني الافتراضي",
    "Plataforma web personalizable": "منصة الويب قابلة للتخصيص",
    "Aplicación móvil personalizable": "تطبيق الهاتف المحمول قابل للتخصيص",
    "Servicios adicionales (opcional)": "خدمات إضافية (اختياري)",


    "Enviar a un amigo": "إرسال إلى صديق",
    "Guardar y enviar nota": "حفظ وإرسال المذكرة",
    "Validar y guardar edad": "التحقق وحفظ العمر",
    "Acceder con el teléfono": "الوصول باستخدام الهاتف",
    "Has completado el módulo": "لقد أكملت الوحدة",
    "Has completado la unidad": "لقد أكملت الوحدة",
    "El módulo no tiene retos": "الوحدة ليست بها تحديات",
    "La edad debe ser mayor a 0": "العمر يجب أن يكون أكبر من 0",
    "Escribe aquí tu comentario": "اكتب تعليقك هنا",
    "Regístrate con tu teléfono": "سجل الدخول باستخدام هاتفك",
    "Configuración de tu cuenta": "إعداد حسابك",
    "Envía un mensaje o llama a": "أرسل رسالة أو اتصل بـ",
    "Correo enviado exitosamente": "تم إرسال البريد الإلكتروني بنجاح",
    "Verificar número de teléfono": "التحقق من رقم الهاتف",
    "Ingresa tu número de teléfono": "أدخل رقم هاتفك",
    "Envia un correo electrónico a": "أرسل بريدًا إلكترونيًا إلى",
    "El usuario ya está registrado": "المستخدم مسجل بالفعل",
    "El usuario no está registrado": "المستخدم غير مسجل",
    "Arrastra la respuesta correcta": "اسحب الإجابة الصحيحة",
    "Las contraseñas deben coincidir": "يجب أن تتطابق كلمات المرور",
    "El laboratorio no tiene módulos": "المختبر ليس به وحدات",
    "Selecciona la respuesta correcta": "اختر الإجابة الصحيحة",
    "Acceder con el correo electrónico": "الوصول باستخدام البريد الإلكتروني",
    "No estás habilitado para ingresar": "ليس لديك إذن للوصول",
    "¿Deseas comunicarte con un tutor?": "هل تريد التحدث مع معلم؟",
    "Existe un usuario con ese teléfono": "هناك مستخدم بتلك الهوية",
    "Regístrate con tu correo electrónico": "سجل الدخول باستخدام بريدك الإلكتروني",
    "Nota guardada y enviada exitosamente": "تم حفظ وإرسال المذكرة بنجاح",
    "para que puedas cambiar tu contraseña": "لكي تتمكن من تغيير كلمة المرور الخاصة بك",
    "El código de verificación no es válido": "رمز التحقق غير صحيح",
    "La unidad siguiente no tiene lecciones": "الوحدة التالية ليست بها دروس",
    "¡Lo hacemos por ti!": "نحن نفعل ذلك من أجلك!",
    "por un período de un": "لمدة",
    "se realizó con éxito": "تمت بنجاح",
    "¡Gracias por tu ayuda!": "شكرًا لمساعدتك!",
    "La donación fue exitosa": "كان التبرع ناجحًا",
    "Elige cómo quieres ayudar": "اختر كيف تريد المساعدة",
    "Gracias por tu donación de": "شكرًا لتبرعك بمبلغ",
    "¡Cerremos la brecha juntos!": "لنُغلق الفجوة معًا!",
    "El valor de la donación es $0": "قيمة التبرع هي 0 دولار",
    "Reportes de impacto específicos": "تقارير تأثير محددة",
    "Sistema de gestión de proyectos": "نظام إدارة المشاريع",
    "Asistencia técnica y de soporte": "الدعم الفني والدعم",
    "Análisis y reportes automáticos": "تحليلات وتقارير تلقائية",
    "Mensajes de audio personalizables": "رسائل صوتية قابلة للتخصيص",
    "Plataforma de gestión de contenido": "منصة إدارة المحتوى",
    "Diseño y animación de avatares y más": "تصميم ورسوم متحركة للصورة الرمزية والمزيد",
    "Funciones de seguimiento y evaluación": "وظائف التتبع والتقييم",
    "Sistema de gestión de aprendizaje (LMS)": "نظام إدارة التعلم (LMS)",
    "Asistencia en coordinación de proyectos": "مساعدة في تنسيق المشاريع",
    "Existe un usuario con esa identificación": "هناك مستخدم بتلك المعرفة",
    "Iniciar sesión con tu correo electrónico": "تسجيل الدخول باستخدام بريدك الإلكتروني",
    "Institución educativa o comunidad afiliada": "مؤسسة تعليمية أو مجتمع تابع",
    "Existe un usuario con ese correo electrónico": "هناك مستخدم بذلك البريد الإلكتروني",


    "Puedes acceder y ver los contenidos que se ofrecen": "يمكنك الوصول ومشاهدة المحتويات المقدمة",
    "Ante cualquier duda puedes contactar a tu docente": "في حالة وجود أي استفسار يمكنك التواصل مع معلمك",
    "Al continuar, estás indicando que aceptas nuestros": "بالمتابعة، أنت توافق على شروطنا",
    "Ingresa el código de 6 dígitos que enviamos al número": "ادخل الرمز المكون من 6 أرقام الذي أرسلناه إلى الرقم",
    "de estudiantes registrados ha terminado el laboratorio": "من الطلاب المسجلين قد انتهوا من المعمل",
    "Se enviará un mensaje con un enlace al correo electrónico": "سيتم إرسال رسالة تحتوي على رابط إلى البريد الإلكتروني",
    "Debes ser mayor de edad para poder acceder a esta sección": "يجب أن تكون في سن الرشد للوصول إلى هذا القسم",
    "Mensaje enviado exitosamente, revisa tu correo electrónico": "تم إرسال الرسالة بنجاح، يرجى مراجعة بريدك الإلكتروني",
    "Correo electrónico ya está siendo utilizado por otra cuenta": "البريد الإلكتروني مستخدم بالفعل من قبل حساب آخر",
    "Acceso desactivado temporalmente debido a muchos intentos fallidos": "تم تعطيل الوصول مؤقتًا بسبب العديد من المحاولات الفاشلة",
    "¡Hola! Te damos la bienvenida a la versión web de la aplicación O-lab": "مرحبًا! نرحب بك في النسخة الويب لتطبيق O-lab",
    "¡Te mantendremos informado sobre el impacto de tu donación! #somoselorigen": "سنبقيك على علم بتأثير تبرعك! #نحن_الأصل",
    "Los laboratorios son tu espacio de trabajo en los que realizarás actividades": "المعامل هي المساحة الخاصة بك حيث ستقوم بإجراء الأنشطة",
    "Aquí podrás ver la sección en la que te encuentras dentro de la aplicación web": "هنا يمكنك رؤية القسم الذي توجد فيه داخل التطبيق الويب",
    "Estos mensajes son monitoreados para garantizar la seguridad de nuestros usuarios": "يتم مراقبة هذه الرسائل لضمان أمان مستخدمينا",
    "Los módulos son secciones en las que ingresarás para aprender y realizar actividades": "الوحدات هي الأقسام التي ستدخلها لتعلم وإجراء الأنشطة",
    "Esta sección solo está habilitada para mayores de edad, por favor valida tu edad para continuar": "هذا القسم مخصص فقط للبالغين، يرجى التحقق من عمرك للمتابعة",
    "Se enviará un mensaje a la dirección de correo electrónico que has ingresado para que puedas cambiar tu contraseña.": "سيتم إرسال رسالة إلى عنوان البريد الإلكتروني الذي أدخلته لتتمكن من تغيير كلمة المرور الخاصة بك",
    "Si presionas Verificar número de teléfono se enviará un SMS. Se aplicarán las tarifas de mensaje y datos": "إذا ضغطت على التحقق من رقم الهاتف سيتم إرسال رسالة نصية قصيرة. سيتم تطبيق رسوم الرسائل والبيانات",
    "En esta sección puedes cambiar tus datos personales, escoger una foto de perfil y revisar el progreso de tus cursos": "يمكنك في هذا القسم تغيير بياناتك الشخصية، واختيار صورة للملف الشخصي، ومراجعة تقدم دوراتك",
    "En este espacio encontrarás material de estudio que está dividido por unidades, lecciones y actividades": "في هذه المساحة ستجد مواد الدراسة المنقسمة حسب الوحدات والدروس والأنشطة",
    "Este es un espacio público en el que encontrarás material de estudio realizado y compartido por los estudiantes de O-Lab": "هذه مساحة عامة حيث ستجد المواد الدراسية التي أعدها وشاركها طلاب O-Lab",
    "En esta sección se registran todo tipo de apuntes que quieras guardar, pueden ser posteriormente publicadas en el apartado de blog si así lo deseas": "في هذا القسم يمكنك تسجيل أي نوع من الملاحظات التي ترغب في الاحتفاظ بها، يمكن نشرها لاحقًا في قسم المدونة إذا كنت ترغب في ذلك",
    "El 100% de cada donación va directamente a una persona/escuela/comunidad específica que espera nuestro kit #educacióndigitalparatodos que los equipa con tecnología, capacitación a profesores y nuestro LMS y aplicación de aprendizaje fuera de línea O-lab lleno de cursos complementarios, vocacionales y STEAM creados en colaboración con maestros e instituciones locales en todo el mundo.": "100٪ من كل تبرع يذهب مباشرة إلى شخص / مدرسة / مجتمع معين ينتظر مجموعتنا #تعليم_رقمي_للجميع التي تجهزهم بالتكنولوجيا وتدريب المعلمين ومنصتنا LMS وتطبيق التعلم خارج الاتصال O-lab المليء بدورات مكملة ومهنية وSTEAM تم إنشاؤها بالتعاون مع المعلمين والمؤسسات المحلية في جميع أنحاء العالم",
    "Con o-lab es posible personalizar los requisitos de todas las instituciones, entidades educativas u organizaciones. Con todas las características y funciones que tus beneficiarios o educadores necesitan para presentar todos los temas, conferencias, cursos y más.": "مع O-lab، يمكن تخصيص متطلبات جميع المؤسسات والهيئات التعليمية أو المنظمات. بجميع الميزات والوظائف التي يحتاجها المستفيدون أو المعلمون الخاصون بك لتقديم جميع المواضيع والمحاضرات والدورات وأكثر من ذلك.",
    "Árabe":"عرب"
  }
};

export default languaje_AR;