import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { IUserAnonymous } from "dto/user-anonymous.dto";
import { State } from "store/types";
import { history } from "configureStore";
import * as actions from "store/actions";
import Test from "components/Login/AnonymousUser/Test";
import Loading from "components/Common/Loading";
import Background from "components/Common/Background";
import AppBarLayout from "layouts/AppBarLayout";

const useStyles = makeStyles(() => ({ 
  root: { height: "100vh" },
  view: { 
    height: "calc(100% - 70px)", 
    position: "relative",
    "&.zoom": {  height: "100%" },
  },
  page: { 
    height: "100%", 
    overflow: "auto",
    position: "relative", 
    alignItems: "center",
    justifyContent: "center",
  },
  item: { height: "100%" },
}));

const AnonymousUserTestPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((s: State) => s.user.user);
  const anonymousUser = useSelector((s: State) => s.anonymousUser.anonymousUser);
  const anonymousUserLoading = useSelector((s: State) => s.anonymousUser.anonymousUserLoading);
  const [zoom, setZoom] = useState(false);
  const [background, setBackground] = useState("/img/background/clase_06.png");
  const anonymousUserls = localStorage.getItem("anonymousUser");
  const userAnonymous = JSON.parse(anonymousUserls ?? "{}");
  const home = !!user.nit || (!!userAnonymous?.uuid && !!userAnonymous?.userType);

  useEffect(() => { if (!!home) history.push("/home") }, [home]);

  const toogleZoom = () => setZoom((v) => !v);

  const createAnonymousUser = (user: IUserAnonymous) => {
    if (!!anonymousUser?.uuid) {
      dispatch(actions.updateAnonymousUser(
        {
          birthday: user.birthday,
          userType: user.userType,
          personalityTests: user.personalityTests,
        },
        callbackUpdateAnonymousUser,
      ));
    } else dispatch(actions.createAnonymousUser(user));
  }
  const callbackUpdateAnonymousUser = () => history.push("/explore");

  return (
    <Grid className={classes.root} container>
      <AppBarLayout zoom={zoom} />
      
      <Grid className={clsx(classes.view, zoom && "zoom")} container>
        <Background backgroundImage={background} />
        
        <Grid className={classes.page} container>
          <Test 
            zoom={zoom} 
            toogleZoom={toogleZoom} 
            updateBackground={setBackground}
            createAnonymousUser={createAnonymousUser}
          />
        </Grid>

        <Loading loading={anonymousUserLoading} />
      </Grid>
    </Grid>
  )
}

export default AnonymousUserTestPage;
