import React, { FC } from "react";
import { styled } from "@material-ui/core/styles";

const Input = styled("input")({ display: "none" });

interface IProps {
  accept?: "image" | "audio";
  onChangeFile: (file: File) => void;
}

const InputFile: FC<IProps> = ({ accept, onChangeFile }) => {
  const type = accept === "audio" ? "audio/*" : "image/*";

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!!e.currentTarget.files && !!e.currentTarget.files.length) {
      const file: File = e.currentTarget.files[0];
      onChangeFile(file);
    } else onChangeFile(new File([], ""));
  }

  return (<Input type="file" accept={type} onChange={onChange} />);
}

export default InputFile;
