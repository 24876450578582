import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { Dialog, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { State } from "store/types";
import Loading from "components/Common/Loading";
import FormButton from "components/Form/FormButton";
import FormTypography from "components/Form/FormTypography";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 32,
    maxWidth: 400,
    borderRadius: 16,
    backgroundColor: "white",
  },
  content: { gap: 20 },
}));

interface IProps {
  open: boolean;
  title?: string;
  loading?: boolean;
  subtitle?: string;
  onClose: () => void;
  generateLaboratoryCertificate: () => void;
}

const DialogLaboratoryCompleted: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const user = useSelector((state: State) => state.user.user);
  const subtitle = !!user?.nit 
    ? "Ya has completado el Laboratorio satisfactoriamente. Puedes descargar el certificado a continuación" 
    : "Ya has completado el curso satisfactoriamente";

  return (
    <Dialog classes={{ paper: classes.paper }} open={props.open}>
      <Grid className={classes.content} container>
        <Grid container justifyContent="center">
          <img src="/img/icons/certificate.svg" alt="" width={90} />
        </Grid>
        <Grid item xs={12}>
          <FormTypography align="center" variant="h5" fontWeight={700}>
            {t("¡Curso completado!")}
          </FormTypography>
          <FormTypography align="center" fontSize={18}>
            {t(subtitle)}
          </FormTypography>
        </Grid>
        {!!user?.nit && (
          <Grid container justifyContent="center">
            <FormButton 
              primary
              maxWidth={240} 
              fullWidth
              onClick={props.generateLaboratoryCertificate}
            >
              {t("Descargar certificado")}
            </FormButton>
          </Grid>
        )}
        <Grid container justifyContent="center">
          <FormButton maxWidth={240} fullWidth onClick={props.onClose}>
            {t("Ok")}
          </FormButton>
        </Grid>
      </Grid>

      <Loading loading={props.loading} white />
    </Dialog>
  )
}

export default DialogLaboratoryCompleted;
