import Survey from "services/survey.services";
import { IQuestion } from "dto/question.dto";
import { Action } from "./types";
import * as types from "./types";
import * as actions from "store/modules/analytics/actions";
import { uploadFileStorage } from "store/actions";
import { IUser } from "dto/user.dto";
import { ISurvey } from "dto/survey.dto";
import { surveyInit } from "initials/survey.init";

export const getSurveyPrivate = (uuid: string): Action => async (dispatch) => {
  try {
    const survey: ISurvey = await Survey.getSurveyByUuid(uuid);
    if (!!survey && survey.update_date > new Date("01/31/2024").getTime()) {
      const questions = survey?.questions?.filter((q) => (q?.type !== 19 && q.type !== 24));
      dispatch(setSurveyCurrent({ ...survey, questions }));
      dispatch(actions.analytics("access_survey_private", {}));
    } else if (!!survey) {
      dispatch(setSurveyCurrent(survey));
      dispatch(actions.analytics("access_survey_private", {}));
    } else dispatch(resetSurveyCurrent());
  } catch (error) {
    dispatch(resetSurveyCurrent());
  }
}
export const setSurveyCurrent = (survey: ISurvey): Action => async (dispatch) => {
  dispatch({ type: types.SURVEY_CURRENT, payload: survey });
  dispatch({type: types.SURVEY_LOADING, payload: false});
}
export const resetSurveyCurrent = (): Action => (dispatch) => {
  dispatch(setSurveyCurrent(surveyInit));
}

export const getSurveyPublic = (uuid: string): Action => async (dispatch, getState) => {
  try {
    const surveyPublicLoading = getState().survey.surveyPublicLoading
    if (!!surveyPublicLoading) {
      const survey: ISurvey = await Survey.getSurveyByUuid(uuid);
      if (!!survey && survey.update_date > new Date("01/31/2024").getTime()) {
        const questions = survey?.questions?.filter((q) => (q?.type !== 19 && q.type !== 24));
        dispatch(setSurveyPublic({ ...survey, questions }));
        dispatch(actions.analytics("access_survey_public", {}));
      } else if (!!survey) {
        dispatch(setSurveyPublic(survey));
        dispatch(actions.analytics("access_survey_public", {}));
      } else dispatch(resetSurveyPublic());
    }
  } catch (error) {
    dispatch(resetSurveyPublic());
    console.log("Error Get Survey Public: ", error);
  }
  dispatch({type: types.PUBLIC_SURVEY_LOADING, payload: false});
}
export const setSurveyPublic = (value: ISurvey): Action => (dispatch) => {
  dispatch({type: types.PUBLIC_SURVEY, payload: value});
}
export const resetSurveyPublic = (): Action => (dispatch) => {
  dispatch(setSurveyPublic(surveyInit));
}

export const saveSurveyAnswers = (
  uuid: string, 
  questions: IQuestion[], 
  user?: IUser
): Action => async (dispatch) => {
  dispatch({type: types.SAVE_ANSWER_LOADING, payload: true});
  try {
    const answers: IQuestion[] = [];
    for (let i = 0; i < questions.length; i++) {
      const question = questions[i];
      if (question.type === 16 && !!question.file_name) {
        const fileName = question.file_name;
        if (typeof fileName === "object" && !!fileName.name) {
          const uuid = new Date().getTime().toString();
          const text = `${uuid}${fileName.name.replace(/\s+/g, '').toLowerCase()}`;
          const path = `surveys/images/${text}`;
          await dispatch(uploadFileStorage(fileName, path, text));
          answers.push({...question, file_name: text});
        } else answers.push(question);
      } else answers.push(question);
    }
    if (!!user) {
      const answer = { date: new Date().getTime().toString(), answers, user }
      await Survey.postAnswer(uuid, answer);
      dispatch(actions.analytics("perform_survey"));
    } else {
      const answer = { date: new Date().getTime().toString(), answers }
      await Survey.postAnswer(uuid, answer);
      dispatch(actions.analytics("perform_survey"));
    }
    dispatch(openCongratulations());
  } catch (error) {
    console.log("Save Survey Answers Error: ", error);
  }
  dispatch({type: types.SAVE_ANSWER_LOADING, payload: false});
}

export const openCongratulations = (): Action => (dispatch) => {
  dispatch({type: types.DIALOG_CONGRATULATIONS, payload: true});
}
export const closeCongratulations = (): Action => (dispatch) => {
  dispatch({type: types.DIALOG_CONGRATULATIONS, payload: false});
}
