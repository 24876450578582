import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Grid } from '@material-ui/core';
import { IUser } from "dto/user.dto";
import * as actions from '../store/actions';
import { State } from '../store/types';
import Checkout from '../components/Payment/Checkout';
import Background from '../components/Common/Background';
import Loading from '../components/Common/Loading';
import LayoutOlab from '../layouts/LayoutOlab' ;
import { IEntity } from 'dto/entity.dto';

const useStyles = makeStyles(() => ({
  root: { position: 'relative' },
  page: {
    height: 'calc(100vh - 60px)',
    marginTop: 60,
    backgroundColor: 'white',
  },
}));

const CheckoutPage = () => {
  const classes  = useStyles();
  const dispatch = useDispatch();
  const loading         = useSelector((state: State) => state.checkout.loading);
  const projects        = useSelector((state: State) => state.checkout.projects);
  const licensesLoading = useSelector((state: State) => state.checkout.licensesLoading);

  const createUserEntity = (
    user: IUser, 
    entity: IEntity, 
    password: string, 
    callback: () => void,
  ) => {
    dispatch(actions.createUserEntity(user, entity, password, callback));
  }

  return (
    <Grid className={classes.root} container>
      {licensesLoading && (<Loading loading />)}
      <LayoutOlab />
      <Grid className={classes.page} container justify="center" alignItems="center">
        <Background />
        <Checkout 
          loading={loading}
          projects={projects}
          createUserEntity={createUserEntity}
        />
      </Grid>
    </Grid>
  )
}

export default CheckoutPage;
