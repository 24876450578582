import React, { FC } from "react";
import Carousel from "react-elastic-carousel";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ITask, IUser } from "dto/user.dto";
import { ISurvey } from "dto/survey.dto";
import { IContent } from "dto/content.dto";
import { ILaboratory } from "dto/laboratory.dto";
import { soundCarouselPrev, soundCarouselNext } from "utils";
import SurveyButton from "components/Home/SurveyButton";
import FormButtonIcon from "components/Form/FormButtonIcon";
import LaboratoryButton from "components/Home/LaboratoryButton";

const useStyles = makeStyles((theme) => ({
  labs: {
    width: "fit-content",
    alignItems: "center",
    "& .rec-carousel-wrapper": {
      position: "relative",
      flexDirection: "column-reverse",
      "& .rec-carousel": {
        "& .rec-slider-container": { 
          width: 1280,
          margin: 0, 
          [theme.breakpoints.down("xl")]: { width: 1280 }, 
          [theme.breakpoints.down("lg")]: { width: 960 }, 
          [theme.breakpoints.down("md")]: { width: 640 }, 
          [theme.breakpoints.down("sm")]: { width: 320 },
          [theme.breakpoints.down("xs")]: { width: "100%" },
          "& .rec-slider": {
            "& .rec-swipable": {
              "& .rec-carousel-item": {
                display: "flex",
                alignItems: "center",
              },
            },
          },
        },
      },
      "& .rec-pagination": { 
        margin: 0,
        padding: "7px 0",
        "& .rec-dot": { boxShadow: "0 0 2px 1px rgb(0 0 0 / 50%)" },
        "& .rec-dot_active": { backgroundColor: "#ff6315", boxShadow: "none" },
      }
    },
    [theme.breakpoints.down("xs")]: { width: "100%" },
  },
  arrow: {
    width: 45,
    height: 230,
  },
}));

interface IArrowButtons {
  type: string;
  isEdge: boolean;
  onClick: () => void;
}

interface IProps {
  user: IUser;
  tasks: ITask[];
  contents: IContent[];
  entityName: string;
  selectSurvey: (survey: ISurvey) => void;
  selectContent: (c: IContent) => void;
  generateLaboratoryCertificate: (laboratory: ILaboratory) => void;
}

const HomeCarousel: FC<IProps> = (props) => {
  const classes = useStyles();
  const breakPoints = [
    { width:   0, itemsToShow: 1 },
    { width: 640, itemsToShow: 2 },
    { width: 960, itemsToShow: 3 },
    { width: 1280, itemsToShow: 4 },
  ]

  const arrowButtons: FC<IArrowButtons> = ({type, isEdge, onClick}) => {
    return (type === 'PREV' ? (
      <Grid className={classes.arrow} container alignItems="center">
        {!isEdge && (
          <FormButtonIcon 
            image="/img/home/back.svg" 
            onClick={onClick}
            onMouseDown={soundCarouselPrev} 
          />
        )}
      </Grid>
    ) : (
      <Grid className={classes.arrow} container alignItems="center">
        {!isEdge && (
          <FormButtonIcon 
            image="/img/home/next.svg"
            onClick={onClick}
            onMouseDown={soundCarouselNext}
          />
        )}
      </Grid>
    ))
  }

  return (
    <Grid className={classes.labs} container>
      <Carousel isRTL={false} breakPoints={breakPoints} renderArrow={arrowButtons}>
        {props.contents?.map((content, i) => (content.type === "survey" ? (
          <SurveyButton  
            key={i} 
            survey={content} 
            selectSurvey={props.selectSurvey} 
          />
        ) : (
          <LaboratoryButton
            key={i}
            user={props.user}
            tasks={props.tasks}
            laboratory={content} 
            entityName={props.entityName}
            selectContent={props.selectContent}
            generateLaboratoryCertificate={props.generateLaboratoryCertificate}
          />
        )))}
      </Carousel>
    </Grid>
  )
}

export default HomeCarousel;
