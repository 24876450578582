import React from 'react';
import { makeStyles, Typography, DialogContent } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  content: { '& .MuiTypography-root': { marginBottom: 15 } },
}));

const TermsServiceES = () => {
  const classes = useStyles();

  return (
    <DialogContent className={classes.content}>
      <Typography variant="subtitle2" align="justify" gutterBottom>
        O-lab es una plataforma y aplicación móvil (en conjunto, las "Plataformas") 
        que contiene cursos y programas de formación en línea y fuera de línea creada 
        por instituciones públicas y privadas para los usuarios de la aplicación móvil O-lab. 
        Fundación El Origen registrada bajo el NIT 900900464-0 en Bogotá - Colombia es quien 
        participa como licenciatario de la app O-lab en Colombia.
      </Typography>
        
      <Typography variant="caption" align="justify" display="block" gutterBottom>
        Estos términos y condiciones se refieren al uso de la plataforma & app (las “Plataformas”) 
        de cursos y programas de formación, denominada “O-lab”.  El presente es aplicable en todas 
        sus plataformas; APP para sistemas operativos Android, y APP web, así como cualquier 
        funcionalidad en el sitio web: www.o-lab.app o cualquier otro servicio que se le pueda 
        prestar al usuario o institución educativa y patrocinador. Estos términos y condiciones 
        se consideran aceptados por los usuarios de las Plataformas, al registrarse e ingresar y 
        en el caso de no aceptarlas, deberán de dejar de utilizarse de forma inmediata. 
        FUNDACIÓN EL ORIGEN como su licenciatario, se reserva el derecho de realizar cambios en 
        estos términos y condiciones que tendrán efecto a partir de la fecha de su publicación. 
        El uso de estas plataformas constituirá la aceptación de estos cambios.
      </Typography>

      <Typography variant="caption" align="justify" display="block" gutterBottom>
        1. Condiciones de acceso y utilización FUNDACIÓN EL ORIGEN condiciona la utilización 
        los servicios de las Plataformas al previo diligenciamiento de formularios y el pago 
        correspondiente por la prestación de sus servicios. El cliente garantiza la autenticidad 
        y actualidad de todos los datos que comunique y será el único responsable de las 
        manifestaciones falsas o inexactas que realice.
      </Typography>

      <Typography variant="caption" align="justify" display="block" gutterBottom>
        2. Acerca de la información depositada en estas Plataformas El contenido de este sitio 
        es únicamente para propósitos educativos. Toda la información depositada en estas 
        Plataformas, así como nombres, imágenes, fotos, etc. se proporcionan sin ninguna 
        representación o aval y sin garantía de ningún tipo, ya sea expresa o implícita. 
        En ningún caso la Fundación El Origen se hace responsable de cualquier daño directo o 
        indirecto causado por el uso de las Plataformas, ya sea por acción u omisión. 
        Los siguientes lineamientos y políticas son definidos con la finalidad de mejorar el uso 
        y la administración de las Plataformas educativas O-lab.
      </Typography>

      <Typography variant="caption" align="justify" display="block" gutterBottom>
        3. Licencia limitada de uso Fundación El Origen autoriza al usuario/cliente/aliado a 
        acceder a las Plataformas para propósitos educativos. Se prohíbe cualquier tipo de 
        reproducción o modificación de cualquier contenido publicado en este sitio, a menos de 
        que Fundación El Origen autorice lo contrario, de manera expresa y por escrito.  
        Fundación El Origen se reserva todos los derechos que no estén explícitamente otorgados 
        en este documento.
      </Typography>

      <Typography variant="caption" align="justify" display="block" gutterBottom>
        4. Propiedad intelectual La propiedad intelectual del desarrollo de la aplicación y 
        del servicio que se presta es exclusiva de Fundación El Origen.  Esto incluye la 
        totalidad del software y sus partes, así como la marca, el nombre, el logo, y/o 
        cualquiera de sus componentes. <br />
        Está prohibida la copia y/o distribución total o parcial del software, sin permiso 
        por escrito del propietario, incluso si el fin de dicha copia es una actividad sin 
        lucro económico. El software de Fundación El Origen es intransferible a cualquier 
        título y bajo cualquier modalidad. <br />
        Así mismo, toda la información puesta a disposición, insertada, mencionada, usada en
        las Plataformas y sus contenidos (textos, fotografías, gráficos, imágenes, tecnología,
        software, links, contenidos, diseño gráfico, código fuente, etc.), así como las marcas 
        y demás signos distintivos son propiedad de la FUNDACIÓN EL ORIGEN o de terceros que 
        han autorizado a la Institución el uso de sus derechos de propiedad intelectual. 
        El uso y acceso a las Plataformas no concede, otorga, o transfiere algún tipo de 
        derecho, licencia o autorización de uso o disposición sobre los derechos de propiedad 
        intelectual. Por tanto, queda prohibida la reproducción total o parcial de los 
        contenidos protegidos por la ley de propiedad intelectual, así como el uso comercial 
        de los contenidos de la aplicación móvil, su venta, distribución a terceros o su 
        modificación. Cualquier otro uso requiere autorización previa, escrita y explícita 
        de la FUNDACIÓN EL ORIGEN, por tanto, siempre que se utilice la información relacionada 
        en ella deberá protegerse el derecho de autor que la Ley le otorga. Cualquier uso no 
        autorizado constituirá una violación a los presentes Términos y Condiciones de Uso, 
        y a las normas vigentes nacionales e internacionales sobre Propiedad Intelectual.
      </Typography>
          
      <Typography variant="caption" align="justify" display="block" gutterBottom>
        5. Contraprestación La entidad que desea patrocinar el acceso de la app a usuarios 
        “Administradores”, le pagará de manera anual a la FUNDACIÓN EL ORIGEN la 
        contraprestación por el pago de sus servicios: el monto, fecha de pago, 
        método de pago y los derechos y obligaciones por las partes 
        (la Empresa y la Fundación) quedarán sujetas a las especificaciones establecidas 
        en el Contrato de Prestación de Servicios que se celebre con cada entidad en 
        específico, por lo que en caso de duda se deberá consultar dicho contrato en específico.
      </Typography>

      <Typography variant="caption" align="justify" display="block" gutterBottom>
        6. Políticas de uso y restricciones de administradores y usuarios En cuanto las 
        políticas y restricciones de administradores y usuarios se consideran los siguientes 
        puntos definidos en los requerimientos de migración ya conocidos: 
        I. Políticas y restricciones del usuario: <br />
        a. La utilización de las Plataformas por cualquier persona, le atribuye 
        la calidad de usuario y ello implica la adhesión a los términos de uso. <br />
        b. A través de las Plataformas, el usuario tendrá acceso a la plataforma y a la 
        utilización de los contenidos educativos. <br />
        c. El usuario reconoce que el uso de los contenidos no le proporciona ningún derecho 
        de propiedad sobre los mismos. <br />
        d. Para acceder a los contenidos de las Plataformas, 
        el usuario está obligado a matricularse en tiempo y forma especificados 
        y al reglamento vigente de la FUNDACIÓN EL ORIGEN. <br />
        e. El administrador de las Plataformas le proporciona al usuario un código de curso, 
        estos son datos únicos para cada usuario. El usuario asume la responsabilidad de mantener 
        la confidencialidad y el uso de sus datos de usuario y contraseña proporcionados. 
        El usuario no deberá cambiar por ningún motivo las claves de acceso o 
        nombres de usuario asignados. <br />
        f. Es responsabilidad del usuario informar al administrador, en dado caso de que 
        sospeche que alguien más está usando su cuenta para acceder a las Plataformas, 
        se procederá a realizar un cambio de contraseña o usuario.<br />
        g. Las Plataformas sólo podrá ser utilizada para propósitos 
        académicos y de capacitación.<br />
        h. El usuario debe reportar al administrador de cualquier caso del cual crea que se 
        está infringiendo los derechos de autor, o atente contra la propiedad intelectual, 
        así como material inapropiado.<br />
        i. El usuario se obliga a usar el contenido de forma correcta y se obliga a respetar 
        todos los derechos de propiedad intelectual sobre el contenido.<br />
        j. El contenido está destinado solamente para el uso de los usuarios autorizados, 
        por lo tanto, está permitido imprimir y copiar dicho contenido siempre y cuando 
        sea para uso personal de estudio y fines académicos.<br />
        k. Para utilizar los contenidos de las Plataformas es necesario que el usuario 
        proporcione ciertos datos de carácter personal asignados en el Perfil, 
        FUNDACIÓN EL ORIGEN no compartirá información personal alguna que haya sido 
        proporcionada a terceras personas.<br />
        l. Las plataformas permiten el desarrollo de contenidos y establece espacios de 
        intercambio como espacios de tareas, el usuario alumno es responsable 
        del material que utilice, así como cualquier acto que ocurra a través del uso 
        de su clave de usuario.<br />
        m.	El usuario debe garantizar las siguientes acciones:<br />
        i. Evitar publicar contenidos que afecten la imagen de la FUNDACIÓN EL ORIGEN 
        y O-lab, así como de sus miembros.<br />
        ii. No publicar propaganda política o religiosa.<br />
        iii. No publicar cualquier vínculo con dirección electrónica que afecte la dignidad 
        de la comunidad educativa.<br />
        iv. Procurar que los contenidos, imágenes, videos y enlaces que publique sean de menor 
        tamaño posible para que no afecten el rendimiento del sistema.<br />
        v. Evitar publicar contenidos de los cuales no sea el autor, o en su caso, 
        definir las referencias correspondientes.<br />
        n. El usuario deberá de revisar que los archivos que publique, estén libres de virus, 
        cumplan los lineamientos antes mencionados.<br />
        o. FUNDACIÓN EL ORIGEN se reserva el derecho de revisar, remover,
        editar un material que un usuario haya publicado sin tomar en cuenta las 
        consideraciones anteriores, así como suspender de manera temporal o definitiva al 
        usuario responsable.<br />
        p. La FUNDACIÓN EL ORIGEN es ajena a los vínculos de internet externos y que pueden 
        ser accedidos por medio del contenido en las Plataformas. <br />
        q. Queda prohibida la reproducción total o parcial de los contenidos de esta página, 
        por cualquier medio o procedimiento, sin la autorización previa, expresa y por escrito 
        de la FUNDACIÓN EL ORIGEN. Las imágenes, diseño de los textos, los gráficos, así como 
        el contenido presentado en esta página, son ilustrativos e informativos y son propiedad 
        de la Fundación El Origen, reconociendo la autoría intelectual de los diseñadores. 
        Toda forma de utilización no autorizada será perseguida. <br />
        r. En los usuarios, la asignación de roles queda establecida de la siguiente manera: <br />
        1. El administrador requiere una clase superusuario en la plataforma O-lab y puede contar 
        con privilegios de administración. <br />
        2. El tutor sólo necesita privilegios de profesor sin permiso de edición a excepción 
        de aquellos que son autorizados por el administrador. <br />
        3. El estudiante sólo requiere privilegios de aprendiz. 4. El superusuario cuenta con 
        todos los privilegios de administración. <br />
      </Typography>
          
      <Typography variant="caption" align="justify" display="block" gutterBottom>
        7. Acceso y navegación en la aplicación Al hacer uso de las Plataformas el Usuario se 
        obliga a actuar de manera lícita y diligente y se compromete a no realizar ninguna de 
        las actividades descritas a continuación: <br />
        1. Reproducir, copiar, distribuir, modificar, vender, licenciar, adaptar, el contenido, 
        aquí expuesto, sin contar con la autorización previa, expresa y por escrito de la 
        FUNDACIÓN EL ORIGEN. <br />
        2. Reproducir, copiar, distribuir, modificar, vender, licenciar, adaptar, cualquier 
        elemento, texto, fotografía o material audiovisual de las Plataformas sujeto a derechos 
        de propiedad intelectual sin contar con la autorización previa de la 
        FUNDACIÓN EL ORIGEN. <br />
        3. Vulnerar, eludir, manipular de cualquier manera o mediante cualquier procedimiento
        las medidas tecnológicas de seguridad. <br />
        4. Usar los contenidos de las Plataformas para fines ilegales, contrarios a estos 
        Términos y Condiciones de Uso, que atenten contra la ley. No se necesita pedir permiso 
        antes de proporcionar un enlace a los recursos de esta plataforma de cursos.
      </Typography>
      
      <Typography variant="caption" align="justify" display="block" gutterBottom>
        8. Seguridad Los únicos responsables de mantener a salvo y evitar el uso por 
        terceros de las contraseñas de acceso a las plataformas O-lab, son los propios 
        usuarios, no siendo en ningún caso responsabilidad de la FUNDACIÓN EL ORIGEN. 
        Le aconsejamos que no revele ninguna información personal sobre sí mismo o de 
        cualquier otra persona.
      </Typography>

      <Typography variant="caption" align="justify" display="block" gutterBottom>
        9. Incumplimiento de los términos y condiciones Cualquier controversia o 
        realización de conducta prohibida por los presentes términos y condiciones 
        y/o al reglamento general de la FUNDACIÓN EL ORIGEN, tendrá como consecuencia 
        la cancelación inmediata del perfil del usuario sancionado. Ambos documentos 
        normativos se encontrarán siempre disponibles y al alcance de los usuarios en 
        las páginas de inicio de plataformas digitales educativas que utilicen.
      </Typography>

      <Typography variant="caption" align="justify" display="block" gutterBottom>
        10.	Soporte técnico Ofrecemos servicio técnico remoto vía correo electrónico 
        de respuesta entre 24 a 48 horas.
      </Typography>
    </DialogContent>
  )
}

export default TermsServiceES
