export const languaje_FIL = {
  translation: {
    Curso:"Course",
    "¡Curso completado!":"Course completed!",
    "¡Unidad terminada!":"Unit finished!",
    "¡Módulo completado!":"Module completed!",
    "Curso no completado":"Course not completed",
    "Unidad no completada":"Unit not completed",
    "Módulo no completado":"Module not completed",
    "Dale continuar para avanzar":"Click continue to advance",
    "Avanza a la siguiente unidad":"Advance to the next unit",
    "Has desbloqueado la siguiente unidad":"You have unlocked the next unit",
    "Ya has completado el curso satisfactoriamente":"You have already completed the course satisfactorily",
    Estudio:"Study",
    Trabajo:"Job",
    Startup:"Startup",
    unidades:"units",
    Posgrado:"Postgraduate",
    Comercial:"Comercial",
    Liderazgo:"Leadership",
    Tecnología:"Technology",
    Freelancer:"Freelancer",
    Empresario:"Businessman",
    Operaciones:"Operations",
    Emprendimiento:"Entrepreneurship",
    "Director / VP":"Director / VP",
    "Lider de equipo":"Team leader",
    "Entidad pública":"Public entity",    
    "Empresa privada":"Private company",
    "Básica primaria":"Elementary school",
    "Recursos humanos":"Human Resources",
    "Básica secundaria":"Basic high school",
    "Miembro de equipo":"Team member",
    "Educación superior":"Higher education",
    "Actualmente, yo...":"Actually me...",
    "Gestión de equipos":"Team management",
    "Conseguir un empleo":"Getting a job",
    "Habilidades blandas":"Soft skills",
    "Gestión de proyectos":"Project management",
    "Conocimiento general":"General knowledge",
    "Escoge tus intereses":"Choose your interests",
    "Desarrollo de carrera":"Career development",
    "Institución educativa":"Educational institution",
    "Responsabilidad social":"Social responsability",
    "Orientación vocacional":"Vocational orientation",
    "¿Qué te describe mejor?":"What describes you best?",
    "Ninguna de las anteriores":"None of the above",
    "Entidad sin ánimo de lucro":"Non-profit entity",
    "Marketing y comunicaciones":"Marketing and communications",
    "¿A qué área laboral perteneces?":"What area of work do you belong to?",
    "¿Cuál es tu nivel de educación?":"What is your level of education?",
    "¿Cuál es tu fecha de nacimiento?":"What is your birth date?",
    "Estoy buscando estudiar o trabajar":"I am looking to study or work",
    "¿En qué tipo de organización trabajas?":"What type of organization do you work for?",
    "¿Cuántos integrantes tiene tu organización?":"How many members does your organization have?",
    
    Idioma:"Language",
    Ingles:"English",
    Español:"Spanish",
    "¡Hola!":"Hello!",
    "¡Todo listo!":"All ready!",
    "Súper administrador":"Super admin",
    "¡Bienvenido a O-lab!":"Welcome to O-lab!",
    "¡Ya casi terminamos!":"We are almost done!",
    "Crea tu perfil ahora":"Create your profile now",
    "¿Salir del laboratorio?":"Get out of the lab?",
    "¿Tienes un código organizacional?":"Do you have an organizational code?",
    "Configura el idioma de esta plataforma":"Set the language of this platform",
    "Si te sales del laboratorio perderás el progreso guardado hasta el momento":"If you leave the laboratory you will lose the progress saved so far",
    "Te recomendamos crear una nueva cuenta para tener tu propio perfil, además de guardar el progreso de tus contenidos, compartir en la comunidad, chatear y mucho más":"We recommend that you create a new account to have your own profile, as well as save the progress of your content, share in the community, chat and much more",
    
    Hola:"Hello",
    Omitir:"Skip",
    Explora:"Explore",
    Comenzar:"Start",
    "Ver curso":"See course",
    "Todo listo":"All ready",
    "Busca labs":"Search labs",
    "¡Empecemos!":"Let us begin!",
    "Elige un país":"Choose a country",
    "Elige tu país":"Choose your country",
    "Ver formulario":"See form",
    "Solicitar demo":"Request demo",
    "Nombre completo":"Full name",
    "Respuesta única":"Single answer",
    "Crea una cuenta":"Create an account",
    "Elige tu género":"Choose your gender",
    "Ya tengo cuenta":"I already have an account",
    "Crear cuenta con":"Create account with",
    "Llena los campos":"Fill in the fields",
    "Ingresa tu correo":"Enter your email",
    "Ingresa tu nombre":"Enter your name",
    "Antes de comenzar":"Before starting",
    "Bienvenido a O-lab":"Welcome to O-lab",
    "Completa tus datos":"Complete your data",
    "Múltiple respuesta":"Multiple answer",
    "Ya casi terminamos":"We are almost done",
    "Edad minima 7 años":"Minimum age 7 years",
    "Mínimo 6 caracteres":"Min 6 characters",
    "Repite tu contraseña":"Repeat your password",
    "Prefiero no responder":"Prefer not to answer",
    "Saltar, no tengo código":"Skip, I have no code",
    "Unirme a mi organización":"Join my organization",
    "Fecha de nacimiento incorrecta":"Wrong date of birth",
    "Labs libres ofrecidos por O-lab":"Free labs offered by O-lab",
    "Tienes un código organizacional":"Do you have an organizational code",
    "El código ingresado no es válido":"The code entered is invalid",
    "Ahora haces parte de la organización":"Now you are part of the organization",
    "Necesitamos saber un poco más sobre ti":"We need to know a little more about you",
    "Personaliza O-lab para tu organización":"Customize O-lab for your organization",
    "Todavía no perteneces a una organización":"You don't belong to an organization yet",
    "ya tienes acceso a su contenido y comunidad en O-lab":"you already have access to its content and community at O-lab",
    "Lleva tus entrenamientos, inducciones, comunicaciones y más al siguiente nivel":"Take your trainings, inductions, communications and more to the next level",
    "Si todavía no tienes el código organizacional, después lo puedes ingresar desde tu perfil":"If you still do not have the organizational code, you can enter it later from your profile",
    "Responde este corto cuestionario para poder empezar a explorar el contenido gratutito ofrecido por O-lab":"Take this short quiz to start exploring the free content offered by O-lab",
    "Si tu organización te compartió un código de O-Lab ingrésalo en el siguiente campo, de lo contrario salta este paso":"If your organization shared an O-Lab code with you, enter it in the next field, otherwise skip this step",
    
    o:"or",
    País:"Country",
    Falso:"False",
    Ninguno:"None",
    Primaria:"Primary",
    Verdadero:"True",
    Finalizar:"Finalize",
    Incorrecta:"Incorrect",
    Formulario: "Form",
    Secundaria:"High school",
    "Idioma urdu":"Urdu language",
    "Cargar audio":"Upload sound",
    "Grabar audio":"Record audio",
    "Continúa asi":"Keep it up",
    "Configuración":"Settings",
    "Editar género":"Edit gender",
    "Cargar imagen":"Upload image",
    "¡Felicidades!": "Congratulations!",
    "Idioma inglés":"English language",
    "Idioma tagalo":"Tagalog language",
    "Idioma español":"Spanish language",
    "Idioma francés":"French language",
    "Felicitaciones":"Congratulations",
    "Notificaciones":"Notifications",
    "Opción múltiple":"Multiple choice",
    "No te descuides":"Don't neglect",
    "Nueva contraseña":"New password",
    "Verdadero y falso":"True and false",
    "Guardar grabación":"Save recording",
    "Detener grabación":"Stop recording",
    "Iniciar grabación":"Start recording",
    "Respuesta Correcta":"Correct Answer",
    "Seleccionar idioma":"Select language",
    "Fecha de nacimiento":"Birthdate",
    "Vuelve a intentarlo":"Try again",
    "Respuesta Incorrecta":"Incorrect Answer",
    "Información personal":"Personal information",
    "Salir de la grabación":"Exit recording",
    "¡Bienvenido de nuevo!":"Welcome back!",
    "Terminaste esta encuesta":"You finished this survey",
    "Iniciar sesión con email":"Sign in with email",
    "Haga clic para regístrate":"Click to sign up",
    "Haga clic para ingresar a":"Click to enter",
    "Editar fecha de nacimiento":"Edit date of birth",
    "Confirmar nueva contraseña":"Confirm new password",
    "Iniciar sesión con teléfono":"Sign in with phone",
    "Encuentra todas las palabras":"Find all the words",
    "Seleccionar verdadero o falso":"Select true or false",
    "Haga clic para iniciar sesión":"Click to login",
    "Escribe tu contraseña de acceso":"Write your access password",
    "Seleccione la respuesta correcta":"Select the correct answer",
    "Haga clic para ingresar al tutorial":"Click to enter the tutorial",
    "Haga clic para volver al menú anterior":"Click to return to the previous menu",
    "Haga clic para seleccionar la respuesta":"Click to select answer",
    "Escribe tu correo electrónico de acceso":"Write your access email",
    "Haga clic para restablecer la contraseña":"Click to reset password",
    "Haga clic para iniciar sesión con el email":"Click to login with email",
    "Haga clic para ingresar a la vista de blog":"Click to enter blog view",
    "Haga clic para ingresar a la vista de notas":"Click to enter note view",
    "Haga clic para regresar al listado de módulo":"Click to return to module listing",
    "Haga clic para ingresar al buzón de mensajes":"Click to enter the mailbox",
    "Haga clic para iniciar sesión con el teléfono":"Click to login with phone",
    "Haga clic para regresar a la lección anterior":"Click to return to the previous lesson",
    "Haga clic para regresar a la pregunta anterior":"Click to return to the previous question",
    "Haga clic para continuar a la lección siguiente":"Click to continue to the next lesson",
    "Haga clic para reproducir el audio de la lección":"Click to play the audio of the lesson",
    "Haga clic para continuar a la pregunta siguiente":"Click to continue to the next question",
    "Haga clic para reproducir el audio de la pregunta":"Click to play the audio of the question",
    "Haga clic para regresar al listado de laboratorios":"Click to return to the list of laboratories",
    
    "Cargar archivo":"Upload file",
    "Guardar y salir":"Save and exit",
    "Educación continua":"Continuing education",
    "Aceleración escolar":"School acceleration",
    "Repetir formulario":"Repeat form",
    "Formulario privado":"Private form",
    "Finalizar formulario":"Finish form",
    "Página no encontrada":"Page not Found",
    "Educación vocacional":"Vocational education",
    "Formulario no encontrado":"Form not found",
    "Educación complementaria":"Complementary education",
    "No tienes laboratorios habilitados":"You do not have enabled laboratories",
    "El formulario es privado, verifique el acceso":"The form is private, check access",
    "Debes finalizar primero los módulos anteriores":"You must finish the previous modules first",
    "El formulario solicitado no existe, verifique el acceso":"The requested form does not exist, check access",
    "Lo sentimos, no se pudo encontrar la página que solicitó, vuelva a la página de inicio":"we' re sorry the page you requested could not be found please go back to the homepage",

    "Ante cualquier duda puedes contactar a tu docente":"If you have any questions, you can contact your teacher",
    "¡Hola! Te damos la bienvenida a la versión web de la aplicación O-lab":"Hi there! We welcome you to the web version of the O-lab application",
    "Los laboratorios son tu espacio de trabajo en los que realizarás actividades":"The laboratories are your workspace where you will carry out activities",
    "Aquí podrás ver la sección en la que te encuentras dentro de la aplicación web":"Here you can see the section in which you are within the web application",
    "Los módulos son secciones en las que ingresarás para aprender y realizar actividades":"Modules are sections that you will enter to learn and perform activities",
    "En este espacio encontrarás material de estudio que está dividido por unidades, lecciones y actividades":"In this space you will find study material that is divided by units, lessons and activities",
    "En esta sección puedes cambiar tus datos personales, escoger una foto de perfil y revisar el progreso de tus cursos":"In this section you can change your personal data, choose a profile photo and check the progress of your courses",
    "Este es un espacio público en el que encontraras material de estudio realizado y compartido por los estudiantes de O-Lab":"This is a public space where you will find study material made and shared by O-Lab students",
    "En esta sección se registran todo tipo de apuntes que quieras guardar, pueden ser posteriormente publicadas en el apartado de blog si así lo desea":"In this section all kinds of notes that you want to save are recorded, they can be later published in the blog section if you wish",

    //---------------------------------------------------------------------------------------------//
    
    "a"             :"sa",
    "El"            :"Ang",
    "OK"            :"Sige",
    "ID"            :"ID",
    "Yo"            :"Ako",
    "año"           :"taon",
    "Año"           :"Taon",
    "mes"           :"buwan",
    "Mes"           :"Buwan",
    "Ver"           :"Tingnan mo",
    "Para"          :"Para sa",
    "Reto"          :"Hamon",
    "Edad"          :"Edad",
    "Sexo"          :"Kasarian",
    "Otro"          :"Iba pa",
    "Blog"          :"Blog",
    "años"          :"taon",
    "Nota"          :"Tandaan",
    "Mayo"          :"Mayo",
    "Junio"         :"Hunyo",
    "Julio"         :"Hulyo",
    "Otros"         :"Ang iba",
    "Dudas"         :"Mga pagdududa",
    "Donar"         :"Mag-donate",
    "Pesos"         :"Piso",
    "Marzo"         :"Marso",
    "Abril"         :"Abril",
    "Salir"         :"Umalis na",
    "Notas"         :"Mga tala",
    "Buzón"         :"Mailbox",
    "Nuevo"         :"Bago",
    "Grado"         :"Baitang",
    "Retos"         :"Mga hamon",
    "Enero"         :"Enero",
    "Nombre"        :"Pangalan",
    "Agosto"        :"August",
    "Buscar"        :"Maghanap para sa",
    "Asunto"        :"Paksa",
    "Cerrar"        :"Isara",
    "Unidad"        :"Yunit",
    "Enviar"        :"Ipadala",
    "Volver"        :"Bumalik ka",
    "Editar"        :"I-edit",
    "Género"        :"Kasarian",
    "Título"        :"Kwalipikasyon",
    "Módulo"        :"Modyul",
    "Perfil"        :"Profile",
    "Inicio"        :"Simula ",
    "Cursos"        :"Kurso ",
    "E-mail"        :"E-mail",
    "Dólares"       :"Dolyar",
    "Mensual"       :"Buwan-buwan",
    "Mensaje"       :"Mensahe",
    "Aceptar"       :"Tanggapin",
    "Lección"       :"Aralin",
    "Febrero"       :"Pebrero",
    "Octubre"       :"Oktubre",
    "Público"       :"Pampubliko",
    "Privado"       :"Pribado",
    "Repetir"       :"Ulitin",
    "Guardar"       :"Magtipid",
    "Docente"       :"Guro",
    "Módulos"       :"Mga modyul",
    "Eliminar"      :"Tanggalin",
    "Femenino"      :"Pambabae",
    "Teléfono"      :"Telepono",
    "Lenguaje"      :"Wika",
    "Cancelar"      :"Kanselahin",
    "Explicar"      :"Ipaliwanag",
    "Enviados"      :"Nagpadala",
    "Unidades"      :"Mga yunit",
    "Mensajes"      :"Mga mensahe",
    "Tutorial"      :"Pagtuturo",
    "Licencia"      :"Lisensya",
    "Usuarios"      :"Mga gumagamit",
    "usuarios"      :"mga gumagamit",
    "Reenviar"      :"Ipadala muli",
    "Respuesta"     :"Sagot",
    "Noviembre"     :"Nobyembre",
    "Diciembre"     :"Disyembre",
    "Comunidad"     :"Pamayanan",
    "Recibidos"     :"Natanggap",
    "Responder"     :"Sagot",
    "Compartir"     :"Magbahagi",
    "Continuar"     :"Magpatuloy",
    "Masculino"     :"Lalaki",
    "Siguiente"     :"Susunod",
    "finalizar"     :"tapusin",
    "Septiembre"    :"Setyembre",
    "Regístrate"    :"Mag-sign up",
    "Bienvenido"    :"Maligayang pagdating",
    "Materiales"    :"Mga materyales ",
    "Contraseña"    :"Password",
    "Respuestas"    :"Mga sagot",
    "Destacados"    :"Itinatampok",
    "Actualizar"    :"Mag-upgrade",
    "Estudiante"    :"Mag-aaral",
    "Resultados"    :"Mga resulta",
    "Felicidades"   :"Binabati kita",
    "Comentarios"   :"Mga Komento",
    "Laboratorio"   :"Laboratoryo",
    "Descripción"   :"Paglalarawan",
    "Institución"   :"Institusyon",
    "Contáctanos"   :"Makipag-ugnayan sa amin",
    "Actividades"   :"Mga aktibidad",
    "Laboratorios"  :"Mga laboratories",
    "Calificación"  :"Kwalipikasyon",
    "Organización"  :"Organisasyon",
    "Nacionalidad"  :"Nasyonalidad",
    "Administrador" :"Tagapangasiwa",
    "Almacenamiento":"Imbakan",

    "y nuestra"               :"at ang aming",
    "Ver todos"               :"Ipakita lahat",
    "Ver todas"               :"Ipakita lahat",
    "Mis blogs"               :"Ang aking mga blog",
    "Mis notas"               :"Mga sulatin ko",
    "Ciudad/País"             :"Lungsod/Bansa",
    "Enviar nota"             :"Magpadala ng tala",
    "Bienvenido a"            :"Maligayang pagdating sa",
    "Crear cuenta"            :"Lumikha ng Account",
    "Ver unidades"            :"Tingnan ang mga unit",
    "Guardar nota"            :"I-save ang tala",
    "Comprar ahora"           :"Bumili ka na ngayon",
    "Cerrar sesión"           :"Mag-sign off",
    "Publicado por"           :"Inilathala ni",
    "Mensaje nuevo"           :"Bagong mensahe",
    "Iniciar sesión"          :"Mag log in",
    "Primera lengua"          :"Unang wika",
    "Segunda lengua"          :"Pangalawang wika",
    "Enviar mensaje"          :"Magpadala ng Mensahe",
    "Regístrate con"          :"Mag-sign up gamit ang",
    "Blog principal"          :"Pangunahing blog",
    "Compartir nota"          :"Ibahagi ang tala",
    "Aplicación web"          :"Web application",
    "del laboratorio"         :"ng lab",
    "Respuesta libre"         :"Libreng sagot",
    "RESPUESTA LIBRE"         :"LIBRENG SAGOT",
    "Guardar cambios"         :"I-save ang mga pagbabago",
    "Actualizar nota"         :"Tala ng pag-update",
    "Única respuesta"         :"Sagot lang",
    "Subir evidencia"         :"Mag-upload ng ebidensya",
    "Campo requerido"         :"Kinakailangang patlang",
    "Plan grande"             :"Malaking plano",
    "Donación segura"         :"Secure na donasyon",
    "Enviar respuesta"        :"Magpadala ng tugon",
    "Verificar código"        :"Suriin ang code",
    "Plan mediano"            :"Katamtamang plano",
    "Plan pequeño"            :"Maliit na plano",
    "Usuarios finales"        :"Mga end user",
    "usuarios finales"        :"mga end user",
    "Has completado un"       :"Nakumpleto mo na ang",
    "Actualizar Perfil"       :"I-update ang profile",
    "Siguiente noticia"       :"Susunod na balita",
    "de almacenamiento"       :"imbakan",
    "Cambiar contraseña"      :"Palitan ANG password",
    "Perfil actualizado"      :"Nai-update na profile",
    "Correo electrónico"      :"Email ",
    "Completar registro"      :"Kumpletuhin ang pagpaparehistro",
    "Contraseña invalida"     :"Di wastong password",
    "Personalizar avatar"     :"Ipasadya ang avatar ",
    "Confirmar contraseña"    :"Confirm password",
    "Servicios adicionales"   :"Karagdagang serbisyo",
    "Asistencia pedagógica"   :"Tulong sa pedagogical",
    "Objetivos pedagógicos"   :"Mga layunin ng pedagogical",
    "Restablecer contraseña"  :"Ibalik ang password",
    "Plan personalizado"      :"Personalized na plano",
    "Cantidad personalizada"  :"Pasadyang halaga",
    "Usuarios administradores":"Mga gumagamit ng admin",
    "usuarios administradores":"mga gumagamit ng admin",
    "Voluntariado corporativo":"Pagboboluntaryo ng kumpanya",

    "Dar una vez"                     :"Magbigay ng isang beses",
    "Ver en línea"                    :"Manood online",
    "Escribe aquí"                    :"Magsulat dito",
    "Validar edad"                    :"Patunayan ang edad",
    "Sin realizar"                    :"Inalis na",
    "Nuevo mensaje"                   :"Bagong mensahe",
    "Doblaje de voz"                  :"Voiceover",
    "Ver resultados"                  :"Tingnan ang mga resulta",
    "Progreso total"                  :"Pangkalahatang pag-unlad",
    "Sopa de letras"                  :"Sopas ng alpabeto",
    "Código de curso"                 :"Course code",
    "Nombre de unidad"                :"Pangalan ng unit",
    "Código del curso"                :"Course code",
    "Nombre y apellido"               :"Pangalan at apelyido",
    "Título de la nota"               :"Pamagat ng tala",
    "Buzón de mensajes"               :"Inbox",
    "Buscar por nombre"               :"Maghanap ayon sa pangalan",
    "Número de tarjeta"               :"Numero ng card",
    "No hay actividades"              :"Walang aktibidad",
    "Compra de licencia"              :"Pagbili ng lisensya",
    "Nombre del titular"              :"Pangalan ng may-ari",
    "Editar laboratorio"              :"I-edit ang lab",
    "Iconos de posición"              :"Mga icon ng posisyon",
    "Número de teléfono"              :"Numero ng telepono ",
    "Iniciar sesión con"              :"Mag-login kasama si",
    "Compartir como blog"             :"Ibahagi bilang blog",
    "Completa la oración"             :"Kumpletuhin ang pangungusap",
    "Términos de servicio"            :"Mga palatuntunan",
    "Máximo 50 carácteres"            :"Maximum na 50 character",
    "Escribe tu respuesta"            :"Isulat ang iyong sagot",
    "Diseño de contenidos"            :"Disenyo ng nilalaman",
    "Completa tu registro"            :"Kumpletuhin ang iyong pagpaparehistro",
    "Agrega tu información"           :"Idagdag ang iyong impormasyon",
    "Descargar certificado"           :"Mag-download ng sertipiko",
    "Encuentra las palabras"          :"Hanapin ang mga salita",
    "Seleccionar una imagen"          :"Pumili ng isang imahe",
    "Política de privacidad"          :"Patakaran sa Pagkapribado",
    "Código de verificación"          :"Verification code",
    "Nombre de organización"          :"Pangalan ng Organisasyon",
    "Estudiantes registrados"         :"Mga rehistradong estudyante",
    "Comprar/renovar licencia"        :"Bumili/mag-renew ng lisensya",
    "Condiciones del servicio"        :"Mga kondisyon ng serbisyo",
    "Nota enviada exitosamente"       :"Matagumpay na naipadala ang tala",
    "Nota guardada exitosamente"      :"Matagumpay na na-save ang tala",
    "Nota eliminada exitosamente"     :"Matagumpay na naalis ang tala",
    "Nota compartida exitosamente"    :"Matagumpay na ibinahagi tala",
    "Verificar correo electrónico"    :"Patunayan ang email",
    "Organización/Escuela/Empresa"    :"Organisasyon/Paaralan/Negosyo",
    "Nota actualizada exitosamente"   :"Matagumpay na na-update ang tala",
    "Soporte técnico virtual"         :"Virtual teknikal na suporta",
    "Plataforma web personalizable"   :"Nako-customize na web platform",
    "Aplicación móvil personalizable" :"Nako-customize na mobile app",
    "Servicios adicionales (opcional)":"Mga karagdagang serbisyo (opsyonal)",

    "Enviar a un amigo"                           :"Ipadala sa isang kaibigan",
    "Guardar y enviar nota"                       :"I-save at ipadala ang tala",
    "Validar y guardar edad"                      :"Patunayan at i-save ang edad",
    "Acceder con el teléfono"                     :"Pag-access gamit ang telepono",
    "El módulo no tiene retos"                    :"Walang hamon ang modyul",
    "Has completado el módulo"                    :"Nakumpleto mo na ang modyul",
    "Has completado la unidad"                    :"Nakumpleto mo na ang unit",
    "Escribe aquí tu comentario"                  :"Isulat ang iyong puna dito",
    "Configuración de tu cuenta"                  :"Pag-configure ng iyong account",
    "Envía un mensaje o llama a"                  :"Magpadala ng mensahe o tawag sa",
    "La edad debe ser mayor a 0"                  :"Ang edad ay dapat na higit sa 0",
    "Regístrate con tu teléfono"                  :"Mag-sign up gamit ang iyong telepono",
    "Correo enviado exitosamente"                 :"Matagumpay na naipadala ang mail",
    "Verificar número de teléfono"                :"I-verify ang numero ng telepono",
    "Ingresa tu número de teléfono"               :"Ipasok ang numero ng iyong telepono",
    "Envia un correo electrónico a"               :"Magpadala ng isang email sa",
    "El usuario ya está registrado"               :"Nakarehistro na ang gumagamit",
    "El usuario no está registrado"               :"Ang gumagamit ay hindi nakarehistro",
    "Arrastra la respuesta correcta"              :"I-drag ang tamang sagot",
    "El laboratorio no tiene módulos"             :"Walang mga module ang lab",
    "Las contraseñas deben coincidir"             :"Kailangang tugma ang mga password",
    "Existe un usuario con ese correo"            :"Mayroong isang gumagamit na may email na iyon",
    "Selecciona la respuesta correcta"            :"Piliin ang tamang sagot",
    "Acceder con el correo electrónico"           :"Pag-access gamit ang email",
    "No estás habilitado para ingresar"           :"Hindi ka makapasok",
    "¿Deseas comunicarte con un tutor?"           :"Nais mo bang makipag-usap sa isang tagapagturo?",
    "Existe un usuario con ese teléfono"          :"Mayroong isang gumagamit na may teleponong iyon",
    "Regístrate con tu correo electrónico"        :"Mag-sign up gamit ang iyong email",
    "Nota guardada y enviada exitosamente"        :"Na-save ang tala at matagumpay na naipadala",
    "para que puedas cambiar tu contraseña"       :"upang mapalitan mo ang iyong password",
    "La unidad siguiente no tiene lecciones"      :"Ang susunod na yunit ay walang mga aralin",
    "El código de verificación no es válido"      :"Ang verification code ay hindi wasto",
    "¡Lo hacemos por ti!"                         :"Ginagawa namin ito para sa iyo!",
    "por un período de un"                        :"para sa isang panahon ng isa",
    "se realizó con éxito"                        :"ay matagumpay na nagawa",
    "¡Gracias por tu ayuda!"                      :"Salamat sa iyong tulong!",
    "La donación fue exitosa"                     :"Naging matagumpay ang donasyon",
    "Elige cómo quieres ayudar"                   :"Piliin kung paano mo gustong tumulong",
    "Gracias por tu donación de"                  :"Salamat sa iyong donasyon ng",
    "¡Cerremos la brecha juntos!"                 :"Sabay nating tulay ang agwat!",
    "El valor de la donación es $0"               :"Ang halaga ng donasyon ay $0",
    "Reportes de impacto específicos"             :"Mga ulat sa partikular na epekto",
    "Sistema de gestión de proyectos"             :"Sistema ng Pamamahala ng Proyekto",
    "Asistencia técnica y de soporte"             :"Teknikal na tulong at suporta",
    "Análisis y reportes automáticos"             :"Awtomatikong pagsusuri at mga ulat",
    "Mensajes de audio personalizables"           :"Nako-customize na mga mensaheng audio",
    "Plataforma de gestión de contenido"          :"Platform ng pamamahala ng nilalaman",
    "Diseño y animación de avatares y más"        :"Mga animation, ilustrasyon at higit pa",
    "Funciones de seguimiento y evaluación"       :"Mga tampok ng pagsubaybay at pagsusuri",
    "Sistema de gestión de aprendizaje (LMS)"     :"Learning management system (LMS)",
    "Asistencia en coordinación de proyectos"     :"Tulong sa propesyonal na proyekto",
    "Iniciar sesión con tu correo electrónico"    :"Mag-sign in gamit ang iyong email",
    "Existe un usuario con esa identificación"    :"Mayroong isang gumagamit na may ID na iyon",
    "Institución educativa o comunidad afiliada"  :"Institusyong pang-edukasyon o kaakibat na pamayanan",
    "Existe un usuario con ese correo electrónico":"Mayroong isang gumagamit na may email na iyon",
    
    
    "Puedes acceder y ver los contenidos que se ofrecen":"Maaari mong i-access at tingnan ang nilalamang inaalok",
    "Al continuar, estás indicando que aceptas nuestros":"Sa pamamagitan ng pagpapatuloy, ipinapahiwatig mo na tinatanggap mo ang aming",
    "Ingresa el código de 6 digitos que enviamos al numero":"Ipasok ang code na ipinadala namin sa numero",
    "de estudiantes registrados ha terminado el laboratorio":"ng mga rehistradong estudyante ay nakatapos ng lab",
    "Se enviará un mensaje con un link al correo electrónico":"Ipapadala ang isang mensahe na may isang link sa email",
    "Debes ser mayor de edad para poder acceder a esta sección":"Dapat ay nasa edad ka na upang ma-access ang seksyong ito",
    "Mensaje enviado exitosamente, revisa tu correo electrónico":"Matagumpay na naipadala ang mensahe, suriin ang iyong email",
    "Correo electrónico ya está siendo utilizada por otra cuenta":"Ginagamit na ang email ng isa pang account",
    "Acceso desactivado temporalmente debido a muchos intentos fallidos":"Pansamantalang hindi pinagana ang pag-access dahil sa maraming hindi matagumpay na pagtatangka",
    "¡Te mantendremos informado sobre el impacto de tu donación! #somoselorigen":"Papanatilihin ka naming updated sa epekto ng iyong donasyon! #wearetheorigin",
    "Estos mensajes son monitoreados para garantizar la seguridad de nuestros usuarios":"Ang mga mensaheng ito ay sinusubaybayan upang matiyak ang kaligtasan ng aming mga gumagamit",
    "Esta sección solo esta habilitada para mayores de edad, por favor valida tu edad para continuar":"Pinapagana lang ang seksyong ito para sa mga may sapat na gulang, mangyaring patunayan ang iyong edad upang magpatuloy",
    "Si presionas Verificar número de teléfono se enviará un SMS. Se aplicarán las tarifas de mensaje y datos":"Kung pinindot mo ang I-verify ang numero ng telepono, isang SMS ang ipapadala. Malalapat ang mga rate ng mensahe at data.",
    "Se enviará un mensaje a la dirección de correo electrónico que has ingresado para que puedas cambiar tu contraseña.":"Ipapadala ang isang mensahe sa email address na iyong ipinasok upang mabago mo ang iyong password.",
    "Con o-lab es posible personalizar los requerimientos de todas las instituciones, entidades educativas u organizaciones. Con todas las características y funciones que tus beneficiarios o educadores necesitan para presentar todos los temas, conferencias, cursos y más.":"Sa pamamagitan ng o-lab, posibleng i-customize ang mga kinakailangan ng lahat ng institusyon, entidad na pang-edukasyon o organisasyon. Sa lahat ng feature at function na kailangan ng iyong mga grantees o educators na ipakita ang lahat ng paksa, kumperensya, kurso at higit pa.",
    "El 100% de cada donación va directamente a una persona/escuela/comunidad específica que espera nuestro kit #educacióndigitalparatodos que los equipa con tecnología, capacitación a profesores y nuestro LMS y aplicación de aprendizaje fuera de línea O-lab lleno de cursos complementarios, vocacionales y STEAM creados en colaboración con maestros e instituciones locales en todo el mundo.":"100% ng bawat donasyon ay direktang napupunta sa isang partikular na tao/paaralan/komunidad na naghihintay para sa aming #digitaleducationforall kit na nagbibigay sa kanila ng teknolohiya, pagsasanay at aming LMS at offline na app sa pag-aaral na O-lab na puno ng mga complementary, vocational at STEAM na mga kurso na ginawa kasama ng mga lokal na guro at institusyon sa buong mundo.",
  }
};

export default languaje_FIL;