import React, { FC, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Avatar, Grid, Typography, IconButton } from "@material-ui/core";
import * as utils from "utils";
import PickerDate from "./PickerDate";
import InformationOutside from "./InformationOutside";

const useStyles = makeStyles(() => ({
  date: {
    padding: "12px 16px",
    border: "2.6px solid #f0f0f0",
    alignItems: "center",
    borderRadius: 16,
  },
  edit: { position: "relative", zIndex: 2, borderColor: "#37d31c" },
  avatar: {
    width: 60,
    height: 60,
    marginRight: 16,
    borderRadius: 16,
  },
  content: { 
    width: "calc(100% - 116px)",
    alignItems: "center",
  },
  h6: { color: "#5f5f5f", fontWeight: 700, },
  body1: { color: "#8d8d8d", fontWeight: 500 },
  field: {
    "& .MuiInput-root": {
      color: "#5f5f5f",
      fontSize: 20,
      fontWeight: 700,
      "&:after": { display: "none" },
      "&:before": { display: "none" },
      "& .MuiInputBase-input": { padding: 0 },
    },
  },
  button: { 
    padding: 0,
    marginLeft: 8,
    "& .MuiAvatar-root": { width: 32,  height: 32, },
  },
}));

interface IProps {
  value: string;
  updateProfile: (name: string, value: string, c: () => void) => void
}

const FieldDate: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);
  const label = utils.getBirthdate(props.value);

  const toogleEdit = () => setEdit((v) => !v);

  const updateDate = (date: string) => {
    props.updateProfile("date", date, toogleEdit);
  }

  return (
    <>
      <Grid className={clsx(classes.date, edit && classes.edit)} container>
        <Avatar className={classes.avatar} src="/img/profile/date.svg" alt="" />
        <Grid className={classes.content} container>
          <Grid item xs={12}>
            <Typography className={classes.body1} noWrap>
              {t("Fecha de nacimiento")}
            </Typography>
            {edit ? (
              <PickerDate value={props.value} updateDate={updateDate} />
            ) : (
              <Typography className={classes.h6} variant="h6" noWrap>
                {label}
              </Typography>
            )}
          </Grid>
        </Grid>

        <IconButton className={classes.button} onClick={toogleEdit}>
          <Avatar src="/img/profile/edit_field.svg" alt="" />
        </IconButton>
      </Grid>

      {edit && (<InformationOutside onClick={toogleEdit} />)}
    </>
  )
}

export default FieldDate;
