import Content from "services/content.services";
import { IContent } from "dto/content.dto";
import { Action } from "./types";
import * as utils from "utils";
import * as types from "./types";
import { ILaboratory } from "dto/laboratory.dto";

export const getContents = (): Action => async (dispatch, getState) => {
  dispatch({ type: types.CONTENTS_LOADING, payload: true });
  const user = getState().user.user;
  try {
    const { surveys, laboratories } = await Content.get(user?.nit);
    const labs = await utils.getLaboratories(laboratories, user);
    dispatch(setContents([...labs, ...surveys]));
  } catch(e) {
    if (!!user?.permissions?.length || !!user?.permissions_survey?.length) {
      const contents = await utils.getContents(user);
      dispatch(setContents(contents));
    } else dispatch(setContents([]));
    console.log("Error Actions Get Contents");
  }
  dispatch({ type: types.CONTENTS_LOADING, payload: false });
}
export const setContents = (contents: IContent[]): Action => async (dispatch) => {
  dispatch({ type: types.CONTENTS, payload: contents });
}

export const getContentsFree = (typeUser: string): Action => async (dispatch) => {
  dispatch({ type: types.CONTENTS_FREE_LOADING, payload: true });
  try {
    const { laboratories } = await Content.getContentsFree(typeUser);
    const laboratoriesNew = laboratories?.map((laboratory: ILaboratory) => ({
      ...laboratory,
      modules: utils.getModulesWithQuestions(laboratory)
    }))
    const contents: IContent[] = [...laboratoriesNew];
    dispatch(setContentsFree(contents));
  } catch (e) {
    console.log("Error Get Laboratories Free: ", e)
  }
  dispatch({ type: types.CONTENTS_FREE_LOADING, payload: false });
}
export const setContentsFree = (value: IContent[]): Action => (dispatch) => {
  dispatch({ type: types.CONTENTS_FREE, payload: value });
}
