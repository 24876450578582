import axios from "axios";
import firebase from "firebase/app";
import "firebase/database";
import { IUserAnonymous, IUserAnonymousRequest } from "dto/user-anonymous.dto";

const url = process.env.REACT_APP_BACKEND_URL;
const route = `${url}/api`;

class UserAnonymous {
  async get(): Promise<IUserAnonymous[]> {
    const { data } = await axios.get(`${route}/static/users_anonymous`);
    return data?.data ?? [];
  }

  async post(request: IUserAnonymousRequest): Promise<IUserAnonymous> {
    const { data } = await axios.post(`${route}/users-anonymous`, request);
    return data?.data;
  }

  async update (uuid: string, request: IUserAnonymousRequest) {
    await firebase.database().ref(`users_anonymous/${uuid}`).update(request);
  };

  async getAnonymousUserByUuid(uuid: string) {
    const data = await firebase.database().ref(`users_anonymous/${uuid}`).once("value");
    return data?.val()
  }
}

const userAnonymous = new UserAnonymous()
export default userAnonymous;
