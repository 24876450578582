import React, { FC, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { IQuestion } from "dto/question.dto";
import CheckboxButton from "components/Question/CheckboxButton";

const useStyles = makeStyles(() => ({
  type14: { 
    height: "100%", 
    padding: 16,
    alignItems: "center", 
  },
}));

interface IProps {
  answer: string;
  question: IQuestion;
  viewFeedback: boolean;
  selectAnswer: (a: string) => void;
}

const Type14: FC<IProps> = (props) => {
  const classes = useStyles();
  const { answer, question, viewFeedback } = props;
  const [answersAux, setAnswersAux] = useState<string[]>([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { getAnswersSelected(); }, [question]);

  const getAnswersSelected = () => {
    if (!!answer) {
      const answersNew = answer.split(",");
      setAnswersAux(answersNew);
    } else setAnswersAux([]);
  }

  const onChange = (value: string) => {
    const filter = answersAux.filter((f) => f === value);
    if (!filter[0] && noneOption(value)) {
      setAnswersAux([value]);
      props.selectAnswer(value);
    } else if (!filter[0]) {
      const aux = [...answersAux, value];
      setAnswersAux(aux);
      props.selectAnswer(String(aux));
    } else {
      const aux = answersAux.filter((f) => f !== value);
      setAnswersAux([...aux]);
      props.selectAnswer(String(aux));
    }
  };

  const disabledChecked = (value: boolean) => {
    return typeof answer === "string" && noneOption(answer) && !value;
  }

  const noneOption = (value: string) => {
    const word = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    return word === "ninguno" || word === "ninguna"; 
  }

  const getChecked = (value: string) => {
    if (answer === value) return true;
    else if (!!answersAux && !!answersAux.length) {
      return !!answersAux.filter((a) => a === value)[0];
    } else return false;
  }
  const getCorrect = (value: string) => {
    const answerCorrects = question.answer_corrects ?? [];
    
    for (let i = 0; i < answerCorrects.length; i++) {
      if (answerCorrects[i] === value) return true;
    }

    return false;
  }
  const getFeedback = (value: string) => {
    if (!viewFeedback) return false;
    else {
      for (let i = 0; i < answersAux.length; i++) {
        if (answersAux[i] === value) return true;
      }
      return false;
    }
  }
  const getNotFeedback = () => {
    const { type, answer_corrects } = props.question;
    return type === 14 && 
      (!answer_corrects || !answer_corrects?.length);
  }

  return (
    <Grid className={classes.type14} container>
      <Grid container spacing={1}>
        {!!question.answers && question.answers.map((value, i) => (
          <Grid key={i} item xs={12}>
            <CheckboxButton 
              label={value}
              value={value}
              checked={getChecked(value)}
              correct={getCorrect(value)}
              feedback={getFeedback(value)}
              onChange={onChange}
              disabled={disabledChecked(getChecked(value)) || viewFeedback}
              notFeedback={getNotFeedback()}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

export default Type14;
