import {  IProperties, ISurvey, ISurveyCongratulations } from "dto/survey.dto";

const propertiesInit: IProperties = {
  wallpaper: "",
  button_color: "#3F0775",
  wallpaper_url: "",
  primary_color: "#ff6315",
  tertiary_color: "#e15915",
  secondary_color: "#ff6315",
  background_color: "#ffffff",
  background_video: "",
  button_text_color: "#ffffff",
  background_video_url: "",
};

const surveyCongratulationsInit: ISurveyCongratulations = {
  image: "",
  text_one: "¡Felicidades!",
  text_two: "Terminaste esta encuesta",
  audio_fl: "",
  audio_sl: "",
  image_url: "",
  audio_fl_url: "",
  audio_sl_url: "",
};

export const surveyInit: ISurvey = {
  uuid: "",
  date: "",
  name: "",
  tags: [],
  type: "survey",
  image: "",
  entity: "",
  answers: [],
  sponsor: "",
  privacy: false,
  app_code: "",
  questions: [],
  image_url: "",
  properties: propertiesInit,
  created_by: "",
  public_url: "",
  description: "",
  update_date: 0,
  entity_image: "",
  congratulations: surveyCongratulationsInit,
  entity_image_url: "",
};


