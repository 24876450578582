import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { object, string } from "yup";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { soundClick } from "utils";
import FormButton from "components/Form/FormButton";
import PasswordReset from "../PasswordReset";
import TermsConditions from "../TermsConditions";
import { 
  Grid, 
  TextField, 
  IconButton,
  Typography,
  InputAdornment,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  h5: { color: "#5f5f5f", fontWeight: 700, },
  field: { 
    "& fieldset": { border: 0 },
    "& .MuiInputBase-root ": { 
      borderRadius: 10, 
      backgroundColor: "#F6F6F6", 
    }, 
  },
  button: {
    color: "white",
    fontWeight: 700,
    borderRadius: 10,
    backgroundColor: "#ff6300",
    textTransform: "inherit",
  },
}));

interface IInitValues { email: string, password: string }

interface IProps {
  loading: boolean;
  passwordReset: (e: string, c: () => void) => void;
  signInWithEmail: (e: string, p: string) => void;
}

const SignInWithEmail: FC<IProps> = ({ loading, passwordReset, signInWithEmail }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [password, setPassword] = React.useState(true);
  const [passReset, setPassReset] = React.useState(false);
  const initialValues = { email: "", password: "" }
  const validations = object().shape({
    email: string().required(t('Campo requerido')).email('example@example.com'),
    password: string().required(t('Campo requerido')),
  });

  const onSubmit = (values: IInitValues) => {
    const email = values.email.toLowerCase().trim();
    signInWithEmail(email, values.password);
  }
  const changePassword = () => setPassword(!password);

  // const openPassReset = () => setPassReset(true);
  const closePassReset = () => setPassReset(false);

  const onKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === " ") e.preventDefault();
  }

  return (
    passReset ? (
      <PasswordReset 
        loading={loading}
        passwordReset={passwordReset}
        closePassReset={closePassReset} 
      />
    ) : (
      <Formik 
        initialValues={initialValues} 
        validationSchema={validations} 
        onSubmit={onSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography className={classes.h5} variant="h5">
                  {t("Iniciar sesión con tu correo electrónico")}
                </Typography>
              </Grid> 
              <Grid className={classes.field} item xs={12}>
                <Field
                  as={TextField}
                  name="email"
                  variant="outlined"
                  placeholder={t("Correo electrónico")}
                  size="small"
                  error={errors.email && touched.email}
                  helperText={touched.email && errors.email}
                  onKeyPress={onKeyPress}
                  aria-label={t("Escribe tu correo electrónico de acceso")}
                  disabled={loading}
                  fullWidth
                  autoFocus
                />
              </Grid>
              <Grid className={classes.field} item xs={12}>
                <Field 
                  as={TextField}
                  name="password"
                  variant="outlined"
                  placeholder={t("Contraseña")}
                  type={!!password ? 'password' : 'text'}
                  size="small"
                  error={errors.password && touched.password}
                  helperText={touched.password && errors.password}
                  aria-label={t("Escribe tu contraseña de acceso")}
                  disabled={loading}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={changePassword} 
                          onMouseDown={soundClick}
                        >
                          {!!password ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <FormButton 
                  type="submit"
                  primary
                  disabled={loading}
                  aria-label={t("Haga clic para iniciar sesión")}
                  fullWidth
                >
                  {t("Iniciar sesión")}
                </FormButton>
              </Grid>
              <Grid item xs={12}>
                <TermsConditions />
              </Grid>
              {/* <Grid item xs={12}>
                <Button 
                  className={classes.button} 
                  variant="contained" 
                  onClick={openPassReset}
                  onMouseDown={soundClick}
                  disabled={loading}
                  aria-label={t("Haga clic para restablecer la contraseña")}
                  fullWidth
                >
                  {t("Restablecer contraseña")}
                </Button>
              </Grid> */}
            </Grid>
          </Form>
        )}
      </Formik>
    )
  )
}

export default SignInWithEmail;