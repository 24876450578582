import React, { FC } from "react";
import { Text } from "react-konva";
import { ITemplateItem } from "dto/template.dto";

interface IProps {
  refStage?: any;
  templateItem: ITemplateItem;
  selectTemplateItem?: (templateItem: ITemplateItem) => void;
}

const TemplateItemText: FC<IProps> = (props) => {
  const onClick = () => {
    !!props.selectTemplateItem && props.selectTemplateItem(props.templateItem);
  }

  const onMouseEnter = () => {
    if (!!props.templateItem?.skip_logic && !!props.refStage?.current) {
      props.refStage.current.container().style.cursor = "pointer";
    }
  }
  const onMouseLeave = () => {
    if (!!props.refStage?.current) {
      props.refStage.current.container().style.cursor = "default";
    }
  }

  return (
    <Text
      x={props.templateItem.x}
      y={props.templateItem.y}
      id={props.templateItem.id}
      fill={props.templateItem?.fill}
      text={props.templateItem.text}
      padding={4}
      fontSize={props.templateItem?.font_size}
      fontStyle={props.templateItem?.font_style}
      fontFamily={props.templateItem?.font_family}
      textDecoration={props.templateItem?.text_decoration}
      onTap={onClick}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  );
};

export default TemplateItemText;
