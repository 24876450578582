import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { State } from "store/types";
import { history } from "configureStore";
import Welcome from "components/Login/AnonymousUser/Welcome";
import PageLoginCarrusel from "components/Pages/PageLoginCarrusel";

const AnonymousUserPage = () => {
  const user = useSelector((state: State) => state.user.user);
  const anonymousUser = localStorage.getItem("anonymousUser");
  const userAnonymous = JSON.parse(anonymousUser ?? "{}");
  const home = !!user.nit || (!!userAnonymous?.uuid && !!userAnonymous?.userType);

  useEffect(() => { if (!!home) history.push("/home"); }, [home]);

  return (
    <PageLoginCarrusel>
      <Welcome />
    </PageLoginCarrusel>
  )
}

export default AnonymousUserPage;
