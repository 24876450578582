import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Grid, InputAdornment, TextField, Typography } from "@material-ui/core";
import { IContent } from "dto/content.dto";
import * as utils from "utils";
import ContentFree from "components/Explore/ContentFree";

const useStyles = makeStyles((theme) => ({
  explore: {
    gap: 32,
    height: "100%", 
    padding: "32px 128px",
    position: "relative", 
    [theme.breakpoints.down("md")]: { padding: "32px 64px" },
    [theme.breakpoints.down("sm")]: { padding: "32px 32px" },
    [theme.breakpoints.down("xs")]: { padding: "16px 16px", gap: 16 },
  },
  title: { color: "#5f5f5f", fontSize: 40, fontWeight: 700, },
  subtitle: { color: "#8d8d8d", fontSize: 18, fontWeight: 500, },
  field: {
    "& .MuiInputBase-root": {
      borderRadius: 12,
      "& fieldset": { border: "2px solid #f1f1f1 !important" },
    },
    [theme.breakpoints.down("xs")]: { width: "100%" },
  },
  content: {
    height: "calc(100% - 119px)",
    overflow: "auto",
    alignItems: "flex-start",
    [theme.breakpoints.down("xs")]: { height: "calc(100% - 143px)", },
  },
  free: {
    display: "flex",
    alignItems: "center",
    paddingBottom: 32,
    "&:nth-child(odd)": { paddingRight: 25 },
    "&:nth-child(even)": { paddingLeft: 25 },
    [theme.breakpoints.down("xs")]: {
      "&:nth-child(odd)": { paddingRight: 0 },
      "&:nth-child(even)": { paddingLeft: 0 },
    },
  },
  btn: {

  }
}));

interface IProps {
  contents: IContent[];
  selectContent: (value: IContent) => void;
}

const Explore: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [contentList, setContentList] = useState<IContent[]>([]);
  const [contentFiter, setContentFiter] = useState("");

  useEffect(() => {
    if (!!props.contents?.length && !!contentFiter) {
      const aux = utils.filterContents(props.contents ?? [], contentFiter);
      setContentList(aux);
    } else if (!!props.contents?.length) setContentList(props.contents);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.contents]);

  const onChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    const aux = utils.filterContents(props.contents ?? [], value);
    setContentList(aux);
    setContentFiter(value);
  };

  return (
    <Grid className={classes.explore} container>
      <Grid container>
        <Grid item sm={6} xs={12}>
          <Typography className={classes.title} noWrap>
            {t("Explora")}
          </Typography>
          <Typography className={classes.subtitle} noWrap>
            {t("Labs libres ofrecidos por O-lab")}
          </Typography>
        </Grid>
        <Grid item container justifyContent="flex-end" sm={6} xs={12}>
          <TextField
            className={classes.field} 
            size="small" 
            variant="outlined" 
            placeholder={`${t("Busca labs")}...`}
            onChange={onChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src="/img/explore/search.svg" alt="" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Grid className={classes.content} container>
        {contentList?.map((content, i) => (
          <React.Fragment key={i}>
            {i === 3 && (
              <Grid key="laboratory" className={classes.free} item xs={12} sm={6}>
                <img src="/img/explore/laboratory.svg" alt=""  width="100%" />
              </Grid>
            )}
            <Grid className={classes.free} item xs={12} sm={6}>
              <ContentFree 
                content={content} 
                selectContent={props.selectContent} 
              />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </Grid>
  )
}

export default Explore;
