import { ITask } from "dto/user.dto";
import { IQuestion } from "dto/question.dto";
import * as utils from "utils/question.utils";
import { taskInit } from "initials/task.init";

export const updateTask = (tasks: ITask[], task: ITask) => {
  let position = 0;
  const tasksNew: ITask[] = [];

  for (let i = 0; i < tasks.length; i++) {
    if (
      tasks[i].laboratory === task.laboratory && 
      tasks[i].module === task.module && 
      tasks[i].challenger === task.challenger
    ) { 
      position = i
    }
  }

  for (let i = 0; i < tasks.length; i++) {
    if (i === position) tasksNew.push(task);
    else tasksNew.push(tasks[i]);
  }

  return tasksNew;
}
export const getCreateTask = async (path: string, task: ITask, tasks: ITask[]) => {
  const questions: IQuestion[] = [];
  for (let i = 0; i < task?.questions?.length; i++) {
    const question = task.questions[i];
    if (question.type === 16) {
      const file = question?.file_name ?? "";
      const file_name = await utils.getQuestionType16FileName(path, file);
      questions.push({ ...question, file_name });
    } else questions.push(question);
  }
  const response: ITask[] = [...tasks, { ...task, questions }];
  return response;
}
export const getUpdateTask = async (path: string, task: ITask, tasks: ITask[]) => {
  let position = 0;
  let taskEdit = taskInit;
  const tasksNew: ITask[] = [];
  const questions: IQuestion[] = [];

  for (let i = 0; i < tasks?.length; i++) {
    if (
      tasks[i].laboratory === task?.laboratory && 
      tasks[i].module === task?.module && 
      tasks[i].challenger === task?.challenger
    ) { 
      position = i;
      taskEdit = tasks[i];
    }
  }

  for (let i = 0; i < task?.questions?.length; i++) {
    const question = task.questions[i];
    if (question.type === 16) {
      const file = question?.file_name ?? "";
      const file_name = await utils.getQuestionType16FileName(
        path, 
        file, 
        taskEdit?.questions[i]?.file_name,
      );
      questions.push({ ...question, file_name });
    } else questions.push(question);
  }

  for (let i = 0; i < tasks?.length; i++) {
    if (i === position) tasksNew.push({ ...task, questions });
    else tasksNew.push(tasks[i]);
  }

  return tasksNew;
}
