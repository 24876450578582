import React from "react";
import { Close } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { IconButton, Grid } from "@material-ui/core";
import { State } from "store/types";
import { history } from "configureStore";
import LayoutOlab from "layouts/LayoutOlab";
import PrivacyPolicyES from "components/Common/PrivacyPolicyES";
import PrivacyPolicyEN from "components/Common/PrivacyPolicyEN";

const useStyles = makeStyles(() => ({
  page: {
    height: "calc(100vh - 60px)",
    position: "relative",
    marginTop: 60,
    overflowY: "auto",
  },
  header: { padding: 5, justifyContent: "flex-end" },
}));

const PrivacyPolicyPage = () => {
  const classes = useStyles();
  const user = useSelector((state: State) => state.user.user);
  const languageCode = useSelector((state: State) => state.layout.language.code);

  const onClose = () => {
    if (!!user.nit) history.push("/home");
    else history.push("/");
  }

  return (
    <Grid container>
      <LayoutOlab />
      <Grid className={classes.page} container>
        <Grid className={classes.header} container>
          <IconButton size="small" onClick={onClose}>
            <Close />
          </IconButton>
        </Grid>
        {(languageCode === "es") && (<PrivacyPolicyES />)}
        {(languageCode !== "es") && (<PrivacyPolicyEN />)}
      </Grid>
    </Grid>
  )
}

export default PrivacyPolicyPage;
