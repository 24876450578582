import { ITask } from "dto/user.dto";
import { IModule } from "dto/module.dto";
import { IChallenger } from "dto/challenger.dto";
import { ILaboratory, ILaboratoryCongratulations } from "dto/laboratory.dto";
import { taskInit } from "initials/task.init";
import { challengerCongratulationsInit, challengerInit } from "initials";
import * as utils from "utils";

export const getChallengesFiles = async (
  laboratoryName: string, 
  moduleName: string,
  challenges: IChallenger[],
) => {
  try {
    const data: IChallenger[] = [];
    for (let i = 0; i < challenges?.length; i++) {
      const challenger = await getChallengerFiles(
        laboratoryName, 
        moduleName, 
        challenges[i]
      );
      data.push(challenger);
    }
    return data;
  } catch (error) {
    return challenges ?? [];
  }
}

export const getChallengerLast = (module: IModule, challenger: IChallenger) => {
  const challengerLast = !!module?.challenges?.length 
    ? module?.challenges[module?.challenges?.length - 1]
    : challengerInit;
  return challenger?.name === challengerLast?.name;
}
export const getChallengerNext = (module: IModule, challenger: IChallenger) => {
  let index = 0;
  const challenges = module?.challenges ?? [];
  for (let i = 0; i < challenges.length; i++) {
    if (challenges[i]?.name === challenger?.name) index = i
  }
  return challenges[index + 1];
}
export const getChallengerTask = (
  laboratoryName: string, 
  moduleName: string, 
  challengerName: string,
  tasks: ITask[],
) => {
  const tasksData = tasks?.filter((task) => (
    task.laboratory === laboratoryName && 
    task.module === moduleName && 
    task.challenger === challengerName
  ));
  return !!tasksData?.length ? tasksData[tasksData?.length - 1] : taskInit;
}
export const getChallengerUnlocked = (
  laboratory: ILaboratory,
  module: IModule, 
  challenger: IChallenger, 
  tasks: ITask[],
) => {
  const percentage = utils.getLaboratoryProgress(laboratory, tasks).percentage;
  if (module?.challenges?.length) {
    if (!!laboratory?.challenges_blocked || !!laboratory?.sequential_mode) {
      let rate = 0;
      let challengerCurrent = challengerInit;
      const challenges = module?.challenges ?? [];
      for (let i = 0; i < challenges?.length; i++) {
        const taskAux = getChallengerTask(
          laboratory?.name, 
          module?.name, 
          challenges[i]?.name, 
          tasks,
        );
        if (!!taskAux?.challenger) {
          rate = taskAux?.rate;
          challengerCurrent = challenges[i];
        }
      }
      const challengesBlockedAndSequential = 
        !!laboratory?.challenges_blocked 
          && !!laboratory?.sequential_mode
          && rate < laboratory?.challenges_rate_min;
      const challengesBlocked = 
        !!laboratory?.challenges_blocked 
          && rate < laboratory?.challenges_rate_min;
      if (!!challengerCurrent?.name) {
        if (!!challengesBlockedAndSequential) {
          return challengerCurrent?.name === challenger?.name;
        } else if (challengesBlocked) {
          const challengerBack = isChallengerBack(module, challenger, challengerCurrent);
          return challengerCurrent?.name === challenger?.name || !!challengerBack;
        } else if (!!laboratory?.sequential_mode) {
          const challengerNext = getChallengerNext(module, challengerCurrent);
          if (!!challengerNext) return challengerNext?.name === challenger?.name;
          else return percentage >= 1;
        } else if (!!laboratory?.challenges_blocked) {
          const challengerNext = getChallengerNext(module, challengerCurrent);
          const challengerBack = isChallengerBack(module, challenger, challengerNext);
          return challengerNext?.name === challenger?.name || !!challengerBack;
        } else return true;
      } else return challenges[0]?.name === challenger?.name;
    } else return true;
  } else return false;
}
export const getChallengerFinished = (
  laboratoryName: string, 
  moduleName: string, 
  challengerName: string,
  tasks: ITask[],
) => {
  if (!!tasks) {
    for (let i = 0; i < tasks?.length; i++) {
      if (
        tasks[i].laboratory === laboratoryName && 
        tasks[i].module === moduleName && 
        tasks[i].challenger === challengerName
      ) {
       return true;
      }
    }
    return false;
  } else return false;
}
export const getChallengerWithTasks = (laboratoryName: string, module: IModule, tasks: ITask[]) => {
  let count = 0;
  let challengesLength = module?.challenges?.length ?? 0;
  if (!!tasks && !!tasks.length) {
    for (let i = 0; i < challengesLength; i++) {
      for (let j = 0; j < tasks.length; j++) {
        if (tasks[j].laboratory === laboratoryName && tasks[j].module === module.name) {
          if (tasks[j].challenger === module.challenges[i].name) count = count + 1;
        }
      }
    }

    if (count < challengesLength) {
      if (
        !!module?.challenges[count]?.steps?.length ||
        !!module?.challenges[count]?.questions?.length
      ) {
        return module.challenges[count];
      } else return null
    } else if (count >= challengesLength) {
      let count2 = count;
      do { 
        count2 = count2 - challengesLength; 
      } while (count2 >= challengesLength);
      if (
        !!module?.challenges[count2]?.steps?.length ||
        !!module?.challenges[count2]?.questions?.length
      ) {
        return module.challenges[count2]
      } else return null
    } else return null
  } else if (
    !!module?.challenges[0]?.steps?.length ||
    !!module?.challenges[0]?.questions?.length
  ) {
    return module.challenges[0]
  } else return null;
}

export const getChallengerNextWithTasks = (
  laboratoryName: string, 
  module: IModule, 
  challenger: IChallenger,
  tasks: ITask[],
  challengerBlocked?: boolean,
) => {
  if (!!challengerBlocked) {
    return { challenger: undefined, type: "blocked"};
  } else if (challenger.selected) {
    return { challenger: undefined, type: "selected"};
  } else if (!!laboratoryName && !!module?.challenges?.length) {
    if (module.challenges[module.challenges.length - 1].name !== challenger.name) {
      const challengerNext = getChallengerWithTasks(laboratoryName, module, tasks);
      if (!!challengerNext) {
        return { challenger: challengerNext, type: "next"};
      } else return { challenger: undefined, type: "finished"};
    } else return { challenger: undefined, type: "finished"};
  } else return { challenger: undefined, type: "not-found"};
}
export const getChallengerCongratulations = async (
  laboratoryName: string, 
  moduleName: string, 
  challenger: IChallenger,
) => {
  try {
    const congratulations: ILaboratoryCongratulations = {
      complete_image: await utils.downloadFile(
        `${laboratoryName}/${moduleName}/challenges`, 
        challenger?.congratulations?.complete_image ?? "",
        "/gif/default/challenger/congratulations_complete_image_default.gif"
      ),
      complete_audio_fl: await utils.downloadFile(
        `${laboratoryName}/${moduleName}/challenges`, 
        challenger?.congratulations?.complete_audio_fl ?? "",
        "/audio/default/challenger/congratulations_complete_audio_default.mp3",
      ), 
      complete_audio_sl: await utils.downloadFile(
        `${laboratoryName}/${moduleName}/challenges`, 
        challenger?.congratulations?.complete_audio_sl ?? "",
        "/audio/default/challenger/congratulations_complete_audio_default.mp3",
      ),
      complete_text_one: !!challenger?.congratulations?.complete_text_one 
        ? challenger.congratulations.complete_text_one 
        : "¡Unidad Terminada!",
      complete_text_two: !!challenger?.congratulations?.complete_text_two
        ? challenger.congratulations.complete_text_two
        : "Avanza a la siguiente unidad",
      incomplete_image: await utils.downloadFile(
        `${laboratoryName}/${moduleName}/challenges`, 
        challenger?.congratulations?.incomplete_image ?? "",
        "/gif/default/challenger/congratulations_incomplete_image_default.gif",
      ),
      incomplete_audio_fl: await utils.downloadFile(
        `${laboratoryName}/${moduleName}/challenges`, 
        challenger?.congratulations?.incomplete_audio_fl ?? "",
        "/audio/default/challenger/congratulations_incomplete_audio_default.mp3",
      ),
      incomplete_audio_sl: await utils.downloadFile(
        `${laboratoryName}/${moduleName}/challenges`, 
        challenger?.congratulations?.incomplete_audio_sl ?? "",
        "/audio/default/challenger/congratulations_incomplete_audio_default.mp3",
      ),
      incomplete_text_one: !!challenger?.congratulations?.incomplete_text_one
        ? challenger.congratulations.incomplete_text_one
        : "¡Oops!",
      incomplete_text_two: !!challenger?.congratulations?.incomplete_text_two
        ? challenger.congratulations.incomplete_text_two
        : "Vuelve a intentarlo",
    }
    return congratulations;
  } catch (error) {
    return challengerCongratulationsInit;
  }
}
export const getChallengerSequentialEnabled = (
  laboratoryName: string,
  module: IModule,
  challenger: IChallenger,
  tasks: ITask[],
) => {
  let index = 0;
  const challenges = module?.challenges ?? [];

  for (let i = 0; i < challenges.length; i++) {
    if (challenges[i].name === challenger.name) index = i;
  }
  
  if (!!index) {
    return getChallengerFinished(
      laboratoryName,
      module?.name,
      challenges[index - 1]?.name,
      tasks
    );
  } else return true;
}

const isChallengerBack = (
  module: IModule, 
  challenger: IChallenger,
  challengerCurrent: IChallenger,
) => {
  if (!!module?.challenges?.length) {
    let bandera = true;
    const challenges = module.challenges;
    const challengesBack: IChallenger[] = [];

    for (let i = 0; i < challenges.length; i++) {
      if (challenges[i]?.name === challengerCurrent?.name) bandera = false;
      if (!!bandera) challengesBack.push(challenges[i]);
    }

    const challengesAux = challengesBack?.filter((c) => (c?.name === challenger?.name));
    return !!challengesAux?.length;
  } else return false;
}

const getChallengerFiles = async (
  laboratoryName: string, 
  moduleName: string,
  challenger: IChallenger,
) => {
  try {
    const audio_url = await utils.downloadFile(
      `${laboratoryName}/${moduleName}/challenges`,
      challenger?.audio,
    );
    const image_url = await utils.downloadFile(
      `${laboratoryName}/${moduleName}/challenges`,
      challenger?.image,
    );
    const audio_wa_url = await utils.downloadFile(
      `${laboratoryName}/${moduleName}/challenges`,
      challenger?.audio_wa,
    );
    return { ...challenger, audio_url, image_url, audio_wa_url }
  } catch (error) {
    return challenger;
  }
}
