import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, styled } from "@material-ui/core/styles";
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { messageError } from "components/Common/Toast";
import DialogUploadFile from "components/Survey/DialogUploadFile";

const useStyles = makeStyles(() => ({
  type16: {
    height: "100%", 
    padding: 16, 
    alignItems: "center",
  },
  text: { "& .MuiInputBase-root": { borderRadius: 16 } },
  button: { 
    color: "white", 
    height: 37,
    backgroundColor: "#9fdfe5", 
    "& .MuiButton-label": { height: "100%", overflow: "hidden" },
  },
}));

const Audio = styled("audio")({ width: "100%", height: 45 });
const Image = styled("img")({ maxWidth: "100%", maxHeight: 220 });

interface IProps {
  file: { file: File, path: string };
  answer: string;
  selectFile: (file: File, path: string) => void;
  selectAnswer: (a: string) => void;
}

const Type16: FC<IProps> = ({ file, answer, selectFile, selectAnswer }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [type, setType] = useState("");
  const [dialogUploadFile, setDialogUploadFile] = useState(false)

  useEffect(() => {
    if (!!file.path && file.file.type.indexOf("image") !== -1) setType("IMAGE");
    else if (!!file.path && file.file.type.indexOf("audio") !== -1) setType("AUDIO");
    else setType("");
  }, [file])

  const uploadFile = (file: File) => {
    const FILE_SIZE = 20480 * 1024; //20MG
    if (file?.size <= FILE_SIZE) {
      const path = window.URL.createObjectURL(file);
      selectFile(file, path);
    } else messageError(t("Tamaño máximo del archivo 20 MB"));
  }
  const openDialogUploadFile = () => setDialogUploadFile(true);
  const closeDialogUploadFile = () => setDialogUploadFile(false);

  return (
    <Grid className={classes.type16} container>
      <Grid container spacing={2}>
        <Grid item container justifyContent="center" xs={12}>
          {!!type && type === "IMAGE" && (<Image src={file.path} alt="" />)}
        </Grid>

        <Grid item xs={12}>
          <TextField 
            className={classes.text}
            size="small"
            value={answer}
            minRows={5}
            variant="outlined"
            onChange={({ target: { value } }) => selectAnswer(value)}
            multiline
            fullWidth
            autoFocus
          />
        </Grid>

        {!!type && type === "AUDIO" && (
          <Grid item container justifyContent="center" xs={12}>
            <Audio src={file.path} controls />
          </Grid>
        )}

        <Grid item xs={12}>
          <Button
            className={classes.button}
            variant="contained"
            component="label"
            onClick={openDialogUploadFile}
            fullWidth
          >
            <Typography noWrap>
              {!!file.file && !!file.file.name ? file.file.name : t("Cargar archivo")}
            </Typography>
          </Button>
        </Grid>
      </Grid>

      <DialogUploadFile 
        open={dialogUploadFile}
        onClose={closeDialogUploadFile}
        uploadFile={uploadFile}
      />
    </Grid>
  )
}

export default Type16;
