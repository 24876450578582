import React from "react"
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Page404 from "components/Pages/Page404";
import AppBarDefault from "layouts/AppBarDefault";

const useStyles = makeStyles(() => ({
	root: { height: "100vh" },
  page: { 
    height: "calc(100% - 70px)", 
    position: "relative", 
  },
  content: {
    height: "100%",
    position: "relative", 
    overflow: "auto",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Page404Page = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.root} container>
      <AppBarDefault />

      <Grid className={classes.page} container>
        <Grid className={classes.content} container>
          <Page404 />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Page404Page
