import React, { FC } from "react";
import { Grid } from "@material-ui/core";
import ReactPlayer from "react-player";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  type19: { 
    height: "100%", 
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    "& .video-react": {
      width: "100%",
      height: "100%",
      padding: "0 !important",
      "& .video-react-video": { width: "100%", height: "100%" },
      "& .video-react-button": { display: "none" },
      "& .video-react-control-bar": { display: "none" },
    },
  },
  player: { 
    [theme.breakpoints.down("xs")]: { 
      height: "335px !important"
    },
  },
}));

interface IProps {
  url: string;
  playing?: boolean;
}

const Type19: FC<IProps> = ({ url, playing }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.type19} container>
      <ReactPlayer 
        className={classes.player}
        url={url}
        width="100%"
        height="100%"
        playing={playing}
        controls 
      />
    </Grid>
  )
}

export default Type19;
