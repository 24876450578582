import { IPermissionsPlatform } from "dto/permission.dto";

export const TeacherRules: IPermissionsPlatform = {
  actions: [
    {
      action: "accessPlatform",
      label: "Permiso para acceder a la plaforma",
    },
    {
      action: "menuHome",
      label: "Permiso de menu de pagina inicio",
    },
    {
      action: "menuLaboratory",
      label: "Permiso de menu de pagina laboratorios",
    },
    {
      action: "menuProfile",
      label: "Permiso de menu de pagina perfil",
    },
    {
      action: "menuUsers",
      label: "Permiso de menu de pagina usuarios",
    },
    {
      action: "menuStatistics",
      label: "Permiso de menu de pagina estadísticas",
    },
    {
      action: "createLaboratory",
      label: "Puede crear Laboratorios",
    },
    {
      action: "showModules",
      label: "Puede ver modulos de un laboratorio",
    },
    {
      action: "editLaboratory",
      label: "Puede editar un laboratorio",
    },
    {
      action: "createModule",
      label: "Puede crear un modúlo",
    },
    {
      action: "editModule",
      label: "Puede editar un modúlo",
    },
    {
      action: "createChallenge",
      label: "Puede crear una unidad",
    },
    {
      action: "showChallenges",
      label: "Puede ver listado de unidades",
    },
    {
      action: "updateChallenges",
      label: "Puede editar una unidad",
    },
    {
      action: "deleteChallenges",
      label: "Puede eliminar una unidad",
    },
    {
      action: "showSteps",
      label: "Puede ver listado de lecciones",
    },
    {
      action: "createSteps",
      label: "Puede crear una lección",
    },
    {
      action: "updateSteps",
      label: "Puede editar una lección",
    },
    {
      action: "deleteSteps",
      label: "Puede eliminar una lección",
    },
    {
      action: "showQuestions",
      label: "Puede ver listado de actividades",
    },
    {
      action: "createQuestions",
      label: "Puede crear una actividad",
    },
    {
      action: "updateQuestions",
      label: "Puede editar una actividad",
    },
    {
      action: "deleteQuestions",
      label: "Puede eliminar una actividad",
    },
    {
      action: "updatePermissionMasive",
      label: "Puede actualizar permisos masivos",
    },
    {
      action: "printUsers",
      label: "Puede imprimir usuarios",
    },
    {
      action: "filterUsers",
      label: "Puede filtrar usuarios",
    },
    {
      action: "addUsers",
      label: "Puede agregar usuarios",
    },
    {
      action: "updateUsers",
      label: "Puede actualizar usuarios",
    },
    {
      action: "updatePermissionApp",
      label: "Puede agregar permisos para la aplicación",
    },
    {
      action: "viewTasks",
      label: "Puede ver el listado de tareas",
    },
  ],
  resource: [],
}