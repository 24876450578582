import React, { FC, useEffect, useState } from "react";
import firebase from "firebase";
import IntlTelInput from "react-intl-tel-input";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Typography, Grid } from "@material-ui/core";
import "firebase/auth";
import "react-intl-tel-input/dist/main.css";
import FormButton from "components/Form/FormButton";
import { countriesData } from "./CountriesData";
import TermsConditions from "./TermsConditions";

const useStyles = makeStyles(() => ({
  h5: { color: "#5f5f5f", fontWeight: 700, },
  button: {
    color: "white",
    fontWeight: 700,
    borderRadius: 10,
    backgroundColor: "#ff6300",
    textTransform: "initial",
  },
  intlTelInput: {
    "& .intl-tel-input": {
      width: "100%",
      "& .flag-container": {
        "& .selected-flag": {
          borderRadius: "10px 0 0 10px",
          "& .selected-dial-code": { paddingLeft: 5 },
        },
        "& .country-list": { top: 32, left: 0, right: 0, maxHeight: 180 },
      },
      "& input": {
        width: "100%",
        border: 0,
        padding: "10px 16px",
        fontSize: 16,
        borderRadius: 10,
        backgroundColor: "#f6f6f6",
      },
    },
  },
  caption: { 
    color: "#929292",
    fontWeight: 500 ,
    "& span": { color: "#ff6300", cursor: "pointer" }, 
  },
}));

interface IObject {
  iso2?: string;
  name?: string;
  dialCode?: string;
  priority?: number;
  areaCodes?: any;
}

interface IProps {
  loading: boolean
  getPhoneNumber: (phoneNumber: string) => void
  sendPhoneNumber: (phoneNumber: string, reCAPTCHA: any) => void
}

const PhoneNumber:FC<IProps> = ({ loading, getPhoneNumber, sendPhoneNumber}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [reCAPTCHA, setReCAPTCHA] = useState<any>(null);
  const [phoneNumber, setPhoneNumber] = useState({value: false, number: ""});
  const telInputProps = {
    onKeyPress: (e: {key: string, preventDefault: () => void}) => {
      const reg = new RegExp('^[0-9]+$');
      if (!reg.test(e.key)) e.preventDefault();
    },
    onKeyUpCapture: (e: { keyCode: number }) => {
      if (e.keyCode === 13) onSendPhoneNumber()
    }, 
  }

  useEffect(() => {
    setReCAPTCHA(
      new firebase.auth.RecaptchaVerifier("recaptcha-container", {
        type: "image",
        size: "invisible",
        badge: "bottomleft",
        style: "none",
        callback: () => {},
        expiredCallback: () => {},
        uiShown: () => {},
      })
    );
    return () => { setReCAPTCHA(null) }
  }, []);

  const onSendPhoneNumber = () => {
    if (phoneNumber.value) {
      sendPhoneNumber(phoneNumber.number, reCAPTCHA);
      getPhoneNumber(phoneNumber.number);
    }
    else {}
  }

  const phoneNumberChange = (value: boolean, tex: string, obj: IObject, number: string) => {
    setPhoneNumber({value, number: number.replace(/ /g, "")})
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography className={classes.h5} variant="h5">
          {t("Ingresa tu número de teléfono")}
        </Typography>
      </Grid>
      <Grid className={classes.intlTelInput} item xs={12}>
        <IntlTelInput 
          defaultCountry="co"
          preferredCountries={[]}
          placeholder={t("Número de teléfono")}
          // @ts-ignore
          countriesData={countriesData}
          telInputProps={telInputProps}
          onPhoneNumberChange={phoneNumberChange}
          disabled={loading}
        />
      </Grid>
      <Grid item xs={12}> 
        <FormButton 
          primary
          onClick={onSendPhoneNumber}
          disabled={loading}
          fullWidth
        >
          {t("Verificar número de teléfono")}
        </FormButton>
      </Grid>
      <Grid item xs={12}>
        <Typography 
          className={classes.caption} 
          align="center"
          display="block" 
          variant="caption" 
        >
          {t("Si presionas Verificar número de teléfono se enviará un SMS. Se aplicarán las tarifas de mensaje y datos")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TermsConditions />
      </Grid>
      <div id="recaptcha-container" className="recaptcha-container"></div>
    </Grid>
  )
}

export default PhoneNumber;
