const svgImage = (c1: String) => `
  <svg width="77" height="72" viewBox="0 0 77 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M38.5 0L47.3683 27.2938L76.0667 27.2938L52.8492 44.1623L61.7175 71.4562L38.5 54.5877L15.2825 71.4562L24.1508 44.1623L0.93327 27.2938L29.6317 27.2938L38.5 0Z"
      fill="${c1}"
    />
  </svg>
`;

export const SvgStar = (c1?: string) => {
  const a = !!c1 ? c1 : "#ff6300";
  return `data:image/svg+xml;charset=UTF-8;base64,${btoa(svgImage(a))}`;
};

export default SvgStar;
