import React, { FC, useRef } from "react";
import { Text } from "react-konva";
import { ITemplateItem } from "dto/template.dto";

interface IProps {
  templateItem: ITemplateItem;
}

const TemplateItemText: FC<IProps> = (props) => {
  const refText = useRef<any>();
  const width = !!props.templateItem?.width
    ? props.templateItem.width
    : refText?.current?.textWidth;

  return (
    <Text
      x={props.templateItem.x}
      y={props.templateItem.y}
      id={props.templateItem.id}
      ref={refText}
      fill={props.templateItem?.fill}
      text={props.templateItem?.text}
      align={props.templateItem.align}
      width={width}
      fontSize={props.templateItem?.font_size}
      fontStyle={props.templateItem?.font_style}
      fontFamily={props.templateItem?.font_family}
      textDecoration={props.templateItem?.text_decoration}
    />
  );
};

export default TemplateItemText;
