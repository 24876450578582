import React, { FC, MouseEventHandler } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  outside: {
    inset: 0,
    zIndex: 1,
    border: 0,
    padding: 0,
    position: "fixed",
    backgroundColor: "transparent",
  },
}));

interface IProps {
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const InformationOutside: FC<IProps> = (props) => {
  const classes = useStyles();

  return (
    <button className={classes.outside} onClick={props.onClick} />
  )
}

export default InformationOutside;
