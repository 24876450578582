import React, { FC } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { IUser } from "dto/user.dto";
import MenuPhoto from "./MenuPhoto";
import MenuOptions from "./MenuOptions";

const useStyles = makeStyles((theme) => ({
  menu: { 
    height: "100%",
    border: "2.6px solid #f0f0f0",
    boxShadow: "none",
    borderRadius: 16,
    [theme.breakpoints.down("xs")]: { width: "100%" }, 
  },
}));

interface IProps {
  user: IUser;
  section: number;
  photoLoading: boolean;
  logOut: () => void;
  updatePhoto: (data: File) => void;
  selectSection: (data: number) => void;
}

const ProfileMenu: FC<IProps> = (props) => {
  const classes = useStyles();

  return (
    <Grid className={classes.menu} container>
      <MenuPhoto 
        user={props.user}
        loading={props.photoLoading} 
        updatePhoto={props.updatePhoto} 
      />
      <MenuOptions 
        section={props.section} 
        selectSection={props.selectSection} 
      />
    </Grid>
  )
}

export default ProfileMenu;
