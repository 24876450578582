export const click = new Audio("/audio/click.wav");
export const soundClick = () => click.play();

export const carouselPrev = new Audio("/audio/carousel_prev.wav");
export const soundCarouselPrev = () => carouselPrev.play();
export const carouselNext = new Audio("/audio/carousel_next.wav");
export const soundCarouselNext = () => carouselNext.play();

export const unitFinish = new Audio("/audio/unit_finish.wav");
export const soundUnitFinish = () => unitFinish.play();

export const questionMultiple = new Audio("/audio/question_multiple.wav");
export const soundQuestionMultiple = () => questionMultiple.play();

export const newMessage = new Audio("/audio/message.wav");
export const soundNewMessage = () => newMessage.play();
