import React, { FC, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ITemplate } from "dto/template.dto";
import { templateDefault } from "initials/template.init";
import { certificateInit } from "initials/certificate.init";
import * as toast from "components/Common/Toast";
import * as utils from "utils";
import Loading from "components/Common/Loading";
import FormButton from "components/Form/FormButton";
import TemplateStage from "components/Template/TemplateStage";

const useStyles = makeStyles(() => ({
  paper: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  content: { gap: 16, position: "relative" },
  blocker: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  footer: { gap: 8, position: "relative", justifyContent: "flex-end" },
}));

interface IProps {
  open: boolean;
  userName: string;
  entityName: string;
  laboratoryName: string;
  certificateUuid: string;
  onClose: () => void;
}

const DialogCertificate: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const ref = useRef();
  const [loading, setLoading] = useState(false);
  const [certificate, setCertificate] = useState(certificateInit);

  useEffect(() => {
    getCertificate(props.certificateUuid);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.certificateUuid]);

  const getCertificate = async (uuid: string) => {
    const data = await utils.getCertificateByUuid(uuid);
    if (!!data?.uuid) setCertificate(data);
    else setCertificate(certificateInit);
  }

  const getTemplate = () => {
    if (!!certificate?.templates?.length) {
      const template = { ...certificate.templates[0] };
      return { ...template, ref };
    } else return { ...templateDefault, ref };
  };
  const template: ITemplate = getTemplate();
  const style = { maxWidth: !!template.width ? template.width : "fit-content" };

  const downloadCertificate = async () => {
    setLoading(true);
    const dataURL = template?.ref?.current?.toDataURL({ pixelRatio: 3 });
    const fileName = `Certificate ${props.userName}`;
    await utils.convertBase64ToPdf(dataURL, fileName);
    setLoading(false);
    toast.messageSuccess("¡Certificado descargado con éxito!");
  };

  return (
    <Dialog
      open={props.open}
      classes={{ paper: classes.paper }}
      fullScreen
      onClose={props.onClose}
    >
      <Grid className={classes.content} container style={style}>
        <TemplateStage 
          template={template}
          userName={props.userName}
          entityName={props.entityName}
          laboratoryName={props.laboratoryName} 
        />
        <Grid className={classes.blocker} container></Grid>
        <Grid className={classes.footer} container>
          <FormButton minWidth={180} onClick={props.onClose}>
            {t("Cerrar")}
          </FormButton>
          <FormButton primary minWidth={180} onClick={downloadCertificate}>
            {t("Descargar")}
          </FormButton>
        </Grid>
      </Grid>
      <Loading loading={loading} white />
    </Dialog>
  );
};

export default DialogCertificate;

    