import * as types from "./types";

const INITIAL_STATE: types.GenderState = {
  genders: [],
  gendersLoading: false,
}

export const gender = (prevState = INITIAL_STATE, action: types.GenderActionType) => {
  switch (action.type) {
    case types.GENDERS: 
      return { ...prevState, genders: action.payload }
    case types.GENDERS_LOADING: 
      return { ...prevState, gendersLoading: action.payload }

    default: 
      return prevState
  }
}
