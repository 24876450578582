import Entity from "services/entity.services";
import { IEntity } from "dto/entity.dto";
import { entityInit } from "initials/entity.init";

export const getEntityByNit = async (nit: string) => {
  try {
    const db = await Entity.get();
    const entity = db?.filter((c) => (c.nit === nit))[0];
    if (!!entity?.nit) return entity;
    else return undefined;
  } catch (error) {
    console.log("Error Utils Get Entity By Nit: ", error);
    return undefined;
  }
}

export const getEntityByName = async (
  { entities, entityName } : { entities?: IEntity[]; entityName: string; }
) => {
  if (!!entities?.length) {
    const entity = entities?.filter(({ name }) => (name === entityName))[0];
    return { entity, entities };
  } else {
    try {
      const entities = await Entity.get();
      const entity = entities?.filter(({ name }) => (name === entityName))[0];
      return { entity, entities };
    } catch (error) {
      return { entity: entityInit, entities: entities ?? [] };
    }
  }
}
