const svgImage = (c1: String) => `
  <svg width="65" height="200" viewBox="0 0 65 200" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M64.17 199.72L31.05 176.51L0 199.72V0H64.17V199.72Z" fill="${c1}"/>
  </svg>
`;

export const SvgShape06 = (c1?: string) => {
  const a = !!c1 ? c1 : "#ff6300";
  return `data:image/svg+xml;charset=UTF-8;base64,${btoa(svgImage(a))}`;
};

export default SvgShape06;
