export const questions = [
  { id: 0,  name: "¿QUÉ ES O-LAB?" },
  { id: 1,  name: "¿CÓMO FUNCIONA?" },
  { id: 2,  name: "¿FUNCIONA O-LAB CON O SIN CONEXIÓN A INTERNET?" },
  { id: 3,  name: "¿A QUIÉN PUEDE SERVIR O-LAB?" },
  { id: 4,  name: "O-LAB ES PARA TODOS" },
  { id: 5,  name: "QUIERO VER CÓMO FUNCIONA O-LAB DESDE DENTRO" },
  { id: 6,  name: "¿QUÉ ES UNA PLATAFORMA LMS?" },
  { id: 7,  name: "¿LAS LICENCIAS DE O-LAB INCLUYEN LOS IMPUESTOS SOBRE LA RENTA?" },
  { id: 8,  name: "¿ES NECESARIO INSTALAR LA PLATAFORMA DE CREACIÓN Y GESTIÓN DE CONTENIDOS?" },
  { id: 9,  name: "¿CÓMO PERCIBE LA FUNDACIÓN EL ORIGEN SUS LOGROS EN TERMINOS ECONÓMICOS?" },
  { id: 10, name: "¿QUÉ MÁS TIENE O-LAB?" },
  { id: 11, name: "NO ENTIENDO CLARAMENTE LO QUE QUIERE DECIR CON LICENCIAS" },
  { id: 12, name: "NUESTRA MISIÓN ES PROPORCIONAR EDUCACIÓN A TODOS" },
  { id: 13, name: "SI ADQUIERES TU LICENCIA ESTARÁS" },
  { id: 14, name: "EDUCACIÓN DIGITAL" },
  { id: 15, name: "EL FONDO DE APRENDIZAJE DE ORIGEN NO PERCIBE SUS LOGROS EN TÉRMINOS ECONÓMICOS, SINO EN TÉRMINOS DE DESARROLLO COMUNITARIO" },
  { id: 16, name: "¿CUÁNTOS CURSOS PUEDO CREAR CON O-LAB?" },
  { id: 17, name: "¿PUEDO PROBAR O-LAB ANTES DE COMPRAR UNA LICENCIA?" },
  { id: 18, name: "¿QUÉ CONTIENE EL KIT DE HERRAMIENTAS DIGITALEAS DE O-LAB?" },
]