import React, { FC } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Button, Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  options: {
    height: "calc(100% - 124px)",
    alignItems: "flex-start",
  },
  content: { gap: 24, padding: "24px 14px" },
  button: { 
    color: "#8d8d8d",
    padding: "6px 16px",
    textAlign: "start",
    borderRadius: 16,
    textTransform: "initial", 
    justifyContent: "initial",
  },
  select: {
    color: "#0aa9e2",
    background: "#ebfaff",
    "&:hover": { background: "#ebfaff" },
  },
  h6: { fontWeight: 700, },
}));

interface IProps {
  section: number;
  selectSection: (section: number) => void;
}

const MenuOptions: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { section } = props;
  const options = [
    {
      id: 0,
      title: "Información personal",
      onClick: () => props.selectSection(0),
    },
    // {
    //   id: 1,
    //   title: "Notificaciones",
    //   onClick: () => props.selectSection(1),
    // },
    {
      id: 2,
      title: "Contraseña",
      onClick: () => props.selectSection(2),
    },
    {
      id: 3,
      title: "Idioma",
      onClick: () => props.selectSection(3),
    },
  ]

  return (
    <Grid className={classes.options} container>
      <Grid className={classes.content} container>
        {options.map(({ id, title, onClick }, i) => (
          <Button 
            key={i}
            className={clsx(classes.button, id === section && classes.select)}
            fullWidth
            disabled={id === 1}
            onClick={onClick}
          >
            <Typography className={classes.h6} variant="h6">
              {t(title)}
            </Typography>
          </Button>
        ))}
      </Grid>
    </Grid>
    
  )
}

export default MenuOptions;
