import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Grid, Card, Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    padding: 15,
    position: "relative",
    minHeight: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  card: {
    padding: "30px 45px",
    minWidth: 600,
    maxWidth: 600,
    textAlign: "center",
    borderRadius: 20,
  },
}));

const NotPermissions = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid className={classes.root} container>
      <Card className={classes.card}>
        <img src="/assets/Img/olab-logo.svg" alt="" width="100%" />
        <Typography align="center" variant="h5" gutterBottom>
          {t("No tienes laboratorios habilitados")}
        </Typography>
      </Card>
    </Grid>
  )
}

export default NotPermissions;
