import React, { FC } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import { RadioButtonChecked, RadioButtonUnchecked } from "@material-ui/icons";
import { soundQuestionMultiple } from "utils/audios.utils";
import FeedbackText from "./FeedbackText";

const useStyles = makeStyles(() => ({
  radio: {
    color: "#8d8d8d",
    border: "2px solid #f0f0f0",
    padding: "12px 16px",
    boxShadow: "0px 2px 0px 0px #f0f0f0",
    borderRadius: 16,
    overflowWrap: "anywhere",
    textTransform: "initial",
    "& .MuiButton-label": { gap: 12 },
    "&.Mui-disabled": { 
      boxShadow: "none",
      borderWidth: 2,
      "& .MuiSvgIcon-root": { color: "rgba(0, 0, 0, 0.26)" }, 
    },
  },
  selected: {
    border: "2px solid #0aa9e2",
    boxShadow: "0px 2px 0px 0px #0aa9e2",
    backgroundColor: "white",
    "&:hover": { backgroundColor: "white" },
    "& .MuiSvgIcon-root": { color: "#0aa9e2" },
    "& .MuiTypography-root": { color: "#0aa9e2" },
  },
  correct: {
    border: "2px solid #2cc113 !important",
    boxShadow: "0px 2px 0px 0px #2cc113 !important",
    backgroundColor: "#37d31c",
    "& .MuiSvgIcon-root": { color: "white !important" },
    "& .MuiTypography-root": { color: "white" },
  },
  incorrect: {
    border: "2px solid #d80909 !important",
    boxShadow: "0px 2px 0px 0px #d80909 !important",
    backgroundColor: "#e02424",
    "& .MuiSvgIcon-root": { color: "white !important" },
    "& .MuiTypography-root": { color: "white" },
  },
  text: {
    width: "calc(100% - 36px)",
    fontSize: 18,
    textAlign: "left",
    fontWeight: 500,
    lineHeight: 1,
  },
}));

interface IProps {
  label: string;
  value: string;
  correct?: boolean;
  checked?: boolean;
  feedback?: boolean;
  disabled?: boolean;
  notFeedback?: boolean;
  onChange: (value: string) => void;
}

const RadioButton: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { label, value, correct, checked, feedback, notFeedback } = props;

  const onClick = () => props.onChange(value);

  return (
    <>
      {false && (<FeedbackText correct={correct} feedback={feedback} />)}
      
      <Button 
        className={clsx(
          classes.radio, 
          !!checked && classes.selected,
          !!feedback && !notFeedback && !!correct && classes.correct,
          !!feedback && !notFeedback &&  !correct && classes.incorrect,
        )} 
        variant="outlined" 
        fullWidth
        onClick={onClick}
        disabled={props.disabled}
        onMouseDown={soundQuestionMultiple}
        aria-label={`${t("Haga clic para seleccionar la respuesta")} ${label}`}
      >
        {checked ? <RadioButtonChecked /> : <RadioButtonUnchecked />}
        <Typography className={classes.text}>{t(label)}</Typography>
      </Button>
    </>
  )
}

export default RadioButton;
