import React, { FC, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Grid, IconButton } from "@material-ui/core";
import { history } from "configureStore";
import Loading from "components/Common/Loading";
import SignInMenu from "components/Login/SignIn/SignInMenu";
import SignInWithEmail from "components/Login/SignIn/SignInWithEmail";
import SignInWithPhone from "components/Login/SignIn/SignInWithPhone";

const useStyles = makeStyles((theme) => ({
  signin: { 
    height: "100%",
    position: "relative",
    backgroundColor: "white", 
  },
  header: {
    padding: "60px 76px 0",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: { padding: "60px 52px 0" },
    [theme.breakpoints.down("sm")]: { padding: "60px 32px 0" },
    [theme.breakpoints.down("xs")]: { padding: "20px 12px 0" },
  },
  back: {
    left: 76,
    padding: 0,
    outline: "0 !important",
    position: "absolute",
    borderRadius: 12,
    "& .MuiAvatar-root": { width: "100%", height: 40, borderRadius: 4 },
    [theme.breakpoints.down("md")]: {  left: 52, },
    [theme.breakpoints.down("sm")]: {  left: 32, },
    [theme.breakpoints.down("xs")]: {  left: 12, },
  },
  content: { 
    height: "calc(100% - 127px)", 
    padding: "32px 80px",
    overflow: "auto",
    alignItems: "center",
    [theme.breakpoints.down("md")]: { padding: "32px 64px" },
    [theme.breakpoints.down("sm")]: { padding: "32px 32px" },
    [theme.breakpoints.down("xs")]: { padding: "32px 16px" },
  },
}));

interface IProps {
  confirm: any;
  loading: boolean;
  confirmCode: any;
  passwordReset: (e: string, c: () => void) => void;
  signInWithEmail: (e: string, pas: string) => void;
  sendPhoneNumber: (p: string, rc: any) => void;
  changePhoneNumber: () => void;
  sendCodeVerification: (c: string) => void
}

//MENU: 0 | EMAIL: 1 | PHONE: 0;

const SignIn: FC<IProps> = (props) => {
  const classes = useStyles();
  const [signIn, setSignIn] = useState(0);

  const closeSignIn = () => {
    if (signIn === 0) history.push("/");
    else if (signIn === 2 && !!props.confirm) props.changePhoneNumber();
    else setSignIn(0);
  }

  return (
    <Grid className={classes.signin} container>
      <Grid className={classes.header} container>
        <IconButton className={classes.back} onClick={closeSignIn}>
          <Avatar src="/img/login/back.png" alt="" />
        </IconButton>
        <img src="/img/logos/olab.svg" alt="" width={150} />
      </Grid>
      <Grid className={classes.content} container>
        {signIn === 1 ? (
          <SignInWithEmail
            loading={props.loading}
            passwordReset={props.passwordReset}
            signInWithEmail={props.signInWithEmail} 
          />
        ) : signIn === 2 ? (
          <SignInWithPhone
            confirm={props.confirm}
            loading={props.loading}
            confirmCode={props.confirmCode}
            sendPhoneNumber={props.sendPhoneNumber}
            sendCodeVerification={props.sendCodeVerification}
          />
        ) : (
          <SignInMenu setSignIn={setSignIn} />
        )}
      </Grid>

      <Loading loading={props.loading} white />
    </Grid>
  )
}

export default SignIn;
