import React, { FC } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';
import { makeStyles, Grid, IconButton, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { IDonation } from '../../services/checkout.services';
import Loading from '../Common/Loading';
import Stripe from './Stripe';

const useStyles = makeStyles(() => ({
  payment: { height: 'calc(100% - 20px)', position: 'relative' },
  header: { 
    padding: 13, 
    borderBottom: '1px solid darkgray',
    '& .MuiIconButton-root': { marginRight: 15 }
  },
  elements: { height: 'calc(100% - 60px)' },
}));

interface IProps {
  donation: IDonation
  prevSection: () => void
  nextSection: () => void
}

const Payment: FC<IProps> = ({donation, prevSection, nextSection}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const publishableKey = process.env.REACT_APP_PUBLISHABLE_KEY ?? "";
  const stripePromise = loadStripe(publishableKey);
  const [loading, setLoading] = React.useState(false)

  return (
    <Grid className={classes.payment} container>
      {loading && (<Loading white loading />)}

      <Grid className={classes.header} container alignItems="center">
        <IconButton size="small" onClick={prevSection}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h6" component="div" color="textSecondary">
          {t("Agrega tu información")}
        </Typography>
      </Grid>

      <Elements stripe={stripePromise}>
        <Grid className={classes.elements} container>
          <Stripe 
            donation={donation}
            nextSection={nextSection}
            updateLoading={setLoading} 
          />
        </Grid>
      </Elements>
    </Grid>
  )
}

export default Payment;
