import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import FormButton from "components/Form/FormButton";
import ButtonLanguage from "layouts/ButtonLanguage";
import TermsConditions from "./TermsConditions";

const useStyles = makeStyles((theme) => ({
  menu: { 
    height: "100%", 
    alignItems: "center",
    backgroundColor: "white", 
  },
  header: {
    padding: "60px 0 0",
    position: "relative",
    justifyContent: "center",
  },
  content: { 
    height: "calc(100% - 127px)", 
    padding: "32px 120px",
    alignItems: "center",
    [theme.breakpoints.down("xl")]: { padding: "32px 120px" },
    [theme.breakpoints.down("lg")]: { padding: "32px 80px" },
    [theme.breakpoints.down("md")]: { padding: "32px 64px" },
    [theme.breakpoints.down("sm")]: { padding: "32px 32px" },
    [theme.breakpoints.down("xs")]: { padding: "32px 16px" },
  },
  title: { color: "#5f5f5f", fontSize: 40, fontWeight: 700 },
  subtitle: { 
    color: "#8d8d8d", 
    fontSize: 18, 
    fontWeight: 500,
    marginBottom: 20,
  },
  language: {
    width: "fit-content",
    right: 120,
    padding: 0,
    outline: "0 !important",
    position: "absolute",
    borderRadius: 12,
    [theme.breakpoints.down("xl")]: {  right: 120, },
    [theme.breakpoints.down("lg")]: {  right: 80, },
    [theme.breakpoints.down("md")]: {  right: 64, },
    [theme.breakpoints.down("sm")]: {  right: 32, },
    [theme.breakpoints.down("xs")]: {  right: 16, },
  },
}));

interface IProps {
  openSignIn: () => void;
  openCreateAccount: () => void;
  openAnonymousUser: () => void;
}

const LoginMenu: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const languageButton = false;

  return (
    <Grid className={classes.menu} container>
      <Grid className={classes.header} container>
        <img src="/img/logos/olab.svg" alt="" width={150} />
        {languageButton && (
          <Grid className={classes.language} container>
            <ButtonLanguage primary />
          </Grid>
        )}
      </Grid>
      <Grid className={classes.content} container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.title} align="center">
              {t("¡Hola!")}
            </Typography>
            <Typography className={classes.subtitle} align="center">
              {t("¡Bienvenido a O-lab!")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormButton primary onClick={props.openCreateAccount} fullWidth>
              {t("Crear cuenta")}
            </FormButton>
          </Grid>
          <Grid item xs={12}>
            <FormButton primary onClick={props.openSignIn} fullWidth>
              {t("Ya tengo cuenta")}
            </FormButton>
          </Grid>
          <Grid item xs={12}><TermsConditions /></Grid>
          <Grid item xs={12}>
            <FormButton fullWidth onClick={props.openAnonymousUser}>
              {t("Omitir")}
            </FormButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LoginMenu;
