import React, { FC } from "react";
import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  button: {
    border: "2px solid #f0f0f0",
    padding: 8,
    boxShadow: "0px 2px 0px 0px #f0f0f0",
    borderRadius: 12,
    backgroundColor: "white",
    "&:hover": { backgroundColor: "white" },
  },
  default: { border: 0, boxShadow: "none", },
  secondary: {
    border: "2px solid #0aa9e2",
    boxShadow: "0px 2px 0px 0px #0a96c8",
    backgroundColor: "#0aa9e2",
    "&:hover": {
      boxShadow: "0px 2px 0px 0px #0a96c8",
      backgroundColor: "#0aa9e2",
    },
  },
}));

interface IProps {
  size?: number | string;
  image: string;
  padding?: number | string;
  default?: boolean;
  secondary?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onMouseDown?: React.MouseEventHandler<HTMLButtonElement>;
}

const FormButtonIcon: FC<IProps> = (props) => {
  const classes = useStyles();
  const style = { width: props.size, height: props.size, padding: props.padding };
  const className = clsx(
    classes.button, 
    !!props.default && classes.default, 
    !!props.secondary && classes.secondary
  );

  return (
    <IconButton 
      className={className}
      onClick={props.onClick}
      onMouseDown={props.onMouseDown}
      style={style}
    >
      <img src={props.image} alt="" />
    </IconButton>
  )
}

export default FormButtonIcon;
