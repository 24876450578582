import React, { FC, useEffect, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Grid, CircularProgress } from "@material-ui/core";
import FormTypography from "components/Form/FormTypography";

const useStylesFacebook = makeStyles((theme: Theme) => ({
  root: { position: "relative" },
  bottom: { color: "#ff6300", opacity: 0.38 },
  top: {
    left: 0,
    color: "#ff6300",
    position: "absolute",
    animationDuration: "10000ms",
  },
  circle: { strokeLinecap: "round" },
  rate: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
  },
}));

interface IProps {
  rate: number;
  color?: string;
}

const CircularProgressBar: FC<IProps> = (props) => {
  const classes = useStylesFacebook();
  const color = !!props.color ? props.color : "#ff6300";
  const [rate, setRate] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => { setRate(props.rate * 20); }, 250);

    return () => clearTimeout(timeout);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid className={classes.root} item style={{ height: 85 }}>
      <CircularProgress
        className={classes.bottom}
        size={85}
        value={100}
        style={{ color: props.color }}
        variant="determinate"
        thickness={7}
      />
      <CircularProgress
        className={classes.top}
        size={85}
        value={rate}
        style={{ color: props.color }}
        variant="determinate"
        classes={{ circle: classes.circle }}
        thickness={7}
      />
      <Grid className={classes.rate} container>
        <FormTypography color={color} variant="h6" fontWeight={700}>
          {`${props.rate}/5`}
        </FormTypography>
      </Grid>
    </Grid>
  );
};

export default CircularProgressBar;
