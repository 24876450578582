import { AppThunkAction } from "store/types";
import { GenderActionType } from "./actions";
import { IGender } from "dto/gender.dto";

export * from "./actions";
export * from "./constants";

export interface GenderState {
  genders: IGender[];
  gendersLoading: boolean;
}

export type Action = AppThunkAction<GenderActionType>
