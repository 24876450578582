const svgImage = (c1: String) => `
  <svg width="75" height="73" viewBox="0 0 75 73" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 47.76L37.31 72.08L74.28 47.76V0H0V47.76Z" fill="${c1}"/>
  </svg>
`;

export const SvgShape04 = (c1?: string) => {
  const a = !!c1 ? c1 : "#ff6300";
  return `data:image/svg+xml;charset=UTF-8;base64,${btoa(svgImage(a))}`;
};

export default SvgShape04;
