import i18n from "i18next";
import * as types from "./types";
import { Action } from "./types";
import * as utils from "utils/layout.utils";
import * as actions from "store/modules/analytics/actions";
import { ILanguage, TypeLanguageCode } from "dto/layout.dto";

export const selectOrganizationImage = (url: string): Action => (dispatch) => {
  dispatch({ type: types.ORGANIZATION_IMAGE, payload: url });
}

export const selectModuleLogo = (url: string): Action => (dispatch) => {
  dispatch({ type: types.MODULE_LOGO, payload: url });
}

export const getLanguage = (): Action => (dispatch) => {
  const i18nextLng = localStorage.getItem("i18nextLng");
  if (!!i18nextLng) {
    const data = utils.getLanguage(i18nextLng);
    dispatch(setLanguage(data));
  } else dispatch(setLanguage({ code: "es", flag: "ES" }));
}
export const setLanguage = (value: ILanguage): Action => (dispatch) => {
  dispatch({ type: types.LANGUAGE, payload: value });
}
export const updateLanguage = (code: TypeLanguageCode): Action => (dispatch) => {
  const language = utils.getLanguage(code);
  i18n.changeLanguage(language.code).then((t) => { t("Inicio") });
  localStorage.setItem("i18nextLng", language.code);
  localStorage.setItem("languajeCode", language.flag);
  dispatch(setLanguage(language));
  dispatch(actions.analytics("update_app_web_language", {}));
}

export const resetDataLayout = (): Action => (dispatch) => {
  dispatch({ type: types.MODULE_LOGO, payload: "" });
  dispatch({ type: types.ORGANIZATION_IMAGE, payload: "" });
}
