import axios from "axios";
import firebase from "firebase/app";
import "firebase/database";
import { IEntityCode, IEntityCodeRequest } from "dto/entity-code.dto";

const url = process.env.REACT_APP_BACKEND_URL;
const route = `${url}/api`;

class EntityCode {
  async get(): Promise<IEntityCode[]> {
    const { data } = await axios.get(`${route}/static/codes`);
    return data?.data ?? [];
  }

  async update (uuid: string, req: IEntityCodeRequest) {
    await firebase.database().ref(`/codes/${uuid}/`).update(req);
  };
}

const entityCode = new EntityCode();
const EntityCodeClass = Object.freeze(entityCode);
export default EntityCodeClass;