import * as types from "./types";
import { challengerInit } from "initials";
import { ChallengerActionType, ChallengerState } from "./types";

export const INITIAL_STATE: ChallengerState = {
 challenger: challengerInit,
 challengerFree: challengerInit,
};

export const challenger = (prevState = INITIAL_STATE, action: ChallengerActionType) => {
  switch (action.type) {
    case types.CHALLENGER:
	    return { ...prevState, challenger: action.payload };
    case types.CHALLENGER_FREE:
	    return { ...prevState, challengerFree: action.payload };

    default:
      return prevState;
  }
}
