import React, { FC } from "react";
import ReactPlayer from "react-player";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  video: { 
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "black",
  },
}));

interface IProps {
  videoUrl: string;
}

const StepVideo: FC<IProps> = ({ videoUrl }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.video} container>
      <ReactPlayer 
        width="100%" 
        height="100%" 
        controls 
        url={videoUrl} 
      />
    </Grid>
  )
}

export default StepVideo;
