import * as utils from "utils";
import EntityCode from "services/entity-code.services";
import { IEntityCode } from "dto/entity-code.dto";

export const getEntityCodeByCode = async (code: string) => {
  try {
    const db = await EntityCode.get();
    const data = db?.filter((c) => (c.code === code))[0];
    if (!!data?.uuid) {
      const entity = await utils.getEntityByNit(data?.entity_id);
      const entityCode: IEntityCode = {
        ...data,
        permissions: data?.permissions ?? [],
        entity_logo_url: await utils.downloadFile("entities", entity?.logo),
        permissions_survey: data?.permissions_survey ?? [],
      }
      return entityCode;
    } else return undefined;
  } catch (error) {
    console.log("Error Utils Get Entity Code By Code: ", error);
    return undefined;
  }
}