import React from 'react';
import { makeStyles, Card, CardMedia, CardContent, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  card: { width: '100%' },
}));

const Course = () => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardMedia
        component="img"
        height="170"
        image="/images/landing_img_04.svg"
        alt=""
      />
      <CardContent>
        <Typography variant="caption" display="block">
          <b>Parturient Venenatis Etiam</b>
        </Typography>
        <Typography variant="caption" display="block">
          Amet minim mollt nom desenunt ullamco est sit allqua dolor do amest sint
          Amet minim mollt nom desenunt ullamco est sit allqua dolor do amest sint
        </Typography>
      </CardContent>
    </Card>
  )
}

export default Course;
