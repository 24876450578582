import axios from "axios";

const url = process.env.REACT_APP_BACKEND_URL;
const route = `${url}/api`;

class Admin {
  async verifiedEmail(email: string) {
    const data = await axios.post(`${route}/verifiedEmail`, { email });
    return data?.data;
  }

  async verifiedPhone(phone: string) {
    const data = await axios.post(`${route}/verifiedPhone`, { phone });
    return data?.data;
  }
}

const admin = new Admin()
export default admin;
