import React, { FC, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { object, ref, string } from "yup";
import { Field, Form, Formik } from "formik";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Grid, IconButton, InputAdornment, TextField, Typography } from "@material-ui/core";
import FormButton from "components/Form/FormButton";

const useStyles = makeStyles((theme) => ({
	form: { 
    width: 570, 
    maxWidth: "100%", 
    textAlign: "center",
  },
  h6: { 
    width: 180,
    color: "#5f5f5f", 
    textAlign: "right",
    fontWeight: 700, 
    lineHeight: 1.2,
    marginRight: 20,
    [theme.breakpoints.down("xs")]: { width: "100%", lineHeight: 1.5 },
  },
  field: {
    width: "calc(100% - 200px)",
    "& .MuiInputBase-root": {
      borderRadius: 8,
      "& fieldset": { border: "2px solid #f0f0f0" },
      "& .MuiInputBase-input": { padding: "9px 16px 9px 16px" },
    },
    [theme.breakpoints.down("xs")]: { width: "100%" },
  },
}));

interface IInitialValues {
	confirm: string;
	password: string;
}

interface IProps {
  loading: boolean;
  changePassword: (password: string) => void;
}

const ProfilePassword: FC<IProps> = ({ loading, changePassword }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [password, setPassword] = useState(true);
  const initialValues: IInitialValues = { confirm: "", password: "" };
  const validation = object().shape({
		password: string()
    .required(t("Campo requerido"))
    .min(6, t("Minimo 6 caracteres")),
		confirm: string()
			.required(t("Campo requerido"))
			.oneOf([ref("password"), null], t("Las contraseñas deben coincidir")),
	});

  const tooglePassword = () => setPassword(!password);

  const onSubmit = (values: IInitialValues) => {
		changePassword(values?.password);
	};

  return (
    <Formik 
      initialValues={initialValues} 
      validationSchema={validation} 
      onSubmit={onSubmit}
    >
      {({ errors, touched }) => (
        <Form className={classes.form}>
          <Grid container spacing={1}>
            <Grid item container alignItems="center">
              <Typography className={classes.h6} variant="h6">
                {t("Nueva contraseña")}
							</Typography>
              <Field
                className={classes.field}
                as={TextField}
                name="password"
                type={!!password ? "password" : "text"}
                size="small"
                error={!!errors.password && !!touched.password}
                variant="outlined"
                disabled={loading}
                helperText={!!touched.password && errors.password}
                placeholder="•••••••••••••"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton size="small" onClick={tooglePassword}>
                        {!!password ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item container alignItems="center">
              <Typography className={classes.h6} variant="h6">
                {t("Confirmar nueva contraseña")}
							</Typography>
              <Field
                className={classes.field}
                as={TextField}
                name="confirm"
                type={!!password ? "password" : "text"}
                size="small"
                error={errors.confirm && touched.confirm}
                variant="outlined"
                disabled={loading}
                helperText={touched.confirm && errors.confirm}
                placeholder="•••••••••••••"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton size="small" onClick={tooglePassword}>
                        {!!password ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item container justifyContent="flex-end">
              <FormButton 
                type="submit" 
                primary
                minWidth="calc(100% - 200px)" 
                disabled={loading}
              >
                {t("Restablecer contraseña")}
              </FormButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default ProfilePassword;
