import React, { FC, useEffect, useState } from "react";
import clsx from "clsx";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { makeStyles } from "@material-ui/core/styles";
import { RootRef, Grid, Card, Divider } from "@material-ui/core";
import { IQuestion, IQuestionPreview } from "dto/question.dto";
import { soundClick } from "utils";
import Information from "components/Question/QuestionInformation/Information";
import DroppableItem from "../DroppableItem";

const useStyles = makeStyles((theme) => ({
  question: { 
    height: "100%",
    [theme.breakpoints.down("xs")]: { overflow: "auto" },
  },
  content: {
    width: "calc(50% - 1px)",
    height: "100%",
    [theme.breakpoints.down("xs")]: { width: "100%", height: "fit-content" } 
  },
  context: { 
    height: "100%", 
    padding: 16, 
    overflow: "auto",
    alignItems: "center",
    justifyContent: "center",
    "&::-webkit-scrollbar": { width: 8 },
    "&::-webkit-scrollbar-track": { backgroundColor: "white" },
    "&::-webkit-scrollbar-thumb": { backgroundColor: "#c1c1c1", borderRadius: 8 },
    [theme.breakpoints.down("xs")]: { height: "fit-content",padding: "4px 8px" }, 
  },
  divider: { 
    width: 2, 
    backgroundColor: "#f0f0f0",
    [theme.breakpoints.down("xs")]: { display: "none" }, 
  },
  text: { 
    fontSize: "1rem", 
    alignItems: "center", 
    "& p": { margin: 0, fontFamily: "inherit !important" }, 
    "& span": { fontFamily: "inherit !important" },
  },
  card: {
    border: "1px solid rgba(0, 0, 0, 0.3)",
    padding: "0 8px",
    minWidth: 170,
    fontSize: "1rem",
    textAlign: "center",
    borderRadius: 10,
    "&.disabled": { 
      color: "rgba(0, 0, 0, 0.26)", 
      borderColor: "rgba(0, 0, 0, 0.26)", 
    },
  },
}));

interface IColumn {
  answers: { id: string; list: string[]; }
  answer0: { id: string; answer: string; }
  answer1: { id: string; answer: string; }
  answer2: { id: string; answer: string; }
  answer3: { id: string; answer: string; }
}

const columnsInit: IColumn = {
  answers: { id: "answers", list:   [] },
  answer0: { id: "answer0", answer: "" },
  answer1: { id: "answer1", answer: "" },
  answer2: { id: "answer2", answer: "" },
  answer3: { id: "answer3", answer: "" },
}

interface IProps {
  book?: boolean;
  answer: string;
  preview: IQuestionPreview;
  question: IQuestion;
  answerRating: boolean;
  viewFeedback: boolean;
  languageAudio: string;
  questionNumber: number;
  questionsTotal: number;
  progressBarColor: string;
  selectAnswer: (answer: string) => void;
}

const Type02: FC<IProps> = (props) => {
  const classes = useStyles();
  const { answer, question, viewFeedback } = props;
  const [columns, setColumns] = useState<IColumn>(columnsInit);
  const [segments, setSegments] = useState<string[]>([]);
  const notFeedback = !question.answer_correct && question.type === 2;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { getQuestionSegments(); }, [question]);

  useEffect(() => {
    const answersInit = [
      question?.answer_a ?? "",
      question?.answer_b ?? "",
      question?.answer_c ?? "",
      question?.answer_d ?? "",
    ];
    if (!!answer && typeof answer === "string") {
      const answersAux = answersInit?.filter((a) => (a !== answer));
      setColumns({
        ...columnsInit,
        answer0: { ...columns.answer0, answer: answer },
        answers: { ...columns.answers, list: answersAux ?? [] },
      });
    } else {
      setColumns({
        ...columnsInit,
        answers: { ...columns.answers, list: answersInit ?? [] },
      });
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { saveAnswer(); }, [columns]);

  const getQuestionSegments = () => {
    let count = 0;
    const text = question.question.split("#?#").join("|");
    for (let i = 0; i < text.length; i++) {
      if (text[i].toLowerCase() === "|") count++;
    }

    const array: string[] = [];
    for (let i = 0; i <= count; i++) array.push("");

    let index = 0;
    for (let i = 0; i < text.length; i++) {
      if (text[i].toLowerCase() === "|") index = index + 1;
      else array[index] = array[index] + text[i];
    }
    setSegments(array);
  }

  const saveAnswer = () => {
    if (segments.length > 1) {
      let count = 0;
      for (let i = 0; i < (segments.length - 1); i++) {
        const x = `answer${i}`;
        if (x==="answer0"||x==="answer1"||x==="answer2"||x==="answer3") {
          if (columns[x].answer !== "") count = count + 1;
        }
      }

      if (count === (segments.length - 1)) {
        let answerAux = question.question;
        for (let i = 0; i < (segments.length - 1); i++) {
          const x = `answer${i}`;
          if (x==="answer0"||x==="answer1"||x==="answer2"||x==="answer3") {
            answerAux = columns[x].answer;
          }
        }
        props.selectAnswer(answerAux);
      } else props.selectAnswer("");
    }
  }
  
  const onDragEnd = ({source, destination}: DropResult) => {
    if (destination === undefined || destination === null) return null;
    if (source.droppableId === destination.droppableId) return null;

    // @ts-ignore
    const start = columns[source.droppableId];
    // @ts-ignore
    const end = columns[destination.droppableId];

    if (destination.droppableId === "answers") {
      const newStartAns = { id: start.id, answer: "" };
      const endList = end.list;
      endList.splice(destination.index, 0, start.answer);
      const endCol = { id: end.id, list: endList };
      setColumns((state: IColumn) => ({
        ...state,
        [newStartAns.id]: newStartAns,
        [endCol.id]: endCol
      }));
    } else if(source.droppableId === "answers") {
      if (!end.answer) {
        const startList = start.list.filter((_: string, i: number) => i !== source.index);
        const startCol = { id: start.id, list: startList };
        const answer = start.list[source.index]
        const newEndCol = { id: end.id, answer: answer };
        setColumns((state: IColumn) => ({
          ...state,
          [startCol.id]: startCol,
          [newEndCol.id]: newEndCol
        }));
        return null;
      } else {
        const aux1 = start.list.filter((_: string, i: number) => i !== source.index);
        aux1.splice(source.index, 0, end.answer);
        const newStartCol = { id: start.id, list: aux1 };
        const answer = start.list[source.index];
        const newEndCol = { id: end.id, answer: answer };
        setColumns((state: IColumn) => ({
          ...state,
          [newStartCol.id]: newStartCol,
          [newEndCol.id]: newEndCol
        }));
        return null;
      }
    } else if (end.answer === "") {
      const newStartAns = { id: start.id, answer: "" };
      const newEndAns = { id: end.id, answer: start.answer };
      setColumns((state: IColumn) => ({
        ...state,
        [newStartAns.id]: newStartAns,
        [newEndAns.id]: newEndAns
      }));
    } else if (end.answer !== "") {
      const newStartAns = { id: start.id, answer: end.answer };
      const newEndAns   = { id: end.id,   answer: start.answer };
      setColumns((state: IColumn) => ({
        ...state,
        [newStartAns.id]: newStartAns,
        [newEndAns.id]: newEndAns
      }));
    }
  };

  const getQuestionText = (html: string) => {
    return <span dangerouslySetInnerHTML={{__html: html}} />;
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Grid className={classes.question} container>
        <Grid className={classes.content} container>
          <Information 
            preview={props.preview}
            question={props.question}
            answerRating={props.answerRating}
            viewFeedback={props.viewFeedback}
            languageAudio={props.languageAudio}
            questionNumber={props.questionNumber}
            questionsTotal={props.questionsTotal}
            progressBarColor={props.progressBarColor}
          >
            <Grid className={classes.context} container>
              <Grid className={classes.text} item container>
                {segments.map((item, i) => {
                  const x = `answer${i}`;
                  if (x === "answer0" || x==="answer1" || x==="answer2" || x==="answer3") {
                    return (
                      <div key={i} style={{ display: "contents" }}>
                        {getQuestionText(item)}
                        {i < segments.length - 1 && (
                          <DroppableItem 
                            answer={columns[x]} 
                            correct={props.answerRating} 
                            feedback={props.viewFeedback} 
                            notFeedback={notFeedback}
                          />
                        )}
                      </div>
                    )
                  } else return (
                    <div key={i} style={{ display: "contents" }}>
                      {getQuestionText(item)}
                    </div>
                  );
                })}
              </Grid>
            </Grid>
          </Information>
        </Grid>
        
        <Divider className={classes.divider} orientation="vertical" flexItem />

        <Grid className={classes.content} container>
          <Grid className={classes.context} container>
            <Droppable droppableId={columns.answers.id}>
              {(provided) => (
                <RootRef rootRef={provided.innerRef}>
                  <div style={{ gap: 12, display: "grid" }}>
                    {columns.answers.list.map((item: string, index: number) => {
                      return (
                        <Draggable 
                          key={item} 
                          index={index} 
                          draggableId={item} 
                          isDragDisabled={props.viewFeedback}
                        >
                          {(provided) => (
                            <RootRef rootRef={provided.innerRef}>
                              <Card 
                                className={clsx(classes.card, viewFeedback && "disabled")}
                                variant="outlined"
                                onMouseDown={soundClick}
                                {...provided.draggableProps} 
                                {...provided.dragHandleProps}
                              >
                                {item}
                              </Card>
                            </RootRef>
                          )}
                        </Draggable>
                      )
                    })}
                    {provided.placeholder}
                  </div>
                </RootRef>
              )}
            </Droppable>
          </Grid>
        </Grid>
      </Grid>
    </DragDropContext>
  )
}

export default Type02;
