import { IUser } from "dto/user.dto";
import { ADMIN, CONTENT_CREATOR, SUPER_ADMIN } from "initials/rol.init";

export const getIsSuperAdmin = (user: IUser) =>
  !!user?.admin && user?.rol === SUPER_ADMIN;

export const getIsAdmin = (user: IUser) =>
  getIsSuperAdmin(user) || user?.rol === ADMIN;

export const getIsContentCreator = (user: IUser) =>
  getIsAdmin(user) || user?.rol === CONTENT_CREATOR;