import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Grid, Card, Typography } from '@material-ui/core';
import { FormControl, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { ILicense } from '../../../services/checkout.services';
import { LicenseIcon }       from '../../Svg/LicenseIcon';
import { LicenseBackground } from '../../Svg/LicenseBackground';

const useStyles = makeStyles(() => ({
  card: { 
    width: 400, 
    height: 160,
    position: 'relative', 
    padding: '0 10px',
    marginBottom: 10,
  },
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    '& img': { height: '100%' }
  },
  content: { position: 'relative' },
  title: { height: 50, '& img': { marginRight: 10 } },
  price: { 
    height: 80, 
    color: 'white', 
    '& .MuiTypography-subtitle1': { fontWeight: 500, textTransform: 'capitalize' },
  },
  formControl: { 
    width: '100%',
    '& .MuiFormGroup-root': { width: '100%', justifyContent: 'center' },
    '& .MuiRadio-colorSecondary.Mui-checked': { color: '#ff6315' },
    '& .MuiTypography-root': { fontWeight: 'bold' },
  },
}));

interface IProps { 
  license: ILicense
  currency: "usd"|"cop"
  setCurrency: (val: "usd"|"cop") => void
}

const Selected: FC<IProps> = ({license, currency, setCurrency}) => {
  const classes  = useStyles();
  const { t } = useTranslation();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "usd" || e.target.value === "cop") setCurrency(e.target.value);
  };

  return (
    <>
      <Card className={classes.card}>
        <Grid className={classes.background} container>
          <img  src={LicenseBackground(license.color_one, license.color_two)} alt="" />
        </Grid>
        
        <Grid className={classes.content} container alignItems="center">
          <Grid className={classes.title} container alignItems="center">
            <img src={LicenseIcon(license.color_one)} alt="" />
            <Typography variant="h5">
              <b>{t("Licencia")} 1 {t(license.amount.name)}</b>
            </Typography>
          </Grid>
          
          <Grid className={classes.price} container alignItems="center">
            <Grid item xs={5}>
              <Typography variant="subtitle1" display="block">
                {t(license.name)}
              </Typography>
              <Typography variant="subtitle2" display="block">
                {`${license.min_users} - ${license.max_users} ${t("usuarios")}`}
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography align="right" variant="h5" display="block">
                <b>${license.amount[currency].toLocaleString("en")}</b>
                {currency.toUpperCase()}/{t(license.amount.name)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Card>
      <FormControl className={classes.formControl}>
        <RadioGroup value={currency} onChange={onChange} row>
          <FormControlLabel value="usd" control={<Radio />} label="USD" />
          <FormControlLabel value="cop" control={<Radio />} label="COP" />
        </RadioGroup>
      </FormControl>
    </>
  )
}

export default Selected;
