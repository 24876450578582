import React, { FC } from "react";
import _ from "lodash";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Avatar, Button, Grid, Typography } from "@material-ui/core";
import { IContent } from "dto/content.dto";

const useStyles = makeStyles(() => ({
  free: {
    height: 190,
    border: "3.07px solid #f0f0f0",
    padding: "0 24px",
    textAlign: "initial",
    boxShadow: "0px 3.065814971923828px 0px 0px #f0f0f0",
    borderRadius: 24,
    textTransform: "initial",
    "&:hover": { backgroundColor: "white", },
    "& .MuiButton-label": { gap: 24, },
  },
  avatar: { 
    width: 156,
    height: 156,
    borderRadius: 16,
  },
  content: { width: "calc(100% - 184px)", height: 160 },
  h5: { 
    color: "#5f5f5f", 
    overflow: "hidden",
    maxHeight: 89,
    fontWeight: 700,
    lineHeight: 1.2,
  },
  subtitle: { color: "#8d8d8d", fontSize: 18, fontWeight: 500 },
  button: { 
    color: "#0aa9E2",
    padding: "6px 16px",
    fontSize: 16,
    maxWidth: 130,
    fontWeight: 500,
    borderRadius: 10,
    textTransform: "initial",
    justifyContent: "center",
    backgroundColor: "#ebfaff",
    "&:hover": { color: "#895aB8", backgroundColor: "#f8f2fe" },
    "&.survey": {
      color: "#895aB8",
      backgroundColor: "#f8f2fe",
      "&:hover": { color: "#895aB8", backgroundColor: "#f8f2fe" }
    }
  },
}));

interface IProps {
  content: IContent;
  selectContent: (value: IContent) => void;
}

const ContentFree: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const label = props.content.type === "survey" ? "Formulario" : "Curso";
  const image = props.content?.type === "survey"
    ? props.content.image_url
    : props.content.imageUrl;
  const subtitle = props.content?.type === "survey"
    ? `${props.content?.questions?.length} ${t("Actividades")}`
    : `${props.content?.modules?.length} ${t("Módulos")}`;
  const displayName = props.content?.displayName ?? props.content?.name;

  const selectContent = () => props.selectContent(props.content);

  return (
    <Grid 
      className={classes.free} 
      onClick={selectContent}
      component={Button} 
      container 
    >
      <Avatar className={classes.avatar} alt="" src={image} />
      <Grid className={classes.content} container>
        <Grid item xs={12}>
          <Typography className={classes.h5} variant="h5">
            {_.truncate(displayName, { length: 55 })}
          </Typography>
          <Typography className={classes.subtitle} noWrap>
            {subtitle}
          </Typography>
        </Grid>
        <Grid item container alignItems="flex-end">
          <Grid className={clsx(classes.button, props.content?.type)} container>
            {t(label)}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ContentFree;

