import * as types from "./types";
import { languageInit } from "initials/layout.init";
import { LayoutState, LayoutActionType } from "./types";

export const INITIAL_STATE: LayoutState = {
  language: languageInit,
  moduleLogo: "",
  organizationImage: "",
}

export const layout = (prevState = INITIAL_STATE, action: LayoutActionType) => {
  switch (action.type) {
    case types.LANGUAGE:
      return { ...prevState, language: action.payload }
    case types.MODULE_LOGO:
      return { ...prevState, moduleLogo: action.payload }
    case types.ORGANIZATION_IMAGE: 
      return { ...prevState, organizationImage: action.payload }

    default: 
      return prevState
  }
}
