import React from 'react';
import { makeStyles, Grid, Typography, Avatar } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  information: {
    height: 'calc(100vh - 60px)', 
    padding: '0 100px',
    position: 'relative',
    overflow: 'auto',
    backgroundColor: '#efefef',
  },
  title: { marginBottom: 70 },
  avatar: { width: 200, height: 200, marginBottom: 30 },
}));

const Information = () => {
  const classes  = useStyles();

  return (
    <Grid className={classes.information} container alignItems="center">
      <Grid container>
        <Grid className={classes.title} item xs={12}>
          <Typography align="center" variant="h3">
            <b>¿Quién puede utilizar O-lab?</b>
          </Typography>
        </Grid>
        <Grid item container justifyContent="center" xs={4} style={{padding: '0 30px'}}>
          <Avatar 
            className={classes.avatar} 
            src="/images/landing_img_01.svg" 
            alt="" 
            variant="square" 
          />
          <Typography align="center" variant="h5">
            <b>Universidades, ONGs y Escuelas Privadas</b>
          </Typography>
        </Grid>
        <Grid item container justifyContent="center" xs={4} style={{padding: '0 30px'}}>
          <Avatar 
            className={classes.avatar}
            src="/images/landing_img_02.svg" 
            alt="" 
            variant="square" 
          />
          <Typography align="center" variant="h5">
            <b>Empresas y Entidades Educativas</b>
          </Typography>
        </Grid>
        <Grid item container justifyContent="center" xs={4} style={{padding: '0 30px'}}>
          <Avatar 
            className={classes.avatar}  
            src="/images/landing_img_03.svg" 
            alt="" 
            variant="square" 
          />
          <Typography align="center" variant="h5">
            <b>Escuelas y Organizaciones Rurales</b>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Information;
