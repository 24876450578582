import { laboratoryInit } from "initials";
import { LaboratoryActionType, LaboratoryState } from "./types";
import * as types from "./types";

export const INITIAL_STATE: LaboratoryState = {
  labs: [],
  laboratory: laboratoryInit,
  labsLoading: true,
  laboratories: [],
  laboratoryFree: laboratoryInit,
  laboratoryLoading: false,
  certificateLoading: false,
};

export const laboratory = (prevState = INITIAL_STATE, action: LaboratoryActionType) => {
  switch (action.type) {
	case types.LABS:
	  return { ...prevState, labs: action.payload };
	case types.LABORATORY:
	  return { ...prevState, laboratory: action.payload };
	case types.LABS_LOADING:
	  return { ...prevState, labsLoading: action.payload };
	case types.LABORATORIES:
	  return { ...prevState, laboratories: action.payload };
	case types.LABORATORY_FREE:
	  return { ...prevState, laboratoryFree: action.payload };
	case types.LABORATORY_LOADING:
	  return { ...prevState, laboratoryLoading: action.payload };
	case types.CERTIFICATE_LOADING:
	  return { ...prevState, certificateLoading: action.payload };
	default:
	  return prevState;
  }
};
