import React, { FC } from "react";
import clsx from "clsx";
import { Field } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { FormControl, FormHelperText, MenuItem, Select, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  body1: { color: "#a9a9a9", fontWeight: 500 },
  field: { 
    borderRadius: 8,
    "& fieldset": { border: "2px solid #f0f0f0" },
    "& .MuiSelect-select": {
      color: "#a9a9a9",
      padding: "12px 32px 12px 16px", 
      fontSize: 18,
      fontWeight: 500,
      "&:focus": { backgroundColor: "transparent" },
    },
    "&:hover": { "& .MuiOutlinedInput-notchedOutline": { borderColor: "#f0f0f0" } },
    "&.Mui-error": { 
      "& .MuiOutlinedInput-notchedOutline": { borderColor: "#f44336" },
      "&:hover": { "& fieldset": { borderColor: "#f44336" } },
    },
    "&.Mui-focused": { "& .MuiOutlinedInput-notchedOutline": { borderColor: "#f0f0f0" } },
  },
  item: { fontSize: 18, fontWeight: 500, },
  initial: { "& .MuiSelect-select": { color: "#ededed" } },
  placeholder: { display: "none" },
}));

interface IProps {
  name: string;
  items: { label: string, value: string }[];
  error?: string;
  title?: string;
  initial?: boolean;
  touched?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
}

const FormFieldAsSelect: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const error = !!props.error && !!props.touched;

  return (
    <React.Fragment>
      {props.title && (
        <Typography className={classes.body1}>
          {t(props.title)}
        </Typography>
      )}
      <FormControl 
        error={error}
        variant="outlined" 
        fullWidth={props.fullWidth}
      >
        <Field
          className={clsx(classes.field, props.initial && classes.initial)}
          as={Select}
          name={props.name}
          displayEmpty
        >
          {!!props.placeholder && (
            <MenuItem className={classes.placeholder} value="">
              {t(props.placeholder)}
            </MenuItem>
          )}
          {props.items?.map((item) => (
            <MenuItem className={classes.item} key={item.value} value={item.value}>
              {t(item.label)}
            </MenuItem>
          ))}
        </Field>
        {error && <FormHelperText>{props.error}</FormHelperText>}
      </FormControl>
    </React.Fragment>
  )
}

export default FormFieldAsSelect;
