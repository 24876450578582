import React, { FC } from "react";
import useImage from "use-image";
import { Image } from "react-konva";
import { ITemplateItem } from "dto/template.dto";

interface IProps {
  templateItem: ITemplateItem;
}

const TemplateItemImage: FC<IProps> = (props) => {
  const [image] = useImage(props.templateItem?.image_url ?? "", "anonymous");

  return (
    <Image
      x={props.templateItem.x}
      y={props.templateItem.y}
      id={props.templateItem.id}
      image={image}
      width={props.templateItem.width}
      height={props.templateItem.height}
      scaleX={props.templateItem?.scaleX}
      scaleY={props.templateItem?.scaleY}
    />
  );
};

export default TemplateItemImage;
