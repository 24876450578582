import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IContent } from "dto/content.dto";
import { State } from "store/types";
import { history } from "configureStore";
import * as actions from "store/actions";
import Explore from "components/Explore/Explore";
import PageLayout from "components/Pages/PageLayout";

const ExplorePage = () => {
  const dispatch = useDispatch();
  const user = useSelector((s: State) => s.user.user);
  const fbauth = useSelector((state: State) => state.firebase.auth);
  const contentsFree = useSelector((s: State) => s.content.contentsFree);
  const anonymousUser = useSelector((s: State) => s.anonymousUser.anonymousUser);
  const contentsFreeLoading = useSelector((s: State) => s.content.contentsFreeLoading);
  const [contentsExplore, setContentsExplore] = useState<IContent[]>([]);
  const anonymousUserls = localStorage.getItem("anonymousUser");
  const userAnonymous = JSON.parse(anonymousUserls ?? "{}");
  const auth = !!fbauth?.isEmpty && !!fbauth?.isLoaded && !userAnonymous?.uuid;
  const test = 
    !!fbauth?.isEmpty
    && !!fbauth?.isLoaded 
    && !!userAnonymous?.uuid 
    && !userAnonymous?.userType;

  useEffect(() => { 
    if (!!auth) history.push("/");
    else if (!!test) history.push("/anonymous-user");
  }, [auth, test]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { getContentsFree(); }, [user, anonymousUser]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { getContentsExplore(); }, [contentsFree]);

  const getContentsFree = () => {
    if (
      !contentsFree?.length && 
      !contentsFreeLoading &&
      (!!user?.nit || !!anonymousUser?.uuid)
    ) {
      // const userType = !!user?.userType 
      //   ? user.userType 
      //   : !!anonymousUser?.userType 
      //   ? anonymousUser.userType : "none";
      dispatch(actions.getContentsFree("employee"));
    } else dispatch(actions.setContentsFree(contentsFree));
  }

  const getContentsExplore = () => {
    setContentsExplore(contentsFree);
    // if (!!user.nit && !!user?.sponsor) {
    //   setContentsExplore(contentsFree);
    // } else if (!!user.nit) {
    //   setContentsExplore(utils.getContentsExplore(
    //     user?.userType ?? "none", 
    //     contentsFree,
    //   ));
    // } else if (!!anonymousUser?.uuid) {
    //   setContentsExplore(utils.getContentsExplore(
    //     anonymousUser?.userType ?? "none", 
    //     contentsFree,
    //   ));
    // }
  }

  const selectContent = (value: IContent) => {
    if (value.type === "survey") {
      dispatch(actions.setSurveyFree(value));
      history.push(`/explore/survey/${value.uuid}`);
    } else {
      dispatch(actions.selectLaboratoryFree(value));
      history.push(`/explore/laboratory/${value?.name}`);
    }
  }

  return (
    <PageLayout loading={contentsFreeLoading} hideButtonLanguage={!!user?.nit}>
      <Explore contents={contentsExplore} selectContent={selectContent} />
    </PageLayout>
  )
}

export default ExplorePage;
