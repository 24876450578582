export const languaje_FR = {
  translation: {
    Curso:"Course",
    "¡Curso completado!":"Course completed!",
    "¡Unidad terminada!":"Unit finished!",
    "¡Módulo completado!":"Module completed!",
    "Curso no completado":"Course not completed",
    "Unidad no completada":"Unit not completed",
    "Módulo no completado":"Module not completed",
    "Dale continuar para avanzar":"Click continue to advance",
    "Avanza a la siguiente unidad":"Advance to the next unit",
    "Has desbloqueado la siguiente unidad":"You have unlocked the next unit",
    "Ya has completado el curso satisfactoriamente":"You have already completed the course satisfactorily",
    Estudio:"Study",
    Trabajo:"Job",
    Startup:"Startup",
    unidades:"units",
    Posgrado:"Postgraduate",
    Comercial:"Comercial",
    Liderazgo:"Leadership",
    Tecnología:"Technology",
    Freelancer:"Freelancer",
    Empresario:"Businessman",
    Operaciones:"Operations",
    Emprendimiento:"Entrepreneurship",
    "Director / VP":"Director / VP",
    "Lider de equipo":"Team leader",
    "Entidad pública":"Public entity",    
    "Empresa privada":"Private company",
    "Básica primaria":"Elementary school",
    "Recursos humanos":"Human Resources",
    "Básica secundaria":"Basic high school",
    "Miembro de equipo":"Team member",
    "Educación superior":"Higher education",
    "Actualmente, yo...":"Actually me...",
    "Gestión de equipos":"Team management",
    "Conseguir un empleo":"Getting a job",
    "Habilidades blandas":"Soft skills",
    "Gestión de proyectos":"Project management",
    "Conocimiento general":"General knowledge",
    "Escoge tus intereses":"Choose your interests",
    "Desarrollo de carrera":"Career development",
    "Institución educativa":"Educational institution",
    "Responsabilidad social":"Social responsability",
    "Orientación vocacional":"Vocational orientation",
    "¿Qué te describe mejor?":"What describes you best?",
    "Ninguna de las anteriores":"None of the above",
    "Entidad sin ánimo de lucro":"Non-profit entity",
    "Marketing y comunicaciones":"Marketing and communications",
    "¿A qué área laboral perteneces?":"What area of work do you belong to?",
    "¿Cuál es tu nivel de educación?":"What is your level of education?",
    "¿Cuál es tu fecha de nacimiento?":"What is your birth date?",
    "Estoy buscando estudiar o trabajar":"I am looking to study or work",
    "¿En qué tipo de organización trabajas?":"What type of organization do you work for?",
    "¿Cuántos integrantes tiene tu organización?":"How many members does your organization have?",
    
    Idioma:"Language",
    Ingles:"English",
    Español:"Spanish",
    "¡Hola!":"Hello!",
    "¡Todo listo!":"All ready!",
    "Súper administrador":"Super admin",
    "¡Bienvenido a O-lab!":"Welcome to O-lab!",
    "¡Ya casi terminamos!":"We are almost done!",
    "Crea tu perfil ahora":"Create your profile now",
    "¿Salir del laboratorio?":"Get out of the lab?",
    "¿Tienes un código organizacional?":"Do you have an organizational code?",
    "Configura el idioma de esta plataforma":"Set the language of this platform",
    "Si te sales del laboratorio perderás el progreso guardado hasta el momento":"If you leave the laboratory you will lose the progress saved so far",
    "Te recomendamos crear una nueva cuenta para tener tu propio perfil, además de guardar el progreso de tus contenidos, compartir en la comunidad, chatear y mucho más":"We recommend that you create a new account to have your own profile, as well as save the progress of your content, share in the community, chat and much more",
    
    Hola:"Hello",
    Omitir:"Skip",
    Explora:"Explore",
    Comenzar:"Start",
    "Ver curso":"See course",
    "Todo listo":"All ready",
    "Busca labs":"Search labs",
    "¡Empecemos!":"Let us begin!",
    "Elige un país":"Choose a country",
    "Elige tu país":"Choose your country",
    "Ver formulario":"See form",
    "Solicitar demo":"Request demo",
    "Nombre completo":"Full name",
    "Respuesta única":"Single answer",
    "Crea una cuenta":"Create an account",
    "Elige tu género":"Choose your gender",
    "Ya tengo cuenta":"I already have an account",
    "Crear cuenta con":"Create account with",
    "Llena los campos":"Fill in the fields",
    "Ingresa tu correo":"Enter your email",
    "Ingresa tu nombre":"Enter your name",
    "Antes de comenzar":"Before starting",
    "Bienvenido a O-lab":"Welcome to O-lab",
    "Completa tus datos":"Complete your data",
    "Múltiple respuesta":"Multiple answer",
    "Ya casi terminamos":"We are almost done",
    "Edad minima 7 años":"Minimum age 7 years",
    "Mínimo 6 caracteres":"Min 6 characters",
    "Repite tu contraseña":"Repeat your password",
    "Prefiero no responder":"Prefer not to answer",
    "Saltar, no tengo código":"Skip, I have no code",
    "Unirme a mi organización":"Join my organization",
    "Fecha de nacimiento incorrecta":"Wrong date of birth",
    "Labs libres ofrecidos por O-lab":"Free labs offered by O-lab",
    "Tienes un código organizacional":"Do you have an organizational code",
    "El código ingresado no es válido":"The code entered is invalid",
    "Ahora haces parte de la organización":"Now you are part of the organization",
    "Necesitamos saber un poco más sobre ti":"We need to know a little more about you",
    "Personaliza O-lab para tu organización":"Customize O-lab for your organization",
    "Todavía no perteneces a una organización":"You don't belong to an organization yet",
    "ya tienes acceso a su contenido y comunidad en O-lab":"you already have access to its content and community at O-lab",
    "Lleva tus entrenamientos, inducciones, comunicaciones y más al siguiente nivel":"Take your trainings, inductions, communications and more to the next level",
    "Si todavía no tienes el código organizacional, después lo puedes ingresar desde tu perfil":"If you still do not have the organizational code, you can enter it later from your profile",
    "Responde este corto cuestionario para poder empezar a explorar el contenido gratutito ofrecido por O-lab":"Take this short quiz to start exploring the free content offered by O-lab",
    "Si tu organización te compartió un código de O-Lab ingrésalo en el siguiente campo, de lo contrario salta este paso":"If your organization shared an O-Lab code with you, enter it in the next field, otherwise skip this step",
    
    o:"or",
    País:"Country",
    Falso:"False",
    Ninguno:"None",
    Primaria:"Primary",
    Verdadero:"True",
    Finalizar:"Finalize",
    Incorrecta:"Incorrect",
    Formulario: "Form",
    Secundaria:"High school",
    "Idioma urdu":"Urdu language",
    "Cargar audio":"Upload sound",
    "Grabar audio":"Record audio",
    "Continúa asi":"Keep it up",
    "Configuración":"Settings",
    "Editar género":"Edit gender",
    "Cargar imagen":"Upload image",
    "¡Felicidades!": "Congratulations!",
    "Idioma inglés":"English language",
    "Idioma tagalo":"Tagalog language",
    "Idioma español":"Spanish language",
    "Idioma francés":"French language",
    "Felicitaciones":"Congratulations",
    "Notificaciones":"Notifications",
    "Opción múltiple":"Multiple choice",
    "No te descuides":"Don't neglect",
    "Nueva contraseña":"New password",
    "Verdadero y falso":"True and false",
    "Guardar grabación":"Save recording",
    "Detener grabación":"Stop recording",
    "Iniciar grabación":"Start recording",
    "Respuesta Correcta":"Correct Answer",
    "Seleccionar idioma":"Select language",
    "Fecha de nacimiento":"Birthdate",
    "Vuelve a intentarlo":"Try again",
    "Respuesta Incorrecta":"Incorrect Answer",
    "Información personal":"Personal information",
    "Salir de la grabación":"Exit recording",
    "¡Bienvenido de nuevo!":"Welcome back!",
    "Terminaste esta encuesta":"You finished this survey",
    "Iniciar sesión con email":"Sign in with email",
    "Haga clic para regístrate":"Click to sign up",
    "Haga clic para ingresar a":"Click to enter",
    "Editar fecha de nacimiento":"Edit date of birth",
    "Confirmar nueva contraseña":"Confirm new password",
    "Iniciar sesión con teléfono":"Sign in with phone",
    "Encuentra todas las palabras":"Find all the words",
    "Seleccionar verdadero o falso":"Select true or false",
    "Haga clic para iniciar sesión":"Click to login",
    "Escribe tu contraseña de acceso":"Write your access password",
    "Seleccione la respuesta correcta":"Select the correct answer",
    "Haga clic para ingresar al tutorial":"Click to enter the tutorial",
    "Haga clic para volver al menú anterior":"Click to return to the previous menu",
    "Haga clic para seleccionar la respuesta":"Click to select answer",
    "Escribe tu correo electrónico de acceso":"Write your access email",
    "Haga clic para restablecer la contraseña":"Click to reset password",
    "Haga clic para iniciar sesión con el email":"Click to login with email",
    "Haga clic para ingresar a la vista de blog":"Click to enter blog view",
    "Haga clic para ingresar a la vista de notas":"Click to enter note view",
    "Haga clic para regresar al listado de módulo":"Click to return to module listing",
    "Haga clic para ingresar al buzón de mensajes":"Click to enter the mailbox",
    "Haga clic para iniciar sesión con el teléfono":"Click to login with phone",
    "Haga clic para regresar a la lección anterior":"Click to return to the previous lesson",
    "Haga clic para regresar a la pregunta anterior":"Click to return to the previous question",
    "Haga clic para continuar a la lección siguiente":"Click to continue to the next lesson",
    "Haga clic para reproducir el audio de la lección":"Click to play the audio of the lesson",
    "Haga clic para continuar a la pregunta siguiente":"Click to continue to the next question",
    "Haga clic para reproducir el audio de la pregunta":"Click to play the audio of the question",
    "Haga clic para regresar al listado de laboratorios":"Click to return to the list of laboratories",
    
    "Cargar archivo":"Upload file",
    "Guardar y salir":"Save and exit",
    "Educación continua":"Continuing education",
    "Aceleración escolar":"School acceleration",
    "Repetir formulario":"Repeat form",
    "Formulario privado":"Private form",
    "Finalizar formulario":"Finish form",
    "Página no encontrada":"Page not Found",
    "Educación vocacional":"Vocational education",
    "Formulario no encontrado":"Form not found",
    "Educación complementaria":"Complementary education",
    "No tienes laboratorios habilitados":"You do not have enabled laboratories",
    "El formulario es privado, verifique el acceso":"The form is private, check access",
    "Debes finalizar primero los módulos anteriores":"You must finish the previous modules first",
    "El formulario solicitado no existe, verifique el acceso":"The requested form does not exist, check access",
    "Lo sentimos, no se pudo encontrar la página que solicitó, vuelva a la página de inicio":"we' re sorry the page you requested could not be found please go back to the homepage",

    "¡Hola! Te damos la bienvenida a la versión web de la aplicación O-lab":"Hi there! We welcome you to the web version of the O-lab application",
    "Los laboratorios son tu espacio de trabajo en los que realizarás actividades":"The laboratories are your workspace where you will carry out activities",
    "Aquí podrás ver la sección en la que te encuentras dentro de la aplicación web":"Here you can see the section in which you are within the web application",
    "Los módulos son secciones en las que ingresarás para aprender y realizar actividades":"Modules are sections that you will enter to learn and perform activities",
    "En este espacio encontrarás material de estudio que está dividido por unidades, lecciones y actividades":"In this space you will find study material that is divided by units, lessons and activities",
    "En esta sección puedes cambiar tus datos personales, escoger una foto de perfil y revisar el progreso de tus cursos":"In this section you can change your personal data, choose a profile photo and check the progress of your courses",
    "Este es un espacio público en el que encontraras material de estudio realizado y compartido por los estudiantes de O-Lab":"This is a public space where you will find study material made and shared by O-Lab students",
    "En esta sección se registran todo tipo de apuntes que quieras guardar, pueden ser posteriormente publicadas en el apartado de blog si así lo desea":"In this section all kinds of notes that you want to save are recorded, they can be later published in the blog section if you wish",
    
    //---------------------------------------------------------------------------------------------//
    
    "a"             :"pour",
    "ID"            :"ID",
    "OK"            :"OK",
    "Yo"            :"Je",
    "El"            :"Le",
    "año"           :"an",
    "Año"           :"An",
    "mes"           :"mois",
    "Mes"           :"Mois",
    "Ver"           :"Voir",
    "Para"          :"Pour",
    "Reto"          :"Défier",
    "Edad"          :"Âge",
    "Sexo"          :"Sexe",
    "Otro"          :"Autre",
    "Blog"          :"Blog",
    "Mayo"          :"Peut",
    "años"          :"ans",
    "Nota"          :"Noter",
    "Otros"         :"Les autres",
    "Dudas"         :"Les doutes",
    "Donar"         :"Faire un don",
    "Pesos"         :"Peso",
    "Abril"         :"Avril",
    "Enero"         :"Janvier",
    "Marzo"         :"Mars",
    "Junio"         :"Juin",
    "Julio"         :"Juillet",
    "Salir"         :"Sortir",
    "Grado"         :"Degré",
    "Retos"         :"Défis",
    "Notas"         :"Remarques",
    "Buzón"         :"E-mail",
    "Nuevo"         :"Nouveau",
    "Cerrar"        :"Fermer",
    "Editar"        :"Éditer",
    "Enviar"        :"Envoyer",
    "Volver"        :"Retourner",
    "Género"        :"Le genre",
    "Agosto"        :"Août",
    "Título"        :"Titre",
    "Unidad"        :"Unité",
    "Módulo"        :"Module",
    "Perfil"        :"Profil",
    "Inicio"        :"Début",
    "Cursos"        :"Cours",
    "E-mail"        :"E-mail",
    "Público"       :"Public",
    "Privado"       :"Privé",
    "Lección"       :"Leçon",
    "Repetir"       :"Répéter",
    "Guardar"       :"Sauvegarder",
    "Módulos"       :"Modules",
    "Febrero"       :"Février",
    "Octubre"       :"Octobre",
    "Docente"       :"Professeur",
    "Unidades"      :"Unités",
    "Explicar"      :"Expliquer",
    "Enviados"      :"Expédié",
    "Eliminar"      :"Supprimer",
    "Reenviar"      :"Renvoyer",
    "Femenino"      :"Féminin",
    "Teléfono"      :"Téléphone",
    "Lenguaje"      :"Langue",
    "Cancelar"      :"Annuler",
    "Respuesta"     :"Réponse",
    "Continuar"     :"Continuer",
    "Masculino"     :"Masculin",
    "Compartir"     :"Partager",
    "Siguiente"     :"Suivant",
    "Noviembre"     :"November",
    "Diciembre"     :"Décembre",
    "Comunidad"     :"Communauté",
    "Recibidos"     :"A reçu",
    "Responder"     :"Répondre",
    "Bienvenido"    :"Bienvenue",
    "Materiales"    :"Matériaux",
    "Contraseña"    :"Mot de passe",
    "Respuestas"    :"Réponses",
    "Septiembre"    :"Septembre",
    "Estudiante"    :"Élève",
    "Regístrate"    :"S'inscrire",
    "Destacados"    :"Mis en exergue",
    "Actualizar"    :"Mettre à jour",
    "Actividades"   :"Activités",
    "Felicidades"   :"Toutes nos félicitations",
    "Comentarios"   :"Commentaires",
    "Laboratorio"   :"Laboratoire",
    "Descripción"   :"La description",
    "Institución"   :"Institution",
    "Calificación"  :"Qualification",
    "Organización"  :"Organisation",
    "Nacionalidad"  :"Nationalité",
    "Laboratorios"  :"Laboratoires",
    "Administrador" :"Directeur",
    "Nombre"        :"Nom",
    "Buscar"        :"Rechercher",
    "Asunto"        :"Sujet",
    "Dólares"       :"Dollars",
    "Mensual"       :"Mensuel",
    "Mensaje"       :"Un message",
    "Aceptar"       :"J'accepte",
    "Mensajes"      :"Messages",
    "Tutorial"      :"Didacticiel",
    "Licencia"      :"Licence",
    "Usuarios"      :"Utilisateurs",
    "usuarios"      :"utilisateurs",
    "finalizar"     :"finaliser",
    "Resultados"    :"Résultats",
    "Contáctanos"   :"Contactez-nous",
    "Almacenamiento":"Espace de rangement",
    "identificación":"Identifiant ",

    "y nuestra"               :"et notre",
    "Ver todos"               :"Voir tout",
    "Ver todas"               :"Voir tout",
    "Mis blogs"               :"Mes blogs",
    "Mis notas"               :"Mes notes",
    "Enviar nota"             :"Envoyer une note",
    "Ciudad/País"             :"Ville/Pays",
    "Ver unidades"            :"Afficher les unités",
    "Crear cuenta"            :"Créer un compte",
    "Guardar nota"            :"Enregistrer la note",
    "Bienvenido a"            :"Bienvenue à",
    "Comprar ahora"           :"Acheter maintenant",
    "Cerrar sesión"           :"Fermer la session",
    "Publicado por"           :"Publié par",
    "Mensaje nuevo"           :"Nouveau message",
    "Primera lengua"          :"Première langue",
    "Segunda lengua"          :"Deuxième langue",
    "Iniciar sesión"          :"Commencer la session",
    "Regístrate con"          :"Se connecter avec",
    "Aplicación web"          :"Application web",
    "Enviar mensaje"          :"Envoyer le message",
    "Blog principal"          :"Main blog",
    "Compartir nota"          :"Partager une note",
    "Plan grande"             :"Grand projet",
    "Donación segura"         :"Don sécurisé",
    "del laboratorio"         :"du labo",
    "Actualizar nota"         :"Mettre à jour une note",
    "Única respuesta"         :"Seule réponse",
    "Guardar cambios"         :"Sauvegarder les modifications",
    "Respuesta libre"         :"Réponse gratuite",
    "RESPUESTA LIBRE"         :"RÉPONSE GRATUITE",
    "Campo requerido"         :"Champs requis",
    "Subir evidencia"         :"Télécharger des preuves",
    "Verificar código"        :"Vérifier le code",
    "Plan mediano"            :"Projet moyen",
    "Plan pequeño"            :"Petit projet",
    "Usuarios finales"        :"Les utilisateurs finaux",
    "usuarios finales"        :"les utilisateurs finaux",
    "Enviar respuesta"        :"Envoyer la réponse",
    "de almacenamiento"       :"espace de rangement",
    "Siguiente noticia"       :"Prochaines nouvelles",
    "Has completado un"       :"Vous avez terminé",
    "Actualizar Perfil"       :"Mettre à jour le profil",
    "Completar registro"      :"Enregistrement complet",
    "Correo electrónico"      :"Courrier électronique",
    "Perfil actualizado"      :"Profil mis à jour",
    "Cambiar contraseña"      :"Changer le mot de passe",
    "Contraseña invalida"     :"Mot de passe incorrect",
    "Personalizar avatar"     :"Personnaliser l'avatar",
    "Confirmar contraseña"    :"Confirmez le mot de passe",
    "Servicios adicionales"   :"Des services supplémentaires",
    "Asistencia pedagógica"   :"Assistance pédagogique",
    "Objetivos pedagógicos"   :"Objectifs pédagogiques",
    "Restablecer contraseña"  :"Restaurer le mot de passe",
    "Plan personalizado"      :"Plan personnalisé",
    "Cantidad personalizada"  :"Montant personnalisé",
    "Usuarios administradores":"Utilisateurs administrateurs",
    "Voluntariado corporativo":"Bénévolat d'entreprise",
    "usuarios administradores":"utilisateurs administrateurs",

    "Dar una vez"                     :"Donner une fois",
    "Ver en línea"                    :"Regarder en ligne",
    "Escribe aquí"                    :"Ecrire ici",
    "Validar edad"                    :"Valider l'âge",
    "Sin realizar"                    :"Défait",
    "Nuevo mensaje"                   :"Nouveau message",
    "Sopa de letras"                  :"Soupe à l'alphabet",
    "Doblaje de voz"                  :"Voix off",
    "Ver resultados"                  :"Voir les résultats",
    "Progreso total"                  :"Les progrès d'ensemble",
    "Nombre de unidad"                :"Nom de l'unité",
    "Código del curso"                :"Code de cours",
    "Buzón de mensajes"               :"Boîte de réception",
    "Buscar por nombre"               :"Rechercher par nom",
    "Número de tarjeta"               :"Numéro de carte",
    "Título de la nota"               :"Titre de la note",
    "Nombre y apellido"               :"Nom et prénom",
    "No hay actividades"              :"Aucune activité",
    "Compra de licencia"              :"Achat de licence",
    "Nombre del titular"              :"Nom du propriétaire",
    "Editar laboratorio"              :"Modifier labo",
    "Número de teléfono"              :"Numéro de téléphone",
    "Iconos de posición"              :"Icônes de position",
    "Iniciar sesión con"              :"Connectez-vous avec",
    "Completa la oración"             :"Complète la phrase",
    "Compartir como blog"             :"Partager en tant que blog",
    "Escribe tu respuesta"            :"Ecrivez votre réponse",
    "Máximo 50 carácteres"            :"50 caractères maximum",
    "Términos de servicio"            :"Conditions d'utilisation",
    "Diseño de contenidos"            :"Conception de contenu",
    "Completa tu registro"            :"Complétez votre inscription",
    "Descargar certificado"           :"Télécharger le certificat",
    "Agrega tu información"           :"Ajoutez vos informations",
    "Nombre de organización"          :"Nom de l'organisation",
    "Código de verificación"          :"Code de vérification",
    "Política de privacidad"          :"Politique de confidentialité",
    "Encuentra las palabras"          :"Trouve les mots",
    "Seleccionar una imagen"          :"Sélectionnez une image",
    "Estudiantes registrados"         :"Étudiants inscrits",
    "Comprar/renovar licencia"        :"Acheter/renouveler une licence",
    "Condiciones del servicio"        :"Conditions de service",
    "Nota enviada exitosamente"       :"Note envoyée avec succès",
    "Nota guardada exitosamente"      :"Note enregistrée avec succès",
    "Nota eliminada exitosamente"     :"Remarque supprimée avec succès",
    "Nota compartida exitosamente"    :"Note partagée avec succès",
    "Verificar correo electrónico"    :"Vérifier les courriels",
    "Organización/Escuela/Empresa"    :"Organisation/École/Entreprise",
    "Nota actualizada exitosamente"   :"Note mise à jour avec succès",
    "Soporte técnico virtual"         :"Assistance technique virtuelle",
    "Plataforma web personalizable"   :"Plateforme web personnalisable",
    "Aplicación móvil personalizable" :"Application mobile personnalisable",
    "Servicios adicionales (opcional)":"Services supplémentaires (facultatif)",
    
    "Enviar a un amigo"                           :"Envoyer à un ami",
    "Guardar y enviar nota"                       :"Enregistrer et envoyer une note",
    "Validar y guardar edad"                      :"Valider et gagner de l'âge",
    "Acceder con el teléfono"                     :"Accès avec le téléphone",
    "Has completado el módulo"                    :"Vous avez terminé le module",
    "Has completado la unidad"                    :"Vous avez terminé l'unité",
    "El módulo no tiene retos"                    :"Le module n'a pas de défis",
    "Configuración de tu cuenta"                  :"Configuration de votre compte",
    "Envía un mensaje o llama a"                  :"Envoyez un message ou appelez à",
    "Regístrate con tu teléfono"                  :"Inscrivez-vous avec votre téléphone",
    "La edad debe ser mayor a 0"                  :"L'âge doit être supérieur à 0",
    "Escribe aquí tu comentario"                  :"Écrivez votre commentaire ici",
    "Correo enviado exitosamente"                 :"Courrier envoyé avec succès",
    "Verificar número de teléfono"                :"Vérifier le numéro de téléphone",
    "Envia un correo electrónico a"               :"Envoyer un courriel à",
    "El usuario ya está registrado"               :"L'utilisateur est déjà enregistré",
    "El usuario no está registrado"               :"L'utilisateur n'est pas enregistré",
    "Arrastra la respuesta correcta"              :"Faites glisser la bonne réponse",
    "Las contraseñas deben coincidir"             :"Les mots de passe doivent correspondre",
    "El laboratorio no tiene módulos"             :"Le laboratoire n'a pas de modules",
    "Existe un usuario con ese correo"            :"Il y a un utilisateur avec cet email",
    "Acceder con el correo electrónico"           :"Accès par e-mail",
    "No estás habilitado para ingresar"           :"Vous ne pouvez pas entrer",
    "¿Deseas comunicarte con un tutor?"           :"Vous souhaitez communiquer avec un tuteur?",
    "Existe un usuario con ese teléfono"          :"Il y a un utilisateur avec ce téléphone",
    "Regístrate con tu correo electrónico"        :"Inscrivez-vous avec votre email",
    "Nota guardada y enviada exitosamente"        :"Note enregistrée et envoyée avec succès",
    "para que puedas cambiar tu contraseña"       :"donc tu peux changer ton mot de passe",
    "La unidad siguiente no tiene lecciones"      :"L'unité suivante n'a pas de cours",
    "El código de verificación no es válido"      :"Le code de vérification n'est pas valide",
    "¡Lo hacemos por ti!"                         :"Nous le faisons pour vous !",
    "por un período de un"                        :"pour une durée d'un",
    "se realizó con éxito"                        :"a été fait avec succès",
    "¡Gracias por tu ayuda!"                      :"Merci pour ton aide!",
    "La donación fue exitosa"                     :"Le don a réussi",
    "Elige cómo quieres ayudar"                   :"Choisissez comment vous voulez aider",
    "Gracias por tu donación de"                  :"Merci pour votre don de",
    "¡Cerremos la brecha juntos!"                 :"Comblons le fossé ensemble !",
    "El valor de la donación es $0"               :"La valeur du don est de 0$",
    "Reportes de impacto específicos"             :"Rapports d'impact spécifiques",
    "Sistema de gestión de proyectos"             :"Système de gestion de projet",
    "Asistencia técnica y de soporte"             :"Assistance technique et support",
    "Análisis y reportes automáticos"             :"Analyses et rapports automatiques",
    "Mensajes de audio personalizables"           :"Messages audio personnalisables",
    "Plataforma de gestión de contenido"          :"Plateforme de gestion de contenu",
    "Diseño y animación de avatares y más"        :"Animations, illustrations et plus",
    "Funciones de seguimiento y evaluación"       :"Fonctions de suivi et d'évaluation",
    "Sistema de gestión de aprendizaje (LMS)"     :"Système de gestion de l'apprentissage (LMS)",
    "Asistencia en coordinación de proyectos"     :"Assistance projet professionnel",
    "Existe un usuario con esa identificación"    :"Il y a un utilisateur avec cet identifiant",
    "Iniciar sesión con tu correo electrónico"    :"Connectez-vous avec votre compte de messagerie",
    "Institución educativa o comunidad afiliada"  :"Établissement d'enseignement ou communauté affiliée",
    "Existe un usuario con ese correo electrónico":"Il y a un utilisateur avec cet email",
    
    
    "Ante cualquier duda puedes contactar a tu docente":"Si vous avez des questions, vous pouvez contacter votre professeur",
    "Al continuar, estás indicando que aceptas nuestros":"En poursuivant, vous indiquez que vous acceptez notre",
    "Puedes acceder y ver los contenidos que se ofrecen":"Vous pouvez accéder et visualiser le contenu proposé",
    "Ingresa el código de 6 digitos que enviamos al numero":"Entrez le code à 6 chiffres que nous avons envoyé au numéro",
    "de estudiantes registrados ha terminado el laboratorio":"des étudiants inscrits ont terminé le laboratoire",
    "Se enviará un mensaje con un link al correo electrónico":"Un message sera envoyé avec un lien vers l'e-mail",
    "Debes ser mayor de edad para poder acceder a esta sección":"Vous devez avoir l'âge légal pour accéder à cette section",
    "Mensaje enviado exitosamente, revisa tu correo electrónico":"Message envoyé avec succès, vérifiez votre e-mail",
    "Correo electrónico ya está siendo utilizada por otra cuenta":"L'e-mail est déjà utilisé par un autre compte",
    "Acceso desactivado temporalmente debido a muchos intentos fallidos":"Accès temporairement désactivé en raison de nombreuses tentatives infructueuses",
    "¡Te mantendremos informado sobre el impacto de tu donación! #somoselorigen":"Nous vous tiendrons au courant de l'impact de votre don! #wearetheorigin",
    "Estos mensajes son monitoreados para garantizar la seguridad de nuestros usuarios":"Ces messages sont surveillés pour assurer la sécurité de nos utilisateurs",
    "Esta sección solo esta habilitada para mayores de edad, por favor valida tu edad para continuar":"Cette section est réservée aux adultes, veuillez valider votre âge pour continuerThis section is only enabled for adults, please validate your age to continue",
    "Con o-lab es posible personalizar los requerimientos de todas las instituciones, entidades educativas u organizaciones. Con todas las características y funciones que tus beneficiarios o educadores necesitan para presentar todos los temas, conferencias, cursos y más.":"Avec o-lab, il est possible de personnaliser les exigences de toutes les institutions, entités éducatives ou organisations. Avec toutes les caractéristiques et fonctions dont vos bénéficiaires ou éducateurs ont besoin pour présenter tous les sujets, conférences, cours et plus encore.",
    "El 100% de cada donación va directamente a una persona/escuela/comunidad específica que espera nuestro kit #educacióndigitalparatodos que los equipa con tecnología, capacitación a profesores y nuestro LMS y aplicación de aprendizaje fuera de línea O-lab lleno de cursos complementarios, vocacionales y STEAM creados en colaboración con maestros e instituciones locales en todo el mundo.":"100% de chaque don va directement à une personne/école/communauté spécifique en attendant notre kit #digitaleducationforall qui les équipe avec la technologie, la formation et notre application LMS et d'apprentissage hors ligne O-lab rempli de cours complémentaires, professionnels et STEAM co-créés avec enseignants locaux et institutions du monde entier.",
    "Se enviará un mensaje a la dirección de correo electrónico que has ingresado para que puedas cambiar tu contraseña.":"Un message sera envoyé à l'adresse e-mail que vous avez saisie afin que vous puissiez modifier votre mot de passe.",
    "Si presionas Verificar número de teléfono se enviará un SMS. Se aplicarán las tarifas de mensaje y datos":"Si vous appuyez sur Vérifier le numéro de téléphone, un SMS sera envoyé. Les tarifs des messages et des données s'appliqueront.",
  }
};

export default languaje_FR;