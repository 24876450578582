import { IContent } from "dto/content.dto";
import { AppThunkAction } from "store/types";
import { ContentActionType } from "./actions";

export * from "./actions";
export * from "./constants";

export interface ContentState {
  contents: IContent[];
  contentsFree: IContent[];
  contentsLoading: boolean;
  contentsFreeLoading: boolean;
}

export type Action = AppThunkAction<ContentActionType>;
