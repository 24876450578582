import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { AppBar, Toolbar, Grid } from "@material-ui/core";
import { State } from "store/types";
import ButtonLanguage from "./ButtonLanguage";

const useStyles = makeStyles((theme) => ({
	appBar: {  backgroundColor: 'white', minHeight: '60px' },
	toolbar: {
		minHeight: '60px !important',
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down("xs")]: { padding: '0 10px' }, 
	},
  img: { maxHeight: 40 },
}));

const LayoutOrigin = () => {
  const classes = useStyles();
  const languageCode = useSelector((state: State) => state.layout.language.code);
  const image = languageCode === "es" ? "/images/landing_05.svg" : "/images/landing_06.svg";

  return (
    <AppBar className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Grid container alignItems="center">
          <Grid item container xs={6}>
            <img className={classes.img} src={image} alt=""  />
          </Grid>
          <Grid item container justifyContent="flex-end" alignItems="center" xs={6}>
            <ButtonLanguage />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export default LayoutOrigin;
