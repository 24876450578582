import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Typography, TextField, Grid } from "@material-ui/core";
import FormButton from "components/Form/FormButton";
import TermsConditions from "./TermsConditions";

const useStyles = makeStyles(() => ({
  field: { 
    "& fieldset": { border: 0 },
    "& .MuiInputBase-root ": { 
      borderRadius: 10, 
      backgroundColor: "#F6F6F6", 
    }, 
  },
}));

interface IProps {
  loading: boolean
  phoneNumber: string
  sendCodeVerification: (code: string) => void
}

const CodeVerification: FC<IProps> = ({ phoneNumber, loading, sendCodeVerification }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [code, setCode] = React.useState("");

  const changeCodeVerification = (e: {target: {value: string}}) => setCode(e.target.value);
  const handleCodeVerification = () => sendCodeVerification(code);
  const handleKeyUpCapture = (e: { keyCode: number }) => {
    if ((e.keyCode === 13) && (code.length === 6)) handleCodeVerification()
  }
  const onKeyPress = (e: {key: string, preventDefault: () => void}) => {
    const reg = new RegExp('^[0-9]+$');
    if (!reg.test(e.key)) e.preventDefault();
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography align="center" variant="body2" gutterBottom>
          <b>{t("Ingresa el código de 6 digitos que enviamos al numero")}: {phoneNumber}</b>
        </Typography>
      </Grid>
      <Grid className={classes.field} item xs={12}>
        <TextField 
          size="small"
          variant="outlined" 
          placeholder={t("Código de verificación")} 
          disabled={loading}
          onChange={changeCodeVerification}
          onKeyPress={onKeyPress}
          onKeyUpCapture={handleKeyUpCapture}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <FormButton 
          primary
          onClick={handleCodeVerification} 
          fullWidth
          disabled={loading || code.length !== 6}
        >
          {t("Verificar código")}
        </FormButton>
      </Grid>
      <Grid item xs={12}>
        <TermsConditions />
      </Grid>
    </Grid>
  )
}

export default CodeVerification;
