import Gender from "services/gender.services";
import * as types from "./types";
import { IGender } from "dto/gender.dto";

export const getGenders = (): types.Action => async (dispatch) => {
  dispatch({ type: types.GENDERS_LOADING, payload: true });
  try {
    const genders = await Gender.get();
    dispatch(setGenders(genders));
  } catch(error) { 
    dispatch(setGenders([]));
    console.log("Error Get Gender: ", error)
  }
  dispatch({ type: types.GENDERS_LOADING, payload: false });
}

const setGenders = (genders: IGender[]): types.Action => (dispatch) => {
  dispatch({ type: types.GENDERS, payload: genders });
}
