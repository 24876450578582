
import { Action } from "./types";
import * as types from "./types";
import { IModule } from "dto/module.dto";
import * as actions from "store/actions";
import { moduleInit } from "initials";

export const selectModule = (value: IModule): Action => (dispatch) => {
  dispatch({ type: types.MODULE, payload: value });
}
export const deselectModule = (): Action => (dispatch) => {
  dispatch(actions.deselectChallenger());
  dispatch({ type: types.MODULE, payload: moduleInit });
}
