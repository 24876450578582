import React, { FC } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Grid } from "@material-ui/core";
import clsx from "clsx";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles((theme) => ({
  pdf: {
    height: "100%",
    "& .react-pdf__Document": { width: "100%", height: "100%" },
    "& .react-pdf__Page": { 
      width: "100%",
      height: "100%",
      backgroundColor: "white", 
      "& .react-pdf__Page__annotations": { display: "none" },
    },
    "& .react-pdf__Page__canvas": {
      width: "auto !important",
      height: "auto !important",
      maxWidth: "100%",
      maxHeight: "100%",
      minHeight: "100%",
      marginLeft: "auto",
      marginRight: "auto",
      [theme.breakpoints.down("sm")]: { minHeight: "auto" },
    },
    "& .react-pdf__Page__textContent": { display: "none" },
  },
  loading: {
    height: 450,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    [theme.breakpoints.down("xs")]: { height: "calc(100vh - 110px)" },
    "&.zoom": { height: "calc(100vh - 60px)" },
  },
  circular: { color: "#ff6300" }
}));

interface IProps {
  pdf: string;
  zoom: boolean;
  numberPage: number;
  selectNumberPages: (pages: number) => void;
}

const StepPdf: FC<IProps> = (props) => {
  const classes = useStyles();
  const { zoom } = props;

  const onLoadSuccess = ({numPages} : {numPages: number}) => {
    props.selectNumberPages(numPages);
  }

  return (
    <Grid className={classes.pdf} container>
      <Document 
        file={props.pdf}
        loading={
          <Grid className={clsx(classes.loading, zoom && "zoom")} container>
            <CircularProgress className={classes.circular} size={50} />
          </Grid>
        }
        onLoadSuccess={onLoadSuccess}
      >
        <Page 
          pageNumber={props.numberPage}
          loading={
            <Grid className={clsx(classes.loading, zoom && "zoom")} container>
              <CircularProgress className={classes.circular} size={50} />
            </Grid>
          }
        />
      </Document>
    </Grid>
  )
}

export default StepPdf;
