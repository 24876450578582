import React, { FC, useEffect } from "react";
import { IQuestion, IQuestionPreview } from "dto/question.dto";
import Question from "./Question";
import Notebook from "components/Notebook/Notebook";
import NotebookActions from "components/Notebook/NotebookActions";
import NotebookContent from "components/Notebook/NotebookContent";

interface IProps {
  zoom: boolean;
  total: number;
  answer: string;
  preview: IQuestionPreview;
  question: IQuestion;
  questionNumber: number;
  questionsTotal: number;
  toogleZoom: () => void;
  prevQuestion: () => void;
  nextQuestion: () => void;
  selectAnswer: (answer: string) => void;
  updateBackground: (background: string) => void;
}

const Formulario: FC<IProps> = (props) => {
  useEffect(() => { 
    props.updateBackground(props.preview?.background_image ?? "");
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.question]);

  return (
    <Notebook zoom={props.zoom} backgroundColor="#ff6315">
      <NotebookContent zoom={props.zoom}>
        <Question
          total={props.total}
          answer={props.answer}
          preview={props.preview}
          question={props.question}
          questionsTotal={props.questionsTotal}
          questionNumber={props.questionNumber}
          prevQuestion={props.prevQuestion}
          nextQuestion={props.nextQuestion}
          selectAnswer={props.selectAnswer}
        />
      </NotebookContent>
      <NotebookActions 
        zoom={props.zoom}
        buttonNextText="Continuar"
        buttonNextDisabled={!props.answer}
        buttonNextOnClick={props.nextQuestion}
        buttonBackOnClick={props.prevQuestion}
        buttonZoomOnClick={props.toogleZoom}
      />
    </Notebook>
  )
}

export default Formulario;
