import { entityInit } from "initials/entity.init";
import { EntityActionType, EntityState } from "./types";
import * as types from "./types";

const INITIAL_STATE: EntityState = {
  entity: entityInit,
  entityLoading: false,
}

export const entity = (prevState = INITIAL_STATE, action: EntityActionType) => {
  switch (action.type) {
    case types.ENTITY: 
      return { ...prevState, entity: action.payload }
    case types.ENTITY_LOADING: 
      return { ...prevState, entityLoading: action.payload }

    default: 
      return prevState;
  }
}
