import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IQuestion } from "dto/question.dto";
import { State } from "store/types";
import { history } from "configureStore";
import * as actions from "store/actions";
import PageRoot from "components/Pages/PageRoot";
import Survey from "components/Survey/Survey";
import DialogWithoutSponsor from "components/Explore/DialogWithoutSponsor";

const ExploreSurveyPage = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: State) => state.user.user);
  const surveyFree = useSelector((s: State) => s.explore.surveyFree);
  const languageAudio = useSelector((s: State) => s.user.languageAudio);
  const saveAnswerLoading = useSelector((s: State) => s.survey.saveAnswerLoading);
  const dialogCongratulations = useSelector((s: State) => s.survey.dialogCongratulations);
  const { isEmpty, isLoaded } = useSelector((s: State) => s.firebase.auth);
  const [zoom, setZoom] = useState(false);
  const [dialogWithoutSponsor, setDialogWithoutSponsor] = useState(false);
  const anonymous = localStorage.getItem("anonymousUser") ?? "";

  useEffect(() => { 
    if (!!surveyFree?.uuid) {
      dispatch(actions.analytics("access_survey_free", {}));
    } 
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => { 
    if (!!isEmpty && !!isLoaded && !anonymous) history.push("/");
  }, [isEmpty, isLoaded, anonymous]);
  
  useEffect(() => { if (!surveyFree?.uuid) history.push("/explore"); }, [surveyFree]);

  const onClickZoom = () => setZoom((v) => !v);
  const closeSurveyBook = () => {
    history.push("/explore");
    dispatch(actions.resetSurveyFree());
  }

  const saveSurveyAnswers = (questions: IQuestion[]) => {
    dispatch(actions.saveSurveyAnswers(surveyFree.uuid, questions));
  }

  const closeCongratulations = () => dispatch(actions.closeCongratulations());

  const closeDialogWithoutSponsor = () => setDialogWithoutSponsor(false);
  const openDialogWithoutSponsor = (index: number) => {
    const questionsTotal = surveyFree?.questions?.length ?? 0;
    setDialogWithoutSponsor(
      !user?.sponsor &&
      questionsTotal > 2 && 
      questionsTotal - 1 === index
    );
  }

  const handleAnalytics = (name: string, params?: { [key: string]: string }) => {
    dispatch(actions.analytics(name, params));
  }

  return (
    <PageRoot 
      zoom={zoom} 
      background={surveyFree?.properties?.wallpaper_url}
      hideButtonLanguage={!!user?.nit}
    >
      {!!surveyFree?.questions?.length && (
        <Survey 
          zoom={zoom} 
          survey={surveyFree} 
          questions={surveyFree.questions}
          languageAudio={String(languageAudio)}
          saveAnswerLoading={saveAnswerLoading}
          dialogCongratulations={dialogCongratulations}
          onClickZoom={onClickZoom}
          closeSurveyBook={closeSurveyBook}
          handleAnalytics={handleAnalytics}
          saveSurveyAnswers={saveSurveyAnswers}
          updateQuestionIndex={openDialogWithoutSponsor}
          closeCongratulations={closeCongratulations}
        />
      )}
      <DialogWithoutSponsor 
        open={dialogWithoutSponsor} 
        onClose={closeDialogWithoutSponsor} 
      />
    </PageRoot>
  )
}

export default ExploreSurveyPage;
