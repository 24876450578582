import React, { FC } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { IModule } from "dto/module.dto";
import FormButtonIcon from "components/Form/FormButtonIcon";
import FormTypography from "components/Form/FormTypography";

const useStyles = makeStyles((theme) => ({
  header: { 
    padding: "16px 24px", 
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

interface IProps {
  module: IModule;
}

const DialogChallengesHeader: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const displayName = props.module?.displayName ?? props.module?.name;
  const challengesCount = props.module?.challenges?.length ?? 0;

  return (
    <Grid className={classes.header} container>
      <Grid item>
        <FormTypography variant="h5" fontWeight={700}>
          {displayName}
        </FormTypography>
        <FormTypography color="#929292" fontSize={18}>
          {`${challengesCount} ${t("unidades")}`}
        </FormTypography>
      </Grid>
      <FormButtonIcon image="/img/home/volume.svg" secondary />
    </Grid>
  )
}

export default DialogChallengesHeader;
