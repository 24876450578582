import axios from "axios";
import { ISurvey } from "dto/survey.dto";
import { ILaboratory } from "dto/laboratory.dto";

const url = process.env.REACT_APP_BACKEND_URL;
const route = `${url}/api`;

interface IGetContentsFree { surveys: ISurvey[]; laboratories: ILaboratory[] }

class Content {
  async get(userNit: string) {
    const data = await axios.get(`${route}/users/content/${userNit}`);
    return data?.data?.data ?? { surveys: [], laboratories: [] };
  }

  async getContentsFree (typeUser: string): Promise<IGetContentsFree> {
    const data = await axios.post(`${route}/users/anonymous/content`, { typeUser });
    return data?.data?.data;
  };
}

const content = new Content();
const ContentClass = Object.freeze(content);
export default ContentClass;
