import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { makeStyles, Grid, Card, Typography, Badge } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import { IAmount, ILicense } from '../../../services/checkout.services';
import { LicenseIcon }       from '../../Svg/LicenseIcon';
import { LicenseBackground } from '../../Svg/LicenseBackground';

const useStyles = makeStyles((theme) => ({
  license: { 
    [theme.breakpoints.down("xs")]: {
      '& .MuiBadge-root': { width: '100%' },
    },
    '& .MuiBadge-badge': { top: 3, right: 3 }, 
  },
  card: { 
    cursor: 'pointer',
    width: 425, 
    height: 155,
    position: 'relative', 
    padding: '0 10px',
    boxShadow: 'none',
    [theme.breakpoints.down("xs")]: { width: '100%' },
  },
  selected: { 
    width: 425,
    zIndex: 1,
    border: '5px solid #ff6315',
    padding: '0 5px',
    borderRadius: 10,
    [theme.breakpoints.down("xs")]: { width: '100%' },
  },
  currency: { marginTop: 7 },
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    '& img': { height: '100%' }
  },
  content: { position: 'relative' },
  title: { height: 42, '& img': { width: 30, marginRight: 5 } },
  value: { height: 80, color: 'white' },
  check: { backgroundColor: 'white', color: '#ff6315', fontSize: 30, borderRadius: '50%' },
  mask: { 
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
  },
}));

interface IProps {
  price:    IAmount
  license:  ILicense
  selected: boolean
  currency: "usd"|"cop"
  setPrice: (price: IAmount) => void
}

const LicCard: FC<IProps> = ({price, license, selected, currency, setPrice}) => {
  const classes  = useStyles();
  const { t } = useTranslation();

  return (
    <Grid className={classes.license} container justifyContent="center">
      <Badge
        overlap="circle"
        badgeContent={selected ? (<CheckCircle className={classes.check} />) : <></>}
      >
        <Card 
          className={clsx(classes.card, selected && classes.selected)} 
          onClick={() => setPrice(price)}
        >
          <Grid className={classes.background} container>
            <img  src={LicenseBackground(license.color_one, license.color_two)} alt="" />
          </Grid>
          
          <Grid className={classes.content} container alignItems="center">
            <Grid className={classes.title} container alignItems="center">
              <img src={LicenseIcon(license.color_one)} alt="" />
              <Typography variant="h6">
                {t("Licencia")} 1 {t(price.name)}
              </Typography>
            </Grid>
            
            <Grid className={classes.value} container alignItems="center">
              <Grid item xs={5}>
                <Typography variant="subtitle2" display="block">
                  {t(license.name)}
                </Typography>
                <Typography variant="subtitle2" display="block">
                  {`${license.min_users} - ${license.max_users} ${t("usuarios")}`}
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography align="right" variant="h5" display="block">
                  <b>${price[currency].toLocaleString("en")}</b>
                  {currency.toUpperCase()}/{t(price.name)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          
          {!selected && (<Grid className={classes.mask} container></Grid>)}
        </Card> 
      </Badge>
    </Grid>
  )
}

export default LicCard;
