import { AppThunkAction } from '../../../types';
import { ProfileActionType } from './actions';

export * from './constants';
export * from './actions';

export interface ProfileState {
  section: number
}

export type Action = AppThunkAction<ProfileActionType>
