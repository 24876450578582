import React, { useState } from 'react';
// import { useTranslation } from 'react-i18next';
import { Grid, makeStyles, Card, Typography } from '@material-ui/core';
import { Https } from '@material-ui/icons';
import { IDonation, donationInit } from '../../services/checkout.services';
import { messageError } from '../Common/Toast';
import DonationStepOne   from './DonationStepOne'; 
import DonationStepTwo   from './DonationStepTwo' ;
import DonationStepThree from './DonationStepThree';

const useStyles = makeStyles(() => ({
  card: { 
    height: '100%', 
    backgroundColor: '#f4f4f4',
    '& .MuiCardContent-root': { height: 'calc(100% - 120px)', padding: 15 },
  },
  footer: { 
    height: 20,
    '& .MuiSvgIcon-root': { fontSize: 11, marginRight: 5 },
    '& .MuiTypography-root': { lineHeight: 1 },
  },
}));

const Donation = () => {
  const classes  = useStyles();
  // const { t } = useTranslation();
  const [section, setSection] = useState("MENU");
  const [donation, setDonation] = useState<IDonation>(donationInit);

  const updateDonation = (donation: IDonation) => setDonation({...donation});

  const saveDonation = () => {
    if (!donation.amount) return messageError("El valor de la donación es $0");
    setSection("STRIPE");
  }

  const sectionMenu = () => setSection("MENU");
  const sectionThankYou = () => setSection("THANK_YOU");
  const finalizeDonation = () => {
    sectionMenu();
    setDonation({...donationInit});
  } 

  return (
    <Card className={classes.card}>
      {section === "MENU" && (
        <DonationStepOne 
          donation={donation}
          saveDonation={saveDonation}
          updateDonation={updateDonation} 
        />
      )}

      {section === "STRIPE" && (
        <DonationStepTwo        
          donation={donation} 
          prevSection={sectionMenu}
          nextSection={sectionThankYou}
        />
      )}

      {section === "THANK_YOU" && (
        <DonationStepThree   
          donation={donation} 
          finalizeDonation={finalizeDonation} 
        />
      )}
      
      <Grid className={classes.footer} container justifyContent="center" alignItems="center">
        <Https color="disabled" />
        <Typography variant="caption" color="textSecondary">
          Secure Payment Powered by <b>stripe</b>
          {/* {t("Donación segura")} */}
        </Typography>
      </Grid>
    </Card>
  )
}

export default Donation;
