import * as types from "./types";
import { anonymousUserInit } from "initials/user-anonymous.init";
import { AnonymousUserActionType, AnonymousUserState } from "./types";

const INITIAL_STATE: AnonymousUserState = {
  anonymousUser: anonymousUserInit,
  anonymousUserLoading: false,
}

export const anonymousUser = (prevState = INITIAL_STATE, action: AnonymousUserActionType) => {
  switch (action.type) {
    case types.ANONYMOUS_USER:
      return { ...prevState, anonymousUser: action.payload }
    case types.ANONYMOUS_USER_LOADING:
      return { ...prevState, anonymousUserLoading: action.payload }

    default: 
      return prevState;
  }
}
