import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Image, KeyboardVoice } from "@material-ui/icons";
import InputFile from "./InputFile";
import AudioRecord from "./AudioRecord";
import { 
  List, 
  Dialog, 
  ListItem, 
  ListItemText,
  ListItemIcon, 
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  list: { padding: 8 },
}));

interface IProps {
  open: boolean;
  onClose: () => void;
  uploadFile: (file: File) => void;
}

const DialogUploadFile: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { open, onClose, uploadFile } = props;
  const [audioRecord, setAudioRecord] = useState(false);

  const handleOnClose = () => {
    onClose();
    setAudioRecord(false);
  }

  const handleUploadFile = (file: File) => {
    uploadFile(file);
    handleOnClose();
  }

  const openAudioRecord = () => setAudioRecord(true);
  const closeAudioRecord = () => setAudioRecord(false);

  return (
    <Dialog open={open} onClose={handleOnClose} maxWidth="xs" fullWidth>
      {audioRecord ? (
        <AudioRecord 
          uploadFile={handleUploadFile} 
          closeAudioRecord={closeAudioRecord}
        />
      ) : (
        <List className={classes.list} component="nav">
          <ListItem button component="label">
            <ListItemIcon>
              <Image />
            </ListItemIcon>
            <ListItemText primary={t("Cargar imagen")} />
            <InputFile onChangeFile={handleUploadFile} />
          </ListItem>

          <ListItem button onClick={openAudioRecord}>
            <ListItemIcon>
              <KeyboardVoice />
            </ListItemIcon>
            <ListItemText primary={t("Grabar audio")} />
          </ListItem>
        </List>
      )}
    </Dialog>
  )
}

export default DialogUploadFile;
