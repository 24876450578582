import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Grid, Paper, Button } from '@material-ui/core';
import { TableContainer, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import { CheckCircleOutline } from '@material-ui/icons';
import { servicesTable } from '../Constants';

const useStyles = makeStyles((theme) => ({
  custom: { 
    maxWidth: 130, 
    marginRight: 15,
    [theme.breakpoints.down("xs")]: { maxWidth: '100%',  margin: 0, marginBottom: 15 },
  },
  header: {
    color: 'white',
    width: '100%',
    height: 70,
    display: 'flex',
    padding: '16px 10px',
    fontWeight: 500,
    alignItems: 'center',
    borderRadius: '10px 10px 0 0',
    textTransform: 'capitalize',
    backgroundColor: '#ff6315', 
  },
  table: { 
    width: 'auto', 
    borderRadius: 0,
    [theme.breakpoints.down("xs")]: { width: '100%' },
  },
  check: { width: 140, padding: '0 !important', fontSize: 15 },
  first: { width: 140, padding: '4px 0 !important', fontSize: 15 },
  color: {
    '& .MuiTableCell-root': {
      fontWeight: 'bold',
      border: 0,
      borderRight: '1px solid #a5a5a5', 
      '&:last-child': { borderRight: 0 },
      '& .MuiSvgIcon-root': { color: '#5dc90a' },
    },
    '&:nth-of-type(odd)': { backgroundColor: '#e8e8e8' },
  },
  btn: {
    color: 'white',
    padding: '6px 10px',
    borderRadius: '0 0 10px 10px',
    justifyContent: 'start',
    backgroundColor: '#ff6300',
    '& .MuiButton-label': {
      display: 'flow-root',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    '&:hover': { backgroundColor: 'white', color: '#ff6300' },
  },
  link: { width: '100%', color: 'initial', textDecoration: 'none' },
}));

interface IProps {
  openContactUs: () => void
}

const ServicesCustom: FC<IProps> = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid className={classes.custom} container>
      <Paper className={classes.header}>
        {t("Plan personalizado")}
      </Paper>

      <TableContainer className={classes.table} component={Paper}>
        <Table size="small">
          <TableBody>
            <TableRow className={classes.color}>
              <TableCell className={classes.first} align="center">
                +20 GB
              </TableCell>
            </TableRow>
            <TableRow className={classes.color}>
              <TableCell className={classes.first} align="center">
                +5001
              </TableCell>
            </TableRow>
            <TableRow className={classes.color}>
              <TableCell className={classes.first} align="center">
                +11
              </TableCell>
            </TableRow>
            {servicesTable.map((item) => (
              <TableRow key={item} className={classes.color}>
                <TableCell className={classes.check} align="center">
                  <CheckCircleOutline fontSize="small" />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <a className={classes.link} href="https://o-lab.app/es/pagos-colombia/">
        <Button className={classes.btn} variant="contained" fullWidth>
          {t("Contáctanos")}
        </Button>
      </a>
    </Grid>
  )
}

export default ServicesCustom;
