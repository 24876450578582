import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Grid, Typography, Button } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  optional: { 
    paddingTop: 15, 
    height: 'calc(100vh - 60px)', 
    position: 'relative', 
    [theme.breakpoints.down("xs")]: { padding: 0 },
  },
  img: { position: 'absolute', width: '100%', bottom: 0 },
  content: { 
    height: '100%',
    overflow: 'auto', 
    [theme.breakpoints.down("xs")]: { paddingBottom: 135 },
  },
  info: { 
    width: 400,
    [theme.breakpoints.down("xs")]: { padding: 15 },
  },
  btn: { borderRadius: 25, marginTop: 15 },
  accept: {
    color: 'white',
    backgroundColor: '#ff6300',
    '&:hover': { backgroundColor: 'white', color: '#ff6300' },
  },
  image: {
    [theme.breakpoints.down("xs")]: { padding: 15, '& img': { width: '100%' }, },
  },
  link: { textDecoration: 'none', color: 'initial' },
}));

interface IProps {
  openContactUs: () => void
  closeOptional: () => void
}

const ServiceOptional: FC<IProps> = ({closeOptional}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid className={classes.optional} container>
      <img className={classes.img} src="/images/check_footer.svg" alt="" />
      <Grid className={classes.content} container alignItems="flex-start">
        <Grid item container justifyContent="flex-end" sm={6} xs={12}>
          <Grid className={classes.info} item>
            <Typography align="center" variant="h4" gutterBottom>
              <b>{t("¡Lo hacemos por ti!")}</b>
            </Typography>
            <Typography align="justify" variant="subtitle1" gutterBottom>
              {t("Con o-lab es posible personalizar los requerimientos de todas las instituciones, entidades educativas u organizaciones. Con todas las características y funciones que tus beneficiarios o educadores necesitan para presentar todos los temas, conferencias, cursos y más.")}
            </Typography>
            <a className={classes.link} href="https://o-lab.app/es/pagos-colombia/">
              <Button 
                className={clsx(classes.btn, classes.accept)} 
                variant="contained"
                fullWidth
              >
                {t("Contáctanos")}
              </Button>
            </a>
            <Button className={classes.btn} variant="contained" onClick={closeOptional} fullWidth>
              {t("Volver")}
            </Button>
          </Grid>
        </Grid>

        <Grid className={classes.image} item container justifyContent="center" sm={6} xs={12}>
          <img src="/images/plus_gif.svg" alt="" />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ServiceOptional;
