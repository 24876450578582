import React, { FC } from 'react';
import clsx from 'clsx';
import { makeStyles, Grid, Button } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  currency: { marginBottom: 15 },
  left: { paddingRight: 8, marginBottom: 8 },
  right: { paddingLeft: 8, marginBottom: 8 },
  btn: { backgroundColor: '#d5d5d5', color: 'white', borderRadius: 25 },
  set: { 
    color: 'white',
    backgroundColor: '#ff6315', 
    '&:hover': { backgroundColor: '#ff6315', color: 'white' },
  },
}));

interface IProps {
  currency: "USD" | "COP"
  changeCurrency: (currency: "USD" | "COP") => void 
}

const Currencies: FC<IProps> = ({currency, changeCurrency}) => {
  const classes = useStyles();

  const selectUsd = () => changeCurrency("USD");
  const selectCop = () => changeCurrency("COP");

  return (
    <Grid className={classes.currency} container>
      <Grid className={classes.left} item xs={6}>
        <Button 
          className={clsx(classes.btn, currency === "USD" && classes.set)} 
          variant="contained" 
          onClick={selectUsd}
          fullWidth
        >
          USD
        </Button>
      </Grid>
      <Grid className={classes.right} item xs={6}>
        <Button 
          className={clsx(classes.btn, currency === "COP" && classes.set)} 
          variant="contained" 
          onClick={selectCop}
          fullWidth
        >
          COP
        </Button>
      </Grid>

      {/* <Grid className={classes.left} item xs={6}>
        <Button className={classes.btn} variant="contained" fullWidth>
          Monthly
        </Button>
      </Grid>
      <Grid className={classes.right} item xs={6}>
        <Button className={classes.btn} variant="contained" fullWidth>
          Give once
        </Button>
      </Grid> */}
    </Grid>
  )
}

export default Currencies;
