import React, { FC } from 'react';
import clsx from 'clsx';
import { makeStyles, Grid, Typography, Button } from '@material-ui/core';
import { history } from '../../configureStore';
import Background  from '../Common/Background';
import MenuApps from './MenuApps';
import { IUser } from "dto/user.dto";

const useStyles = makeStyles(() => ({
  cover: {
    position: 'relative',
    height: 'calc(100vh - 60px)', 
    color: 'white',
    overflow: 'auto',
  },
  content: { position: 'relative' },
  item: { padding: '0 60px 0 15px' },
  info: { marginBottom: 15 },
  btn: {
    width: 200,
    fontSize: 20,
    border: '3px solid white',
    borderRadius: 50,
    textTransform: 'capitalize',
  },
  exp: { color: 'white' },
  use: { 
    backgroundColor: '#ff6315', 
    color: 'white', 
    '&:hover': { backgroundColor: 'white', borderColor: '#ff6315', color: '#ff6315' },
  },
}));

interface IProps { user: IUser }

const Presentation: FC<IProps> = ({user}) => {
  const classes  = useStyles();

  const openWebApp = () => {
    if (!!user.nit) history.push('/home');
    else history.push('/');
  }

  return (
    <Grid className={classes.cover} container alignItems="center">
      <Background backgroundImage="/images/landing_checkout.svg" />
      <Grid className={classes.content} container>
        <MenuApps />

        <Grid className={classes.item} item xs={6}>
          <Typography className={classes.info} variant="h1" align="right">
            <b>Fórmate con O-lab</b>
          </Typography>
          <Typography className={classes.info} variant="subtitle1" align="justify">
            <b>O-lab</b> is a LMS and customizable web & mobile app
            that facilites access to educational content and projects created
            by organizations, corporations and schools for learners worldwide.
          </Typography>
          <Grid container justifyContent="flex-end">
            <Button 
              variant="contained" 
              className={clsx(classes.btn, classes.exp)} 
              onClick={openWebApp}
            >
              Explora O-lab
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Presentation;
