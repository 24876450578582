const svgImage = (c1: String) => `
  <svg width="650" height="150" viewBox="0 0 652 149" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M-0.00982666 97.391C65.9753 146.424 156.715 134.676 243.459 111.33C330.164 87.9929 403.316 149.547 482.07 148.654C560.834 147.752 608.687 105.887 652 102.754V-0.0104065H6.10352e-05V97.391H-0.00982666Z"
      fill="${c1}"
    />
  </svg>
`;

export const SvgShape07 = (c1?: string) => {
  const a = !!c1 ? c1 : "#fc883d";
  return `data:image/svg+xml;charset=UTF-8;base64,${btoa(svgImage(a))}`;
};

export default SvgShape07;
