import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import FormButton from "components/Form/FormButton";

const useStyles = makeStyles((theme) => ({
  without: { 
    height: "100%",
    padding: "32px 0",
    textAlign: "center", 
    justifyContent: "center", 
  },
  content: {
    height: "100%",
    overflow: "auto",
    "& img": { height: "calc(100% - 165px)" }
  },
  h5: { 
    color: "#5f5f5f", 
    maxWidth: 570,
    lineHeight: 1.2,
    fontWeight: 700, 
    marginBottom: 16,
  },
  subtitle: { 
    color: "#929292", 
    fontSize: 18,
    maxWidth: 570,
    fontWeight: 500, 
    lineHeight: 1.2,
    marginBottom: 24,
  },
  footer: { maxWidth: 570, justifyContent: "center" },
}));

const WithoutSponsor = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const demo = "/survey/public/1033728361682014723807";

  return (
    <Grid className={classes.without} container>
      <Grid className={classes.content} item>
        <img src="/gif/home/user_without_sponsor.gif" alt="" />
        <Typography className={classes.h5} variant="h5" gutterBottom>
          {t("Personaliza O-lab para tu organización")}
        </Typography>
        <Typography className={classes.subtitle}>
          {(`${t("Lleva tus entrenamientos, inducciones, comunicaciones y más al siguiente nivel")}.`)}
        </Typography>
        <Grid className={classes.footer} container>
          <Link to={demo} rel="noopener noreferrer" target="_blank">
            <FormButton primary minWidth={300}>
              {t("Solicitar demo")}
            </FormButton>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default WithoutSponsor;
