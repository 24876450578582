import React, { FC } from "react";
import { ITaskRequest } from "dto/user.dto";
import { IQuestion, IQuestionAnswer, IQuestionFile } from "dto/question.dto";
import * as utils from "utils";
import QuestionNotebook from "components/Question/QuestionNotebook";

interface IProps {
  path: string;
  zoom: boolean;
  questions: IQuestion[];
  headerColor: string;
  languageAudio: string;
  backgroundColor: string;
  progressBarColor: string;
  buttonNextColorText: string;
  updateTask: (task: ITaskRequest) => void;
  zoomOnClick: () => void;
  openUnitSteps: () => void;
  handleAnalytics: (name: string, params?: { [key: string]: string; }) => void;
  openDialogSuccess: () => void;
  updateQuestionIndex?: (index: number) => void;
}

const NotebookQuestion: FC<IProps> = (props) => {
  const saveQuestionsAnswers = (
    questions: IQuestion[], 
    questionsFiles: IQuestionFile[],
    questionsAnswers: IQuestionAnswer[], 
  ) => {
    const taskQuestions = questions?.map((question, i) => {
      return utils.getTaskQuestion(
        questionsAnswers[i].answer,
        question,
        questionsFiles[i]?.file,
      );
    });
    const answersIsGradable = questionsAnswers?.filter((answer) => (answer.is_gradable));
    if (!!answersIsGradable?.length) {
      const answersWithRate = answersIsGradable.map((answerIsGradable) => (
        !!utils.getAnswerRating(
          answerIsGradable.answer, 
          answerIsGradable.question,
        )
      ));
      const answersCorrect = answersWithRate.filter((rate) => (!!rate));
      const questionsIsGradable = utils.getQuestionsIsGradable(questions);
      const rate = Math.round(answersCorrect?.length / questionsIsGradable?.length * 5);
      props.updateTask({ rate, is_gradable: true, questions: taskQuestions });
    } else props.updateTask({ rate: 5, questions: taskQuestions, is_gradable: false, });
    props.openDialogSuccess();
  }

  return (
    <QuestionNotebook
      path={props.path}
      zoom={props.zoom}
      questions={props.questions}
      headerColor={props.headerColor}
      languageAudio={props.languageAudio}
      backgroundColor={props.backgroundColor}
      progressBarColor={props.progressBarColor}
      buttonNextColorText={props.buttonNextColorText}
      handleAnalytics={props.handleAnalytics}
      buttonZoomOnClick={props.zoomOnClick}
      updateQuestionIndex={props.updateQuestionIndex}
      saveQuestionsAnswers={saveQuestionsAnswers}
      closeQuestionNotebook={props.openUnitSteps}
    />
  )
}

export default NotebookQuestion;
