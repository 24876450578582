import { EmailActionType, EmailState } from "./types";
import { emailInit, newMessagesInit } from "initials/email.init";
import * as types from "./types";

export const EMAIL_INITIAL_STATE: EmailState = {
  emails: [],
  contacts: [],
  newMessages: newMessagesInit,
  emailCurrent: emailInit,
  emailsLoading: false,
  contactsLoading: false,
  dialogDateBirth: false,
  emailNoteLoading: false,
}

export const email = (prevState = EMAIL_INITIAL_STATE, action: EmailActionType) => {
  switch (action.type) {
    case types.EMAILS: return { ...prevState, emails: action.payload }
    case types.CONTACTS: return { ...prevState, contacts: action.payload }
    case types.NEW_MESSAGES: return { ...prevState, newMessages: action.payload }
    case types.EMAIL_CURRENT: return { ...prevState, emailCurrent: action.payload }
    case types.EMAILS_LOADING: return { ...prevState, emailsLoading: action.payload }
    case types.CONTACTS_LOADING: return { ...prevState, contactsLoading: action.payload }
    case types.DIALOG_DATE_BIRTH: return { ...prevState, dialogDateBirth: action.payload }
    case types.EMAIL_NOTE_LOADING: return { ...prevState, emailNoteLoading: action.payload }

    default: return prevState
  }
}
