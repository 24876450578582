import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Avatar, Grid, Typography } from "@material-ui/core";
import { history } from "configureStore";
import FormButton from "components/Form/FormButton";

const useStyles = makeStyles((theme) => ({
  profile: { 
    height: "100%",
    padding: 32,
    overflow: "auto",
    justifyContent: "center",
    backgroundColor: "white",
  },
  content: { 
    height: "calc(100% + 16px)", 
    textAlign: "center", 
  },
  image: { height: "calc(100% - 284px)", justifyContent: "center" },
  item: {  maxWidth: 440,  },
  avatar: { 
    width: "fit-content", 
    height: "100%", 
    borderRadius: 0, 
  },
  h5: { color: "#5f5f5f", fontWeight: 700 },
  subtitle: {
    color: "#929292",
    fontSize: 18,
    fontWeight: 500,
  },
}));

const ProfileAnonymous = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const signIn = () => history.push("/sign-in");
  const createAccount = () => history.push("/create-account");

  return (
    <Grid className={classes.profile} container>
      <Grid className={classes.content} container justifyContent="center" spacing={2}>
        <Grid className={classes.image} container>
          <Avatar 
            className={classes.avatar} 
            src="/img/profile/anonymous.svg" 
            alt="" 
          />
        </Grid>
        <Grid className={classes.item} item>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.h5} variant="h5">
                {t("Crea tu perfil ahora")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.subtitle}>
                {t("Te recomendamos crear una nueva cuenta para tener tu propio perfil, además de guardar el progreso de tus contenidos, compartir en la comunidad, chatear y mucho más")}.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormButton primary fullWidth onClick={createAccount}>
                {t("Crear cuenta")}
              </FormButton>
            </Grid>
            <Grid item xs={12}>
              <FormButton fullWidth onClick={signIn}>
                {t("Iniciar sesión")}
              </FormButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ProfileAnonymous;
