import { SurveyState, SurveyActionType } from './types';
import * as types from './types';
import { surveyInit } from 'initials/survey.init';

export const SURVEY_INITIAL_STATE: SurveyState = {
  surveys: [],
  surveyPublic: surveyInit,
  surveyCurrent: surveyInit,
  surveyLoading: true,
  surveysLoading: true,
  saveAnswerLoading: false,
  surveyPublicLoading: true,
  dialogCongratulations: false,
}

export const survey = (prevState = SURVEY_INITIAL_STATE, action: SurveyActionType) => {
  switch (action.type) {
    case types.SURVEYS: 
      return { ...prevState, surveys: action.payload };

    case types.PUBLIC_SURVEY: 
      return { ...prevState, surveyPublic: action.payload };

    case types.SURVEY_CURRENT: 
      return { ...prevState, surveyCurrent: action.payload };

    case types.SURVEY_LOADING: 
      return { ...prevState, surveyLoading: action.payload };

    case types.SURVEYS_LOADING: 
      return { ...prevState, surveysLoading: action.payload };

    case types.SAVE_ANSWER_LOADING: 
      return { ...prevState, saveAnswerLoading: action.payload };

    case types.PUBLIC_SURVEY_LOADING:
      return { ...prevState, surveyPublicLoading: action.payload };
    
    case types.DIALOG_CONGRATULATIONS:
      return { ...prevState,  dialogCongratulations: action.payload };

    default: 
      return prevState;
  }
}
