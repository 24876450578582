import React, { FC, useState } from "react";
import PhoneNumber from "components/Login/PhoneNumber";
import CodeVerification from "components/Login/CodeVerification";

interface IProps {
  confirm: any;
  loading: boolean;
  confirmCode: any;
  sendPhoneNumber: (phoneNumber: string, reCAPTCHA: any) => void;
  sendCodeVerification: (code: string) => void;
}

const SignInWithPhone: FC<IProps> = (props) => {
  const [phoneNumber, setPhoneNumber] = useState("");

  return (
    !!props.confirmCode ? (
      <></>
    ) : (
      <>
        {!!props.confirm ? (
          <CodeVerification 
            loading={props.loading}   
            phoneNumber={phoneNumber} 
            sendCodeVerification={props.sendCodeVerification} 
          />
        ) : (
          <PhoneNumber 
            loading={props.loading}
            getPhoneNumber={setPhoneNumber} 
            sendPhoneNumber={props.sendPhoneNumber}
          />
        )}
      </>
    )
  )
}

export default SignInWithPhone;
