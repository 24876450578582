import React, { FC } from "react";
import useImage from "use-image";
import { Image } from "react-konva";
import { ITemplateItem } from "dto/template.dto";
import { SvgStar } from "./Svg/SvgStar";
import { SvgCircle } from "./Svg/SvgCircle";
import { SvgSquare } from "./Svg/SvgSquare";
import { SvgOLab01 } from "./Svg/SvgOLab01";
import { SvgOLab02 } from "./Svg/SvgOLab02";
import { SvgShape04 } from "./Svg/SvgShape04";
import { SvgShape05 } from "./Svg/SvgShape05";
import { SvgShape06 } from "./Svg/SvgShape06";
import { SvgShape07 } from "./Svg/SvgShape07";
import { SvgShape08 } from "./Svg/SvgShape08";
import { SvgTriangle } from "./Svg/SvgTriangle";

interface IProps {
  templateItem: ITemplateItem;
}

const TemplateItemShapeSingleColor: FC<IProps> = (props) => {
  const getImage = () => {
    const fill = props.templateItem?.fill;
    const type = props.templateItem.type_shape;
    if (type === "star") return SvgStar(fill);
    else if (type === "circle") return SvgCircle(fill);
    else if (type === "square") return SvgSquare(fill);
    else if (type === "triangle") return SvgTriangle(fill);
    else if (type === "o_lab_01") return SvgOLab01(fill);
    else if (type === "o_lab_02") return SvgOLab02(fill);
    else if (type === "shape_04") return SvgShape04(fill);
    else if (type === "shape_05") return SvgShape05(fill);
    else if (type === "shape_06") return SvgShape06(fill);
    else if (type === "shape_07") return SvgShape07(fill);
    else if (type === "shape_08") return SvgShape08(fill);
    else return "";
  };
  const [image] = useImage(getImage(), "anonymous");

  return (
    <Image
      x={props.templateItem.x}
      y={props.templateItem.y}
      id={props.templateItem.id}
      image={image}
      width={props.templateItem.width}
      height={props.templateItem.height}
      scaleX={props.templateItem?.scaleX}
      scaleY={props.templateItem?.scaleY}
    />
  );
};

export default TemplateItemShapeSingleColor;
