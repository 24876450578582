import { IModule } from "dto/module.dto";
import { AppThunkAction } from "store/types";
import { ModuleActionType } from "./actions";

export * from "./actions";
export * from "./constants";

export interface ModuleState {
  module: IModule;
  moduleFree: IModule;
}

export type Action = AppThunkAction<ModuleActionType>;
