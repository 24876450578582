import firebase from "firebase/app";
import "firebase/database";
import { INote } from "dto/note.dto";

class Note {
  async post(note: INote) {
    await firebase.database().ref(`notes/${note.uid}`).set(note);
    return true;
  };

  async update(note: INote, req: any) {
    await firebase.database().ref(`notes/${note.uid}`).update(req);
    return true;
  };

  async remove(note: INote) {
    await firebase.database().ref(`notes/${note.uid}`).remove();
    return true;
  };
}

const note = new Note();
const NoteClass = Object.freeze(note);
export default NoteClass;
