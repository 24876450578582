import Country from "services/country.services";
import { ICountry } from "dto/country.dto";
import { Action } from "./types";
import * as types from "./types";

export const getCountries = (): Action => async (dispatch) => {
  dispatch({ type: types.COUNTRIES_LOADING, payload: true });
  try {
    const countries = await Country.get();
    dispatch(setCountries(countries));
  } catch(e) { 
    dispatch(setCountries([]));
    console.log("Error Get Countries")
  }
  dispatch({ type: types.COUNTRIES_LOADING, payload: true });
}

const setCountries = (countries: ICountry[]): Action => (dispatch) => {
  dispatch({ type: types.COUNTRIES, payload: countries });
}
