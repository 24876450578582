import { ILanguage } from "dto/layout.dto";

export const languageInit: ILanguage = { code: "es", flag: "ES" };

export const languages: ILanguage[] = [
  { code: "es", flag: "ES" },
  { code: "en", flag: "US" },
  { code: "ur", flag: "PK" },
  { code: "fr", flag: "FR" },
  { code: "fil", flag: "PH" },
  { code: "ar", flag: "AR" },
]
