import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { IQuestion, IQuestionPreview } from "dto/question.dto";
import * as utils from "utils/question.utils";

const useStyles = makeStyles(() => ({
  root: { position: "relative", minHeight: 100 },
  bubble: {
    left: 0,
    width: "100%",
    height: 100,
    bottom: 0,
    position: "absolute",
    borderRadius: 16,
  },
  text: { 
    width: "calc(100% - 100px)", 
    padding: "4px 12px",
    position: "relative",
    alignItems: "center",
    "& .MuiTypography-root": { lineHeight: 1.3 },
  },
  body1: { 
    color: "#5f5f5f", 
    height: "100%",
    overflow: "hidden",
    maxHeight: 45,
    fontWeight: 500, 
  },
  description: {
    color: "#5f5f5f",
    height: "100%",
    fontSize: 18,
    overflow: "hidden",
    maxHeight: 45,
  },
  image: {
    width: 100,
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    "& img": { maxWidth: "100%", maxHeight: "100%" },
  },
  circular: { color: "#ff6300" }
}));

interface IProps {
  preview: IQuestionPreview;
  question: IQuestion;
  answerRating?: boolean;
  viewFeedback?: boolean;
  languageAudio?: string;
}

const QuestionBubble: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { header_color: backgroundColor } = props.question;
  const [bubbleImage, setBubbleImage] = useState("");
  const audioSrc = !!props.answerRating && props.languageAudio === "1"
    ? props.preview?.feedback_properties?.complete_audio_sl
    : !!props.answerRating && props.languageAudio !== "1"
    ? props.preview?.feedback_properties?.complete_audio_fl
    : props.languageAudio === "1"
    ? props.preview?.feedback_properties?.incomplete_audio_sl
    : props.preview?.feedback_properties?.incomplete_audio_fl;

  useEffect(() => {
    setBubbleImage("");
    const img = questionImageHeader();
    const timer = setTimeout(() => { setBubbleImage(img); }, 500);

    return () => clearTimeout(timer);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.question, props.preview, props.viewFeedback]);

  const questionSubtitle = () => {
    if (!!props.viewFeedback && !!props.answerRating) {
      return !!props.question?.feedback_properties?.complete_text_one 
        ? props.question.feedback_properties.complete_text_one 
        : "Felicitaciones";
    } else if (!!props.viewFeedback && !props.answerRating) {
      return !!props.question?.feedback_properties?.incomplete_text_one 
        ? props.question.feedback_properties.incomplete_text_one 
        : "Incorrecta";
    } else {
      return !!props.question?.subtitle 
        ? props.question.subtitle 
        : utils.getQuestionSubtite(props.question?.type).subtitle_header;
    }
  }
  const questionDescription = () => {
    if (!!props.viewFeedback && !!props.answerRating) {
      return !!props.question?.feedback_properties?.complete_text_two 
        ? props.question.feedback_properties.complete_text_two 
        : "Continúa asi";
    } else if (!!props.viewFeedback && !props.answerRating) {
      return !!props.question?.feedback_properties?.incomplete_text_two 
        ? props.question.feedback_properties.incomplete_text_two 
        : "No te descuides";
    } else {
      return !!props.question?.description 
        ? props.question?.description
        : utils.getQuestionSubtite(props.question?.type).description_header;
    }
  }
  const questionImageHeader = () => {
    const feedback = !!props.question?.feedback && !!props.question?.feedback_image;
    if (!!feedback && !!props.viewFeedback && !!props.answerRating) {
      return props.preview?.feedback_properties?.complete_image ?? "";
    } else if (!!feedback && !!props.viewFeedback && !props.answerRating) {
      return props.preview?.feedback_properties?.incomplete_image ?? "";
    } else return props.preview?.image_header ?? "";
  }

  return (
    <Grid className={classes.root} container>
      <Grid className={classes.bubble} item style={{ backgroundColor }} />

      <Grid className={classes.image} container>
        {bubbleImage ? (
          <img className={classes.image} src={bubbleImage} alt="" />
        ) : (
          <CircularProgress className={classes.circular} />
        )}
      </Grid>

      <Grid className={classes.text} item container>
        <Grid item xs={12}>
          <Typography className={classes.body1}>
            {t(questionSubtitle())}
          </Typography>

          <Typography className={classes.description}>
            {t(questionDescription())}
          </Typography>
        </Grid>
      </Grid>

      {!!props.question?.feedback && 
        !!props.question?.feedback_image && 
        !!props.viewFeedback && 
      (
        <audio id="survey_private_question_audio" src={audioSrc} autoPlay>
          Your browser does not support the <code>audio</code> element.
        </audio>
      )}
    </Grid>
  )
}

export default QuestionBubble;
