import React, { FC, useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, Grid, Typography } from "@material-ui/core";
import { IAlphabetSoupResolvePath, IQuestion, IQuestionPreview } from "dto/question.dto";
import { soundClick, alphabetSoupInit } from "utils";
import Information from "components/Question/QuestionInformation/Information";

const useStyles = makeStyles((theme) => ({
  question: { 
    height: "100%",
    flexWrap: "initial",
    [theme.breakpoints.down("xs")]: { display: "block" },
  },
  word: {
    width: "100%",
    border: "1px solid #707070",
    padding: 4,
    textAlign: "center",
    borderRadius: 15,
  },
  wordFound: {
    color: "white",
    borderColor: "#481870",
    backgroundColor: "#481870",
  },
  divider: { 
    width: 2, 
    backgroundColor: "#f0f0f0",
    [theme.breakpoints.down("xs")]: { display: "none" }, 
  },
  alphabetSoup: { 
    alignItems: "center", 
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: { padding: 16 }
  },
  soupContent: { 
    border: "1px solid #707070", 
    padding: 3, 
    borderRadius: 15, 
  },
  letter: {
    width: 32,
    height: 32,
    margin: 3,
    cursor: "pointer",
    border: "1px solid white",
    fontSize: 20,
    textAlign: "center",
    borderRadius: 5,
  },
  disabled: { color: "rgba(0, 0, 0, 0.26)", cursor: "no-drop" },
  found : { color: "white", backgroundColor: "#481870" },
  select: { 
    color: "black",
    border: "1px solid #481870",
    backgroundColor: "white",
  },
}));

interface ILetterSelect {
  name: string,
  posX: number,
  posY: number,
}

interface IWordFound {
  word: string;
  letters: ILetterSelect[];
} 

interface IProps {
  book?: boolean;
  answer: string;
  preview: IQuestionPreview;
  question: IQuestion;
  answerRating: boolean;
  viewFeedback: boolean;
  languageAudio: string;
  questionNumber: number;
  questionsTotal: number;
  progressBarColor: string;
  selectAnswer: (a: string) => void;
}

const Type05: FC<IProps> = (props) => {
  const classes = useStyles();
  const { answer, question, viewFeedback } = props;
  const [wordsFound, setWordsFound] = useState<IWordFound[]>([]);
  const [alphabetSoup, setAlphabetSoup] = useState<string[][]>([]);
  const [lettersSelect, setLettersSelect] = useState<ILetterSelect[]>([]);
  const alphabetAnswer = props.question?.alphabet_answer ?? [];
  const alphabetSoupResolve = props.question?.alphabet_soup_resolve ?? []

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { getWordsFound(); }, [alphabetSoupResolve]);
  
  useEffect(() => { 
    let auxSoup = alphabetSoupInit, aux = 0;

    for (let i = 0; i < 6; i++) {
      for (let j = 0; j < 8; j++) {
        auxSoup[i][j] = question.question[aux];
        aux = aux + 1;
      }
    }
    setAlphabetSoup(auxSoup);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { if (viewFeedback) setLettersSelect([]); }, [viewFeedback]);

  const getWordsFound = () => {
    if (!!answer && !!alphabetSoupResolve && (!wordsFound || !wordsFound.length)) {
      const answersAux = answer.split(",");
      const wordsFoundAux: IWordFound[] = [];
      for (let i = 0; i < answersAux.length; i++) {
        for (let j = 0; j < alphabetSoupResolve.length; j++) {
          if (answersAux[i] === alphabetSoupResolve[j].word) {
            const wordsSelect: ILetterSelect[] = [];
            for (let k = 0; k < alphabetSoupResolve[j].path.length; k++) {
              wordsSelect.push({
                name: answersAux[i][k],
                posX: alphabetSoupResolve[j].path[k].y,
                posY: alphabetSoupResolve[j].path[k].x,
              });
            }
            wordsFoundAux.push({word: answersAux[i], letters: wordsSelect});
          }
        }
      }
      setWordsFound(wordsFoundAux);
    }
  }

  const selectLetter = (name: string, posX: number, posY: number) => {
    if (!lettersSelect.length) {
      setLettersSelect([...lettersSelect, {name, posX, posY}]);
    } else if (lettersSelect.length === 1) {
      if (lettersSelect[0].posX === posX && lettersSelect[0].posY === posY) {
        setLettersSelect([]);
      } else if (
        (lettersSelect[0].posX === posX && lettersSelect[0].posY - 1 === posY) || 
        (lettersSelect[0].posY === posY && lettersSelect[0].posX - 1 === posX) ||
        (lettersSelect[0].posX - 1 === posX && lettersSelect[0].posY - 1 === posY) ||
        (lettersSelect[0].posX - 1 === posX && lettersSelect[0].posY + 1 === posY)
      ) {
        const aux: ILetterSelect[] = [{name, posX, posY}];
        aux.push(lettersSelect[0]);
        setLettersSelect(aux);
      }
      else if (
        (lettersSelect[0].posX === posX && lettersSelect[0].posY + 1 === posY) || 
        (lettersSelect[0].posY === posY && lettersSelect[0].posX + 1 === posX) ||
        (lettersSelect[0].posX + 1 === posX && lettersSelect[0].posY + 1 === posY) ||
        (lettersSelect[0].posX + 1 === posX && lettersSelect[0].posY - 1 === posY)
      ) {
        setLettersSelect([...lettersSelect, {name, posX, posY}])
        searchWord([...lettersSelect, {name, posX, posY}])
      }
    } else {
      const diff = lettersSelect.length - 1
      const init = lettersSelect[0], fini = lettersSelect[diff];
      if (init.posX === posX && init.posY === posY) {
        const aux = []
        for (let k = 1; k < lettersSelect.length; k++) {
          aux.push(lettersSelect[k]);
        }
        setLettersSelect(aux);
        searchWord(aux);
      } else if (fini.posX === posX && fini.posY === posY) {
        const aux = []
        for (let k = 0; k < (lettersSelect.length - 1); k++) {
          aux.push(lettersSelect[k]);
        }
        setLettersSelect(aux);
        searchWord(aux);
      } else if (init.posX === fini.posX) {
        if (init.posX === posX && init.posY - 1 === posY) {
          const aux: ILetterSelect[] = [{name, posX, posY}];
          for (let k = 0; k < lettersSelect.length; k++) {
            aux.push(lettersSelect[k]);
          }
          setLettersSelect(aux);
          searchWord(aux);
        } else if (fini.posX === posX && fini.posY + 1 === posY) {
          setLettersSelect([...lettersSelect, {name, posX, posY}]);
          searchWord([...lettersSelect, {name, posX, posY}]);
        }
      } else if (init.posY === fini.posY) {
        if (init.posY === posY && init.posX - 1 === posX) {
          const aux: ILetterSelect[] = [{name, posX, posY}];
          for (let k = 0; k < lettersSelect.length; k++) {
            aux.push(lettersSelect[k]);
          }
          setLettersSelect(aux)
          searchWord(aux);
        } else if (fini.posY === posY && fini.posX + 1 === posX) {
          setLettersSelect([...lettersSelect, {name, posX, posY}]);
          searchWord([...lettersSelect, {name, posX, posY}]);
        }
      } else if (init.posX + diff === fini.posX && init.posY + diff === fini.posY) {
        if (init.posX - 1 === posX && init.posY - 1 === posY) {
          const aux: ILetterSelect[] = [{name, posX, posY}];
          for (let i = 0; i < lettersSelect.length; i++) {
            aux.push(lettersSelect[i]);
          }
          setLettersSelect(aux)
          searchWord(aux);
        } else if (fini.posX + 1 === posX && fini.posY + 1 === posY) {
          setLettersSelect([...lettersSelect, {name, posX, posY}]);
          searchWord([...lettersSelect, {name, posX, posY}]);
        }
      } else if (init.posX + diff === fini.posX && init.posY - diff === fini.posY) {
        if (init.posX - 1 === posX && init.posY + 1 === posY) {
          const aux: ILetterSelect[] = [{name, posX, posY}];
          for (let i = 0; i < lettersSelect.length; i++) {
            aux.push(lettersSelect[i]);
          }
          setLettersSelect(aux);
          searchWord(aux);
        } else if (fini.posX + 1 === posX && fini.posY - 1 === posY) {
          setLettersSelect([...lettersSelect, {name, posX, posY}]);
          searchWord([...lettersSelect, {name, posX, posY}]);
        }
      }
    }
  }

  const markAnswer = (name: string, x: number, y: number) => {
    for (let i = 0; i < lettersSelect.length; i++) {
      if (
        lettersSelect[i].name === name &&
        lettersSelect[i].posX === x && 
        lettersSelect[i].posY === y
      ) {
        return true
      }
    }
    return false;
  }
  const markWords = (name: string, posX: number, posY: number) => {
    for (let i = 0; i < wordsFound.length; i++) {
      for (let j = 0; j < wordsFound[i].letters.length; j++) {
        if (
          wordsFound[i].letters[j].name === name &&
          wordsFound[i].letters[j].posX === posX && 
          wordsFound[i].letters[j].posY === posY
        ) return true
      }
    }
    return false;
  }
  const markWords2 = (word: string) => {
    for (let i = 0; i < wordsFound.length; i++) {
      if (wordsFound[i].word === word) return true;
    }
    return false;
  }

  const searchWord = (letters: ILetterSelect[]) => {
    let word1 = "", word2 = "";

    for (let i = 0; i < letters.length; i++) {
      word1 = word1 + letters[i].name;
    }
    for (let i = letters.length - 1; i >= 0; i--) {
      word2 = word2 + letters[i].name;
    }

    for (let i = 0; i < wordsFound.length; i++) {
      if (word1 === wordsFound[i].word) return;
    }
    for (let i = 0; i < wordsFound.length; i++) {
      if (word2 === wordsFound[i].word) return;
    }
    
    for (let i = 0; i < alphabetSoupResolve.length; i++) {
      const bandera = searchWordResolve(alphabetSoupResolve[i].path, letters);
      if (!!bandera) {
        saveAnswer([...wordsFound, {word: alphabetSoupResolve[i].word, letters}]);
        setWordsFound([...wordsFound, {word: alphabetSoupResolve[i].word, letters}]);
        setLettersSelect([]);
        return;
      }
    }
  }

  const saveAnswer = (wordsFound: IWordFound[]) => {
    if (
      !!wordsFound && 
      !!wordsFound.length &&
      !!alphabetAnswer && 
      !!alphabetAnswer.length
    ) {
      const answers: string[] = wordsFound.map((w) => (w.word));
      props.selectAnswer(String(answers));
    }
  }

  const searchWordResolve = (path: IAlphabetSoupResolvePath[], letters: ILetterSelect[]) => {
    const px = path.map((p) => (p.x));
    const py = path.map((p) => (p.y));
    const lx = letters.map((letter) => (letter.posY));
    const ly = letters.map((letter) => (letter.posX));
    const length = px.length === lx.length && py.length === ly.length;
    const string = (String(px) === String(lx) && String(py) === String(ly)) ||
    (String(px) === String(lx.reverse()) && String(py) === String(ly.reverse()));

    if (length && string) return true;
    else return false;
  }

  return (
    <Grid className={classes.question} container>
      <Information 
        preview={props.preview}
        question={props.question}
        answerRating={props.answerRating}
        viewFeedback={props.viewFeedback}
        languageAudio={props.languageAudio}
        questionNumber={props.questionNumber}
        questionsTotal={props.questionsTotal}
        progressBarColor={props.progressBarColor}
      >
        <Grid container spacing={1}>
          {!!alphabetAnswer && alphabetAnswer.map((alphabet, i) => (
            <Grid key={i} item xs={6}>
              <Typography 
                className={clsx(
                  classes.word, 
                  markWords2(alphabet) && classes.wordFound
                )}
              >
                {alphabet}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Information>

      <Divider className={classes.divider} orientation="vertical" flexItem />

      <Grid className={classes.alphabetSoup} container>
        <Grid className={classes.soupContent} item>
          {alphabetSoup.map((column, x) => (
            <Grid key={x} container justifyContent="center">
              {column.map((row, y) => (
                <Typography 
                  className={clsx(
                    classes.letter, 
                    viewFeedback && classes.disabled,
                    markWords(row, x, y) && classes.found,
                    markAnswer(row, x, y) && classes.select,
                  )} 
                  key={`${x}${y}`} 
                  onClick={() => selectLetter(row, x, y)}
                  onMouseDown={soundClick}
                >
                  {row}
                </Typography>
              ))}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Type05;
