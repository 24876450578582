import { HomeState, HomeActionType } from "./types";
import * as types from "./types";

export const HOME_INITIAL_STATE: HomeState = {
  zoom: false,
  // contents: [],
  background: "",
  // contentsFree: [],
  modalSuccess: false,
  dialogTutorial: false,
  // contentsFreeLoading: false,
}

export const home = (prevState = HOME_INITIAL_STATE, action: HomeActionType) => {
  switch (action.type) {
    case types.ZOOM: 
      return { ...prevState, zoom: action.payload }
    case types.BACKGROUND: 
      return { ...prevState, background: action.payload }
    case types.MODAL_SUCCESS: 
      return { ...prevState, modalSuccess: action.payload }
    case types.DIALOG_TUTORIAL: 
      return { ...prevState, dialogTutorial: action.payload }

    default: 
      return prevState
  }
}
