import React, { FC, useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Select, MenuItem } from "@material-ui/core";
import { soundClick } from "utils";
import FeedbackText from "../FeedbackText";

const useStyles = makeStyles(() => ({
  question: { height: "100%", padding: 16, alignItems: "center" },
  text: { 
    fontSize: "1rem", 
    textAlign: "initial",
    alignItems: "center", 
    "& p": { margin: 0, fontSize: "1rem" }, 
  },
  select: {
    margin: 2,
    textAlign: "left",
    borderRadius: 50,
    "& .MuiSelect-select": { padding: "5px 30px 5px 15px", minWidth: 50 },
  },
  correct: { 
    color: "#50c988 !important", 
    "& fieldset": { borderColor: "#50c988 !important" },
  },
  incorrect: { 
    color: "#f25b5b !important", 
    "& fieldset": { borderColor: "#f25b5b !important" },
  },
}));

interface IProps {
  answer: string;
  answers: string[];
  question: string;
  notFeedback?: boolean;
  answerRating: boolean;
  viewFeedback: boolean;
  selectAnswer: (answer: string) => void;
}

const Type03: FC<IProps> = (props) => {
  const classes = useStyles();
  const { answer, answers, answerRating, question, viewFeedback } = props;
  const [questionsAux, setQuestionsAux] = useState<string[]>([]);

  useEffect(() => {
    const ques = question.split("#?#").join("|");
    
    let count = 0;
    for (let i = 0; i < ques.length; i++) { 
      if (ques[i].toLowerCase() === "|") count++; 
    }
    
    const quesAux: string[] = [];
    for (let i = 0; i <= count; i++) quesAux.push("");
    let index = 0;
    for (let i = 0; i < ques.length; i++) {
      if (ques[i].toLowerCase() !== "|") {
        quesAux[index] = quesAux[index] + ques[i];
      } else index = index + 1;
    }
    setQuestionsAux(quesAux);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question]);

  const selectAnswer = (e: React.ChangeEvent<{ value: unknown}>) => {
    props.selectAnswer(e.target.value as string);
  };

  const getQuestionText = (html: string) => {
    return <span dangerouslySetInnerHTML={{__html: html}} />;
  }

  return (
    <Grid className={classes.question} container>
      <Grid item xs={12}>
        {false && (<FeedbackText correct={answerRating} feedback={viewFeedback} />)}

        <Grid className={classes.text} container>
          {questionsAux.map((item, i) => (
            <div key={i} style={{ display: "contents" }}>
              {getQuestionText(item)}
              {i < questionsAux.length - 1 && (
                <Select
                  name={i.toString()}
                  className={clsx(
                    classes.select, 
                    viewFeedback && !props.notFeedback && !!answerRating && classes.correct,
                    viewFeedback && !props.notFeedback && !answerRating && classes.incorrect,
                  )}
                  value={answer}
                  variant="outlined" 
                  onChange={selectAnswer}
                  disabled={props.viewFeedback}
                  onMouseDown={soundClick}
                  displayEmpty
                >
                  {answers.map((value, i) => (
                    <MenuItem key={i} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </div>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Type03;
