import React, { FC, MouseEventHandler } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, IconButton } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  button: {
    width: 40,
    height: 40, 
    padding: 2,
    borderRadius: 8,
    "& .MuiAvatar-root": { width: "100%", height: "100%", borderRadius: 0 },
    "&:disabled": { "& .MuiAvatar-root": { filter: "grayscale(1)" }, },
    [theme.breakpoints.down("xs")]: { width: 35, height: 35, },
  },
  select: {
    border: "2px solid #0aa9e2",
    backgroundColor: "#ebfaff",
    "&:hover": { backgroundColor: "#ebfaff" },
    "&:disabled": { borderColor: "grey" },
  },
}));

interface IProps {
  image: string;
  checked?: boolean;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const ButtonLayout: FC<IProps> = (props) => {
  const classes = useStyles();

  return (
    <IconButton
      className={clsx(classes.button, props.checked && classes.select)} 
      onClick={props.onClick} 
      disabled={props.disabled}
    >
      <Avatar src={props.image} alt="" />
    </IconButton>
  )
}

export default ButtonLayout;
