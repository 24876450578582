import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { State } from "store/types";
import { history } from "configureStore";
import LoginMenu from "components/Login/LoginMenu";
import PageLoginCarrusel from "components/Pages/PageLoginCarrusel";

const LoginPage = () => {
  const user = useSelector((state: State) => state.user.user);
  const anonymousUser = localStorage.getItem("anonymousUser");
  const userAnonymous = JSON.parse(anonymousUser ?? "{}");
  const home = !!user.nit || (!!userAnonymous?.uuid && !!userAnonymous?.userType);
  const test = !!user.nit || (!!userAnonymous?.uuid && !userAnonymous?.userType);

  useEffect(() => { 
    if (!!home) history.push("/home");
    // else if (!!test) history.push("/anonymous-user");
  }, [home, test]);

  const openSignIn = () => history.push("/sign-in");
  const openCreateAccount = () => history.push("/create-account");
  const openAnonymousUser = () => history.push("/anonymous-user");

  return (
    <PageLoginCarrusel>
      <LoginMenu 
        openSignIn={openSignIn}
        openAnonymousUser={openAnonymousUser}
        openCreateAccount={openCreateAccount}
      />
    </PageLoginCarrusel>
  )
}

export default LoginPage;
