import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import { object, string } from 'yup';
import { makeStyles, Grid, TextField, Button } from '@material-ui/core';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  form: { '& .MuiInputBase-root': { backgroundColor: 'white' } },
  control: {
    margin: '8px 0 4px',
    '& .MuiInputLabel-outlined': { transform: 'translate(14px, 13px) scale(1)' },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': { 
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& .MuiInputBase-input': { padding: '10.5px 14px' },
  },
  btn: { 
    color: 'white',
    margin: '8px 0 15px',
    borderRadius: 25, 
    backgroundColor: '#ff6300',
    '&:hover': { backgroundColor: 'white', color: '#ff6300' },
  },
}));

interface IInitValues {
  name:      string,
  email:     string,
  phone:     string,
  entity:    string,
  reason:    string,
  message:   string,
  residence: string,
}

const ContactUsForm = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const initValues = { 
    name:      '',
    email:     '',
    phone:     '',
    entity:    '',
    reason:    '',
    message:   '',
    residence: '',
  }
  const validations = object().shape({
    name:      string().required(t('Campo requerido')),
    email:     string().required(t('Campo requerido')).email('example@example.com'),
    phone:     string().required(t('Campo requerido')),
    entity:    string().required(t('Campo requerido')),
    reason:    string().required(t('Campo requerido')),
    message:   string().required(t('Campo requerido')),
    residence: string().required(t('Campo requerido')),
  });
  const onSubmit = async (values: IInitValues) => { }

  return (
    <Formik initialValues={initValues} validationSchema={validations} onSubmit={onSubmit}>
      {({errors, touched}) => (
        <Form>
          <Grid className={classes.form} container>
            <Grid item xs={12}>
              <Field 
                name="name" 
                as={TextField}
                label={t("Nombre y apellido")}
                margin="dense"
                variant="outlined"
                error={errors.name && touched.name}
                helperText={touched.name && errors.name}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl 
                className={classes.control}
                variant="outlined" 
                error={!!errors.reason && !!touched.reason} 
                fullWidth
              >
                <InputLabel id="reason">{t("Asunto")}</InputLabel>
                <Field as={Select} name="reason" label="Asunto" labelId="reason">
                  <MenuItem value={0}>{t("Comprar/renovar licencia")}</MenuItem>
                  <MenuItem value={1}>{t("Servicios adicionales")}</MenuItem>
                  <MenuItem value={2}>{t("Dudas")}</MenuItem>
                  <MenuItem value={3}>{t("Otro")}</MenuItem>
                </Field>
                {touched.reason && (<FormHelperText>{errors.reason}</FormHelperText>)}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Field 
                name="residence" 
                as={TextField}
                label={t("Ciudad/País")}
                margin="dense"
                variant="outlined"
                error={errors.residence && touched.residence}
                helperText={touched.residence && errors.residence}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Field 
                name="email" 
                as={TextField}
                type="email"
                label={t("E-mail")}
                margin="dense"
                variant="outlined"
                error={errors.email && touched.email}
                helperText={touched.email && errors.email}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Field 
                name="phone" 
                as={TextField}
                label={t("Teléfono")}
                margin="dense"
                variant="outlined"
                error={errors.phone && touched.phone}
                helperText={touched.phone && errors.phone}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Field 
                name="entity" 
                as={TextField}
                label={t("Nombre de organización")}
                margin="dense"
                variant="outlined"
                error={errors.entity && touched.entity}
                helperText={touched.entity && errors.entity}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Field 
                name="message" 
                as={TextField}
                rows={4}
                label={t("Mensaje")}
                margin="dense"
                variant="outlined"
                error={errors.message && touched.message}
                helperText={touched.message && errors.message}
                multiline
                fullWidth
              />
            </Grid>
            <Grid container justifyContent="center">
              <Button className={classes.btn} type="submit" variant="contained" fullWidth>
                {t("Enviar")}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default ContactUsForm;
