export interface IStep {
  gif: string;
  pdf: string;
  text: string;
  type: number; //0: normal; 1: video; 2: pdf;
  image: string;
  title: string;
  audio: string;
  video: string;
  pdf_web: string;
  audio_wa: string;
  video_url: string;
  audio_pdf: IAudioPdf[];
  video_type: boolean;
};

export interface IAudioPdf { 
  audio: string; 
  audio_wa: string; 
};

export interface IStepFiles {
  gif: string;
  type: number;
  audio: string;
  pdf_web: string;
  audio_wa: string;
  video_url: string;
  audio_pdf: IAudioPdf[];
};

export const stepFilesInit = {
  gif: "",
  type: 0,
  audio: "",
  pdf_web: "",
  audio_wa: "",
  video_url: "",
  audio_pdf: [],
};
