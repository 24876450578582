import React, { FC, KeyboardEvent } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import { ref, object, string } from "yup";
import FormButton from "components/Form/FormButton";
import TermsConditions from "../TermsConditions";
import FormFieldAsTextField from "components/Form/FormFieldAsTextField";

const useStyles = makeStyles(() => ({
  h5: { color: "#5f5f5f", fontWeight: 700 },
  img: { 
    width: 150, 
    marginBottom: 8, 
  },
}));

interface IInitialValues { 
  email: string; 
  password: string;
  passwordConfirm: string;
}

interface IProps {
  email: string; 
  password: string;
  verifiedEmailError: boolean;
  updateEmail: (email: string) => void;
  verifiedEmail: (email: string) => void;
  updatePassword: (password: string) => void;
  updateVerifiedEmailError: (value: boolean) => void
}

const RegisterEmailVerify: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const initialValues: IInitialValues = { 
    email: props.email, 
    password: props.password,
    passwordConfirm: props.password, 
  };
  
  const validationSchema = object().shape({
    email: string().required(t("Campo requerido")).email("email@olab.com"),
    password: string().required(t("Campo requerido")).min(6, t("Mínimo 6 caracteres")),
    passwordConfirm: string()
      .required(t("Campo requerido"))
      .oneOf([ref("password"), null], t("Las contraseñas deben coincidir")),
  });

  const onSubmit = (values: IInitialValues) => {
    const email = values?.email
      ?.replace(/ /g, "")
      ?.toLowerCase()
      ?.normalize("NFD")
      ?.replace(/[\u0300-\u036f]/g, "");
    props.verifiedEmail(email);
    props.updateEmail(email);
    props.updatePassword(values.password);
  }

  const onFocus = () => {
    if (!!props.verifiedEmailError) {
      props.updateVerifiedEmailError(false);
    }
  }

  const onKeyPress = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === " ") e.preventDefault();
  }

  return (
    <Formik 
      initialValues={initialValues} 
      validationSchema={validationSchema} 
      onSubmit={onSubmit}
    >
      {({errors, touched}) => (
        <Form>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography className={classes.h5} align="center" variant="h5">
                {t("Crea una cuenta")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormFieldAsTextField 
                name="email"
                title="Correo electrónico"
                touched={touched.email || props.verifiedEmailError}
                autoFocus
                fullWidth
                placeholder="Ingresa tu correo"
                onFocus={onFocus}
                onKeyPress={onKeyPress}
                error={
                  !!errors.email ? errors.email 
                  : props.verifiedEmailError ? "El usuario ya está registrado" 
                  : undefined
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormFieldAsTextField 
                name="password"
                type="password"
                error={errors.password}
                title="Contraseña"
                touched={touched.password}
                fullWidth
                placeholder="• • • • • • • • • •"
              />
            </Grid>
            <Grid item xs={12}>
              <FormFieldAsTextField 
                name="passwordConfirm"
                type="password"
                error={errors.passwordConfirm}
                title="Repite tu contraseña"
                touched={touched.passwordConfirm}
                fullWidth
                placeholder="• • • • • • • • • •"
              />
            </Grid>
            <Grid item xs={12}>
              <FormButton type="submit" primary fullWidth>
                {t("Continuar")}
              </FormButton>
            </Grid>
            <Grid item xs={12}>
              <TermsConditions />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default RegisterEmailVerify;
