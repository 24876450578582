import axios from "axios";
import firebase from "firebase/app";
import "firebase/database";
import { ISurveyAnswer } from "dto/survey.dto";

const url = process.env.REACT_APP_BACKEND_URL;
const route = `${url}/api`;

class Survey {
  async update (uuid: string, req: any) {
    await firebase.database().ref(`surveys/${uuid}`).update(req);
    return true;
  };

  async getSurveyByUuid (uuid: string) {
    const { data: { data } } = await axios.get(`${route}/survey/${uuid}`);
    return data[0];
  };

  async postAnswer (uuid: string, answer: ISurveyAnswer) {
    const path = `surveys/${uuid}/answers`;
    const data = await firebase.database().ref(path).push(answer).get();
    return data.key;
  };
}

const survey = new Survey();
const SurveyClass = Object.freeze(survey);
export default SurveyClass;
