import React from 'react';
import ReactPlayer from 'react-player';
import { makeStyles, Grid, Card, CardContent, Typography } from '@material-ui/core';
import { Facebook, Twitter, LinkedIn } from '@material-ui/icons';
import Course from './Course';

const useStyles = makeStyles(() => ({
  courses: { position: 'relative', padding: '0 100px', backgroundColor: '#efefef', },
  card: { width: '100%', position: 'relative', top: -80, },
  content: { padding: '15px 100px 60px !important' },
  text: { color: '#ff6315' },
  course: {
    '&:nth-child(1), &:nth-child(4), &:nth-child(7)': { paddingRight: 16 },
    '&:nth-child(2), &:nth-child(5), &:nth-child(8)': { padding: '0 8px' },
    '&:nth-child(3), &:nth-child(6), &:nth-child(9)': { paddingLeft: 16 },
  },
  network: { marginBottom: 15 },
  text2: { fontWeight: 100, marginBottom: 30 },
}));

const Courses = () => {
  const classes = useStyles();
  
  return (
    <Grid className={classes.courses} container>
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <Typography className={classes.text} align="right" variant="h5" gutterBottom>
            Curso destacado
          </Typography>
          
          <ReactPlayer width="100%" height="100%" url="/videos" controls />
          
          <Grid className={classes.network} container>
            <Grid item container xs={6}>
              <Facebook fontSize="large" />
              <Twitter  fontSize="large" />
              <LinkedIn fontSize="large" />
            </Grid>
            <Grid item xs={6}>
              <Typography align="right" variant="h5">
                <b>Curso de O-lab</b>
              </Typography>
            </Grid>
          </Grid>

          <Typography className={classes.text2} align="justify" variant="h6">
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
            diam nonummy nibh euismod tincidunt ut laoreet dolore
            magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
            quis nostrud exerci tation ullamcorper suscipit lobortis
          </Typography>

          <Grid container>
            {[0, 1, 2].map((i) => (
              <Grid className={classes.course} key={i} item xs={4}>
                <Course />
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default Courses;
