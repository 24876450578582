import { IEntity } from "dto/entity.dto";

export const entityInit: IEntity = {
  nit: "",
  logo: "",
  name: "",
  residence: "",
  icon_color: "",
  theme_apps: "",
  top_bar_color: "",
  background_image: "",
  organization_image: "",
}