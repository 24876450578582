import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
// import { useFormFields, useMailChimpForm } from "use-mailchimp-form";
import { useElements, useStripe, CardElement } from '@stripe/react-stripe-js';
import { Formik, Form, Field } from 'formik';
import { object, string } from 'yup';
import { makeStyles, Grid, TextField, Button, InputAdornment } from '@material-ui/core';
import { Person, Email, Phone } from '@material-ui/icons';
import { IDonation } from '../../services/checkout.services';
import { donationClientSecret } from '../../utils';
import { messageError, messageSuccess } from '../Common/Toast';

const useStyles = makeStyles((theme) => ({
  form: { 
    height: '100%',
    [theme.breakpoints.down("xs")]: {
      height: 'fit-content', 
    },
  },
  content: { 
    height: 'calc(100% - 40px)', 
    padding: 15,
    '& .MuiFormControl-root': {
      marginBottom: 5,
      '& .MuiInputBase-root': { backgroundColor: 'white' },
      '& .MuiFormHelperText-root': { margin: 0 },
      '& .MuiOutlinedInput-notchedOutline': { 
        borderWidth: 1, 
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      '& .MuiFormLabel-root.Mui-focused': { color: 'rgba(0, 0, 0, 0.54)' },
    },
  },
  cardElement: {
    width: '100%',
    margin: '5px 0 8px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    padding: '10px 14px',
    borderRadius: 5,
    backgroundColor: 'white',
  },
  btn: {
    color: 'white',
    backgroundColor: '#ff6315',
    '&:hover': { backgroundColor: '#ff6315', color: 'white' },
  },
  footer: { padding: '0 15px' },
}));

interface IInitValues { name: string, email: string, phone: string }
interface IProps {
  donation: IDonation
  nextSection: () => void
  updateLoading: (load: boolean) => void
}

const Stripe: FC<IProps> = ({donation, nextSection, updateLoading}) => {
  const classes  = useStyles();
  const { t }    = useTranslation();
  const stripe   = useStripe();
  const elements = useElements();

  // const url = "https://originlearningfund.us20.list-manage.com/subscribe/post?u=c560bf951fdea734fa4f6583a&amp;id=fa863f58ee";
  // const { loading, error, success, message, handleSubmit } = useMailChimpForm(url);
  // const [fields, handleFieldChange] = useFormFields({ EMAIL: "" });

  const initValue = { name: '', email: '', phone: '' };
  const validations = object().shape({
    name:  string().required(t('Campo requerido')),
    email: string().required(t('Campo requerido')).email('example@example.com'),
    phone: string().required(t('Campo requerido')),
  });
  const onSubmit = async (values: IInitValues) => {
    const currency = donation.currency === "USD" ? "Dollars" : "Pesos";
    const description = `A new donation worth $${donation.amount} ${currency}`;
    updateLoading(true);

    if (!stripe || !elements) return;
    const card = elements.getElement(CardElement);
    if (card == null) return;
    const billing_details = { name:  values.name, email: values.email, phone: values.phone };

    try {
      const payment = await stripe.createPaymentMethod({type: 'card', card, billing_details});

      if (payment.error) messageError(payment.error.message ?? "");
      else {
        const clientSecret = await donationClientSecret(
          donation.amount, 
          donation.currency, 
          description,
        );
        const { error } = await stripe.confirmCardPayment(clientSecret, {
          payment_method: payment.paymentMethod.id
        });
  
        if (error) messageError(error.message ?? "");
        else {
          messageSuccess("La donación fue exitosa");
          nextSection();
        }
      }
      updateLoading(false);
    }
    catch (e) { updateLoading(false); }
  }

  const onKeyPress = (e: {key: string, preventDefault: () => void}) => {
    const reg = new RegExp('^[0-9]+$');
    if (!reg.test(e.key)) e.preventDefault();
  }

  return (
    <Formik initialValues={initValue} validationSchema={validations} onSubmit={onSubmit}>
      {({errors, touched}) => (
        <Form>
          <Grid className={classes.form} container alignItems="center">
            <Grid className={classes.content} container alignItems="center">
              <Grid container>
                <Field 
                  name="name" 
                  as={TextField}
                  label={t("Nombre")}
                  margin="dense"
                  variant="outlined"
                  error={errors.name && touched.name}
                  helperText={touched.name && errors.name}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Person fontSize="small" color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
                <Field 
                  name="email"
                  as={TextField}
                  type="email"
                  label={t("E-mail")}
                  margin="dense"
                  variant="outlined"
                  error={errors.email && touched.email}
                  helperText={touched.email && errors.email}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email fontSize="small" color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
                <Field 
                  name="phone"
                  as={TextField}
                  label={t("Teléfono")}
                  margin="dense"
                  variant="outlined"
                  onKeyPress={onKeyPress}
                  error={errors.phone && touched.phone}
                  helperText={touched.phone && errors.phone}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Phone fontSize="small" color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
                <CardElement
                  className={classes.cardElement}
                  options={{
                    style: {
                      base: {
                        fontSize: '16px',
                        '::placeholder': { color: 'rgba(0, 0, 0, 0.54)' },
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>

            <Grid className={classes.footer} item xs={12}>
              <Button className={classes.btn} type="submit" variant="contained" fullWidth>
                {t("Donar")}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default Stripe;
