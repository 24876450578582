import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Grid, Avatar, Typography, IconButton } from '@material-ui/core';
import { history } from '../../configureStore';

const useStyles = makeStyles(() => ({
  create: { maxWidth: 510, padding: 15, position: 'relative' },
  iconButton: { width: 150, height: 150 },
  avatar: { width: 100, height: 78 },
  web: { width: 100, height: 68, },
  link: { textDecoration: 'none', color: 'initial' },
}));

const PayStepSix = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const openWeb = () => history.push('/');

  return (
    <Grid container justifyContent="center">
      <Grid className={classes.create} container justifyContent="space-between">
        <Grid item xs={12}>
          <Typography align="center" variant="h6" gutterBottom>
            {t("Puedes acceder y ver los contenidos que se ofrecen")}
          </Typography>
        </Grid>
        <Grid item container justifyContent="center" xs={6}>
          <a 
            className={classes.link} 
            href="https://teachers.o-lab.app" 
            target="_blank"  
            rel="noopener noreferrer"
          >
            <IconButton className={classes.iconButton}>
              <Avatar 
                className={classes.avatar} 
                src="/images/landing_platform.svg" 
                alt="" 
                variant="rounded"
              />
            </IconButton>
          </a>
          <Typography align="center" variant="subtitle2">
            {t("Plataforma de gestión de contenido")}
          </Typography>
        </Grid>
        <Grid item container justifyContent="center" xs={6}>
          <IconButton className={classes.iconButton} onClick={openWeb}>
            <Avatar 
              className={classes.web} 
              src="/images/landing_web.svg" 
              alt="" 
              variant="rounded" 
            />
          </IconButton>
          <Typography variant="subtitle2">{t("Aplicación web")}</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PayStepSix;
