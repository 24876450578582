import React, { FC } from "react";
import { Grid } from "@material-ui/core";
import { IUser } from "dto/user.dto";
import PhoneNumber from "components/Login/PhoneNumber";
import CodeVerification from "components/Login/CodeVerification";
import RegisterPhoneFrom from "./RegisterPhoneForm";
import { userInit } from "initials";
import { ICountry } from "dto/country.dto";

interface IProps {
  code: string;
  confirm: any;
  loading: boolean;
  countries: ICountry[];
  repeatLoading: boolean;
  changeCode: (code: string) => void;
  verifiedPhone: (phone: string, captcha: any) => void;
  registerWithPhone: (code: string, user: IUser) => void;
  updateRepeatLoading: (loading: boolean) => void;
}

const RegisterWithPhone: FC<IProps> = (props) => {
  const { changeCode } = props;
  const [userData, setUserData] = React.useState<IUser>(userInit);
  const [phoneNumber, setPhoneNumber] = React.useState("");

  const getPhoneNumber = (number: string) => setPhoneNumber(number);
  const sendCodeVerification = (code: string) => changeCode(code);

  const registerUser = (user: IUser) => {
    setUserData(user);
    props.registerWithPhone(props.code, user);
  }

  return !!props.confirm && !!props.code ? (
    <RegisterPhoneFrom 
      phone={phoneNumber}
      loading={props.loading}
      userData={userData}
      countries={props.countries}
      repeatLoading={props.repeatLoading}
      registerUser={registerUser}
      updateLoadingLocal={props.updateRepeatLoading}
    />
  ) : !!props.confirm && !props.code ? (
    <Grid item xs={12}>
      <CodeVerification 
        loading={props.loading}   
        phoneNumber={phoneNumber}
        sendCodeVerification={sendCodeVerification} 
      />
    </Grid>
  ) : (
    <Grid item xs={12}>
      <PhoneNumber 
        loading={props.loading}
        getPhoneNumber={getPhoneNumber} 
        sendPhoneNumber={props.verifiedPhone}
      />
    </Grid>
  );
}

export default RegisterWithPhone;
