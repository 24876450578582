import { AppThunkAction } from "store/types";
import { EntityActionType } from "./actions";
import { IEntity } from "dto/entity.dto";

export * from "./actions";
export * from "./constants";

export interface EntityState {
  entity: IEntity;
  entityLoading: boolean;
}

export type Action = AppThunkAction<EntityActionType>
