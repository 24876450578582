import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import { IEntity } from "dto/entity.dto";
import FormButton from "components/Form/FormButton";

const useStyles = makeStyles(() => ({
  h5: { color: "#5f5f5f", fontWeight: 700, marginBottom: 16, },
  subtitle: { 
    color: "#929292", 
    fontSize: 18, 
    fontWeight: 500, 
    marginBottom: 32,
  },
  image: { maxWidth: 250, maxHeight: 150 },
}));

interface IProps {
  entity: IEntity;
  onClose: () => void;
}

const EntityCodeSuccess: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation ();
  const image = !!props.entity?.logo_url 
    ? props.entity?.logo_url 
    : "/img/profile/success.svg";

  return (
    <Grid item xs={12}>
      <img className={classes.image} src={image} alt="" />
      <Typography className={classes.h5} variant="h5">
        {t("¡Todo listo!")}
      </Typography>
      <Typography className={classes.subtitle}>
          {t("Ahora haces parte de la organización")}{" "}{props.entity?.name ?? ""},
          {" "}{t("ya tienes acceso a su contenido y comunidad en O-lab")}.
        </Typography>
      <FormButton primary fullWidth onClick={props.onClose}>
        {t("Continuar")}
      </FormButton>
    </Grid>
  )
}

export default EntityCodeSuccess;
