import * as types from "./types";
import { authInit } from "initials/auth.init";
import { AuthActionType, AuthState } from "./types";

const INITIAL_STATE: AuthState = {
  auth: authInit,
  confirm: null,
  loading: false,
  isNewUser: false,
  confirmCode: null,
  confirmEmail: null,
  loadingLogout: false,
  codeVerification: "",
  verifiedEmailError: false,
  authenticationLoading: false,
}

export const auth = (prevState = INITIAL_STATE, action: AuthActionType) => {
  switch (action.type) {
    case types.AUTH:
      return { ...prevState, auth: action.payload }
    case types.CONFIRM:
      return { ...prevState, confirm: action.payload }
    case types.LOADING:
      return { ...prevState, loading: action.payload }
    case types.IS_NEW_USER:
      return { ...prevState, isNewUser: action.payload }
    case types.CONFIRM_CODE:
      return { ...prevState, confirmCode: action.payload }
    case types.CONFIRM_EMAIL:
      return { ...prevState, confirmEmail: action.payload }
    case types.LOADING_LOGOUT:
      return { ...prevState, loadingLogout: action.payload }
    case types.CODE_VERIFICATION: 
      return { ...prevState, codeVerification: action.payload }
    case types.VERIFIED_EMAIL_ERROR: 
      return { ...prevState, verifiedEmailError: action.payload }
    case types.AUTHENTICATION_LOADING: 
      return { ...prevState, authenticationLoading: action.payload }
    
    default: 
      return prevState
  }
}
