import firebase from "firebase";
import "firebase/auth";
import { IAuth } from "dto/auth.dto";
import { AppThunkAction } from "store/types";
import { AuthActionType } from "./actions";

export * from "./actions";
export * from "./constants";

export interface AuthState {
  auth: IAuth;
  confirm: firebase.auth.ConfirmationResult | null
  loading: boolean;
  isNewUser: boolean;
  confirmCode: firebase.auth.UserCredential | null
  confirmEmail: any;
  loadingLogout: boolean;
  codeVerification: string;
  verifiedEmailError: boolean;
  authenticationLoading: boolean;
}

export type Action = AppThunkAction<AuthActionType>
