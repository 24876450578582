import React, { FC } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LoginCarrusel from "components/Login/LoginCarrusel";

const useStyles = makeStyles(() => ({ 
  root: { 
    height: "100vh",
    overflow: "auto",
    position: "relative", 
  },
}));

interface IProps {
  children: React.ReactNode;
}

const PageLoginCarrusel: FC<IProps> = (props) => {
  const classes = useStyles();

  return (
    <Grid className={classes.root} container>
      <Grid item xs={12} sm={5}>
        {props.children}
      </Grid>
      <Grid item xs={12} sm={7}>
        <LoginCarrusel />
      </Grid>
    </Grid>
  )
}

export default PageLoginCarrusel;
