import React, { FC, useState } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { State } from "store/types";
import { history } from "configureStore";
import * as actions from "store/actions";
import TopBar from "./TopBar";
import ButtonsLayout from "./ButtonsLayout";
import OrganizationImage from "./OrganizationImage";
import DialogLeaveLaboratory from "components/Laboratory/DialogLeaveLaboratory";

const useStyles = makeStyles((theme) => ({
  buttons: {
    gap: 32,
    width: "fit-content",
  },
}));

interface IProps {
  zoom?: boolean;
  topBarColor?: string;
  buttonLanguage?: boolean;
  organizationImage?: string;
  hideButtonLanguage?: boolean;
}

const AppBarLayout: FC<IProps> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state: State) => state.user.user);
  const entity = useSelector((s: State) => s.entity.entity);
  const module = useSelector((state: State) => state.module.module);
  const challenger = useSelector((state: State) => state.challenger.challenger);
  const laboratory = useSelector((state: State) => state.laboratory.laboratory);
  const anonymousUser = useSelector((s: State) => s.anonymousUser.anonymousUser);
  const [url, setUrl] = useState("");
  const [dialogLeaveLaboratory, setDialogLeaveLaboratory] = useState(false);
  const location = history.location.pathname;
  const laboratoryOpen = !!module?.name && !!challenger?.name;
  const topBarColor = !!props.topBarColor ? props.topBarColor : entity?.top_bar_color;
  const organizationImage = !!props.organizationImage 
    ? props.organizationImage 
    : entity?.organization_image_url;

  const handleLogout = () => dispatch(actions.handleLogout());

  const redirectHome = () => {
    if (location !== "/home" && !!laboratoryOpen) {
      openDialogLeaveLaboratory("/home");
    } else if (location !== "/home" && !!laboratory?.name) {
      dispatch(actions.resetSurveyPublic());
      dispatch(actions.deselectLaboratory());
      dispatch(actions.resetSurveyCurrent());
      history.push("/home");
    } else history.push("/home");
  }

  const openDialogLeaveLaboratory = (url: string) => {
    setUrl(url);
    setDialogLeaveLaboratory(true);
  }
  const closeDialogLeaveLaboratory = () => {
    setUrl("");
    setDialogLeaveLaboratory(false);
  }
  const openPageSelected = () => {
    history.push(url);
    closeDialogLeaveLaboratory();
    dispatch(actions.resetSurveyPublic());
    dispatch(actions.deselectLaboratory());
    dispatch(actions.resetSurveyCurrent());
  }

  return (
    <TopBar zoom={props.zoom} topBarColor={topBarColor}>
      <OrganizationImage 
        topBarColor={!!topBarColor}
        organizationImage={organizationImage}
        onClick={redirectHome} 
      />
      
      <Grid className={classes.buttons} container>
        <ButtonsLayout 
          user={user}
          entity={entity}
          anonymousUser={anonymousUser}
          laboratoryOpen={laboratoryOpen}
          hideButtonLanguage={props.hideButtonLanguage}
          logout={handleLogout}
          redirectHome={redirectHome}
          openDialogLeaveLaboratory={openDialogLeaveLaboratory}
        />
      </Grid>

      <DialogLeaveLaboratory
        open={dialogLeaveLaboratory}
        onClose={closeDialogLeaveLaboratory}
        openPageSelected={openPageSelected}
      />
    </TopBar>
  )
}

export default AppBarLayout;
