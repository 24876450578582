import React, { FC } from "react";
import clsx from "clsx";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Loading from "components/Common/Loading";
import Background from "components/Common/Background";
import AppBarLayout from "layouts/AppBarLayout";

const useStyles = makeStyles((theme) => ({ 
  root: { 
    height: "100vh",
    [theme.breakpoints.down("xs")]: {  height: "calc(100vh - 50px)" },
  },
  view: { 
    height: "calc(100% - 70px)", 
    position: "relative", 
    backgroundColor: "white",
    "&.zoom": { height: "100%" }
  },
  page: { 
    height: "100%",
    position: "relative", 
    overflow: "auto",
    alignItems: "center",
    justifyContent: "center",
  },
}));

interface IProps {
  zoom?: boolean;
  loading?: boolean;
  background?: string;
  organizationImage?: string;
  hideButtonLanguage?: boolean;
}

const PageRoot: FC<IProps> = (props) => {
  const classes = useStyles();

  return (
    <Grid className={classes.root} container>
      <AppBarLayout 
        zoom={props.zoom} 
        organizationImage={props.organizationImage}
        hideButtonLanguage={props.hideButtonLanguage} 
      />

      <Grid className={clsx(classes.view, !!props.zoom && "zoom")} container>
        <Background backgroundImage={props.background} />

        <Grid className={classes.page} container>
          {props.children}
        </Grid>
      </Grid>

      <Loading loading={props.loading} />
    </Grid>
  )
}

export default PageRoot;
