import React, { FC } from "react";
import { Group, Rect } from "react-konva";

interface IProps {
  fill?: string;
  width: number;
  height: number;
  cornerRadius?: number | number[];
}

const TemplateStageBackground: FC<IProps> = (props) => {
  return (
    <Group
      x={0}
      y={0}
      width={props.width}
      height={props.height}
    >
      <Rect
        x={0}
        y={0}
        fill={props.fill}
        width={props.width}
        height={props.height}
        cornerRadius={props.cornerRadius}
      />
      {props.children}
    </Group>
  );
};

export default TemplateStageBackground;
