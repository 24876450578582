import React, { FC, MouseEvent } from "react";
import ReactCountryFlag from "react-country-flag"
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { ILanguage, TypeLanguageCode } from "dto/layout.dto";
import { 
  Grow, 
  Paper, 
  Popper, 
  MenuList,
  MenuItem,
  ClickAwayListener,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  menu: { 
    width: 100,
    "& .MuiMenuItem-root": { 
      display: "flex", 
      justifyContent: "center", 
      "& img": { fontSize: 20 },
    },
  },
}));

interface IProps {
  open: boolean;
  anchorRef: React.RefObject<HTMLButtonElement>;
  updateLanguage: (code: TypeLanguageCode) => void
  closeLanguageMenu: (e: MouseEvent<EventTarget>) => void;
  toggleLanguageMenu: () => void;
}

const LanguageMenu: FC<IProps> = (props) => {
  const { open, anchorRef, closeLanguageMenu } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const languages: ILanguage[] = [
    { code: "es", flag: "ES", label: t("Idioma español"), }, 
    { code: "en", flag: "US", label: t("Idioma inglés"), }, 
    { code: "fr", flag: "FR", label: t("Idioma francés"), }, 
    { code: "ur", flag: "PK", label: t("Idioma urdu"), }, 
    { code: "fil", flag: "PH", label: t("Idioma tagalo"), }, 
    { code: "ar", flag: "AR", label: t("Idioma Árabe"), }, 
  ];

  return (
    <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} style={{ transformOrigin: "left bottom" }}>
          <Paper>
            <ClickAwayListener onClickAway={closeLanguageMenu}>
              <MenuList className={classes.menu} autoFocusItem={open}>
                {languages.map((language, i) => (
                  <MenuItem 
                    key={i}
                    onClick={() => {
                      props.updateLanguage(language.code);
                      props.toggleLanguageMenu();
                    }}
                    aria-label={language.label}
                  >
                    <ReactCountryFlag countryCode={language.flag} svg />
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  )
}

export default LanguageMenu;
