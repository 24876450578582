import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import { date, object, string } from "yup";
import { IUser } from "dto/user.dto";
import { ICountry } from "dto/country.dto";
import { userInit } from "initials";
import * as utils from "utils";
import FormButton from "components/Form/FormButton";
import TermsConditions from "../TermsConditions";
import FormFieldAsSelect from "components/Form/FormFieldAsSelect";
import FormFieldAsTextField from "components/Form/FormFieldAsTextField";

const useStyles = makeStyles(() => ({
  h5: { 
    color: "#5f5f5f", 
    fontWeight: 700, 
  },
}));

interface IInitialValues {
  sex: string;
  date: string;
  name: string;
  nationality: string;
}

interface IProps {
  user: IUser;
  email: string;
  password: string;
  countries: ICountry[];
  updateUser: (user: IUser) => void;
  openNextStep: () => void;
  updateLoadingLocal: (loading: boolean) => void;
}

const RegisterEmailForm: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const genders = utils.genders?.map((g) => ({ label: g.label, value: g.value }));
  const countries = props.countries?.map((c) => ({ label: c.label, value: c.value }));
  const dateMin = new Date("01-01-1960");

  const initialValues: IInitialValues = {
    sex: props.user.sex,
    date: props.user.date ?? "",
    name: props.user.name,
    nationality: props.user.nationality,
  }
  const validationSchema = object().shape({
    sex: string().required(t("Campo requerido")),
    name: string().required(t("Campo requerido")),
    date: date()
      .required(t("Fecha de nacimiento incorrecta"))
      .min(dateMin, "Fecha de nacimiento incorrecta")
      .test(
        "global-ok",
        t("Edad minima 7 años"),
        async (date) => utils.getMinAge(date ?? "", 7)
      ),
    nationality: string().required(t("Campo requerido")),
  });
  const onSubmit = async (values: IInitialValues) => {
    const date = new Date(values?.date);
    const user: IUser = {
      ...userInit,
      age: Number(utils.getAge(values?.date)),
      rol: "Estudiante",
      sex: values.sex,
      date: date.toString(),
      name: values.name,
      admin: false,
      email: props.email,
      nationality: values.nationality,
    }
    props.updateUser(user);
    props.openNextStep();
  }

  return (
    <Formik 
      initialValues={initialValues} 
      validationSchema={validationSchema} 
      onSubmit={onSubmit}
    >
      {({errors, touched, values}) => (
        <Form>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography 
                className={classes.h5} 
                align="center" 
                variant="h5"
              >
                {t("Completa tus datos")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormFieldAsTextField 
                name="name"
                title="Nombre completo"
                error={errors.name}
                touched={touched.name}
                fullWidth
                placeholder="Ingresa tu nombre"
              />
            </Grid>
            <Grid item xs={12}>
              <FormFieldAsSelect 
                name="sex"
                items={genders}
                error={errors.sex}
                title="Género"
                initial={!values.sex}
                touched={touched.sex}
                placeholder="Elige tu género"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormFieldAsTextField 
                name="date"
                type="date"
                title="Fecha de nacimiento"
                error={errors.date}
                touched={touched.date}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormFieldAsSelect 
                name="nationality"
                items={countries}
                error={errors.nationality}
                title="País"
                initial={!values.nationality}
                touched={touched.nationality}
                placeholder="Elige tu país"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormButton primary type="submit" fullWidth>
                {t("Continuar")}
              </FormButton>
            </Grid>
            <Grid item xs={12}>
              <TermsConditions />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>   
  )
}

export default RegisterEmailForm;
