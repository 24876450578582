const svgImage = (c1: string, c2: string, c3: string) => `
<svg width="90" height="90" viewBox="0 0 90 91" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M45 90.1537C69.8528 90.1537 90 69.9721 90 45.0769C90 20.1816 69.8528 0 45 0C20.1472 0 0 20.1816 0 45.0769C0 69.9721 20.1472 90.1537 45 90.1537Z"
    fill="${c2}"
  />
  <path
    d="M45 84.5729C66.7758 84.5729 84.4286 66.8899 84.4286 45.077C84.4286 23.264 66.7758 5.58104 45 5.58104C23.2242 5.58104 5.57144 23.264 5.57144 45.077C5.57144 66.8899 23.2242 84.5729 45 84.5729Z"
    fill="${c3}"
  />
  <path
    d="M45 78.8846C63.6396 78.8846 78.75 63.7484 78.75 45.077C78.75 26.4055 63.6396 11.2693 45 11.2693C26.3604 11.2693 11.25 26.4055 11.25 45.077C11.25 63.7484 26.3604 78.8846 45 78.8846Z"
    fill="${c1}"
  />
  <path
    d="M65.7857 37.7788C67.9286 35.6323 67.9286 32.0905 65.7857 29.8367C63.6429 27.5828 60.1071 27.6902 57.8571 29.8367L35.25 52.4824C33.1072 54.6289 33.1072 58.1707 35.25 60.4245C37.3929 62.6784 40.9286 62.5711 43.1786 60.4245L65.7857 37.7788Z"
    fill="${c3}"
  />
  <path
    d="M35.3571 60.3172C37.5 62.4638 41.0357 62.4638 43.2857 60.3172C45.5357 58.1707 45.4286 54.6289 43.2857 52.3751L32.0357 41.1059C29.8929 38.9594 26.3571 38.9594 24.1071 41.1059C21.9643 43.2524 21.9643 46.7942 24.1071 49.048L35.3571 60.3172Z"
    fill="${c3}"
  />
</svg>
`;

export const SvgShape03 = (c1?: string, c2?: string, c3?: string) => {
  const a = !!c1 ? c1 : "#37d31c";
  const b = !!c2 ? c2 : "#cdcdcd";
  const c = !!c3 ? c3 : "#ffffff";
  return `data:image/svg+xml;charset=UTF-8;base64,${btoa(svgImage(a, b, c))}`;
};

export default SvgShape03;
