import { AppThunkAction } from "store/types";
import { IUserAnonymous } from "dto/user-anonymous.dto";
import { AnonymousUserActionType } from "./actions";

export * from "./actions";
export * from "./constants";

export interface AnonymousUserState {
  anonymousUser: IUserAnonymous;
  anonymousUserLoading: boolean;
}

export type Action = AppThunkAction<AnonymousUserActionType>;
