import React, { FC } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  typeUnity: { 
    height: "100%", 
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "center",
  },
}));

interface IProps {
  question: string;
}

const Type22: FC<IProps> = (props) => {
  const classes = useStyles();

  return (
    <Grid className={classes.typeUnity} container>
      <iframe
        id="type_unity"
        src={props.question}
        title="Type Unity"
        width="100%"
        height="100%"
        style={{ border: 0 }}
      />
    </Grid>
  )
}

export default Type22;
