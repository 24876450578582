export const servicesTable = [
  "Plataforma web personalizable",
  "Aplicación móvil personalizable",
  "Sistema de gestión de aprendizaje (LMS)",
  "Funciones de seguimiento y evaluación",
  "Soporte técnico virtual",
];

// export const servicesTable = [
//   "Asistencia técnica y de soporte",
//   "Análisis y reportes automáticos",
//   "Mensajes de audio personalizables",
// ];

export const servicesOpt = [
  "Asistencia en coordinación de proyectos",
  "Diseño y animación de avatares y más",
  "Reportes de impacto específicos",
  "Sistema de gestión de proyectos",
  "Voluntariado corporativo",
  "Asistencia pedagógica",
  "Diseño de contenidos",
  "Doblaje de voz",
  "Otros",
];