const svgImage = (c1: String) => `
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 1080 1080">
    <defs>
      <style>
        .cls-1 {
          fill: #546f7a;
        }
        .cls-2 {
          fill: #c7c7c7;
        }
        .cls-3 {
          fill: #dfddde;
        }
      </style>
    </defs>
    <!-- Generator: Adobe Illustrator 28.7.1, SVG Export Plug-In . SVG Version: 1.2.0 Build 142)  -->
    <g>
      <g id="Capa_1">
        <g>
          <path class="cls-2" d="M540,28C257.23,28,28,257.23,28,540s229.23,512,512,512,512-229.23,512-512S822.77,28,540,28ZM540,1008.04c-258.49,0-468.04-209.55-468.04-468.04S281.51,71.96,540,71.96s468.04,209.55,468.04,468.04-209.55,468.04-468.04,468.04Z"/>
          <g>
            <g>
              <circle class="cls-3" cx="540" cy="540" r="417.62" transform="translate(-223.68 540) rotate(-45)"/>
              <circle class="cls-3" cx="540" cy="540" r="417.62" transform="translate(-223.68 540) rotate(-45)"/>
            </g>
            <path class="cls-1" d="M430.51,662.49c-2.66,3.84-2.66,8.85-3.51,13.45-3.53,19.04-21.8,31.18-38.52,40.94-54.83,32.02-109.69,63.48-163.27,97.38,76.56,87.8,189.15,143.36,314.79,143.36,131.95,0,249.53-61.26,326.06-156.82-46.33-24.01-92.65-48.01-138.98-72.02-10.89-5.64-21.81-11.3-33.24-15.73-8.99-3.48-18.53-6.33-25.89-12.56-11.48-9.7-8.71-25.33-11.76-38.65-.82-3.64-1.7-7.43-4.02-10.35-5.44-6.84-16.5-6.48-22.52-12.82-3.65-3.84-4.74-9.45-4.85-14.74-.07-3.36.21-6.84,1.82-9.78,1.91-3.5,6.26-5.24,9.28-7.72,3.2-2.63,6.52-4.96,9.95-7.29,7.28-4.97,14.03-10.34,17.43-18.76,4.94-12.25,8.28-26.47,9.72-39.57,1.53-13.88-1.55-30.35,4.39-43.44,5.72-12.62,17.62-18.26,20.28-32.97,2.27-12.54-.03-28.74-5.08-40.33-2.36-5.41-6.12-8.83-9.33-13.48-3.39-4.92-1.19-10.83-.48-16.53,2.15-17.12-1.52-34.37-5.17-51.23-1.29-5.98-2.6-11.99-4.81-17.7-4.62-11.95-13.38-22.17-24.24-28.91-5.49-3.41-11.78-4.94-17.27-8.16-6.06-3.56-12.27-6.46-18.78-9.23-14.43-6.13-29.22-11.53-44.43-15.37-29.73-7.51-61.89-8.82-90.21,4.54-14.66,6.94-28.54,19.28-30.1,35.42-20.71-5.87-44.79,3.75-55.76,22.27-8.74,14.75-10.5,31.88-9.72,48.69.71,15.41,6.21,29.37,8.45,44.66.62,4.23,1.15,8.9-1.21,12.46-1.98,2.99-5.65,4.57-7.5,7.64-1.37,2.26-1.56,5.02-1.71,7.66-1.12,19.06-1.89,39.58,8.41,55.66,1.45,2.27,3.13,4.43,5.25,6.08,1.67,1.3,3.59,2.26,5.39,3.39,8.75,5.46,14.56,14.76,17.79,24.56,3,9.09,2.51,18.57,4.85,27.64,1.9,7.39,4.32,15.48,6.97,22.72,2.23,6.08,6.31,9.76,10.79,14.08,4.66,4.49,7.45,9.64,14.18,11.52.4,1.34,1.11,2.57,1.65,3.86,1.42,3.41,1.56,7.2,1.69,10.91.23,6.72.87,17.35-3.72,22.8-5.77,6.84-17.63,4.68-23.05,12.5Z"/>
          </g>
        </g>
      </g>
    </g>
  </svg>
`;

export const ProfileSvg = (c1?: string) => {
  const a = !!c1 ? c1 : "#ff6300";
  return `data:image/svg+xml;charset=UTF-8;base64,${btoa(svgImage(a))}`;
};

export default ProfileSvg;
