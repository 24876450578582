import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, IconButton } from "@material-ui/core";
import FormTypography from "components/Form/FormTypography";

const useStyles = makeStyles((theme) => ({
  buttons: { 
    width: "fit-content",
    border: "2px solid #0aa9e2",
    padding: "8px 18px",
    borderRadius: 12,
    backgroundColor: "#ebfaff",
  },
  content: { 
    width: 86, 
    height: 24, 
    alignItems: "center", 
    justifyContent: "space-between", 
  },
  button: { padding: 0, borderRadius: 4 },
}));

interface IProps {
  numberPage: number;
  numberPages: number;
  onClickPrev?: () => void;
  onClickNext?: () => void;
}

const NotebookButtonsPages: FC<IProps> = (props) => {
  const classes = useStyles();

  return (
    <Grid className={classes.buttons} container>
      <Grid className={classes.content} container>
        <IconButton className={classes.button} onClick={props.onClickPrev}>
          <img src="/img/icons/prev_blue.svg" alt="" />
        </IconButton>
        <FormTypography color="#0aa9e2" variant="body2">
          {props.numberPage}/{props.numberPages}
        </FormTypography>
        <IconButton className={classes.button} onClick={props.onClickNext}>
          <img src="/img/icons/next_blue.svg" alt="" />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default NotebookButtonsPages;
