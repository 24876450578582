import React, { FC } from "react";
import clsx from "clsx";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { RootRef, makeStyles, Card, Typography } from "@material-ui/core";
import { soundClick } from "utils";

const useStyles = makeStyles(() => ({
  answer: {
    border: "1px solid rgba(0, 0, 0, 0.3)",
    margin: "0 4px",
    minWidth: 70,
    maxWidth: 70,
    position: "relative",
    minHeight: 25,
    maxHeight: 30,
    borderRadius: 10,
  },
  skyp: { border: 0, maxWidth: "fit-content", minWidth: 50 },
  card: {
    border: "1px solid rgba(0, 0, 0, 0.3)",
    padding: "1px 4px", 
    boxShadow: "none", 
    textAlign: "center",
    borderRadius: 10, 
    "&.correct": { color: "#50c988", borderColor: "#50c988", },
    "&.incorrect": { color: "#f25b5b", borderColor: "#f25b5b", },
  },
  placeholder: { 
    top: 0,
    left: 0,
    right: 0,
    color: "#ccc",
    bottom: 0,
    position: "absolute",
    paddingLeft: 5,
  }
}));

interface IProps {
  answer: { id: string, answer: string };
  correct?: boolean;
  feedback?: boolean;
  notFeedback?: boolean;
}

const DroppableItem: FC<IProps> = ({ answer, correct, feedback, notFeedback }) => {
  const classes = useStyles();

  return (
    <Droppable droppableId={answer.id}>
      {(provided) => (
        <RootRef rootRef={provided.innerRef}>
          <div className={clsx(classes.answer, !!answer.answer && classes.skyp)}>
            {!!answer.answer && (
              <Draggable 
                index={1} 
                draggableId={answer.id} 
                isDragDisabled={!!feedback}
              >
                {(provided) => (
                  <RootRef rootRef={provided.innerRef}>
                    <Card 
                      className={clsx(
                        classes.card,
                        !!feedback && !notFeedback &&  !!correct && "correct",
                        !!feedback && !notFeedback && !correct && "incorrect",
                      )}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      onMouseDown={soundClick}
                    >
                      <Typography noWrap>{answer.answer}</Typography>
                    </Card>
                  </RootRef>
                )}
              </Draggable>
            )}
            {provided.placeholder}
          </div>
        </RootRef>
      )}
    </Droppable>
  )
}

export default DroppableItem;
