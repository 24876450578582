import { Action } from "./types";
import * as types from "./types";
import { ISurvey } from "dto/survey.dto";
import { surveyInit } from "initials";

export const setSurveyFree = (value: ISurvey): Action => (dispatch) => {
  dispatch({ type: types.SURVEY_FREE, payload: value });
}
export const resetSurveyFree = (): Action => (dispatch) => {
  dispatch(setSurveyFree(surveyInit));
}
