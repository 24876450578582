import React, { FC } from "react";
import clsx from "clsx";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  content: { 
    height: "calc(100% - 50px)",
    borderRadius: 20,
    backgroundColor: "white",
    [theme.breakpoints.down("xs")]: { 
      height: "calc(100% - 60px)",
      borderRadius: 0,
    },
  },
  zoom: { height: "calc(100% - 60px)", borderRadius: 0 },
}));

interface IProps {
  zoom?: boolean;
}

const NotebookContent: FC<IProps> = ({ zoom, children }) => {
  const classes = useStyles();
  const className = clsx(classes.content, !!zoom && classes.zoom);

  return (
    <Grid className={className} container>
      {children}
    </Grid>
  )
}

export default NotebookContent;
