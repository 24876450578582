import { Action } from "./types";
import EntityCode from "services/entity-code.services";
import { IEntityCode } from "dto/entity-code.dto";
import { IUser, IUserRequest } from "dto/user.dto";

export const addUserToUsersGroup = (entityCode: IEntityCode, user: IUser): Action => async () => {
  try {
    const data = entityCode?.users ?? [];
    const userNew: IUserRequest = {
      nit: user.nit,
      email: user.email,
      phone: user.phone,
    }
    const users = [...data, userNew];
    await EntityCode.update(entityCode.uuid, { users });
  } catch (error) {
    console.log("Error Add User To Users Group: ", error);
  }
}
