import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ReactCodeInput from "react-code-input";

const useStyles = makeStyles(() => ({
  code: {
    display: "flex !important",
    justifyContent: "space-between",
    "& input": {
      width: 52,
      color: "#5f5f5f",
      height: 64,
      border: "2px solid #f0f0f0",
      fontSize: 40,
      textAlign: "center",
      fontWeight: 700,
      fontFamily: "Montserrat",
      borderRadius: 8,
      "&:focus": { outline: "unset" },
      "&:input[type=number]": { MozAppearance: "textfield" },
    },
    "& input[type=number]::-webkit-inner-spin-button,": {
      WebkitAppearance: "none",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
    },
    "& input[type=number]": { MozAppearance: "textfield" },
  },
}));

interface IProps {
  name?: string;
  type?: "number" | "text" | "tel" | "password",
  fields?: number;
  inputMode?: "tel" | "email" | "numeric";
  marginBottom?: string | number;
  onChange: (value: string) => void;
}

const FormInputCode: FC<IProps> = (props) => {
  const classes = useStyles();

  return (
    <ReactCodeInput 
      className={classes.code} 
      name={props.name ?? ""}
      type={props.type} 
      fields={props.fields ?? 6} 
      inputMode={props.inputMode ?? "email"}
      onChange={props.onChange}
    />
  )
}

export default FormInputCode;
