import React, { FC, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, CircularProgress } from "@material-ui/core";
import { CloudDownload } from "@material-ui/icons";
import Files from "services/files.services";
import { messageError, messageSuccess } from "components/Common/Toast";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  type17: {
    height: "100%", 
    padding: 16, 
    alignItems: "center",
  },
  button: { color: "white", backgroundColor: "#9fdfe5" },
  circular: { color: "#ff6300" }
}));

interface IProps {
  fileUrl: string;
  fileName: string;
}

const Type17: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const downloadFile = async () => {
    if (!!props.fileName && !!props.fileUrl) {
      setLoading(true);
      await Files.downloadFileAny(props.fileName, props.fileUrl);
      setLoading(false);
      messageSuccess("Descarga de archivo correcta");
    } else messageError("Descarga de archivo fallida");
  };

  return (
    <Grid className={classes.type17} container>
      <Button
        className={classes.button}
        variant="contained"
        component="label"
        startIcon={!loading && <CloudDownload />}
        onClick={downloadFile}
        disabled={loading}
        fullWidth
      >
        {loading ? (
          <CircularProgress className={classes.circular} size={25} />
        ) : (
          t("Descargar archivo")
        )}
      </Button>
    </Grid>
  )
}

export default Type17;
