import React, { FC } from "react";
import TopBar from "./TopBar";
import OrganizationImage from "./OrganizationImage";

interface IProps {
  zoom?: boolean;
  buttonsLayout?: boolean;
  buttonLanguage?: boolean;
  organizationImage?: string;
}

const AppBarDefault: FC<IProps> = (props) => {
  return (
    <TopBar zoom={props.zoom} borderBottom={0}>
      <OrganizationImage organizationImage={props.organizationImage} />
    </TopBar>
  )
}

export default AppBarDefault;
