export const AUTH = "[AUTH] SET_AUTH";
export const CONFIRM = "[AUTH] SET_CONFIRM";
export const LOADING = "[AUTH] SET_LOADING";
export const IS_NEW_USER = "[AUTH] SET_IS_NEW_USER";
export const CONFIRM_CODE = "[AUTH] SET_CONFIRM_CODE";
export const CONFIRM_EMAIL = "[AUTH] SET_CONFIRM_EMAIL";
export const LOADING_LOGOUT = "[AUTH] SET_LOADING_LOGOUT";
export const CODE_VERIFICATION = "[AUTH] SET_CODE_VERIFICATION";
export const VERIFIED_EMAIL_ERROR = "[AUTH] SET_VERIFIED_EMAIL_ERROR";
export const AUTHENTICATION_LOADING = "[AUTH] AUTHENTICATION_LOADING";
