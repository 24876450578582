import React, { ChangeEvent, FC, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { IGender } from "dto/gender.dto";
import * as utils from "utils";
import InformationOutside from "./InformationOutside";
import { 
  Grid,
  Avatar,
  Select, 
  MenuItem, 
  IconButton,
  Typography, 
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  field: {
    padding: "12px 16px",
    border: "2.6px solid #f0f0f0",
    alignItems: "center",
    borderRadius: 16,
  },
  edit: { position: "relative", zIndex: 2, borderColor: "#37d31c" },
  avatar: {
    width: 60,
    height: 60,
    marginRight: 16,
    borderRadius: 16,
  },
  content: { 
    width: "calc(100% - 116px)", 
    alignItems: "center",
  },
  h6: { color: "#5f5f5f", fontWeight: 700, },
  body1: { color: "#8d8d8d", fontWeight: 500 },
  select: {
    "&:after": { display: "none" },
    "&:before": { display: "none" },
    "& .MuiSelect-select": { padding: "0 24px 0 0" },
  },
  button: { 
    padding: 0,
    marginLeft: 8,
    "& .MuiAvatar-root": { width: 32,  height: 32, },
  },
}));

interface IProps {
  value: string;
  genders: IGender[];
  updateProfile: (name: string, value: string, c: () => void) => void
}

const FieldSex: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);
  const label = utils.getGenderLabel(props.value);

  const toogleEdit = () => setEdit((v) => !v);
  const onChange = ({ target: { value } }: ChangeEvent<{ value: unknown }>) => {
    props.updateProfile("sex", String(value), toogleEdit);
  }

  return (
    <>
      <Grid className={clsx(classes.field, edit && classes.edit)} container>
        <Avatar className={classes.avatar} src="/img/profile/sex.svg" alt="" />
        <Grid className={classes.content} container>
          <Grid item xs={12}>
            <Typography className={classes.body1} noWrap>
              {t("Género")}
            </Typography>
            {edit ? (
              <Select
                className={classes.select}
                value={utils.getGenderValue(props.value)}
                onChange={onChange}
                fullWidth
              >
                {props.genders?.map((gender, key) => (
                  <MenuItem key={key} value={utils.getGenderValue(gender.id)}>
                    <Typography className={classes.h6} variant="h6" noWrap>
                      {t(gender.label)}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <Typography className={classes.h6} variant="h6" noWrap>
                {t(label)}
              </Typography>
            )}
          </Grid>
        </Grid>
        <IconButton className={classes.button} onClick={toogleEdit}>
          <Avatar src="/img/profile/edit_field.svg" alt="" />
        </IconButton>
      </Grid>

      {edit && (<InformationOutside onClick={toogleEdit} />)}
    </>
  )
}

export default FieldSex;
