import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import { object, string, ref } from 'yup';
import { makeStyles, Grid, Button, Typography } from '@material-ui/core';
import { InputAdornment, IconButton, TextField } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { IUser } from "dto/user.dto";
import { TeacherRules } from '../../roles';
import Loading from '../Common/Loading';
import { IEntity } from 'dto/entity.dto';
import { entityInit } from 'initials/entity.init';
import { userInit } from 'initials';

const useStyles = makeStyles(() => ({
  create: { maxWidth: 380, padding: 15, position: 'relative' },
  accept: {
    color: 'white',
    marginTop: 8,
    backgroundColor: '#ff6315',
    '&:hover': { backgroundColor: 'white', color: '#ff6315' },
  },
}));

interface IInitValues { email: string, confirm: string, password: string };

interface IProps {
  info: { name: string, email: string, phone: string, entity: string }
  loading: boolean
  nextStep: () => void
  createUserEntity: (user: IUser, entity: IEntity, password: string, callback: () => void) => void
}

const CreateUser: FC<IProps> = ({info, loading, nextStep, createUserEntity}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [password, setPassword] = React.useState(true);
  const initValues: IInitValues = { email: info.email, confirm: '', password: '' }

  const changePassword = () => setPassword(!password);

  const validations = object().shape({
    email: string().required(t('Campo requerido')).email('example@example.com'),
    confirm: string().required(t('Campo requerido'))
    .oneOf([ref('password'), null], t('Las contraseñas deben coincidir')),
    password: string().required(t('Campo requerido')).min(6, 'Mínimo 6 caracteres'),
  });
  const onSubmit = async (values: IInitValues) => {
    const user: IUser = {
      ...userInit,
      age:     1,
      rol:     "Docente",
      name:    info.name,
      email:   values.email,
      phone:   info.phone,
      sponsor: info.entity,
      permissionsPlatform: TeacherRules,
    };
    const entity: IEntity = {
      ...entityInit,
      name: info.entity,
      phone: info.phone,
      legal__representative: info.name,
    };
    createUserEntity(user, entity, values.password, nextStep);
  }

  return (
    <Grid container justifyContent="center">
      <Grid className={classes.create} container>
        {loading && (<Loading white loading />)}
        <Formik initialValues={initValues} validationSchema={validations} onSubmit={onSubmit}>
          {({errors, touched}) => (
            <Form>
              <Typography variant="h6" align="center" gutterBottom>
                {t("Completa tu registro")}
              </Typography>
              <Field 
                name="email"
                as={TextField}
                label={t("Correo electrónico")}
                margin="dense"
                variant="outlined"
                error={errors.email && touched.email}
                helperText={touched.email && errors.email}
                fullWidth
              />
              <Field 
                name="password"
                as={TextField}
                label={t("Contraseña")}
                margin="dense"
                variant="outlined"
                type={!!password ? 'password' : 'text'}
                error={errors.password && touched.password}
                helperText={touched.password && errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton size="small" onClick={changePassword}>
                        {!!password ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
              <Field 
                name="confirm"
                as={TextField}
                label={t("Confirmar contraseña")}
                margin="dense"
                variant="outlined"
                type={!!password ? 'password' : 'text'}
                error={errors.confirm && touched.confirm}
                helperText={touched.confirm && errors.confirm}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton size="small" onClick={changePassword}>
                        {!!password ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
              <Button 
                className={classes.accept} 
                type="submit" 
                variant="contained"
                fullWidth
              >
                {t("Completar registro")}
              </Button>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  )
}

export default CreateUser;
