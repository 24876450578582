import axios from "axios";
import firebase from "firebase/app";
import "firebase/database";
import { IEntity } from "dto/entity.dto";

const url = process.env.REACT_APP_BACKEND_URL;
const route = `${url}/api`;

class Entity {
  async get(): Promise<IEntity[]> {
    const { data } = await axios.get(`${route}/static/entities`);
    return data?.data ?? [];
  }

  async post(entity: IEntity) {
    await firebase.database().ref(`entities/${entity.nit}`).set(entity);
  };
}

const entity = new Entity();
const EntityClass = Object.freeze(entity);
export default EntityClass;
